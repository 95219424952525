<template>
    <div>
        <pc-solutions v-if="responsive == 'PC'"></pc-solutions>
        <mobile-solutions v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-solutions>
    </div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcSolutions from "./pc/Solutions";
import MobileSolutions from "./mobile/Solutions";

export default {
    data() {
        return {

        }
    },
    created() {
        this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/solutions.md')
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcSolutions,MobileSolutions
    },
    watch: {

    },
    methods: {

    },
}
</script>
