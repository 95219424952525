<template>
    <div class="bg-gradient">
        <div class="grid-list-xl pa-0 pt-0 px-0">
            <v-row align="center" justify="center" class="mx-0 mt-12" id="ieo-header" v-if="responsive == 'PC'">
               <v-carousel cycle height="380" hide-delimiter-background :show-arrows="false" :interval="6000">
                    <!-- <v-carousel-item>
                        <router-link to="/dividend/0x6130ff302f24267380753e52ece48b852be3dd38" class="del-underline white--text pointer">
                            <v-row justify="center" align="center" class="pb-0">
                                <v-col cols="12" class="text-center py-12">
                                    <div class="py-5 fs-28">{{ $t('dividend.iobDividend') }}</div>
                                    <div class="py-5 fs-28">{{ $t('dividend.investorsDividendText') }}</div>
                                    <div class="fs-48">{{ $t('dividend.requestNow') }}</div>
                                </v-col>
                            </v-row>
                        </router-link>
                    </v-carousel-item> -->
                   <v-carousel-item>
                        <router-link to="/staking" class="del-underline white--text pointer">
                            <v-row justify="center" class="pb-0">
                                <v-col cols="12" class="text-center py-12">
                                    <div class="pt-5 pb-12 fs-22">{{ $t('pool.defyLiquidityPoolStaking') }}</div>
                                    <!-- <div class="fs-28">{{ $t('pool.poolTradingProfit') }} ({{ $t('pool.currentlyUpAnnualized1') + ' ' + (defyAnnualizedReturn * 100).toFixed(2) }}% {{ $t('pool.currentlyUpAnnualized2') }})</div> -->
                                    <div class="fs-28">{{ $t('pool.poolTradingProfit') }} ({{ $t('pool.firstYearEnding') }})</div>
                                    <div class="fs-36">+</div>
                                    <div class="fs-48 title_txt">
                                        <span>18% <span class="fs-22">APY</span> {{ $t('pool.inFiTokens') }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </router-link>
                    </v-carousel-item>
                    <v-carousel-item>
                        <a href="https://app.uniswap.org/#/add/v2/ETH/0xF03d05eD4Cf4E723C04500Ab64281BfA591968F2" target="_blank" class="del-underline white--text pointer">
                            <v-row justify="center" align="center" class="pb-0">
                                <v-col cols="12" class="text-center py-12">
                                    <div class="pt-15 pb-12 fs-28">{{ $t('pool.addLiquidity3') }}</div>
                                    <div class="fs-48">{{ $t('pool.addLiquidity4') }}</div>
                                </v-col>
                            </v-row>
                        </a>
                    </v-carousel-item>
                </v-carousel>
            </v-row>
            <v-row align="center" justify="center" class="mx-0" id="ieo-header" v-if="responsive != 'PC'">
               <v-carousel cycle height="420" hide-delimiter-background :show-arrows="false" :interval="6000">
                    <!-- <v-carousel-item>
                        <router-link to="/dividend/0x6130ff302f24267380753e52ece48b852be3dd38" class="del-underline white--text pointer">
                            <v-row justify="center" align="center">
                                <v-col cols="12" class="text-center pt-70">
                                    <div class="pb-5 fs-28">{{ $t('dividend.iobDividend') }}</div>
                                    <div class="py-5 fs-28">{{ $t('dividend.investorsDividendText') }}</div>
                                    <div class="fs-48">{{ $t('dividend.requestNow') }}</div>
                                </v-col>
                            </v-row>
                        </router-link>
                    </v-carousel-item> -->
                    <v-carousel-item class="">
                        <router-link to="/staking" class="del-underline white--text pointer">
                            <v-row justify="center" class="pb-0">
                                <v-col cols="12" class="text-center pt-12">
                                    <div class="pb-12 fs-28">{{ $t('pool.defyLiquidityPoolStaking') }}</div>
                                    <div class="fs-18">{{ $t('pool.poolTradingProfit') }}</div>
                                    <!-- <div class="fs-18">({{ $t('pool.currentlyUpAnnualized1') + ' ' + (defyAnnualizedReturn * 100).toFixed(2) }}% {{ $t('pool.currentlyUpAnnualized2') }})</div> -->
                                    <div class="fs-18">({{ $t('pool.firstYearEnding') }})</div>
                                    <div class="fs-36">+</div>
                                    <div class="fs-42 title_txt pb-15">
                                        <span>18% <span class="fs-22 ">APY</span> {{ $t('pool.inFiTokens') }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </router-link>
                    </v-carousel-item>
                    <v-carousel-item>
                        <a href="https://app.uniswap.org/#/add/v2/ETH/0xF03d05eD4Cf4E723C04500Ab64281BfA591968F2" target="_blank" class="del-underline white--text pointer">
                            <v-row justify="center" align="center">
                                <v-col cols="12" class="text-center pt-100 mt-10">
                                    <div class="fs-28">{{ $t('pool.addLiquidity1') }}<span class="fs-48">18%</span>{{ $t('pool.addLiquidity2') }}</div>
                                </v-col>
                            </v-row>
                        </a>
                    </v-carousel-item>
                </v-carousel>
            </v-row>
        </div>
    </div>
</template>
<style>
</style>

<script>
import Vue from 'vue'
import config from '@/config.js';
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive", "defyAnnualizedReturn"]),
    },
    watch: {

    },
    methods: {

    },
}
</script>
