import Vue from 'vue'
import VueRouter from 'vue-router'
import FAQ from '@/pages//common/FAQ.vue'
import Page404 from '@/pages/common/Page404.vue'
import Terms from '@/pages/common/Terms.vue'
import Home from '@/pages/Home.vue'
import About from '@/pages/About.vue'
import Pools from '@/pages/Pools.vue'
import Solutions from '@/pages/Solutions.vue'
import Tokens from '@/pages/Tokens.vue'
import DAO from '@/pages/DAO.vue'
import Holdings from '@/pages/defy/Holdings.vue'
import Staking from '@/pages/Staking.vue'
import StakingDefy from '@/pages/staking/StakingDefy.vue'
import StakingFI from '@/pages/staking/StakingFI.vue'
import Activity from '@/pages/Activity.vue'
import UserActivity from '@/pages/UserActivity'
import ManagePool from '@/pages/pool/ManagePool'
import Dividend from '@/pages/Dividend.vue'
import Contact from '@/pages/Contact.vue'
import Untrading from '@/pages/Untrading.vue'


Vue.use(VueRouter)

const routes = [
  { path: '*', name:'page404', component: Page404, meta: { keepAlive: true } },
  // { path: '/', name: 'home', component: Home, meta: { keepAlive: true } },
  { path: '/', name: 'untrading', component: Untrading, meta: { keepAlive: true } },
  { path: '/managed-pools', name: 'home', component: Home, meta: { keepAlive: true } },
  { path: '/about', name: 'about', component: About, meta: { keepAlive: true } },
  { path: '/pools', name: 'pools', component: Pools, meta: { keepAlive: true } },
  { path: '/solutions', name: 'solutions', component: Solutions, meta: { keepAlive: true } },
  { path: '/tokens', name: 'tokens', component: Tokens, meta: { keepAlive: true } },
  { path: '/dao', name: 'dao', component: DAO, meta: { keepAlive: true } },
  { path: '/faq', name: 'faq', component: FAQ, meta: { keepAlive: false } },
  { path: '/terms', name:'terms', component: Terms, meta: { keepAlive: true } },
  // { path: '/defy/holdings', name:'holdings', component: Holdings, meta: { keepAlive: true } },
  { path: '/pool/0xca38034a69ebedd28ce62368890e7cf187309a0f', name:'holdings', component: Holdings, meta: { keepAlive: true } },
  { path: '/staking', name:'staking', component: Staking, meta: { keepAlive: true } },
  // { path: '/staking/defy', name:'StakingDefy', component: StakingDefy, meta: { keepAlive: true } },
  { path: '/pool/0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59/buy', name:'StakingDefy', component: StakingDefy, meta: { keepAlive: true } },
  // { path: '/staking/fi', name:'StakingFI', component: StakingFI, meta: { keepAlive: true } },
  { path: '/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2/buy', name:'StakingFI', component: StakingFI, meta: { keepAlive: true } },
  { path: '/activity/:address', name:'Activity', component: Activity, meta: { keepAlive: false } },
  { path: '/investments', name:'UserActivity', component: UserActivity, meta: { keepAlive: true } },
  { path: '/pool/:address', name: 'ManagePool', component: ManagePool, meta: { keepAlive: false } },
  { path: '/dividend/:address', name: 'Dividend', component: Dividend, meta: { keepAlive: false } },
  { path: '/contact', name: 'Contact', component: Contact, meta: { keepAlive: false } },

  // 懒加载
  // { path: '/about', name: 'About', component: () => import('../views/About.vue'), meta: { keepAlive: true } }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 切换页面滚动到页面顶部
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// router文件夹-->index.js文件
//cv以下代码解决路由地址重复的报错问题(一劳永逸)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

