<template>
    <div>
        <v-container class="px-0 mt-16 pt-16">
            <v-row justify="center" align="center">
                <!-- <v-col cols="11" class="text-center">
                    <span>PC VIEW SUPPORTED ONLY</span>
                </v-col> -->
                <v-col cols="11" class="pb-70">
                    <pool-nav-line-chart :address="address"></pool-nav-line-chart>
                </v-col>
                <v-col cols="11" class="pb-70">
                    <pool-fee-structure :address="address"></pool-fee-structure>
                </v-col>
                <v-col cols="11">
                    <share-price-metrics-pool :address="address"></share-price-metrics-pool>
                </v-col>
                <v-col cols="11">
                    <h5>{{ $t('holding.holdings') }}
                        <span class="title_txt mr-3 ml-3">
                            <a :href="`${CommonJS.getBlockchainExplorerUrl(blockchainNetwork)}/tokenholdings?a=${address}`" class="pure-red--text del-underline" target="_blank" v-if="address != null">
                                <span>{{ address.substring(0,6) + "..." + address.substring(address.length - 4)  }}&#8599;</span>
                            </a>
                        </span>
                        <span class="title_txt">
                            <router-link :to="'/activity/' + address" target="_blank" class="del-underline white--text pointer" v-if="address != null">
                                <span class="pure-red--text">Activity</span>
                            </router-link>
                        </span>
                    </h5>
                </v-col>
                <v-col cols="11">
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <v-col cols="11">
                    <v-card dark tile>
                        <div class="card-body pa-4">
                            <div class="align-items-center media">
                                <div class="media-body">
                                    <h2 class="fs-12 dark-gray--title">{{ $t('holding.valueInETH') }}</h2>
                                    <span class="fs-28 fw-normal grey--text">{{ formatNum(valueInETH.toFixed(6))}}</span>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="11">
                    <v-card dark tile>
                        <div class="card-body pa-4">
                            <div class="align-items-center media">
                                <div class="media-body">
                                    <h2 class="fs-12 dark-gray--title">{{ $t('holding.valueInUSD') }}</h2>
                                    <span class="fs-28 fw-normal grey--text">${{ formatNum(valueInUSD.toFixed(2)) }}</span>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="11">
                    <v-card dark tile>
                        <div class="card-body pa-4">
                            <div class="align-items-center media">
                                <div class="media-body">
                                    <h2 class="fs-12 dark-gray--title">{{ $t('holding.total') }} {{ minted }} {{ $t('holding.minted') }}</h2>
                                    <span class="fs-28 fw-normal grey--text">{{ formatNum(totalSupply.toFixed(6)) }}</span>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="11" class="pb-70">
                    <v-row>
                        <v-col cols="12" v-if="!loading">
                            <!-- <v-expansion-panels tile accordion focusable multiple>
                                <v-expansion-panel v-for="item in holdingItems" class="dark-gray--text">
                                    <v-expansion-panel-header class="fs-12">
                                        <v-row>
                                            <v-col cols="6" class="ml-n2 mr-2">{{ item.name }}</v-col>
                                            <v-col cols="6" class="text-right">{{ item.symbol == '--' ? '' : item.symbol }}</v-col>
                                        </v-row>
                                        <template v-slot:actions >
                                            <v-icon color="primary" class="mr-n5">$expand</v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="fs-12">
                                        <v-row dense>
                                            <v-col class="dark-gray--title ml-n2 mr-1" cols="6" v-if="item.amount != 0">{{ $t('holding.quantity') }}</v-col>
                                            <v-col class="text-right" cols="6" v-if="item.amount != 0">{{(item.amount == null || item.amount == 0) ? "--" : formatNum(Number(item.amount).toFixed(6))}}</v-col>
                                            <v-col class="dark-gray--title ml-n2 mr-1" cols="6" v-if="item.price != 0">{{ $t('holding.lastPrice') }}</v-col>
                                            <v-col class="text-right" cols="6" v-if="item.price != 0">{{item.price == 0 ? "--" : "$" + formatNum(item.price.toFixed(4))}}</v-col>
                                            <v-col class="dark-gray--title ml-n2 mr-1" cols="6">{{ $t('holding.valueInETH') }}</v-col>
                                            <v-col class="text-right" cols="6">{{item.valueInETH == 0 ? "--" : formatNum(Number(item.valueInETH).toFixed(4))}}</v-col>
                                            <v-col class="dark-gray--title ml-n2 mr-1" cols="6">{{ $t('holding.valueInUSD') }}</v-col>
                                            <v-col class="text-right" cols="6">{{item.value == 0 ? "--" : "$" + formatNum(Number(item.value).toFixed(2))}}</v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels> -->
                            <v-expansion-panels tile accordion focusable multiple>
                                <v-expansion-panel v-for="item in holdingItems" class="dark-gray--text">
                                    <v-expansion-panel-header class="fs-12">
                                        <v-row>
                                            <v-col cols="6" class="ml-n2 mr-2">
                                                <span :class="item.expansion ? 'title_txt' : ''">
                                                    <span>
                                                        <a target="_blank" :class="item.expansion ? 'del-underline pure-red--text' : 'dark-gray--text'">{{ item.name }}</a>
                                                    </span>
                                                </span>
                                            </v-col>
                                            <v-col cols="6" class="text-right">{{ item.symbol == '--' ? '' : item.symbol }}</v-col>
                                        </v-row>
                                        <template v-slot:actions >
                                            <v-icon color="primary" class="mr-n5">$expand</v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="fs-12">
                                        <v-row dense>
                                            <v-col cols="12">
                                                <router-link :to="item.expansion ? getUrl(item.platform, item.address) : ''" class="del-underline ma-0 pa-0">
                                                    <v-row>
                                                        <v-col :cols="item.expansion ? '11' : '12'">
                                                            <v-row dense :class="[item.expansion ? 'mr-n7' : '']">
                                                                <v-col class="dark-gray--title ml-n2 mr-1" cols="6" v-if="item.amount != 0">{{ $t('holding.quantity') }}</v-col>
                                                                    <v-col class="text-right" cols="6" v-if="item.amount != 0">{{(item.amount == null || item.amount == 0) ? "--" : formatNum(Number(item.amount).toFixed(6))}}</v-col>
                                                                    <v-col class="dark-gray--title ml-n2 mr-1" cols="6" v-if="item.price != 0">{{ $t('holding.lastPrice') }}</v-col>
                                                                    <v-col class="text-right" cols="6" v-if="item.price != 0">{{item.price == 0 ? "--" : "$" + formatNum(item.price.toFixed(4))}}</v-col>
                                                                    <v-col class="dark-gray--title ml-n2 mr-1" cols="6">{{ $t('holding.valueInETH') }}</v-col>
                                                                    <v-col class="text-right" cols="6">{{item.valueInETH == 0 ? "--" : formatNum(Number(item.valueInETH).toFixed(4))}}</v-col>
                                                                    <v-col class="dark-gray--title ml-n2 mr-1" cols="6">{{ $t('holding.valueInUSD') }}</v-col>
                                                                    <v-col class="text-right" cols="6">{{item.value == 0 ? "--" : "$" + formatNum(Number(item.value).toFixed(2))}}</v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col cols="1" v-if="item.expansion">
                                                            <router-link :to="getUrl(item.platform, item.address)" class="del-underline">
                                                                    <v-icon class="dark-gray--text ml-n1" :class="[item.platform == 'OKEx' ? 'mt-3' : 'mt-10']">mdi-chevron-right</v-icon>
                                                            </router-link>
                                                        </v-col>
                                                    </v-row>
                                                </router-link>
                                            </v-col>
                                            <v-col cols="12" v-if="item.expansion" class="ml-n2 mt-n5">
                                                <v-expansion-panels tile accordion multiple>
                                                    <v-expansion-panel v-for="child in item.data" class="dark-gray--text">
                                                        <v-expansion-panel-header class="fs-12">
                                                            <v-row dense v-if="item.platform == 'dHEDGE' || item.platform == 'Wallet' || item.platform == 'Ledger'" class="ml-n7 align-center">
                                                                <v-col cols="6" class="d-flex align-center">
                                                                    <blockchain-network :network="child.blockchainNetwork" :size="3"></blockchain-network>
                                                                    <span class="ml-2">{{ child.name }}</span>
                                                                </v-col>
                                                                <v-col cols="6" class="text-right">{{ child.symbol }}</v-col>
                                                            </v-row>
                                                            <v-row dense v-if="item.platform == 'Binance' || item.platform == 'FTX' || item.platform == 'Kraken' || item.platform == 'OKEx'" class="ml-n7">
                                                                <v-col cols="6" class="">
                                                                    <span>{{ child.symbol }}</span>
                                                                </v-col>
                                                                <v-col cols="6" class="text-right" :class="child.side == '--' ? '' : (child.side == 'Long' ? 'green--text' : 'red--text')">{{ child.side }}</v-col>
                                                            </v-row>
                                                            <template v-slot:actions>
                                                                <v-icon color="primary" class="mr-n7">$expand</v-icon>
                                                            </template>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content class="fs-12">
                                                            <v-row dense>
                                                                <v-col cols="6" class="dark-gray--title ml-n5 mr-5">{{ $t('holding.quantity') }}</v-col>
                                                                <v-col cols="6" class="text-right">{{ formatNum(Number(child.amount == null ? child.size : child.amount).toFixed(6)) }}</v-col>
                                                                <v-col cols="6" class="dark-gray--title ml-n5 mr-5">{{ $t('holding.lastPrice') }}</v-col>
                                                                <v-col cols="6" class="text-right">{{ child.price == 0 ? "--" : "$" + formatNum(child.price.toFixed(4)) }}</v-col>
                                                                <v-col cols="6" class="dark-gray--title ml-n5 mr-5">{{ $t('holding.valueInETH') }}</v-col>
                                                                <v-col cols="6" class="text-right">{{ child.valueInETH == 0 ? "--" : formatNum(Number(child.valueInETH).toFixed(4)) }}</v-col>
                                                                <v-col cols="6" class="dark-gray--title ml-n5 mr-5">{{ $t('holding.valueInUSD') }}</v-col>
                                                                <v-col cols="6" class="text-right">{{ child.value == 0 ? "--" : "$" + formatNum(Number(child.value).toFixed(2)) }}</v-col>
                                                            </v-row>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col cols="12" class="" v-if="loading">
                            <v-skeleton-loader dense type="table-row-divider@6"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="11" class="pb-250">
                    <pool-description :address="address" :description="description"></pool-description>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style scoped>
.theme--dark.v-expansion-panels .v-expansion-panel {
    background-color: transparent!important;
}
</style>>
</style>

<script>
import { mapGetters} from "vuex";
import config from '@/config.js';
import PoolNavLineChart from "@/highcharts/PoolNAVLineChart";
import PoolFeeStructure from "@/components/pool/PoolFeeStructure";
import PoolDescription from "@/components/pool/PoolDescription";
import SharePriceMetricsPool from "@/components/asset/SharePriceMetricsPool";
import BlockchainNetwork from "@/components/common/BlockchainNetwork";
export default {
    data() {
        return {
            address:this.$route.params.address,
            faq: config.faq,
            fi: config.domainName,
            environment: config.environment,
            HoldingHeader:[
                { text: this.$t('holding.assetName') },
                { text: this.$t('holding.symbol') },
                { text: this.$t('holding.quantity'), align:"end"},
                { text: this.$t('holding.lastPrice'), align:"end"},
                { text: this.$t('holding.valueInETH'), align:"end"},
                { text: this.$t('holding.valueInUSD'), align:"end"}
            ],
            blockchainNetwork: '',
            holdingItems:[],
            valueInETH:0,
            valueInUSD:0,
            totalSupply:0,
            description: null,
            minted:null,
            loading: false
        }
    },
    created() {
        this.isExist(this.address);
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PoolNavLineChart, PoolFeeStructure, SharePriceMetricsPool, PoolDescription, BlockchainNetwork
    },
    watch: {
        $route() {
            this.address = this.$route.params.address;
        },
        address(val){
            if (val) {
                this.isExist(val);
            }
        }
    },
    methods: {
        // 判读地址是否在数据库中
        isExist(address){
            let api = config.baseUrl + "/pool/public/exist?address=" + address;
            this.$http.get(api).then(res=>{
                let data = res.data;
                if(data.code == 200){
                    this.minted = data.result.poolName + " (" + data.result.symbol +")";
                    this.getHolding();
                }else{
                    this.$router.push({ name:'page404'})
                }
            })
        },
        getHolding(){
            this.loading = true;
            let api =  config.baseUrl + "/pool/public/holdings?address=" + this.address;
            this.$http.get(api).then(res=>{
                let data = res.data;
                this.loading = false;
                if(data.code == 200){
                    this.blockchainNetwork = data.result.blockchainNetwork;
                    this.holdingItems = data.result.holding;
                    this.valueInETH = data.result.valueInETH;
                    this.valueInUSD = data.result.valueInUSD;
                    this.totalSupply = data.result.totalSupply;
                    this.description = data.result.description;
                }
            })
        },
        formatNum(num) {
            if (num == null) return '';
            if (num == NaN || num == 'NaN') return '';
            if (num == 'undefined') return '';
            if (num == '--') return '--';
            let number = num + '';
            let numberArray = number.split('.');
            let integerPart = numberArray[0];
            let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(integerPart)) {
                integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
            }
            return integerPart + decimalPart;
        },
        // 获取URL
        getUrl(platform, address){
            let url = '';
            switch(platform){
                case 'OKEx': url = '/activity/defy-pool-on-okex'; break;
                case 'Binance': url = '/activity/defy-pool-on-binance'; break;
                case 'FTX': url = '/activity/defy-pool-on-ftx'; break;
                case 'Kraken': url = '/activity/defy-pool-on-kraken'; break;
                case 'Wallet': url = '/activity/' + address; break;
                case 'dHEDGE': url = '/pool/' + address; break;
                default: url = ''; break;
            }
            return url;
        },
    },
}
</script>
