<template>
<div>
    <video class="border-999" controls="controls" preload="auto" :poster="imgUrl" playsinline="true" x5-video-orientation="portraint" :src="url" height="auto" :width="videoSize"></video>
</div>
</template>
<style>
</style>

<script>
import Vue from 'vue'
import config from '@/config.js';
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            url: "https://files.iob.fi/2020/11/1605670843454-a36b6e9d633658e7b237e148ebe6f412-One-wallet-for-cryptos-stocks-and-commodities.mp4",
            imgUrl: "https://files.iob.fi/2020/11/1605670836449-c7eea7f9af155be8a5f232a7c84f2d73-One-wallet-for-cryptos-stocks-and-commodities.webp"
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
        videoSize() {
            // responsive == 'PC' ? 849 : 300
            switch (this.responsive) {
                case "PC":
                    return 849;
                case "mobile":
                    return document.body.clientWidth;
                case "pad":
                    return 649;
                default:
                    return 849;
            }
        }
    },
    watch: {

    },
    methods: {

    },
}
</script>
