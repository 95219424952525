<template>
	<div ref="screenshot">
		<div class="fw-bold fs-20 pure-red--text d-flex flex-wrap align-center" v-if="!loading && responsive == 'PC'">
			<!-- <div class="d-flex flex-wrap align-center"> -->
			
			<span class="underline-gradient"><a :href="'https://app.dhedge.org/pool/' + address"
					class="del-underline pure-red--text" target="_blank">{{ poolTitle }}</a></span>
			<blockchain-network class="ml-2" :network="blockchainNetwork" :size="4"></blockchain-network>
			<!-- </div> -->
			<v-spacer></v-spacer>
            <span v-if="poolInfo.deprecated" class="fs-14 dark-gray--text">
                {{ poolInfo.deprecatedMessage }}
            </span>
			<a v-else :href="'https://app.dhedge.org/pool/' + address" class="del-underline pure-red--text" target="_blank">
				<v-btn tile small class="float-right text-none button--gradient-orange glow-orange black--text">
					{{ $t('common.invest') }}
                </v-btn>
			</a>
		</div>
		<div class="fw-bold fs-20 pure-red--text" v-if="loading && responsive == 'PC'">
			<span>--</span>
			<!-- <a :href="'https://app.dhedge.org/pool/' + address" class="del-underline pure-red--text" target="_blank">
				<v-btn tile small class="float-right text-none button--gradient-orange glow-orange black--text">
					{{ $t('common.invest') }}
                </v-btn>
			</a> -->
		</div>
		<!-- <div class="fw-bold fs-20 ml-3" v-else>
        <v-row class="mb-3">
            <div class="lightred--text underline-gradient">
                <a :href="'https://app.dhedge.org/pool/' + address" class="del-underline pure-red--text" target="_blank">{{ poolName }}</a>
            </div>
        </v-row>
        <v-row>
            <a :href="'https://app.dhedge.org/pool/' + address" class="del-underline pure-red--text" target="_blank">
                <v-btn tile small class="float-right text-none button--gradient-pink glow-red">Invest</v-btn>
            </a>
        </v-row>
    </div> -->
		<div class="fs-24 fw-bold mt-10" v-if="responsive == 'PC'">
			<v-row class="dark-gray--text font-weight-bold fs-20">
				<v-col cols="6">
					<span v-if="!loading">{{ poolName + ' ' + manageName }}</span>
					<span v-if="loading">--</span>
				</v-col>
				<v-col cols="6" class="text-right">
					<span v-if="manageName != null">{{ $t('nav.totalPerformance') }}</span>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="6">
					<div class="font-4x">
						{{ option.series[0].data.length == 0 ? '--' : '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) + ' *' }}
					</div>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="6">
					<span class="float-right mr-2">
						<v-tooltip left :max-width="400" fixed :close-delay="100" :z-index="99">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-icon color="grey" class="pointer mt-9">icon icon-help1</v-icon>
								</span>
							</template>
							<span>
								* Price feed for the synthetic assets held in this pool may not be sourced from the same
								markets used by dHEDGE and may be delayed up to 20 minutes. Information is provided 'as
								is' and solely for informational purposes, not for trading purposes. Transactions in
								this pool should only be executed directly through <a href="https://app.dhedge.org/"
									target="_blank" class="lineblue--text">https://app.dhedge.org/</a>.
							</span>
						</v-tooltip>
					</span>
					<span class="float-right mr-2 font-4x "
						:class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')">{{performance == 0 ? '' : (performance > 0 ? ('+' + performance.toFixed(2) + '%') : (performance.toFixed(2) + '%')) }}</span>
				</v-col>
			</v-row>
		</div>
		<div class="mb-3 fw-bold" v-if="responsive == 'mobile' || responsive == 'pad'">
			<div class="fs-24  d-flex flex-wrap align-center">
				<span class="underline-gradient"><a :href="'https://app.dhedge.org/pool/' + address" class="del-underline pure-red--text"
					target="_blank">{{ poolTitle }}</a></span>
					<blockchain-network class="ml-2" :network="blockchainNetwork" :size="4"></blockchain-network>
			</div>
            <div v-if="poolInfo.deprecated" class="fs-14 dark-gray--text mt-8">
                {{ poolInfo.deprecatedMessage }}
            </div>
			<h4 class="font-3x dark-gray--text fw-bold fs-20 mb-n2 mt-8">{{ $t('nav.totalPerformance') }}</h4>
			<div class="font-3x" :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')">
				{{performance == 0 ? '--' : (performance > 0 ? ('+' + performance.toFixed(2) + '%') : (performance.toFixed(2) + '%')) }}
			</div>
			<v-row dense class="mb-n4">
				<v-col cols="8">
					<h4 class="dark-gray--text fw-bold fs-12 mt-2" v-if="!loading">{{ manageName }}</h4>
				</v-col>
				<v-col cols="4" class="text-right mt-2 ml-n3 fs-12">
					<div v-if="option.series[0].data.length > 0">
						{{ '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) }}</div>
					<div v-if="option.series[0].data.length == 0">--</div>
				</v-col>
			</v-row>
		</div>
		<div class="mb-3 fs-12" v-if="responsive == 'PC'">
			<span @click="day=7" class="pointer mr-1"
				:class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
			<span @click="day=30" class="pointer ml-1 mr-1"
				:class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
			<span @click="day=90" class="pointer ml-1 mr-1"
				:class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
			<span @click="day=365" class="pointer ml-1 mr-1"
				:class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
			<span @click="day=0" class="pointer ml-1 mr-10"
				:class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
			<span class="grey--text">Interval</span>
			<span class="ml-2 pointer" :class="[interval.value == 'day' ? 'lineblue--text ' : 'grey--text']"
				@click="interval = intervalList[0]">{{ $t('common.1d') }}</span>
			<span class="ml-2 pointer" :class="[interval.value == 'week' ? 'lineblue--text ' : 'grey--text']"
				@click="interval = intervalList[1]">{{ $t('common.1w') }}</span>
			<span class="ml-2 pointer" :class="[interval.value == 'month' ? 'lineblue--text ' : 'grey--text']"
				@click="interval = intervalList[2]">{{ $t('common.1m') }}</span>
			<span v-show="!isTakingScreenshot" class="float-right mr-2">
				<!-- <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.refresh')" @click="getPoolNAV">mdi-refresh</v-icon> -->
				<!-- <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon> -->
				<!-- <v-icon size="18" class="lineblue--text" title="Full Screen" @click="toggleFullscreen">mdi-fullscreen</v-icon> -->
			</span>
			<br>
		</div>
		<div class="mb-3 fs-12" v-if="responsive == 'mobile'|| responsive == 'pad'">
			<span @click="day=7" class="pointer mr-1"
				:class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
			<span @click="day=30" class="pointer ml-1 mr-1"
				:class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
			<span @click="day=90" class="pointer ml-1 mr-1"
				:class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
			<span @click="day=365" class="pointer ml-1 mr-1"
				:class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
			<span @click="day=0" class="pointer ml-1 mr-10"
				:class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
			<span class="float-right mr-3">
				<span class="grey--text">{{ $t('common.interval') }}</span>
				<span class="ml-2 pointer" :class="[interval.value == 'day' ? 'lineblue--text ' : 'grey--text']"
					@click="interval = intervalList[0]">{{ $t('common.1d') }}</span>
				<span class="ml-2 pointer" :class="[interval.value == 'week' ? 'lineblue--text ' : 'grey--text']"
					@click="interval = intervalList[1]">{{ $t('common.1w') }}</span>
				<span class="ml-2 pointer" :class="[interval.value == 'month' ? 'lineblue--text ' : 'grey--text']"
					@click="interval = intervalList[2]">{{ $t('common.1m') }}</span>
			</span>
			<br>
			<span v-show="!isTakingScreenshot" class="float-right mr-2">
				<!-- <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.refresh')" @click="getPoolNAV">mdi-refresh</v-icon>
            <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
            <v-icon size="18" class="lineblue--text" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon> -->
			</span>
		</div>
		<a :href="'https://app.dhedge.org/pool/' + address" class="del-underline" target="_blank">
			<div :id="address" style="height: 360px" v-if="responsive == 'PC'" class="ml-n2 mr-n1"></div>
		</a>
		<a :href="'https://app.dhedge.org/pool/' + address" class="del-underline" target="_blank">
			<div :id="address" style="height: 290px" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
		</a>
		<v-row dense align="center" v-if="responsive == 'PC'">
			<v-col cols="8" class="fs-12">
				<span class="pointer" @click="feeType = 'before'"
					:class="feeType == 'before' ? 'pure-red--text underline-gradient' :''">{{ $t('nav.beforeFees') }}</span>
				<span class="ml-5 pointer" @click="feeType = 'after'"
					:class="feeType == 'after' ? 'underline-gradient pure-red--text' :''">{{ $t('nav.afterFees') }}</span>
			</v-col>
			<v-col cols="4" class="fs-12 text-right pr-3">
				<span class="pointer" :class="[logBtn ? 'lineblue--text' :'grey--text']"
					@click="logBtn = !logBtn">log</span>
			</v-col>
		</v-row>
		<v-row dense align="center" v-if="responsive != 'PC'">
			<v-col cols="6" class="fs-12">
				<v-select :items="tabList" class="fs-12" item-text="text" item-value="value" v-model="feeType"
					hide-details dense solo></v-select>
			</v-col>
			<v-col cols="6" class="fs-12 text-right pr-3">
				<span class="pointer" :class="[logBtn ? 'lineblue--text' :'grey--text']"
					@click="logBtn = !logBtn">log</span>
			</v-col>
		</v-row>
		<v-dialog v-model="screenshotDialog" persistent width="450">
			<v-card>
				<v-card-title class="grey lighten-2">
					<span class="black--text">{{$t("common.imageURL")}}</span>
					<v-spacer></v-spacer>
					<v-btn light icon @click="screenshotDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<div v-show="!uploadBase64Loading" class="mt-3">
						<v-text-field id="imageHttpUrl" v-model="imageHttpUrl" single-line outlined disabled
							hide-details class="mt-3 mb-3"></v-text-field>
						<v-btn text small class="text-none lineblue--text pl-0" @click="saveImage()">
							{{$t("common.save")}}</v-btn>
						<v-btn text small class="text-none lineblue--text" v-clipboard:copy="imageHttpUrl"
							v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{$t("common.copy")}}
						</v-btn>
						<v-btn text small class="text-none lineblue--text" :href="imageHttpUrl" target="_black">
							{{$t("common.open")}}</v-btn>
					</div>
					<div v-if="uploadBase64Loading" class="mt-3 text-center">
						<v-progress-circular indeterminate color="primary"></v-progress-circular>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<div class="mt-70">
			<address-analyses :address="address"></address-analyses>
		</div>
	</div>
</template>

<style scoped>
	svg.highcharts-root {
		font-family: "Lexend Deca", sans-serif !important;
	}

	.test tspan:active {
		text: expression(target="_blank");
	}
</style>

<script>
	import config from '@/config.js';
	import Highcharts from 'highcharts/highstock';
	import {
		mapGetters
	} from "vuex";
	import AddressAnalyses from '@/components/pool/AddressAnalyses';
	import BlockchainNetwork from "@/components/common/BlockchainNetwork";
	export default {
		data() {
			return {
				fi: config.fi,
				environment: config.environment,
				day: 365,
				intervalList: [{
						text: '1D',
						value: 'day'
					},
					{
						text: '1W',
						value: 'week'
					},
					{
						text: '1M',
						value: 'month'
					},
				],
				interval: {
					text: '1D',
					value: 'day'
				},
				// 图片弹窗
				screenshotDialog: false,
				// candleChart图片base64地址
				imageBase64Url: null,
				// candleChart图片http地址
				imageHttpUrl: null,
				// 上传图片加载中
				uploadBase64Loading: false,
				// 是否正在截图
				isTakingScreenshot: false,
				chart: null,
				loading: false,
				performance: 0,
				poolName: null,
				poolTitle: null,
				manageName: null,
				feeType: "before",
				option: {
					// 图表类型
					chart: {
						backgroundColor: '',
						type: 'line',
						// x方向和y方向都可以用鼠标拖拽放大
						zoomType: 'xy'
					},
					title: {
						text: '',
						align: 'left',
						style: {
							color: '#9E9E9E'
						},
					},
					subtitle: {
						text: '',
						align: 'left',
						style: {
							color: '#999999'
						},
					},
					// x轴
					xAxis: {
						categories: [],
						lineWidth: 1,
						tickInterval: 30,
						tickWidth: 1,
						tickPosition: 'inside',
						lineColor: '#666666',
						tickColor: '#666666',
						type: 'datetime',
						dateTimeLabelFormats: {
							millisecond: '%m/%d/%Y %H:%M:%S',
							second: '%m/%d/%Y %H:%M:%S',
							minute: '%m/%d/%Y %H:%M',
							hour: '%m/%d/%Y %H:%M',
							day: '%m/%d',
							week: '%m/%d',
							month: '%m/%Y',
							year: '%Y'
						},
						labels: {
							enabled: true
						}
					},
					// y轴
					yAxis: {
						type: 'logarithmic',
						title: {
							text: null
						},
						// min: 0.8,
						tickInterval: 0.1,
						visible: true,
						// 横线宽度设置为0表示不显示横线
						gridLineWidth: 0,
						opposite: true,
						offset: 20,
						labels: {
							align: "left",
							x: -10,
							formatter: function() {
								return this.value.toFixed(2);
							}
						},
					},
					// 鼠标悬浮提示框
					tooltip: {
						valueDecimals: 4,
						valuePrefix: '',
						valueSuffix: '',
						followTouchMove: true,
						dateTimeLabelFormats: {
							millisecond: '%m/%d/%Y %H:%M:%S UTC',
							second: '%m/%d/%Y %H:%M:%S UTC',
							minute: '%m/%d/%Y %H:%M UTC',
							hour: '%m/%d/%Y %H:%M UTC',
							day: '%m/%d/%Y %H:%M UTC',
							week: '%m/%d %H:%M UTC',
							month: '%m/%Y',
							year: '%Y'
						}
					},
					// 数据
					series: [{
						// type: 'spline',
						name: 'NAV',
						data: [],
						// color: '#FEE108',
						//线条渐变
						color: {
							linearGradient: {
								x1: 0,
								x2: 0,
								y1: 0,
								y2: 1
							},
							stops: [
								[0, '#4CAF50'],
								[1, '#FEE108']
							]
						},
						lineWidth: 3
					}],
					noData: {
						style: {
							fontWeight: 'bold',
							fontSize: '15px',
							color: '#303030'
						}
					},
					// 图例导航
					legend: {
						enabled: false,
						align: 'left',
						itemStyle: {
							color: '#999999'
						},
						itemHoverStyle: {
							color: '#999999'
						},
						itemHiddenStyle: {
							color: '#606063'
						},
						title: {
							style: {
								color: '#C0C0C0',
								fontFamily: '\'Lexend Deca\', sans-serif'
							}
						}
					},
					navigation: {
						buttonOptions: {
							symbolStroke: 'black',
							theme: {
								fill: '#EEEEEE',
							},
						}
					},
					// 图形参数
					plotOptions: {
						line: {
							lineWidth: 2,
							cursor: 'pointer',
							marker: {
								enabled: false
							}
						}
					},
					// 版权信息
					credits: {
						enabled: true,
						href: config.domainName,
						text: '',
						className: 'a',
						position: {
							x: -12,
							y: -25
						},
						style: {
							fontSize: '12px',
							color: 'white',
							target: '_black'
						}
					},
					exporting: {
						enabled: false,
						filename: 'NAV',
						buttons: {
							contextButton: {
								align: "left",
								verticalAlign: "top",
								x: -10,
								y: 0
							}
						}
					},
					loading: {
						style: {
							backgroundColor: 'silver'
						},
						labelStyle: {
							color: 'white',
							fontSize: '36px'
						}
					}
				},
				logBtn: true,
				tabList: [{
						text: this.$t('nav.beforeFees'),
						value: "before"
					},
					{
						text: this.$t('nav.afterFees'),
						value: "after"
					},
				],
				// 区块链网络
				blockchainNetwork: '',
                // 池子信息
                poolInfo: {}
			}
		},
		props: ['address'],
		watch: {
			day(val) {
				this.getPoolNAV();
			},
			'interval.value'(newVal, oldVal) {
				this.getPoolNAV();
			},
			// 监听加载中
			loading(newVal) {
				if (this.chart) {
					if (newVal) {
						this.chart.showLoading();
					} else {
						this.chart.hideLoading();
					}
				}
			},
			logBtn(val) {
				if (val) {
					this.option.yAxis.type = 'logarithmic';
				} else {
					this.option.yAxis.type = 'linear';
				}
				this.renderChart();
			},
			address(val) {
				this.getFeeAdjustedPoolNAV();
			},
			feeType(val) {
				this.getPoolNAV();
			}
		},
		created() {

		},
		mounted() {
			this.getInceptionInfo();
			this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
			this.renderChart();
		},
		computed: {
			...mapGetters(['responsive', 'darkMode'])
		},
		components: {
			AddressAnalyses,
			BlockchainNetwork
		},
		methods: {
			getPoolNAV() {
				if (this.feeType == 'before') {
					this.getFeeAdjustedPoolNAV();
				} else if (this.feeType == 'after') {
					this.getRawPoolNAV();
				}
			},
			// 查询调整fee的NAV
			getFeeAdjustedPoolNAV() {
				this.loading = true;
				this.performance = 0;
				let interval = this.interval.value;
				if (interval == 'day') {
					this.option.xAxis.tickInterval = 20;
				} else if (interval == 'week') {
					this.option.xAxis.tickInterval = 5;
				} else if (interval == 'month') {
					this.option.xAxis.tickInterval = 2;
				}
				this.option.series[0].data = [];
				this.option.xAxis.categories = [];
				let api = config.baseUrl + "/pool/nav/public/fee-adjusted/algorithm-3/" + this.address + "?day=" + this
					.day + "&interval=" + interval;
				// if (this.environment == "Development") {
				//     api = "https://testnet.iobots.pro/iobots/pool/nav/public/fee-adjusted/" + this.address + "?day=" + this.day + "&interval=" + interval;
				// } else {
				//     api = config.baseUrl + "/pool/nav/public/fee-adjusted/" + this.address + "?day=" + this.day + "&interval=" + interval;
				// }
				this.$http.get(api).then(res => {
					let data = res.data;
					if (data.code == 200) {
						let dataList = data.result.navInUSD;
						this.option.series[0].data = dataList;
						// this.option.xAxis.categories = data.result.date;
						let temp = [];
						for (let i = 0; i < data.result.date.length; i++) {
							let element = data.result.date[i];
							temp.push(this.CommonJS.formatBritishDate(element));
						}
						this.option.xAxis.categories = temp;
						this.poolName = data.result.name + '';
						this.poolTitle = data.result.name + ' on dHEDGE';
						this.manageName = this.$t('nav.feeAdjustedNav') + ' (' + this.$t('nav.beforeFees') + ')';
						this.performance = data.result.roe * 100;
						this.option.series[0].name = data.result.name + ' ' + this.$t('nav.feeAdjustedNav') +
							' (' + this.$t('nav.beforeFees') + ')';
						// if (dataList[0] <= dataList[dataList.length - 1]) {
						//     this.option.series[0].color = '#4CAF50';
						// } else {
						//     this.option.series[0].color = '#F44336';
						// }
						// 重新渲染图表
						this.renderChart();
						this.loading = false;
					} else {
						this.loading = false;
						this.noData();
					}
				})
			},
			// 查询原始NAV
			getRawPoolNAV() {
				this.loading = true;
				this.performance = 0;
				let interval = this.interval.value;
				if (interval == 'day') {
					this.option.xAxis.tickInterval = 20;
				} else if (interval == 'week') {
					this.option.xAxis.tickInterval = 5;
				} else if (interval == 'month') {
					this.option.xAxis.tickInterval = 2;
				}
				this.option.series[0].data = [];
				this.option.xAxis.categories = [];
				let api = config.baseUrl + "/pool/nav/public/" + this.address + "?day=" + this.day + "&interval=" +
					interval;
				this.$http.get(api).then(res => {
					let data = res.data;
					if (data.code == 200) {
						let dataList = data.result.navInUSD;
						this.option.series[0].data = dataList;
						// this.option.xAxis.categories = data.result.date;
						let temp = [];
						for (let i = 0; i < data.result.date.length; i++) {
							let element = data.result.date[i];
							temp.push(this.CommonJS.formatBritishDate(element));
						}
						this.option.xAxis.categories = temp;
						this.poolName = data.result.name + '';
						this.poolTitle = data.result.name + ' on dHEDGE';
						this.manageName = this.$t('nav.nav') + ' (' + this.$t('nav.afterFees') + ')';
						this.performance = data.result.roe * 100;
						this.option.series[0].name = data.result.name + ' ' + this.$t('nav.nav') + ' (' + this.$t(
							'nav.afterFees') + ')';
						// if (dataList[0] <= dataList[dataList.length - 1]) {
						//     this.option.series[0].color = '#4CAF50';
						// } else {
						//     this.option.series[0].color = '#F44336';
						// }
						// 重新渲染图表
						this.renderChart();
						this.loading = false;
					} else {
						this.loading = false;
						this.noData();
					}
				})
			},
			formatDateFunction(timestamp) {
				if (timestamp == 0) {
					return '--';
				} else {
					if ("localTime" == localStorage.getItem("iobots-time")) {
						return formatDate(timestamp);
					} else {
						return formatUTCDate(timestamp)
					}
				}
			},
			// 渲染图表
			renderChart() {
				this.chart = Highcharts.chart(this.address, this.option);
			},
			noData() {
				this.renderChart();
			},
			// 生成图片
			generateImage() {
				// let pageYoffset = window.pageYoffset;
				// let documentElementScrollTop = document.documentElement.scrollTop;
				// let scrollTop = document.body.scrollTop;
				// window.pageYoffset = 0;
				// document.documentElement.scrollTop = 0;
				// document.body.scrollTop = 0;
				// // 正在截图
				// this.isTakingScreenshot = true;
				// let _this = this;
				// // 延时操作，不要问为什么，不延迟不可以
				// setTimeout(function() {
				//   // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
				//   html2canvas(_this.$refs.screenshot, {
				//     backgroundColor: _this.darkMode ? '#303030' : '#EEEEEE',
				//     useCORS: true
				//   }).then((canvas) => {
				//     let url = canvas.toDataURL('image/png');
				//     _this.imageBase64Url = url;
				//     // 生成截图完毕
				//     _this.isTakingScreenshot = false;
				//     window.pageYoffset = pageYoffset;
				//     document.documentElement.scrollTop = documentElementScrollTop;
				//     document.body.scrollTop = scrollTop;
				//     _this.uploadBase64Loading = true;
				//     _this.screenshotDialog = true;
				//     // 把生成的base64位图片上传到服务器,生成在线图片地址
				//     _this.uploadBase64();
				//   })
				// }, 1);
			},
			// 上传base64
			uploadBase64() {
				this.uploadBase64Loading = true;
				let api = config.baseUrl + '/upload/public/base64';
				let param = {
					base64: this.imageBase64Url
				};
				this.$http.post(api, param).then(response => {
					let data = response.data;
					this.uploadBase64Loading = false;
					if (data.code == 200) {
						this.imageHttpUrl = data.result;
					} else {
						this.$store.dispatch('snackbarMessageHandler', data.message);
					}
				}, error => {
					this.uploadBase64Loading = false;
					this.$store.dispatch('snackbarMessageHandler', 'oops');
				});
			},
			// 点击下载图片
			saveImage() {
				let a = document.createElement('a');
				// 设置图片地址
				a.href = this.imageBase64Url;
				// 设定下载名称
				a.download = this.poolName;
				// 点击触发下载
				a.click();
			},
			// 复制成功回调函数
			copySuccess() {
				this.$store.dispatch('snackbarMessageHandler', 'copied');
			},
			// 复制失败回调函数
			copyError() {
				this.$store.dispatch('snackbarMessageHandler', 'copyFailed');
			},
			//全屏
			toggleFullscreen() {
				if (this.chart.fullscreen) {
					this.chart.fullscreen.toggle();
				}
			},
			// 根据地址查询池子成立信息
			getInceptionInfo() {
                this.loading = true;
				let api = config.baseUrl + "/pool/public/info/inception/" + this.address;
				this.$http.get(api).then(res => {
					let data = res.data;
					if (data.code == 200) {
                        this.poolInfo = data.result;
						let inceptionPeriod = data.result.inceptionPeriod;
						if (inceptionPeriod.years > 0) {
							this.day = 0;
							this.interval = this.intervalList[2];
						}
						this.blockchainNetwork = data.result.blockchainNetwork;
					}
                    this.loading = false;
					// 查询NAV
					this.getPoolNAV();
				});
			}
		}
	}
</script>
