<template>
    <div>
        <div v-if="responsive == 'PC'" style="width: 100px;">
            <v-sparkline
                :id="address"
                :value="dataList"
                :gradient="gradients"
                :smooth="5"
                :line-width="8"
                :height="100"
                :padding="5"
                stroke-linecap="round"
                gradient-direction="top"
                type="trend"
                auto-line-width
                :auto-draw-duration="800"
                auto-draw
              ></v-sparkline>
        </div>
        <div v-if="responsive != 'PC'" style="width: 70px;">
            <v-sparkline
                :id="address"
                :value="dataList"
                :gradient="gradients"
                :smooth="5"
                :line-width="8"
                :height="80"
                :padding="5"
                stroke-linecap="round"
                gradient-direction="top"
                type="trend"
                auto-line-width
                :auto-draw-duration="800"
                auto-draw
              ></v-sparkline>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
                // 渐变颜色
                gradients: ['#4CAF50'],
                // 数据
                dataList: [],
                // 图表无趋势
                noTrend: false
            }
        },
        props: ['address'],
        created(){
            this.getNAV();
        },
        mounted(){

        },
        computed: {
            ...mapGetters(['responsive'])
        },
        watch:{

        },
        methods: {
            getNAV() {
                if (this.address.toLowerCase() == "0xca38034a69ebedd28ce62368890e7cf187309a0f") {
                    this.getDefyNAV();
                } else {
                    this.getPoolNAV();
                }
            },
            getDefyNAV() {
                let api = api = config.baseUrl + "/nav/public/defy?day=30";
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.dataList = data.result.navInUSD;
                        this.noTrend = this.dataList.every(e => e === this.dataList[0]);
                        if (this.dataList[0] <= this.dataList[this.dataList.length - 1]) {
                            this.gradients = ['#4CAF50'];
                        } else {
                            this.gradients = ['#F44336'];
                        }
                        // 设置图表
                        this.setChart();
                    }
                })
            },
            getPoolNAV() {
                let api = api = config.baseUrl + "/pool/nav/public/fee-adjusted/algorithm-3/" + this.address + '?day=30';
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.dataList = data.result.navInUSD;
                        this.noTrend = this.dataList.every(e => e === this.dataList[0]);
                        if (this.dataList[0] <= this.dataList[this.dataList.length - 1]) {
                            this.gradients = ['#4CAF50'];
                        } else {
                            this.gradients = ['#F44336'];
                        }
                        // 设置图表
                        this.setChart();
                    }
                })
            },
            // 设置图表
            setChart() {
                // 如果图表无趋势，则设置居中显示
                if (this.noTrend) {
                    setTimeout(() => {
                        // 获取SVG元素
                        var svgElement = document.getElementById(this.address);
                        // 设置新的viewBox值
                        if (svgElement != null) {
                            if (this.responsive == 'PC') {
                                svgElement.setAttribute('viewBox', '0 80 300 100');
                            } else {
                                svgElement.setAttribute('viewBox', '0 60 300 80');
                            }
                        }
                    }, 100)      
                }
            },
            formatNum(num) {
                if (num == null) return '';
                if (num == NaN || num == 'NaN') return '';
                if (num == 'undefined') return '';
                if (num == '--') return '--';
                let number = num + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return integerPart + decimalPart;
            },
        },
    }
</script>
<style scoped>

</style>