<template>
    <div>
        <v-container class="grid-list-xl px-0">
            <v-row justify="center" align="center" class="h-100 mx-0">
                <v-col cols="11" class="h-35 px-2">
                    <div class="fs-22 gradient9 fi_animate">iob.fi</div>
                    <div class="fs-68 py-8">DAO</div>
                    <div class="fs-28 dark-gray--text pb-16">{{ $t('dao.mobileCoordinatingAGlobalSet') }}</div>
                    <div class="ml-1">
                        <a href="https://rinkeby.client.aragon.org/#/fipopularvote/" target="_blank" class="del-underline">
                            <v-btn tile class="text-none button--gradient-yellow">{{ $t('dao.startVoting') }}</v-btn>
                        </a>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-16 pt-16 pb-150 mx-0">
                <v-col cols="11" class="px-3">
                    <div class="fs-22 gradient9 fi_animate pb-6">{{ $t('dao.wathIs') }}</div>
                    <div class="fs-54 pb-8">iob.fi DAO</div>
                    <div class="fs-16 dark-gray--text pb-8">{{ $t('dao.mostImportantSmartContractsAndAssets') }}</div>
                    <div class="fs-16 dark-gray--text pb-8">{{ $t('dao.substantialPurse') }}</div>
                    <div class="fs-16 dark-gray--text">{{ $t('dao.votingTakesPlace') }}</div>
                </v-col>
                <v-col cols="11" class="pt-16">
                    <div class="fs-22 pb-6"><span class="point-icon-m7 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">DAO</span></div>
                    <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('dao.decentralizedAutonomousOrganization') }}</div>
                    <div class="fs-22 pb-6"><span class="point-icon-m7 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('dao.powerToThePeople') }}</span></div>
                    <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('dao.throughTheDAO') }}</div>
                    <div class="fs-22 pb-6"><span class="point-icon-m7 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('dao.proposeAndVote') }}</span></div>
                    <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('dao.theCommunity') }}</div>
                    <div>
                        <a :href="faq + '/getting-started/faq/#iob-fi-dao'" target="_blank" class="del-underline">
                            <v-btn tile class="text-none mt-16 button--gradient-yellow">{{ $t('common.learnMore') }}</v-btn>
                        </a>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="bg-gradient-1">
            <v-container class="px-3 pt-150 pb-150">
                <v-col cols="11">
                    <div class="fs-22 gradient9 fi_animate pb-6">{{ $t('dao.aHybridVotingSystem') }}</div>
                    <div class="fs-54 pb-8 black--text">{{ $t('dao.popularVote') }}</div>
                    <div class="fs-16 black--text pb-8">{{ $t('dao.weAreProposingANovelApproach') }}</div>
                </v-col>
                <v-col cols="11" class="pt-16">
                    <div class="fs-22 pb-6 black--text"><span class="point-icon-m7 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('dao.trueDemocracy') }}</span></div>
                    <div class="fs-16 black--text pb-8 ml-7">{{ $t('dao.notOnlyForTheRich') }}</div>
                    <div class="fs-22 pb-6 black--text"><span class="point-icon-m7 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('dao.earningFIThroughVoting') }}</span></div>
                    <div class="fs-16 black--text pb-8 ml-7">{{ $t('dao.eachSuccessfulVote') }}</div>
                    <div class="fs-22 pb-6 black--text"><span class="point-icon-m7 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('dao.yourFreeVoteCounts') }}</span></div>
                    <div class="fs-16 black--text pb-8 ml-7">{{ $t('dao.yourTestnetVoteResult') }}</div>
                    <div>
                        <a href="https://rinkeby.client.aragon.org/#/fipopularvote/" target="_blank" class="del-underline">
                            <v-btn tile class="text-none mt-16 button--gradient-yellow">{{ $t('dao.getAirdrop') }}</v-btn>
                        </a>
                    </div>
                </v-col>
            </v-container>
        </div>
        <v-container class="px-0">
            <v-row justify="center" class="pt-150 pb-250 px-3">
                <v-col cols="11">
                    <div class="fs-22 gradient9 fi_animate pb-8">
                        {{ $t('dao.buildWithUS') }}
                    </div>
                    <div class="fs-22 dark-gray--text pb-8">
                        {{ $t('dao.weMakeDecisions') }}
                    </div>
                    <div class="fs-22 gradient9 fi_animate pb-8">
                        {{ $t('dao.shareTheSuccess') }}
                    </div>
                    <div class="fs-22 dark-gray--text pb-8">
                        {{ $t('dao.weArePassionateAboutTheValue') }}
                    </div>
                    <div class="fs-22 dark-gray--text">
                        {{ $t('dao.theFinancialRewards') }}
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="pt-150 pb-150">
                <v-col cols="11" class="text-center">
                    <div class="fs-22 gradient9 fi_animate">{{ $t('common.nutshell') }}</div>
                    <div class="fs-42 mt-16">{{ $t('dao.groupDecisionMaking') }}</div>
                    <a href="https://rinkeby.client.aragon.org/#/fipopularvote/" target="_blank" class="del-underline">
                        <v-btn tile class="mt-16 text-none button--gradient-yellow">{{ $t('dao.startVoting') }}</v-btn>
                    </a>
                </v-col>
            </v-row>
            <v-row justify="center" class="text-center">
                <v-col cols="11" class="py-0 my-0">
                    <a href="https://poweredby.aragon.org/" target="blank">
                        <logo type="aragon" color="aragon"></logo>
                    </a>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style>
</style>
<script>
import Vue from 'vue';
import config from '@/config';
import Logo from '@/components/common/Logo';

export default {
    data() {
        return {
            faq: config.faq
        }
    },
    created() {},
    mounted() {

    },
    computed: {

    },
    components: {
        Logo
    },
    watch: {

    },
    methods: {

    },
}
</script>
