<template>
	<div>
        <pc-dividend v-if="responsive == 'PC'"></pc-dividend>
        <mobile-dividend v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-dividend>
        <contact></contact>
	</div>
</template>
<script>
    import Vue from 'vue'
    import { mapGetters} from "vuex";
    import PcDividend from "./pc/Dividend";
    import MobileDividend from "./mobile/Dividend";
    import Contact from "@/pages/Contact.vue";
    export default {
        data(){
            return {

            }
        },
        components: { PcDividend, MobileDividend, Contact },
        created(){

        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive"]),
        },
        watch:{

        },
        methods: {

        },
    }
</script>
<style>

</style>