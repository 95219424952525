const state = {
	locale: 'en-US'
}

const getters = {
	locale: state => {
		let locale = localStorage.getItem('iob.fi-locale');
		if (locale != null) {
			state.locale = locale;
		} else {
			localStorage.setItem('iob.fi-locale', 'en-US');
			state.locale = 'en-US';
		}
		return state.locale;
	}
}

const actions = {
	localeHandler(context, payload){
		context.commit('localeHandler', payload);
	}
}

const mutations = {
	localeHandler(state, locale){
		localStorage.setItem("iob.fi-locale", locale);
		state.locale = locale;
		if (state.user != null) {
			state.user.locale = locale;
		}
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}