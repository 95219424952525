import { mapGetters } from "vuex";
import config from '@/config.js';
import $ from 'jquery';
import axios from 'axios';

// 参考https://cn.vuejs.org/v2/guide/plugins.html
export default{
	install(Vue){
		// 1. 添加全局方法或属性
		Vue.myGlobalMethod = function () {
		    // 逻辑...
		}

		// 2. 添加全局资源
		Vue.directive('my-directive', {
		    bind (el, binding, vnode, oldVnode) {
		    	// 逻辑...
			}
		    // ...
		})

		// 3. 注入组件选项
		Vue.mixin({
		    created: function () {

		    },
		    computed:{
	            ...mapGetters([])
	      	},
		    methods: {
		    	// 添加访问量
		    	addVisitVolume(){
					this.$http.get(config.baseUrl + "/visit/public/addVisit");
				},
                // 定义加载数据的函数
                // 初始化自适应屏幕
		    	initResponsive(){
					var ua = navigator.userAgent,
			        isWindowsPhone = /(?:Windows Phone)/.test(ua),
			        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
			        isAndroid = /(?:Android)/.test(ua),
					isFireFox = /(?:Firefox)/.test(ua),
					isIPad = (ua.indexOf("iPad") > 0),  
					isChrome = /(?:Chrome|CriOS)/.test(ua), 
					// isSmallScreen = (windows.screen.width <= 1336 && windows.screen.width > 736),  
					isSmallScreen = (document.body.clientWidth <= 1440),  
					isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
					isPhone = /(?:iPhone)/.test(ua) && !isTablet,
					isPC = !isPhone && !isAndroid && !isSymbian && !isSmallScreen;
			        if ((isAndroid || isPhone) && document.body.clientWidth < 960 ) {
				    	this.$store.dispatch('responsiveHandler', 'mobile');
				    } else if ((isTablet || isIPad)) {
				    	this.$store.dispatch('responsiveHandler', 'pad');
				    } else if(isPC || isSmallScreen) {
				    	this.$store.dispatch('responsiveHandler', 'PC');
				    } else {
				    	this.$store.dispatch('responsiveHandler', 'mobile');
					}
					// console.log(document.body.clientWidth)
					
			    },
			    // 获取当前国家代码
				getCountryCode(){
					var ip = null;
					let _this = this;
					$.getScript("https://pv.sohu.com/cityjson?ie=utf-8",function(data){  
							ip = returnCitySN["cip"];
							let api = "https://iob.community/iob/visit/open/getCountryCode?ip="+ip;
							axios.get(api).then(response=>{
								let data = response.data
								if(0 == data.resCode){
									_this.$store.dispatch('countryCodeHandler', data.resMsg);
								}
							})
					});
			    },
			    // 查询实时的DEFY价格
			    getRealTimeDefyNav(){
			    	let api = config.baseUrl + "/asset/public/getDefyNavFromNet";
		            // let api = "http://localhost:3000/asset/public/getDefyNavFromNet";
		            this.$http.get(api).then(res => {
		                let data = res.data;
		                if(data.code == 200){
		                    // 缓存defy价格
                            this.$store.dispatch('defyPriceHandler', data.result);
		                }
		            });
				},
				// 查询DEFY的年化收益率
			    getDefyAnnualizedReturn(){
			    	let api = config.baseUrl + "/defy/public/return/annualized";
		            // let api = "http://localhost:3000/defy/public/return/annualized";
		            this.$http.get(api).then(res => {
		                let data = res.data;
		                if(data.code == 200){
		                    // 缓存DEFY的年化收益率
                            this.$store.dispatch('defyAnnualizedReturnHandler', data.result);
		                }
		            });
				},
				// 查询系统版本号
				getVersion(){
					let api = config.baseUrl + '/system/public/version/iob.fi';
			        this.$http.get(api).then(res => {
			          let data = res.data;
			          if (data.code == 200) {
			            this.$store.dispatch('versionHandler', data.result);
			          }
			        });
				},
				// 查询iobots系统版本号
				getIobotsVersion(){
					let api = config.baseUrl + '/system/public/version/iobots';
			        this.$http.get(api).then(res => {
			          let data = res.data;
			          if (data.code == 200) {
			            this.$store.dispatch('iobotsVersionHandler', data.result);
			          }
			        });
				},
				// 从地址栏获取语言
			    getLanguage() {
				    // 从地址栏获取语言
				    let lang = this.$route.query.lang;
				    if (lang != null) {
				        // 这样判断则大小写都有效
				        lang = lang.toLowerCase();
				        // 取出本地的语言
				        let locale = localStorage.getItem("iob.fi-locale");
				        // 小写
				        locale = locale != null ? locale.toLowerCase() : "";
				        if (lang != locale) {
					        // 和本地语言不一样，需要切换语言
					        if("en-us" == lang){
					            localStorage.setItem('iob.fi-locale', 'en-US');
					        } else if ("zh-cn" == lang) {
					            localStorage.setItem('iob.fi-locale', 'zh-CN');
					        }
					        // 切换完重新加载
					        this.$router.go();
				        } else {
				        	// 否则和本地语言一样，不需要切换
				        }
				    }
			    }
		  	}
		})
		// 4. 添加实例方法
		Vue.prototype.init = function () {
			// 添加访问量
			this.addVisitVolume();
		    // 初始化自适应屏幕
		    this.initResponsive();
		    // 获取当前国家代码
	        this.getCountryCode();
	        // 查询实时的DEFY价格
			this.getRealTimeDefyNav();
			// 查询DEFY的年化收益率
			this.getDefyAnnualizedReturn();
			// 查询系统版本号
			this.getVersion();
			// 查询iobots系统版本号
			this.getIobotsVersion();
			// 从地址栏获取语言
			this.getLanguage();
		}
	}
}
