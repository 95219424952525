<template>
	<div>
        <!-- Description -->
        <div>
            <v-row class="fs-12 dark-gray--text">
                <v-col cols="12" class="fw-bold fs-20">
                    <v-col cols="12" class="px-0 white--text">
                        <h5>{{ $t('description.description') }}</h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
            </v-row>
        </div>
        <div v-if="address=='0xca38034a69ebedd28ce62368890e7cf187309a0f'">
            <v-row class="fs-12 dark-gray--text">
                <!-- <v-col cols="12" class="fw-bold fs-20">
                    <v-col cols="12" class="px-0 white--text">
                        <h5>{{ $t('description.description') }}</h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col> -->
                <v-col cols="12">
                    <div class="pb-4">
                        {{ $t('description.defyDescription') }}
                    </div>
                    <div class="fs-12 pb-2 dark-gray--text">{{ $t('description.defyBasedOn') }}</div>
                    <ol class="fs-12 pb-4 dark-gray--text">
                        <li>{{ $t('description.defyTradingDecisions1') }}</li>
                        <li>{{ $t('description.tradingDecisions2') }}</li>
                        <li>{{ $t('description.tradingDecisions3') }}</li>
                        <li>{{ $t('description.tradingDecisions4') }}</li>
                    </ol>
                    <div class="pb-2">{{ $t('description.toLearnMore') }}</div>
                    <div class="pl-3 title_txt">{{ $t('description.officialWebsite') }}: <a :href="fi" target="_blank" class="del-underline pure-red--text">https://iob.fi</a></div>
                    <div class="pl-3 title_txt">ioBots: <a :href="faq + '/getting-started/faq#what-is-iobots'" target="_blank" class="del-underline pure-red--text">https://docs.iob.fi/getting-started/faq#what-is-iobots</a></div>
                    <div class="pl-3 title_txt">YouTube: <a href="https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg" target="_blank" class="del-underline pure-red--text">https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg</a></div>
                </v-col>
                <!-- <v-col cols="12">
                    <div>{{ $t('description.positionsAPIs') }}</div>
                </v-col> -->
            </v-row>
        </div>
        <div v-if="address=='0x739f41c699276c9b6182aecb9d5f4eca226da8dd'">
            <v-row class="fs-12 dark-gray--text">
                <!-- <v-col cols="12" class="fw-bold fs-20">
                    <v-col cols="12" class="px-0 white--text">
                        <h5>{{ $t('description.description') }}</h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col> -->
                <v-col cols="12">
                    <div class="pb-4">
                        {{ $t('description.iobotsQuasarDescription') }}
                    </div>
                    <div class="pb-2">{{ $t('description.iobotsQuasarBasedOn') }}</div>
                    <ol class="pb-4">
                        <li>{{ $t('description.iobotsQuasarTradingDecisions1') }}</li>
                        <li>{{ $t('description.tradingDecisions2') }}</li>
                        <li>{{ $t('description.tradingDecisions3') }}</li>
                        <li>{{ $t('description.tradingDecisions4') }}</li>
                    </ol>
                    <div class="pb-2">{{ $t('description.toLearnMore') }}</div>
                    <div class="pl-3 title_txt">{{ $t('description.officialWebsite') }}: <a :href="fi" target="_blank" class="del-underline pure-red--text">https://iob.fi</a></div>
                    <div class="pl-3 title_txt">ioBots: <a :href="faq + '/getting-started/faq#what-is-iobots'" target="_blank" class="del-underline pure-red--text">https://docs.iob.fi/getting-started/faq#what-is-iobots</a></div>
                    <div class="pl-3 title_txt">YouTube: <a href="https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg" target="_blank" class="del-underline pure-red--text">https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg</a></div>
                </v-col>
                <!-- <v-col cols="12">
                    <div>{{ $t('description.positionsAPIs') }}</div>
                </v-col> -->
            </v-row>
        </div>
        <div v-if="address=='0x5d98a37f4e4d6fcd9d416f241c1b25fa6737691a'">
            <v-row class="fs-12 dark-gray--text">
                <!-- <v-col cols="12" class="fw-bold fs-20">
                    <v-col cols="12" class="px-0 white--text">
                        <h5>{{ $t('description.description') }}</h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col> -->
                <v-col cols="12">
                    <div class="pb-4">
                        {{ $t('description.iobotsBTCDescription') }}
                    </div>
                    <div class="pb-2">{{ $t('description.iobotsBTCBasedOn') }}</div>
                    <ol class="pb-4">
                        <li>{{ $t('description.iobotsBTCTradingDecisions1') }}</li>
                        <li>{{ $t('description.tradingDecisions2') }}</li>
                        <li>{{ $t('description.tradingDecisions3') }}</li>
                        <li>{{ $t('description.tradingDecisions4') }}</li>
                    </ol>
                    <div class="pb-2">{{ $t('description.toLearnMore') }}</div>
                    <div class="pl-3 title_txt">{{ $t('description.officialWebsite') }}: <a :href="fi" target="_blank" class="del-underline pure-red--text">https://iob.fi</a></div>
                    <div class="pl-3 title_txt">ioBots: <a :href="faq + '/getting-started/faq#what-is-iobots'" target="_blank" class="del-underline pure-red--text">https://docs.iob.fi/getting-started/faq#what-is-iobots</a></div>
                    <div class="pl-3 title_txt">YouTube: <a href="https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg" target="_blank" class="del-underline pure-red--text">https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg</a></div>
                </v-col>
                <!-- <v-col cols="12">
                    <div>{{ $t('description.positionsAPIs') }}</div>
                </v-col> -->
            </v-row>
        </div>
        <div v-if="address=='0x9b5518a3b55a4e922064873aa2e9ad1270c8ea77'">
            <v-row class="fs-12 dark-gray--text">
                <!-- <v-col cols="12" class="fw-bold fs-20">
                    <v-col cols="12" class="px-0 white--text">
                        <h5>{{ $t('description.description') }}</h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col> -->
                <v-col cols="12">
                    <div class="pb-4">
                        {{ $t('description.iobotsETHDescription') }}
                    </div>
                    <div class="pb-2">{{ $t('description.iobotsETHBasedOn') }}</div>
                    <ol class="pb-4">
                        <li>{{ $t('description.iobotsETHTradingDecisions1') }}</li>
                        <li>{{ $t('description.tradingDecisions2') }}</li>
                        <li>{{ $t('description.tradingDecisions3') }}</li>
                        <li>{{ $t('description.tradingDecisions4') }}</li>
                    </ol>
                    <div class="pb-2">{{ $t('description.toLearnMore') }}</div>
                    <div class="pl-3 title_txt">{{ $t('description.officialWebsite') }}: <a :href="fi" target="_blank" class="del-underline pure-red--text">https://iob.fi</a></div>
                    <div class="pl-3 title_txt">ioBots: <a :href="faq + '/getting-started/faq#what-is-iobots'" target="_blank" class="del-underline pure-red--text">https://docs.iob.fi/getting-started/faq#what-is-iobots</a></div>
                    <div class="pl-3 title_txt">YouTube: <a href="https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg" target="_blank" class="del-underline pure-red--text">https://www.youtube.com/channel/UC2ygCKhFNpv8-Mxx1ODQRSg</a></div>
                </v-col>
                <!-- <v-col cols="12">
                    <div>{{ $t('description.positionsAPIs') }}</div>
                </v-col> -->
            </v-row>
        </div>
        <div v-else>
            <v-row class="fs-12 dark-gray--text">
                <v-col cols="12" class="title_txt">
                    <div class="del-underline this-yellow--text" v-html="description"></div>
                </v-col>
            </v-row>
        </div>
        <!-- API描述 -->
        <div>
            <v-row class="fs-12 dark-gray--text">
                <v-col cols="12">
                    <div>{{ $t('description.positionsAPIs') }}</div>
                </v-col>
            </v-row>
        </div>
	</div>
</template>
<script>
    import config from '@/config.js';
    export default {
        data(){
            return {
                faq: config.faq,
                fi: config.domainName,
            }
        },
        props:['address', 'description'],
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {

        },
    }
</script>
<style>
.this-yellow--text {
  color: #999999 !important;
  caret-color: #999999 !important;
}
.this-yellow--text a {
  color: #FEE108 !important;
  caret-color: #FEE108 !important;
}
</style>