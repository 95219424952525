export default {
    // 格式化日期
    formatDate: function (time) {
        var date = new Date(time);
        var year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        // 拼接
        return (month + "/" + day + "/" + year);
    },
    // 格式化时间
    formatTime(timestamp){
        // 在日期格式中，月份是从0开始的，因此要加0
        // 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? ('0' + month) : month;
        var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        // return month + '/' + day + '/' +  + ' ' + hours + ':' + minutes + ':' + seconds;
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    // 格式化数字
    formatNumber: function (num) {
        if (num == null) return '';
        if (num == NaN || num == 'NaN') return '';
        if (num == 'undefined') return '';
        if (num == '--') return '--';
        let number = num + '';
        let numberArray = number.split('.');
        let integerPart = numberArray[0];
        let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(integerPart)) {
            integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
        }
        return integerPart + decimalPart;
    },
    formatNumberUnit : function(num, digits) {
        const si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "K" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "G" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    },
    formatBritishDate: function (time) {
        // 英式日期：日月年
        var date = new Date(time);
        var year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        // 拼接
        return (day + "/" + month + "/" + year);
    },
    // 格式化英式时间
    formatBritishTime(timestamp){
        // 在日期格式中，月份是从0开始的，因此要加0
        // 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? ('0' + month) : month;
        var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    // 集合是否有元素
    hasElements(list){
        return list != null && list.length > 0;
    },
    // 获取当前年份
    getCurrentYear(){
        return new Date().getFullYear();
    },
    // 获取区块链浏览器链接
    getBlockchainExplorerUrl(blockchain) {
        console.log(blockchain);
        switch(blockchain){
            case 'ETHEREUM': return 'https://etherscan.io';
            case 'POLYGON': return 'https://polygonscan.com';
            case 'ARBITRUM_ONE': return 'https://arbiscan.io';
        }
    },
}