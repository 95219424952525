/**
 * [切换环境]
 * @Date 2020/09/16
 * @Author [Louis, Renly, Shirley]
 * @Param {[String]} 环境
 */
// let data = getParamConfig('dev');						// 开发环境
// let data = getParamConfig('devUseTest');				// 开发环境
// let data = getParamConfig('test');					// 测试环境
let data = getParamConfig('prod');					// 生产环境

let config = {
	baseUrl: data.baseUrl,
	domainName: data.domainName,
	sip: data.sip,
	fund: data.fund,
	untrading: data.untrading,
	untradingDocs: data.untradingDocs,
	faq: data.faq,
	environment: data.environment
}

function getParamConfig(env){
	let data = {
		baseUrl: '',
		webSocketUrl: '',
		domainName: '',
		sip: '',
		fund: '',
		untrading: '',
		untradingDocs: '',
		faq: 'https://docs.iob.fi',
		environment: ''
	};
	switch (env) {
		case 'dev':
			data.baseUrl = "http://127.0.0.1:8080/dev";
			data.domainName = "http://127.0.0.1:8080";
			data.sip = 'http://118.126.113.35';
			data.fund = 'https://testnet.iobots.pro';
			data.untrading = 'https://testnet.untrading.org';
			data.untradingDocs = 'https://docs.untrading.org';
			data.environment = 'Development';
			break;
		case 'devUseTest':
			data.baseUrl = "http://127.0.0.1:8080/test";
			data.domainName = "http://127.0.0.1:8080";
			data.sip = 'http://118.126.113.35';
			data.fund = 'https://testnet.iobots.pro';
			data.untrading = 'https://testnet.untrading.org';
			data.untradingDocs = 'https://docs.untrading.org';
			data.environment = 'Development';
			break;
		case 'test':
			data.baseUrl = window.location.protocol + "//" + window.location.host + "/iobots";
			data.domainName = "https://testnet.iob.fi";
			data.sip = 'http://118.126.113.35';
			data.fund = 'https://testnet.iobots.pro';
			data.untrading = 'https://testnet.untrading.org';
			data.untradingDocs = 'https://docs.untrading.org';
			data.environment = 'Test';
			break;
		case 'prod':
			data.baseUrl = "https://api.iob.fi/iobots";
			data.domainName = "https://iob.fi";
			data.sip = "https://iob.community";
			data.fund = 'https://iobots.pro';
			data.untrading = 'https://untrading.org';
			data.untradingDocs = 'https://docs.untrading.org';
			data.environment = '';
			break;
		default:
			data.baseUrl = "http://127.0.0.1:3000";
			data.domainName = "http://127.0.0.1:8080";
			data.sip = 'http://118.126.113.35';
			data.fund = 'https://testnet.iobots.pro';
			data.untrading = 'https://testnet.untrading.org';
			data.untradingDocs = 'https://docs.untrading.org';
			data.environment = 'Development';
			break;
	}
	return data;
}

export default config
