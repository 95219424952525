<template>
	<span>
		<span :style="{width: (size * 0.5) + 'rem', height: (size * 0.5) + 'rem' }" class="icon-radius centering-container tw-bg-black-light" v-if="network == null || network.toLowerCase() == 'ethereum'">
			<img class="tw-inline-block" :style="{ width: (size * 0.4) + 'rem', height: (size * 0.4) + 'rem' }" src="../../../static/img/ethereum.svg" alt="">
		</span>
		<span :style="{width: (size * 0.5) + 'rem', height: (size * 0.5) + 'rem' }" class="icon-radius centering-container tw-bg-black-light" v-else-if="network.toLowerCase() == 'polygon'">
			<img class="tw-inline-block" :style="{ width: (size * 0.3) + 'rem', height: (size * 0.3) + 'rem' }" src="../../../static/img/polygon.svg" alt="">
		</span>
        <span :style="{width: (size * 0.5) + 'rem', height: (size * 0.5) + 'rem' }" class="icon-radius centering-container tw-bg-black-light" v-else-if="network.toLowerCase() == 'arbitrum_one'">
			<img class="tw-inline-block" :style="{ width: (size * 0.4) + 'rem', height: (size * 0.4) + 'rem' }" src="../../../static/img/arbitrum_one.svg" alt="">
		</span>
	</span>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {}
    },
    created() {

    },
    mounted() {

    },
	props: {
		network: {
			type: String,
			default: 'ethereum'
		},
		size: {
			type: Number,
			default: 1
		},
	},
    watch: {

    },
    methods: {
		
    }
  }

</script>

<style lang="scss" scoped>
	.icon-radius {
		padding: .5rem;
		border-radius: 9999px;
		position: relative;
	}
	.centering-container {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	}
	.tw-bg-black-light {
		--tw-bg-opacity: 1;
		background-color: rgba(49, 50, 50, var(--tw-bg-opacity));
	}
	.tw-inline-block {
	  display: inline-block;
	}
</style>
