<template>
    <div>
        <v-container class="grid-list-xl px-0 pb-250">
            <v-row justify="center" align="center" class="h-100">
                <v-col cols="12" class="text-center gradient6 fi_animate" :class="scroll > 130 ? 'shrink fs-48 mt-16' : 'homeTitle'">
                    <span>{{ $t('untrading.endingZeroSumTrading') }}</span>
                </v-col>
            </v-row>
            <v-row justify="center" class="pb-16">
                <v-col cols="12" class="text-center fs-28 dark-gray--text">
                    <div>{{ $t('untrading.givingCircle') }}</div>
                    <a :href="untrading" class="del-underline" target="_blank">
                        <v-btn tile class="text-none mt-16 button--gradient-orange glow-orange black--text">{{ $t('common.learnMore') }}</v-btn>
                    </a>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" class="pt-250">
            </v-row>
            <v-row justify="center" class="mt-16 pt-16">
                <v-col cols="6" class="pl-16">
                    <div class="fs-24 gradient3 fi_animate">{{ $t('untrading.givingSomeToGetMore') }}</div>
                    <div class="fs-68 mt-2"><span v-html="$t('untrading.payingItForward')"></span></div>
                    <a :href="untradingDocs + '/whitepaper'" class="del-underline" target="_blank">
                        <v-btn tile class="text-none mt-16 gradient-btn glow">{{ $t('common.learnMore') }}</v-btn>
                    </a>
                </v-col>
                <v-col cols="6" class="fs-24">
                    <div class="pb-6 pt-14">
                        <span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span>
                        <span class="pl-9"><span v-html="$t('untrading.selling')"></span></span>
                    </div>
                    <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('untrading.futureRewardsFramework') }}</div>
                    <div class="pb-6"><span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('untrading.payLess') }}</span></div>
                    <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('untrading.noProfitInATrade') }}</div>
                    <div class="pb-6"><span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('untrading.endTheZeroSumGame') }}</span></div>
                    <div class="fs-18 dark-gray--text pl-9">{{ $t('untrading.joinOur') }}</div>
                </v-col>
            </v-row>
            <v-row justify="center" class="pt-250">
                <v-col cols="6" class="pl-16">
                    <div class="fs-24 gradient7 fi_animate">{{ $t('untrading.whyYouLoseWhenTrading') }}</div>
                    <div class="fs-68 mt-2"><span v-html="$t('untrading.itIsByDesign')"></span></div>
                    <a :href="untradingDocs + '/whitepaper#h.kplu6gt7s2fs'" class="del-underline" target="_blank">
                        <v-btn tile class="text-none mt-16 button--gradient-pink glow-purple">{{ $t('common.learnMore') }}</v-btn>
                    </a>
                </v-col>
                <v-col cols="6" class="fs-24">
                    <div class="pb-6 pt-14"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('untrading.itIsTheirGame') }}</span></div>
                    <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('untrading.manyTradersFallVictim') }}</div>
                    <div class="pb-6"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('untrading.youAreJustAFeeGenerator') }}</span></div>
                    <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('untrading.inTheCurrentSchemeOfThings') }}</div>
                    <div class="pb-6"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('untrading.letUsChangeTheGameTogether') }}</span></div>
                    <div class="fs-18 dark-gray--text pl-9">{{ $t('untrading.longTermTrends') }}</div>
                </v-col>
            </v-row>
        </v-container>
        <div class="bg-gradient-1">
            <v-container class="px-0 pt-250 pb-250">
                <v-row justify="center">
                    <v-col md="11" class="fs-24 black--text">
                        {{ $t('about.investors') }}
                    </v-col>
                    <v-col md="11">
                        <v-row no-gutters>
                            <v-col cols="4" class="mt-5">
                                <a :href="fund" class="del-underline" target="_blank">
                                    <!-- <v-card light tile outlined max-width="350" class="pa-2 text-center"> -->
                                        <logo type="sponsor" color="iob-m"></logo>
                                        <span class="fs-12 ml-n2 black--text">Token Holders</span>
                                    <!-- </v-card> -->
                                </a>
                            </v-col>
                            <v-col cols="4" class="mt-5">
                                <a href="https://spicevc.com/" class="del-underline" target="_blank">
                                    <!-- <v-card light outlined tile max-width="350" class="pa-2 text-center"> -->
                                        <logo type="sponsor" color="spice"></logo>
                                        <span class="fs-12 ml-5 black--text">SPiCE VC</span>
                                    <!-- </v-card> -->
                                </a>
                            </v-col>
                            <v-col cols="4">
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-16 pt-16" justify="center">
                    <v-col cols="11" class="fs-24 black--text">
                        {{ $t('about.media') }}
                    </v-col>
                    <v-col cols="11">
                        <v-row>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1607322197796-a2a90b1f56ea41ef96814a16233bc4a3-bitcoin.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1607322269797-aff1e11e754746f885614db67939519b-coin.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1607322109797-19a69c82ed74479eac1356dbf00dfdf8-amb.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1605670494928-12d551b9a0e55af28eadf39f1b98a6e4-nasdaq.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1605670488925-b050610df653507ba8311015c54bd422-morningstar.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1605670447902-d897542ae4315d199d6c9e3ade4022d6-bloomberg.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1605670585975-e5fbe65321ed524182bb0b52fa914dc1-yahoofinance.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1606379209080-ab22ec80fa1b56aab9abccb3476357ac-ibt.webp" alt="">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1605670534948-dc4db58e8ac453df8b7b4a64f1d3a203-sina.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1606379072081-ef8c9c3624de5748b3f4caece66d3958-if.webp" alt="">
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <img src="https://files.iob.fi/2020/11/1605670567967-cf06a60af9f854e9b90cad27bf1e443e-sohu.webp" alt="">
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="px-0 pt-250 pb-250">
            <v-row justify="center">
                <v-col md="11">
                    <div class="fs-24 gradient6 fi_animate">{{ $t('about.workWithUS') }}</div>
                    <div class="fs-48 pb-8 mt-2">{{ $t('about.makingContribution') }}</div>
                    <div class="fs-24 pb-8 dark-gray--text">{{ $t('about.privilegeToHavePeopleInterested') }}</div>
                    <div class="fs-24 pb-8 dark-gray--text">{{ $t('about.contributingMembers') }}</div>
                    <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.institutionalQuantDevelopers') }}</span></div>
                    <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.quantitativeApplicationDevelopers') }}</span></div>
                    <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.counterpartyRiskAnalytics') }}</span></div>
                    <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.smartCntractDevelopers') }}</span></div>
                    <div class="ml-5 pb-8 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.communityLeaders') }}</span></div>
                    <div class="fs-24 pb-8 dark-gray--text">{{ $t('about.requirements') }}</div>
                    <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.veryGoodEnglish') }}</span></div>
                    <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.passionateAboutFintech') }}</span></div>
                    <div class="ml-5 pb-16 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.anAutonomousIndividua') }}</span></div>
                    <a href="https://forms.gle/vegRcSAwNqacsxbi8" target="_blank" class="del-underline">
                        <v-btn tile color="soft-blue" class="text-none button--gradient-orange glow-orange black--text">{{ $t('common.applyNow') }}</v-btn>
                    </a>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style>
.display1 {
    animation: dongHua 1s 1 ease-in-out;
    -webkit-animation: dongHua 1s 1 ease-in-out;
}

.display2 {
    animation: dongHua 2s 1 ease-in-out;
    -webkit-animation: dongHua 2s 1 ease-in-out;
}

.display3 {
    animation: dongHua 3s 1 ease-in-out;
    -webkit-animation: dongHua 3s 1 ease-in-out;
}

@keyframes dongHua {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
import Logo from '@/components/common/Logo';
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            scroll: 0,
            fund: config.fund,
            untrading: config.untrading,
            untradingDocs: config.untradingDocs,
        }
    },
    components: { Logo },
    created() {
        this.scrollEvent();
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    watch: {

    },
    methods: {
        scrollEvent() {
            window.addEventListener('scroll', this.handleScroll);
        },
        getCurrentScroll() {
            return window.pageYOffset || document.documentElement.scrollTop;
        },
        handleScroll() {
            this.scroll = this.getCurrentScroll();
        },
    },
    beforeDestroy() {

    }
}
</script>
