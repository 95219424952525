<template>
	<div>
        <div class="mb-10" v-if="responsive == 'PC'">
            <span class="fs-48">
                {{ netAssetValue == '--' ? '' : ('$' + formatNum(netAssetValue.toFixed(4)) + ' &#8727;' ) }}
            </span>
            <span class="float-right ml-3 mt-5" v-show="performance != '--'">
                <v-tooltip top :max-width="400" fixed :close-delay="100" :z-index="99">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-icon color="grey" class="pointer">icon icon-help</v-icon>
                        </span> 
                    </template>
                    <span>
                        * Price feed for the synthetic assets held in this pool may not be sourced from the same markets used by dHEDGE and may be delayed up to 20 minutes. Information is provided 'as is' and solely for informational purposes, not for trading purposes. Transactions in this pool should only be executed directly through <a href="https://app.dhedge.org/" target="_blank" class="lineblue--text">https://app.dhedge.org/</a>.
                    </span>
                </v-tooltip>
            </span>
            <span class="float-right fs-48" :class="performance == '--' ? '' : (performance > 0 ? 'green--text' : 'red--text')" >{{ performance == '--' ? '' : (performance > 0 ? ('+' + performance + '%') : (performance + '%')) }}</span>
        </div>
        <div class="mb-10" v-if="responsive == 'mobile'">
            <div class="fs-48">
                {{ netAssetValue == '--' ? '' : ('$' + formatNum(netAssetValue.toFixed(4)) + ' &#8727;' ) }}
            </div>
            <div>
                <span class="fs-48" :class="performance == '--' ? '' : (performance > 0 ? 'green--text' : 'red--text')" >{{ performance == '--' ? '' : (performance > 0 ? ('+' + performance + '%') : (performance + '%')) }}</span>
                <span class="ml-3" v-show="performance != '--'">
                    <v-tooltip top :max-width="400" fixed :close-delay="100" :z-index="99">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-icon class="mb-5" color="grey">mdi-help-circle</v-icon>
                            </span> 
                        </template>
                        <span>
                            * Price feed for the synthetic assets held in this pool may not be sourced from the same markets used by dHEDGE and may be delayed up to 20 minutes. Information is provided 'as is' and solely for informational purposes, not for trading purposes. Transactions in this pool should only be executed directly through <a href="https://app.dhedge.org/" target="_blank" class="lineblue--text">https://app.dhedge.org/</a>.
                        </span>
                    </v-tooltip>
                </span>
            </div>
        </div>
        <v-sparkline
            :value="dataList"
            :gradient="gradients"
            :smooth="5"
            :line-width="1.3"
            :height="120"
            :padding="0"
            stroke-linecap="round"
            gradient-direction="top"
            type="trend"
            auto-line-width
            :auto-draw-duration="1500"
            auto-draw
          ></v-sparkline>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
                environment: config.environment,
                gradients: ['#4CAF50', '#FEE108'],
                dataList: [],
                netAssetValue: '--',
                performance: '--'
            }
        },
        props: ['address'],
        created(){
            this.getPoolNAV();
        },
        mounted(){

        },
        computed: {
            ...mapGetters(['responsive'])
        },
        watch:{

        },
        methods: {
            getPoolNAV() {
                let api = api = config.baseUrl + "/pool/nav/public/fee-adjusted/algorithm-3/" + this.address;
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.dataList = data.result.navInUSD;
                        this.netAssetValue = data.result.navInUSD[data.result.navInUSD.length - 1];
                        this.performance = (data.result.roe * 100).toFixed(2);
                    }
                })
            },
            formatNum(num) {
                if (num == null) return '';
                if (num == NaN || num == 'NaN') return '';
                if (num == 'undefined') return '';
                if (num == '--') return '--';
                let number = num + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return integerPart + decimalPart;
            },
        },
    }
</script>
