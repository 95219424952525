<template>
    <div>
        <v-card v-if="responsive == 'PC'">
            <v-card-text>
                <v-row dense>
                    <v-col cols="2">Starting Date</v-col>
                    <v-col cols="10" class="text-right">
                        <v-row dense>
                            <v-col cols="2">1 Months</v-col>
                            <v-col cols="2">2 Months</v-col>
                            <v-col cols="2">3 Months</v-col>
                            <v-col cols="2">6 Months</v-col>
                            <v-col cols="2">1 Year</v-col>
                            <v-col cols="2">Since Inception</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="2">{{date}}</v-col>
                    <v-col cols="10" class="text-right">
                        <v-row dense>
                            <v-col cols="2" :class="historyObj == null || historyObj.defy1Month[0].roe == 0  ? '' : ( historyObj.defy1Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                                {{ historyObj == null || historyObj.defy1Month[0].roe == 0 ? "--" : historyObj.defy1Month[0].roe.toFixed(2) + "%"}}
                            </v-col>
                            <v-col cols="2" :class="historyObj == null || historyObj.defy2Month[0].roe == 0 ? '' : ( historyObj.defy2Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                                {{ historyObj == null || historyObj.defy2Month[0].roe == 0 ? "--" : historyObj.defy2Month[0].roe.toFixed(2) + "%"}}
                            </v-col>
                            <v-col cols="2" :class="historyObj == null || historyObj.defy3Month[0].roe == 0 ? '' : ( historyObj.defy3Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                                {{ historyObj == null || historyObj.defy3Month[0].roe == 0 ? "--" : historyObj.defy3Month[0].roe.toFixed(2) + "%"}}
                            </v-col>
                            <v-col cols="2" :class="historyObj == null || historyObj.defy6Month[0].roe == 0 ? '' : ( historyObj.defy6Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                                {{ historyObj == null || historyObj.defy6Month[0].roe == 0 ? "--" : historyObj.defy6Month[0].roe.toFixed(2) + "%"}}
                            </v-col>
                            <v-col cols="2" :class="historyObj == null || historyObj.defy1Year[0].roe == 0 ? '' : ( historyObj.defy1Year[0].roe > 0 ? 'green--text' : 'red--text' )">
                                {{ historyObj == null || historyObj.defy1Year[0].roe == 0 ? "--" : historyObj.defy1Year[0].roe.toFixed(2) + "%"}}
                            </v-col>
                            <v-col cols="2" :class="historyObj == null || historyObj.sinceInception[0].roe == 0 ? '' : ( historyObj.sinceInception[0].roe > 0 ? 'green--text' : 'red--text' )">
                                {{ historyObj == null || historyObj.sinceInception[0].roe == 0 ? "--" : historyObj.sinceInception[0].roe.toFixed(2) + "%"}}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-if="responsive == 'mobile'">
            <v-row class="px-3">
                <v-col cols="12">
                    <span>Starting Date</span>
                    <span class="float-right dark-gray--text">{{date}}</span>
                </v-col>
                <v-col cols="12">
                    <span>1 Months</span>
                    <span class="float-right" :class="historyObj == null || historyObj.defy1Month[0].roe == 0 ? '' : ( historyObj.defy1Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                        {{ historyObj == null || historyObj.defy1Month[0].roe == 0 ? "--" : historyObj.defy1Month[0].roe.toFixed(2) + "%"}}
                    </span>
                </v-col>
                <v-col cols="12">
                    <span>2 Months</span>
                    <span class="float-right" :class="historyObj == null || historyObj.defy2Month[0].roe == 0 ? '' : ( historyObj.defy2Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                        {{ historyObj == null || historyObj.defy2Month[0].roe == 0 ? "--" : historyObj.defy2Month[0].roe.toFixed(2) + "%"}}
                    </span>
                </v-col>
                <v-col cols="12">
                    <span>3 Months</span>
                    <span class="float-right" :class="historyObj == null || historyObj.defy3Month[0].roe == 0 ? '' : ( historyObj.defy3Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                        {{ historyObj == null || historyObj.defy3Month[0].roe == 0 ? "--" : historyObj.defy3Month[0].roe.toFixed(2) + "%"}}
                    </span>
                </v-col>
                <v-col cols="12">
                    <span>6 Months</span>
                    <span class="float-right" :class="historyObj == null || historyObj.defy6Month[0].roe == 0 ? '' : ( historyObj.defy6Month[0].roe > 0 ? 'green--text' : 'red--text' )">
                        {{ historyObj == null || historyObj.defy6Month[0].roe == 0 ? "--" : historyObj.defy6Month[0].roe.toFixed(2) + "%"}}
                    </span>
                </v-col>
                <v-col cols="12">
                    <span>1 Year</span>
                    <span class="float-right" :class="historyObj == null || historyObj.defy1Year[0].roe == 0 ? '' : ( historyObj.defy1Year[0].roe > 0 ? 'green--text' : 'red--text' )">
                        {{ historyObj == null || historyObj.defy1Year[0].roe == 0 ? "--" : historyObj.defy1Year[0].roe.toFixed(2) + "%"}}
                    </span>
                </v-col>
                <v-col cols="12">
                    <span>Since Inception</span>
                    <span class="float-right" :class="historyObj == null || historyObj.sinceInception[0].roe == 0 ? '' : ( historyObj.sinceInception[0].roe > 0 ? 'green--text' : 'red--text' )">
                        {{ historyObj == null || historyObj.sinceInception[0].roe == 0 ? "--" : historyObj.sinceInception[0].roe.toFixed(2) + "%"}}
                    </span>
                </v-col>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Vue from 'vue';
import config from '@/config.js';
import { mapGetters} from "vuex";
export default {
    data(){
        return{
            date:"--",
            historyObj:null,
        }
    },
    created(){
        this.getDefyNavPortfolio();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    watch:{
        
    },
    methods: {
        // 获取defyNav的历史
        getDefyNavPortfolio(){
            // let api = config.fund + "/iobots/asset/public/defy/getDefyNavHistory";
            let api = config.baseUrl + "/asset/public/iobots/performance/defy";
            this.$http.get(api).then((res) => {
                let data = res.data;
                if(data.code == 200){
                    this.date = data.result.date;
                    this.historyObj = data.result.history;
                }
            }).catch((err) => {
                
            });
        },
    },
}
</script>

<style>

</style>