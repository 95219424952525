import { render, staticRenderFns } from "./Holdings.vue?vue&type=template&id=6e7ca3c0&scoped=true&"
import script from "./Holdings.vue?vue&type=script&lang=js&"
export * from "./Holdings.vue?vue&type=script&lang=js&"
import style0 from "./Holdings.vue?vue&type=style&index=0&id=6e7ca3c0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e7ca3c0",
  null
  
)

export default component.exports