<template>
	<div>
        <v-container class="grid-list-xl px-0 mt-70">
            <v-row justify="center" align="center mt-70">
                <v-col cols="11" class="text-center">
                    <span>PC VIEW SUPPORTED ONLY</span>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" v-if="false">
                <v-col cols="11" class="px-0">
                    <h5>
                        <span @click="getUserTx(walletAddress)">My investments</span>
                    </h5>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <v-col cols="11" class="px-0" v-if="walletAddress != null && metaMaskNet == ''">
                    <h5>
                        <span>DAO Assets</span>
                    </h5>
                    <v-divider class="dark-grey mb-5"></v-divider>
                    <v-card tile class="w-500">
                        <v-card-text class="pt-2 pb-2 fs-16">
                            <v-row dense>
                                <v-col cols="2" class="">Name</v-col>
                                <v-col cols="2" ><span class="w-70">Symbol</span></v-col>
                                <v-col cols="4" class="text-right">Balance</v-col>
                                <v-col cols="4" class="text-right">Value</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-divider class="dark-grey"></v-divider>
                    <v-expansion-panels hover tile accordion focusable v-if="!loading">
                        <v-expansion-panel v-for="(item, i) in daoAssetItems" :readonly="item.data.length == 0" :key="i">
                            <v-expansion-panel-header>
                                <v-row class="fs-16">
                                    <v-col cols="2">
                                        <span class="ml-n2">{{item.name}}</span>
                                    </v-col>
                                    <v-col cols="1">
                                        <span class="ml-n2">{{item.symbol}}</span>
                                    </v-col>
                                    <v-col cols="4" class="dark-gray--text text-right">
                                        <span class="mr-n2">{{item.balance == null || item.balance == 0 ? "--" : formatNum(item.balance.toFixed(4))}}</span>
                                    </v-col>
                                    <v-col cols="4" class="dark-gray--text text-right" v-if="item.name == 'IOB'">
                                        <span class="mr-n2">{{item.value == null || item.value == 0 ? "--" : '$' + formatNum(item.value.toFixed(4))}}</span>
                                    </v-col>
                                    <v-col cols="4" class="dark-gray--text text-right" v-if="item.name == 'FI'">
                                        <span class="mr-n2">{{item.balance == null || item.balance == 0 ? "--" : '$' + formatNum((item.balance * fiPrice).toFixed(4))}}</span>
                                    </v-col>
                                </v-row>
                                <template v-slot:actions>
                                    <v-img v-if="item.data.length == 0" src="../../../public/img/1.png" alt="" class="ml-n5"></v-img>
                                    <v-icon v-else class="ml-n5">mdi-chevron-down</v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="mx-n9">
                                    <v-col cols="12" >
                                        <v-data-table disable-sort :headers="userHeaders" :items="item.data" :items-per-page="10" class="dark-gray--text my-n3 mx-0 px-0 black-gradient">
                                            <template v-slot:body="{ items }">
                                                <tbody>
                                                    <tr v-for="(childItem, i) in items" :key="i">
                                                        <td>{{ childItem.symbol }}</td>
                                                        <td>{{ childItem.side }}</td>
                                                        <td class="text-right">{{ formatNum(parseHex(childItem.requestAmount).toFixed(4)) }}</td>
                                                        <!-- <td class="text-right">--</td> -->
                                                        <td class="text-right">{{ childItem.fee }}</td>
                                                        <td>{{ childItem.time }}</td>
                                                        <td class="title_txt pr-0">
                                                            <a :href="'https://etherscan.io/tx/' + childItem.hash" target="_blank" rel="noopener noreferrer" class="del-underline">
                                                                <span class="pure-red--text">{{childItem.hash.substring(0,6) + "..." + childItem.hash.substring(childItem.hash.length-4)}}&#8599;</span>&nbsp;
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-card tile v-if="loading">
                        <v-skeleton-loader dense type="table-row-divider@2"></v-skeleton-loader>
                    </v-card>
                    <!-- <h5 class="mt-5">
                        <span>Pool Assets</span>
                    </h5>
                    <v-divider class="dark-grey mb-5"></v-divider>
                    <v-card tile>
                    <v-card-text class="pt-2 pb-2 fs-16">
                        <v-row dense>
                            <v-col cols="2" class="">Name</v-col>
                            <v-col cols="1" class="">Symbol</v-col>
                            <v-col cols="4" class="text-right">Balance</v-col>
                            <v-col cols="4" class="text-right">Value</v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider class="dark-grey"></v-divider>
                    </v-card> -->
                    <!-- <v-expansion-panels hover tile accordion focusable v-if="!loading">
                        <v-expansion-panel v-for="item in poolAssetItems" :readonly="item.data.length == 0">
                            <v-expansion-panel-header>
                                <v-row class="fs-16">
                                    <v-col cols="2">
                                        <span class="ml-n2">{{item.name}}</span>
                                    </v-col>
                                    <v-col cols="1">
                                        <span class="ml-n2">{{item.symbol}}</span>
                                    </v-col>
                                    <v-col cols="4" class="dark-gray--text text-right">
                                        <span class="mr-n2">{{item.balance == null || item.balance == 0 ? "--" : formatNum(item.balance.toFixed(4))}}</span>
                                    </v-col>
                                    <v-col cols="4" class="dark-gray--text text-right">
                                        <span class="mr-n2">{{item.value == null || item.value == 0 ? "--" : '$' + formatNum(item.value.toFixed(4))}}</span>
                                    </v-col>
                                </v-row>
                                <template v-slot:actions>
                                    <v-img v-if="item.data.length == 0" src="../../../public/img/1.png" alt="" class="ml-n5"></v-img>
                                    <v-icon v-else class="ml-n5">mdi-chevron-down</v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="mx-n9">
                                    <v-col cols="12" >
                                        <v-data-table disable-sort :headers="poolHeaders" :items="item.data" :items-per-page="10" class="dark-gray--text my-n3 mx-0 px-0 black-gradient">
                                            <template v-slot:body="{ items }">
                                                <tbody>
                                                    <tr>
                                                        <td :colspan="poolHeaders.length" class="px-0">
                                                            <v-expansion-panels hover tile accordion focusable>
                                                                <v-expansion-panel v-for="childItem in item.data">
                                                                    <v-expansion-panel-header>
                                                                        <v-row class="dark-gray--text">
                                                                            <v-col cols="1">
                                                                                <span class="ml-n2">{{childItem.symbol}}</span>
                                                                            </v-col>
                                                                            <v-col cols="2" class="text-right ml-10">
                                                                                <span >{{childItem.side}}</span>
                                                                            </v-col>
                                                                            <v-col cols="2" class="text-right ml-1">
                                                                                <span>{{ formatNum(parseHex(childItem.requestAmount).toFixed(4)) }}</span>
                                                                            </v-col>
                                                                            <v-col cols="2" class="text-right ml-10">
                                                                                <span>{{ childItem.fee }}</span>
                                                                            </v-col>
                                                                            <v-col cols="2" class="ml-2">
                                                                                <span>{{ childItem.time }}</span>
                                                                            </v-col>
                                                                            <v-col cols="2" class="title_txt ml-n8">
                                                                                <a :href="'https://etherscan.io/tx/' + childItem.hash" target="_blank" rel="noopener noreferrer" class="del-underline">
                                                                                    <span class="pure-red--text">{{childItem.hash.substring(0,6) + "..." + childItem.hash.substring(childItem.hash.length-4)}}&#8599;</span>&nbsp;
                                                                                </a>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <template v-slot:actions>
                                                                            <v-img v-if="childItem.info.length == 0" src="../../../public/img/1.png" alt="" class="ml-n5"></v-img>
                                                                            <v-icon v-else class="ml-n5">mdi-chevron-down</v-icon>
                                                                        </template>
                                                                    </v-expansion-panel-header>
                                                                    <v-expansion-panel-content v-if="childItem.info.length > 0">
                                                                        <v-row class="dark-gray--text">
                                                                            <v-col cols="12" class="px-0">
                                                                                <v-row dense>
                                                                                    <v-col cols="3">
                                                                                        <span>Symbol</span>
                                                                                    </v-col>
                                                                                    <v-col cols="3">
                                                                                        <span>From</span>
                                                                                    </v-col>
                                                                                    <v-col cols="3">
                                                                                        <span>To</span>
                                                                                    </v-col>
                                                                                    <v-col cols="3" class="text-right">
                                                                                        <span>Amount</span>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-divider></v-divider>
                                                                                <v-row v-for="(info,index) in childItem.info" dense>
                                                                                    <v-col cols="3">{{info.symbol}}</v-col>
                                                                                    <v-col cols="3" class="title_txt">
                                                                                        <a :href="'https://etherscan.io/address/' + info.fromAddress" target="_blank" class="del-underline pure-red--text" rel="noopener noreferrer" :title="info.fromAddress">{{info.fromAddress.substring(0,6) + "..." + info.fromAddress.substring(info.fromAddress.length-4)}}&#8599;</a>
                                                                                    </v-col>
                                                                                    <v-col cols="3" class="title_txt">
                                                                                        <a :href="'https://etherscan.io/address/' + info.toAddress" target="_blank" class="del-underline pure-red--text" rel="noopener noreferrer" :title="info.toAddress">{{info.toAddress.substring(0,6) + "..." + info.toAddress.substring(info.toAddress.length-4)}}&#8599;</a>
                                                                                    </v-col>
                                                                                    <v-col cols="3" class="text-right">{{formatNum(info.amount.toFixed(4))}}</v-col>
                                                                                    <v-col v-if="index < childItem.info.length-1">
                                                                                        <v-divider></v-divider>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-expansion-panel-content>
                                                                </v-expansion-panel>
                                                            </v-expansion-panels>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels> -->
                    <v-card tile v-if="loading">
                        <v-skeleton-loader dense type="table-row-divider@4"></v-skeleton-loader>
                    </v-card>
                </v-col>
                <!-- 未连接 -->
                <v-col cols="11" class="px-0" v-if="walletAddress == null || metaMaskNet != ''">
                    <v-card class="text-center" height="380px">
                        <v-card-text class="pt-16">
                        </v-card-text>
                        <v-card-text class="pt-16">
                            <v-btn v-if="metaMaskNet == '' && metaMaskNet == ''" tile class="text-none mt-5 button--gradient-pink glow-red mt-2" small @click="connectWallet">Connect Wallet</v-btn>
                        </v-card-text>
                        <v-card-text class="pt-2">
                            <span v-if="metaMaskNet != ''" >MAINNET NETWORK SUPPORTED ONLY</span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<style scoped>
.w-70{
    width: 70px!important;
    table-layout: fixed!important;
}
.w-500{
    width: 500px!important;
    table-layout: fixed!important;
}
</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import Bus from "@/components/bus/Bus";
    
    export default {
        data(){
            return {
                userHeaders:[
                    { text: "Symbol" },
                    { text: "Type" },
                    { text: "Amount", align:"end" },
                    // { text: "Value ( ETH )", align:"end" },
                    { text: "Fee ( ETH )", align:"end" },
                    { text: "Time" },
                    { text: "Hash" },
                ],
                poolHeaders:[
                    { text: "Symbol" },
                    { text: "Type", align:"end" },
                    { text: "Amount", align:"end" },
                    // { text: "Value ( ETH )", align:"end" },
                    { text: "Fee ( ETH )", align:"end" },
                    { text: "Time" },
                    { text: "Hash" },
                    { text: "" },
                ],
                loading:false,
                daoAssetItems:[],
                poolAssetItems:[],
            }
        },
        created(){
        },
        mounted(){
            if(this.walletAddress != null && this.metaMaskNet == ''){
                this.getUserTx(this.walletAddress);
            }
        },
        computed: {
            ...mapGetters(['metaMask', 'metaMaskNet','walletAddress','responsive','fiethPrice','ethPrice']),
            fiPrice(){
                return this.fiethPrice * this.ethPrice;
            }
        },
        components:{
        },
        watch:{
            walletAddress(val){
                if(val != null && this.metaMaskNet == ''){
                    this.getUserTx(val)
                }
            },
            metaMaskNet(val){
                if(val == '' && this.walletAddress != null){
                    this.getUserTx(this.walletAddress)
                }
            }
        },
        methods: {
           getUserTx(address){
                if(address == null){
                    return;
                }
                this.loading = true;
                // var addr = "0x4cef296b64923d690f04aca018fac3228f2f71e8";// DEFY-1
                // var addr = "0x993E5cFf7f2dfC727576E4aaDE79f6a7Ec36bb98";// Quasar
                // var addr = "0x64f381f69431c32bf88be85172d16bd719b9c5a3";// IOB
                // var addr = "0xbddf82bdb823d666b5bff940038ecb66f1ce41b5";// FI
                // var addr = "0x00ec30bb1ebd8ae63a54490543d2766279c96022";
                // let api = config.baseUrl + "/pool/activity/public/transactions/user?address=" + addr;
                let api = config.baseUrl + "/pool/activity/public/transactions/user?address=" + address;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.daoAssetItems = data.result.dao;
                        this.poolAssetItems = data.result.pool;
                        this.loading = false;
                    }else{
                        this.loading = false;
                    }
                })
            },
            // 连接钱包
            connectWallet(){
                Bus.$emit('isShowConnectWalletMenu', true);
            },
            parseHex(hex){
                // return this.web3.utils.hexToNumberString(hex) / Math.pow(10,18);
                return hex / Math.pow(10,18);
            },
            formatUTCTime(timestamp){
                // 在日期格式中，月份是从0开始的，因此要加0
                // 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11
                var date = new Date(timestamp);
                var year = date.getUTCFullYear();
                var month = date.getUTCMonth() + 1;
                month = month < 10 ? ('0' + month) : month;
                var day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
                var hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
                var minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
                var seconds = date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();
                return month + '-' + day + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
            },
            formatNum(num) {
                if (num == null) return '';
                if (num == NaN || num == 'NaN') return '';
                if (num == 'undefined') return '';
                if (num == '--') return '--';
                let number = num + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return integerPart + decimalPart;
            },
            copy(val) {
                let _this = this;
                this.$copyText(val, this.$refs.container).then(
                    function () {
                        // 成功回调
                        _this.$store.dispatch("snackbarMessageHandler","Copied")
                    },
                    function () {
                        // 失败回调
                        _this.$store.dispatch("snackbarMessageHandler","Failed")
                    }
                );
            },
        },
    }
</script>