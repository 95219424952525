<template>
    <div>
        <v-container class="grid-list-xl px-0">
            <v-row justify="center" align="center" class="h-100">
                <v-col cols="11" class="h-30">
                    <div class="fs-24 gradient9 fi_animate">iob.fi</div>
                    <div class="fs-100 py-8">DAO</div>
                    <div class="fs-24 dark-gray--text pb-8"><span v-html="$t('dao.coordinatingAGlobalSet')"></span></div>
                    <a href="https://rinkeby.client.aragon.org/#/fipopularvote/" target="_blank" class="del-underline">
                        <v-btn tile class="glow-orange text-none button--gradient-yellow">{{ $t('dao.startVoting') }}</v-btn>
                    </a>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-16 pt-16">
                <v-col md="6">
                </v-col>
                <v-col md="5" class="fs-24 px-6">
                    <div class="fs-24 gradient9 fi_animate pb-6">
                        {{ $t('dao.wathIs') }}</div>
                    <div class="fs-68">iob.fi DAO</div>
                </v-col>
            </v-row>
            <v-row justify="center" class="pt-8 pb-250">
                <v-col cols="6" class="text-right fs-22">
                    <div class="pb-6 pr-16"><span class="mr-3">DAO</span><span class="point-icon-7 animate-point-init animate-point-wave mt-2"></span></div>
                    <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('dao.decentralizedAutonomousOrganization') }}</div>
                    <div class="pb-6 pr-16"><span class="mr-3">{{ $t('dao.powerToThePeople') }}</span> <span class="point-icon-7 animate-point-init animate-point-wave mt-2"></span></div>
                    <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('dao.throughTheDAO') }}</div>
                    <div class="pb-6 pr-16"><span class="mr-3">{{ $t('dao.proposeAndVote') }}</span><span class="point-icon-7 animate-point-init animate-point-wave mt-2"></span></div>
                    <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('dao.theCommunity') }}</div>
                </v-col>
                <v-col cols="5" class="px-6">
                    <div class="fs-18 dark-gray--text pb-8">{{ $t('dao.mostImportantSmartContractsAndAssets') }}</div>
                    <div class="fs-18 dark-gray--text pb-8">{{ $t('dao.substantialPurse') }}</div>
                    <div class="fs-18 dark-gray--text pb-8">{{ $t('dao.votingTakesPlace') }}</div>
                    <div>
                        <a :href="faq + '/getting-started/faq/#iob-fi-dao'" target="_blank" class="del-underline">
                            <v-btn tile class="glow-orange text-none mt-16 button--gradient-yellow">{{ $t('common.learnMore') }}</v-btn>
                        </a>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="bg-gradient-1">
            <v-container class="px-0 pt-250 pb-250">
                <v-row justify="center" class="text-right dark-gray--text">
                    <v-col md="6">
                        <div class="fs-24 gradient9 fi_animate pb-6 pr-16">{{ $t('dao.aHybridVotingSystem') }}</div>
                        <div class="fs-68 black--text pr-16">{{ $t('dao.popularVote') }}</div>
                    </v-col>
                    <v-col md="5">
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="6" class="text-right black--text">
                        <div class="pt-8 pb-8 pr-16">{{ $t('dao.weAreProposingANovelApproach') }}</div>
                        <div class="pr-16">
                            <a href="https://rinkeby.client.aragon.org/#/fipopularvote/" target="_black" class="del-underline white--text">
                                <v-btn tile class="glow-orange text-none mt-16 button--gradient-yellow">
                                    {{ $t('dao.getAirdrop') }}
                                </v-btn>
                            </a>
                        </div>
                    </v-col>
                    <v-col cols="5" class="mt-8">
                        <div class="fs-22 black--text pb-6"><span class="point-icon-7 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('dao.trueDemocracy') }}</span></div>
                        <div class="fs-18 black--text pb-8 pl-9">{{ $t('dao.notOnlyForTheRich') }}</div>
                        <div class="fs-22 black--text pb-6"><span class="point-icon-7 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('dao.earningFIThroughVoting') }}</span></div>
                        <div class="fs-18 black--text pb-8 pl-9">{{ $t('dao.eachSuccessfulVote') }}</div>
                        <div class="fs-22 black--text pb-6"><span class="point-icon-7 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('dao.yourFreeVoteCounts') }}</span></div>
                        <div class="fs-18 black--text pl-9">{{ $t('dao.yourTestnetVoteResult') }}</div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="px-0">
            <v-row justify="center" class="pt-250 pb-250">
                <v-col md="11">
                    <div class="fs-24 gradient9 fi_animate pb-8">
                        {{ $t('dao.buildWithUS') }}
                    </div>
                    <div class="fs-24 dark-gray--text pb-8">
                        {{ $t('dao.weMakeDecisions') }}
                    </div>
                    <div class="fs-24 gradient9 fi_animate pb-8">
                        {{ $t('dao.shareTheSuccess') }}
                    </div>
                    <div class="fs-24 dark-gray--text pb-8">
                        {{ $t('dao.weArePassionateAboutTheValue') }}
                    </div>
                    <div class="fs-24 dark-gray--text">
                        {{ $t('dao.theFinancialRewards') }}
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="px-0">
            <v-row justify="center" class="pt-250 pb-500">
                <v-col md="12" class="text-center fs-24">
                    <div class="fs-24 gradient9 fi_animate">{{ $t('common.nutshell') }}</div>
                    <div class="fs-54 mt-16">{{ $t('dao.groupDecisionMaking') }}</div>
                    <a href="https://rinkeby.client.aragon.org/#/fipopularvote/" target="_blank" class="del-underline">
                        <v-btn tile class="mt-16 glow-orange text-none button--gradient-yellow">{{ $t('dao.startVoting') }}</v-btn>
                    </a>
                </v-col>
            </v-row>
            <v-row justify="center" class="my-16 text-center pb-100">
                <v-col cols="11" class="py-0 my-0">
                    <a href="https://poweredby.aragon.org/" target="_blank" class="del-underline">
                        <logo type="aragon" color="aragon"></logo>
                    </a>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style>
</style>
<script>
import Vue from 'vue';
import config from '@/config';
import Logo from '@/components/common/Logo';
export default {
    data() {
        return {
            faq: config.faq
        }
    },
    created() {
    },
    mounted() {

    },
    computed: {

    },
    components: {
        Logo
    },
    watch: {

    },
    methods: {

    },
}
</script>
