<template>
<div>
    <v-app-bar fixed v-if="responsive == 'PC' ">
        <v-container class="px-0">
            <v-row no-gutters>
                <v-col cols="6" md="3" class="ml-n3">
                    <v-navigation-drawer expand-on-hover width="180" mini-variant-width="105">
                        <div class="pointer d-custom-flex mt-2">
                            <router-link to="/">
                                <!-- <logo type="twoLines" color="black" v-if="environment != ''"></logo> -->
                                <logo type="twoLines" color="gradient1"></logo>
                            </router-link>
                        </div>
                    </v-navigation-drawer>
                </v-col>
                <!-- <v-col cols="6" md="4" class="pt-3 ml-16 pl-16">
                    <v-row dense>
                        <v-col cols="2" class=" float-right ml-5 pl-3">
                            <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline">
                                <v-btn tile small class="glow-red text-none button--gradient-green fs-14">Buy FI</v-btn>
                            </a>
                        </v-col>
                        <v-col cols="8">
                            <a href="https://info.uniswap.org/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline white--text">
                                <div class="justify-center align-items-center align-center pl-5 mt-n1">
                                    <span class="mt-1">
                                        <logo type="fi" color="eth"></logo>
                                    </span>
                                    <span class="ml-1 mb-1 fs-14">1 FI ≈ {{fi == 0 ? "--" : fi}} ETH</span>
                                </div>
                            </a>
                        </v-col>
                    </v-row>
                </v-col> -->
                <v-col cols="6" md="4" class="pt-3 ml-16 pl-16">
                    <!-- 20220705 删除 FI 价格 -->
                    <!-- <a href="https://v2.info.uniswap.org/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline white--text">
                        <div class="justify-center align-items-center align-center pl-6">
                            <span class="mt-1">
                                <logo type="fi" color="eth"></logo>
                            </span>
                            <span class="ml-1 mb-1">1 FI ≈ {{fi == 0 ? "--" : fi}} ETH</span>
                        </div>
                    </a> -->
                </v-col>
                <v-col cols="6" md="4" class="pt-4 text-right">
                    <!-- <v-btn small color="grey darken-4" class="orange--text text--darken-2 fs-14 text-none mr-1" v-if="net != ''">{{ net }}</v-btn> -->
                    <span color="grey darken-4" class="text--darken-2 fs-14 text-none mr-1" v-if="net != ''" :class="netColor + '--text'">{{ net }}</span>
                    <span v-show="account != null">
                        <v-menu offset-y bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" tile small class="fs-14 button--gradient-orange black--text px-2 mr-1">{{ net != "" ? "--" : (fiQuantity == 0 ? fiQuantity : formatNum(fiQuantity.toFixed(2))) }} FI</v-btn>
                            </template>
                            <v-card tile class="button--gradient-orange black--text pb-4" width="350">
                                <v-card-title class="pb-0 pt-5">
                                    <h6 class="ml-6 black--text">{{net == '' ? 'My FI Tokens' : 'Popular Voting on Rinkeby:'}}</h6>
                                </v-card-title>
                                <v-card-text class="pb-0 black--text">
                                    <v-divider></v-divider>
                                    <v-row class="mx-1" justify="center" align="center" dense v-if="net == ''">
                                        <v-col cols="12" class="text-center mt-2">
                                            <logo type="circle" color="w_1"></logo>
                                        </v-col>
                                        <v-col cols="12" class="text-center">
                                            <h3 class="fs-42">{{ formatNum(fiQuantity.toFixed(2)) }}</h3>
                                        </v-col>
                                        <!-- <v-col cols="12" class="text-center">Value: ${{ formatNum(fiValue.toFixed(2)) }}</v-col> -->
                                        <v-col cols="11" class="mt-3">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">iob.fi DAO Assets: </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="4">IOB</v-col>
                                                        <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(iobQuantity.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="4">IOB-V2.1</v-col>
                                                        <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(iobV2Quantity.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11" class="mt-3">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">Pool Investments: </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="4">DEFY-1</v-col>
                                                        <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(defy1Quantity.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="4">DEFY-2</v-col>
                                                        <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(defy2Quantity.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="6">ioBots Quasar</v-col>
                                                        <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(quasarQuantity.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="4">Value:</v-col>
                                                        <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(quasarValue.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col> -->
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="6">ioBots BTC+</v-col>
                                                        <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(btcPlusQuantity.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="4">Value:</v-col>
                                                        <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(btcPlusValue.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col> -->
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="6">ioBots ETH+</v-col>
                                                        <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(ethPlusQuantity.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col>
                                                <!-- <v-col cols="12" class="my-0 py-0">
                                                    <v-row dense>
                                                        <v-col cols="4">Value:</v-col>
                                                        <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(ethPlusValue.toFixed(2)) }}</v-col>
                                                    </v-row>
                                                </v-col> -->
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="12" class="my-0 py-0 text-right">
                                                    <router-link to="/investments" class="del-underline black--text">More</router-link>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mx-1" justify="center" align="center" dense v-else>
                                        <v-col cols="11">
                                            <v-row dense>
                                                <v-col cols="6" class="ml-1">FI-POPULAR-VOTE:</v-col>
                                                <v-col cols="5" class="d-flex flex-row-reverse">{{ formatNum(fiPopularQuantity.toFixed(2)) }}</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </span>
                    <span v-show="account == null">
                        <v-menu offset-y left v-model="isShowConnectWalletMenu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn tile outlined small text class="text-none mr-2" v-bind="attrs" v-on="on">{{ $t('wallet.connectWallet') }}</v-btn>
                            </template>
                            <v-card tile width="340">
                                <v-card-title class="pb-2 pt-5">
                                    <h6>{{ $t('wallet.connectWallet') }}</h6>
                                </v-card-title>
                                <v-card-text class="pb-1">
                                    <v-row justify="center" class="border-999 mx-1" dense>
                                        <v-col cols="12" v-if="metaMask == null">
                                            <!-- <a href="https://metamask.io/" target="_blank" class="del-underline"> -->
                                            <a :href="metaMaskLink" target="_blank" class="del-underline title_txt">
                                                <v-row class=" ma-1 fs-22">
                                                    <span class="fs-16 pure-red--text"><a>Install MetaMask</a></span>
                                                    <v-spacer></v-spacer>
                                                    <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                                                </v-row>
                                            </a>
                                        </v-col>
                                        <v-col cols="12" class="pointer" v-if="metaMask != null" @click="requestAccounts">
                                            <v-row class="mx-3 fs-18 ma-1">
                                                <span class="fs-16">MetaMask</span>
                                                <v-spacer></v-spacer>
                                                <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="fs-12 white--text">{{ $t('wallet.nonCustodialAndSecure') }}</div>
                                            <div class="caption"> {{ $t('wallet.confirmationInformation') }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </span>
                    <span v-if="account != null">
                        <v-menu offset-y left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn tile outlined small text class="fs-14 pointer mr-1 text-none" v-bind="attrs" v-on="on">
                                    <div class="mr-2" :class="netColor"></div>{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}
                                </v-btn>
                            </template>
                            <v-card tile width="320">
                                <v-card-title class="pb-2 pt-5">
                                    <h6>Active Account</h6>
                                </v-card-title>
                                <v-card-text class="pb-2">
                                    <v-row class="border-999 mx-1" dense>
                                        <v-col cols="12">
                                            <v-row class="mx-3 fs-18 ma-1" dense>
                                                <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                                                <span class="fs-14 ml-2">MetaMask</span>
                                                <v-spacer></v-spacer>
                                                <span class="fs-14">{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}</span>
                                                <v-icon size="14" class="ml-2 pointer fs-12" @click="copy()">icon icon-copy1_2</v-icon>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" class="mx-0" dense>
                                        <v-col cols="12">
                                            <v-btn block tile @click="disconnect()" small class="text-none">
                                                <span class="fs-14">Disconnect</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </span>
                    <!-- <v-btn tile small v-if="account != null" class="fs-14 button--gradient-pink px-2 mr-1" @click="defyBreakdownDialog = !defyBreakdownDialog">{{ net != "" ? "--" : (fiQuantity == 0 ? fiQuantity : formatNum(fiQuantity.toFixed(2))) }} FI</v-btn> -->
                    <!-- <v-btn tile outlined text small v-if="account != null" class="fs-14 pointer mr-1 text-none" @click="accountInfoDialog = !accountInfoDialog">
                        <div class="mr-2" :class="netColor"></div>{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}
                    </v-btn> -->
                    <!-- <v-btn tile outlined small text tile class="text-none mr-2" @click="walletConnectDialog = !walletConnectDialog" v-if="account == null">
                        <span>Connect Wallet</span>
                    </v-btn> -->
                    <v-icon class="mt-1" @click.stop="drawer = !drawer">icon icon-bar</v-icon>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
    <v-app-bar fixed class="fi-toolbar" v-if="responsive == 'mobile'">
        <v-row align="center" justify="center" class="justify-space-between">
            <v-col cols="2" class="pt-10 pl-0" @click="goHome()">
                <router-link to="/">
                    <!-- <div class=" pr-9">
                            <a id="logo" class="logo collapsed">
                                <span>
                                    <logo type="twoLines" color="w"></logo>
                                </span>
                                <span class="collapse">
                                    <i>
                                        <logo type="twoLines" color="iob"></logo>
                                    </i>
                                </span>
                            </a>
                        </div> -->
                    <logo type="twoLines" color="m"></logo>
                </router-link>
            </v-col>
            <v-col cols="8" class="align-sm-center justify-center mt-5 pl-8">
                <!-- 20220705 删除 FI 价格 -->
                <!-- <a href="https://info.uniswap.org/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline white--text">
                    <v-row align="center" class="mt-2">
                        <v-col cols="3">
                            <logo type="fi" color="eth"></logo>
                        </v-col>
                        <v-col cols="9" class="mb-2 ml-n3">1 FI ≈ {{fi == 0 ? "--" : fi}} ETH</v-col>
                    </v-row>
                </a> -->
            </v-col>
            <v-col cols="2" class="mt-5 text-right pr-n2">
                <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
                <v-icon @click.stop="drawer = !drawer">icon icon-bar</v-icon>
            </v-col>
        </v-row>
    </v-app-bar>
   <v-app-bar fixed v-if="responsive == 'pad'">
        <v-container class="px-0">
            <v-row align="center" class="justify-space-between">
                <v-col sm="2" class="pl-7" @click="goHome()">
                    <router-link to="/">
                        <logo type="twoLines" color="m"></logo>
                    </router-link>
                </v-col>
               <v-col cols="6" md="4" class="pt-3 ml-16 pl-16">
                   <!-- 20220705 删除 FI 价格 -->
                   <!-- <a href="https://info.uniswap.org/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline white--text">
                       <div class="justify-center align-items-center align-center pl-6">
                           <span class="mt-1">
                               <logo type="fi" color="eth"></logo>
                           </span>
                           <span class="ml-1 mb-1">1 FI ≈ {{fi == 0 ? "--" : fi}} ETH</span>
                       </div>
                   </a> -->
               </v-col>
                       <v-col cols="6" md="4" class="pt-4 text-right">
                           <!-- <v-btn small color="grey darken-4" class="orange--text text--darken-2 fs-14 text-none mr-1" v-if="net != ''">{{ net }}</v-btn> -->
                           <span color="grey darken-4" class="text--darken-2 fs-14 text-none mr-1" v-if="net != ''" :class="netColor + '--text'">{{ net }}</span>
                           <span v-show="account != null">
                               <v-menu offset-y bottom>
                                   <template v-slot:activator="{ on, attrs }">
                                       <v-btn v-bind="attrs" v-on="on" tile small class="fs-14 button--gradient-orange px-2 mr-1">{{ net != "" ? "--" : (fiQuantity == 0 ? fiQuantity : formatNum(fiQuantity.toFixed(2))) }} FI</v-btn>
                                   </template>
                                   <v-card tile class="button--gradient-green pb-4" width="350">
                                       <v-card-title class="pb-0 pt-5">
                                           <h6 class="ml-6">{{net == '' ? 'My FI Tokens' : 'Popular Voting on Rinkeby:'}}</h6>
                                       </v-card-title>
                                       <v-card-text class="pb-0">
                                           <v-divider></v-divider>
                                           <v-row class="mx-1" justify="center" align="center" dense v-if="net == ''">
                                               <v-col cols="12" class="text-center mt-2">
                                                   <logo type="circle" color="w_1"></logo>
                                               </v-col>
                                               <v-col cols="12" class="text-center">
                                                   <h3 class="fs-42">{{ formatNum(fiQuantity.toFixed(2)) }}</h3>
                                               </v-col>
                                               <!-- <v-col cols="12" class="text-center">Value: ${{ formatNum(fiValue.toFixed(2)) }}</v-col> -->
                                               <v-col cols="11" class="mt-3">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">iob.fi DAO Assets: </v-col>
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="4">IOB</v-col>
                                                               <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(iobQuantity.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col>
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="4">IOB-V2.1</v-col>
                                                               <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(iobV2Quantity.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col>
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11" class="mt-3">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">Pool Investments: </v-col>
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="4">DEFY-1</v-col>
                                                               <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(defy1Quantity.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col>
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11">
                                                <v-row dense>
                                                    <v-col cols="12" class="my-0 py-0">
                                                        <v-row dense>
                                                            <v-col cols="4">DEFY-2</v-col>
                                                            <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(defy2Quantity.toFixed(2)) }}</v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-divider></v-divider>
                                            </v-col>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="6">ioBots Quasar</v-col>
                                                               <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(quasarQuantity.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col>
                                                       <!-- <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="4">Value:</v-col>
                                                               <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(quasarValue.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col> -->
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="6">ioBots BTC+</v-col>
                                                               <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(btcPlusQuantity.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col>
                                                       <!-- <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="4">Value:</v-col>
                                                               <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(btcPlusValue.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col> -->
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="6">ioBots ETH+</v-col>
                                                               <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(ethPlusQuantity.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col>
                                                       <!-- <v-col cols="12" class="my-0 py-0">
                                                           <v-row dense>
                                                               <v-col cols="4">Value:</v-col>
                                                               <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(ethPlusValue.toFixed(2)) }}</v-col>
                                                           </v-row>
                                                       </v-col> -->
                                                   </v-row>
                                               </v-col>
                                               <v-col cols="12">
                                                   <v-divider></v-divider>
                                               </v-col>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="12" class="my-0 py-0 text-right">
                                                           <router-link to="/investments" class="del-underline" style="color:#FFFFFFB3;">More</router-link>
                                                       </v-col>
                                                   </v-row>
                                               </v-col>
                                           </v-row>
                                           <v-row class="mx-1" justify="center" align="center" dense v-else>
                                               <v-col cols="11">
                                                   <v-row dense>
                                                       <v-col cols="6" class="ml-1">FI-POPULAR-VOTE:</v-col>
                                                       <v-col cols="5" class="d-flex flex-row-reverse">{{ formatNum(fiPopularQuantity.toFixed(2)) }}</v-col>
                                                   </v-row>
                                               </v-col>
                                           </v-row>
                                       </v-card-text>
                                   </v-card>
                               </v-menu>
                           </span>
                           <!-- <span v-show="account == null"> -->
                           <span v-if="false">
                               <v-menu offset-y left v-model="isShowConnectWalletMenu">
                                   <template v-slot:activator="{ on, attrs }">
                                       <v-btn tile outlined small text class="text-none mr-2" v-bind="attrs" v-on="on">{{ $t('wallet.connectWallet') }}</v-btn>
                                   </template>
                                   <v-card tile width="340">
                                       <v-card-title class="pb-2 pt-5">
                                           <h6>{{ $t('wallet.connectWallet') }}</h6>
                                       </v-card-title>
                                       <v-card-text class="pb-1">
                                           <v-row justify="center" class="border-999 mx-1" dense>
                                               <v-col cols="12" v-if="metaMask == null">
                                                   <!-- <a href="https://metamask.io/" target="_blank" class="del-underline"> -->
                                                   <a :href="metaMaskLink" target="_blank" class="del-underline">
                                                       <v-row class=" ma-1 fs-22">
                                                           <span class="fs-16 title_txt"><a>Install MetaMask</a></span>
                                                           <v-spacer></v-spacer>
                                                           <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                                                       </v-row>
                                                   </a>
                                               </v-col>
                                               <v-col cols="12" class="pointer" v-if="metaMask != null" @click="requestAccounts">
                                                   <v-row class="mx-3 fs-18 ma-1">
                                                       <span class="fs-16">MetaMask</span>
                                                       <v-spacer></v-spacer>
                                                       <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                                                   </v-row>
                                               </v-col>
                                           </v-row>
                                           <v-row>
                                               <v-col cols="12">
                                                   <div class="fs-12 white--text">{{ $t('wallet.nonCustodialAndSecure') }}</div>
                                                   <div class="caption"> {{ $t('wallet.confirmationInformation') }}</div>
                                               </v-col>
                                           </v-row>
                                       </v-card-text>
                                   </v-card>
                               </v-menu>
                           </span>
                           <span v-if="account != null">
                               <v-menu offset-y left>
                                   <template v-slot:activator="{ on, attrs }">
                                       <v-btn tile outlined small text class="fs-14 pointer mr-1 text-none" v-bind="attrs" v-on="on">
                                           <div class="mr-2" :class="netColor"></div>{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}
                                       </v-btn>
                                   </template>
                                   <v-card tile width="320">
                                       <v-card-title class="pb-2 pt-5">
                                           <h6>Active Account</h6>
                                       </v-card-title>
                                       <v-card-text class="pb-2">
                                           <v-row class="border-999 mx-1" dense>
                                               <v-col cols="12">
                                                   <v-row class="mx-3 fs-18 ma-1" dense>
                                                       <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                                                       <span class="fs-14 ml-2">MetaMask</span>
                                                       <v-spacer></v-spacer>
                                                       <span class="fs-14">{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}</span>
                                                       <v-icon size="14" class="ml-2 pointer fs-12" @click="copy()">icon icon-copy1_2</v-icon>
                                                   </v-row>
                                               </v-col>
                                           </v-row>
                                           <v-row justify="center" class="mx-0" dense>
                                               <v-col cols="12">
                                                   <v-btn block tile @click="disconnect()" small class="text-none">
                                                       <span class="fs-14">Disconnect</span>
                                                   </v-btn>
                                               </v-col>
                                           </v-row>
                                       </v-card-text>
                                   </v-card>
                               </v-menu>
                           </span>
                           <!-- <v-btn tile small v-if="account != null" class="fs-14 button--gradient-pink px-2 mr-1" @click="defyBreakdownDialog = !defyBreakdownDialog">{{ net != "" ? "--" : (fiQuantity == 0 ? fiQuantity : formatNum(fiQuantity.toFixed(2))) }} FI</v-btn> -->
                           <!-- <v-btn tile outlined text small v-if="account != null" class="fs-14 pointer mr-1 text-none" @click="accountInfoDialog = !accountInfoDialog">
                               <div class="mr-2" :class="netColor"></div>{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}
                           </v-btn> -->
                           <!-- <v-btn tile outlined small text tile class="text-none mr-2" @click="walletConnectDialog = !walletConnectDialog" v-if="account == null">
                               <span>Connect Wallet</span>
                           </v-btn> -->
                           <v-icon class="mt-1" @click.stop="drawer = !drawer">icon icon-bar</v-icon>
                       </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed right temporary hide-overlay width="380" v-if="responsive == 'PC'">
        <v-card>
            <v-list-item>
                <v-list-item-content>
                    <router-link to="/">
                        <logo type="twoLines" color="gradient1" class="ml-n14"></logo>
                    </router-link>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon size="18" class="mt-2" @click="drawer = !drawer">icon icon-close</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list class="py-0 white-hover" flat>
                <router-link to="/managed-pools" class="white--text del-underline">
                    <v-list-item tile>
                        <v-list-item-content class="pl-4 text-hover">
                            {{ $t('home.managedPools') }}
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <v-divider></v-divider>
            </v-list>
            <v-divider></v-divider>
            <v-list class="py-0 white-hover" flat>
                <router-link to="/investments" class="white--text del-underline">
                    <v-list-item tile>
                        <v-list-item-content class="pl-4 text-hover">
                            {{ $t('home.myInvestments') }}
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <v-divider></v-divider>
            </v-list>
            <v-list class="py-0 white-hover" flat v-for="item in poolItems">
              <!--  <v-list-item-group > -->
                    <router-link :to="'/pool/'+ item.poolAddress" class="white--text del-underline">
                        <v-list-item tile>
                            <v-list-item-content class="pl-4 text-hover">
                                {{ item.name }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <v-divider></v-divider>
              <!--  </v-list-item-group> -->
            </v-list>
            <v-list class="py-0" dense>
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content class="ml-4 my-1 text-hover">{{ $t('common.about') }}</v-list-item-content>
                    </template>
                    <!-- about -->
                    <v-divider></v-divider>
                    <router-link to="/about" class="white--text del-underline">
                        <v-list-item class="white-hover">
                            <v-list-item-content class="ml-4 text-hover">
                                {{ $t('common.about') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- pool -->
                    <router-link to="/pools" class="white--text del-underline">
                        <v-list-item class="white-hover">
                            <v-list-item-content class="ml-4 text-hover">
                                {{ $t('common.pools') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- solutions -->
                    <router-link to="/solutions" class="white--text del-underline">
                        <v-list-item class="white-hover">
                            <v-list-item-content class="ml-4 text-hover">
                                {{ $t('common.solutions') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- tokens -->
                    <router-link to="/tokens" class="white--text del-underline">
                        <v-list-item class="white-hover">
                            <v-list-item-content class="ml-4 text-hover">
                                {{ $t('common.tokens') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- dao -->
                    <router-link to="/dao" class="white--text del-underline">
                        <v-list-item class="white-hover">
                            <v-list-item-content class="ml-4 text-hover">
                                {{ $t('common.dao') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- docs -->
                    <a :href="faq" target="_blank" class="white--text del-underline">
                        <v-list-item class="white-hover">
                            <v-list-item-content class="ml-4 text-hover">
                                {{ $t('common.docs') }}
                            </v-list-item-content>
                        </v-list-item>
                    </a>
                    <!-- team -->
                    <a :href="faq + '/getting-started/faq#who-is-behind-iob-fi-dao'" target="_blank" class="white--text del-underline">
                        <v-list-item class="white-hover">
                            <v-list-item-content class="ml-4 text-hover">
                                {{ $t('common.team') }}
                            </v-list-item-content>
                        </v-list-item>
                    </a>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <v-list class="py-0" flat>
                <!-- faq -->
                <a :href="faq + '/getting-started/faq'" target="_blank" class="white--text del-underline">
                    <v-list-item tile class="white-hover">
                        <v-list-item-content class="pl-4 text-hover">
                            {{ $t('common.faq') }}
                        </v-list-item-content>
                    </v-list-item>
                </a>
                <v-divider></v-divider>
                <!-- iobfund -->
                <a :href="fund" target="_blank" class="white--text del-underline">
                    <v-list-item tile class="white-hover">
                        <v-list-item-content class="pl-4 text-hover">
                            {{ $t('common.iobFund') }}
                        </v-list-item-content>
                    </v-list-item>
                </a>
            </v-list>
        </v-card>
        <v-list class="py-0">
            <!-- Discord -->
            <a href="https://discord.com/channels/748595728208625682/748595728208625685" target="_blank" class="white--text del-underline">
                <v-list-item tile class="pl-8 fs-24 white-hover">
                    <v-list-item-content class="text-hover">
                        <i class="icon icon-discord"></i>
                    </v-list-item-content>
                </v-list-item>
            </a>
            <!-- Twitter -->
            <a href="https://twitter.com/IOB_llc" target="_blank" class="white--text del-underline">
                <v-list-item tile class="pl-8 fs-24 white-hover">
                    <v-list-item-content class="text-hover">
                        <i class="icon icon-twitter"></i>
                    </v-list-item-content>
                </v-list-item>
            </a>
            <!-- Telegram -->
            <a href="https://t.me/iob_fi" target="_blank" class="white--text del-underline">
                <v-list-item tile class="pl-8 fs-24 white-hover">
                    <v-list-item-content class="text-hover">
                            <i class="icon icon-telegram1"></i>
                    </v-list-item-content>
                </v-list-item>
            </a>
            <!-- Facebook -->
            <a href="https://www.facebook.com/iob.fi" target="_blank" class="white--text del-underline">
                <v-list-item tile class="pl-8 fs-24 white-hover">
                    <v-list-item-content class="text-hover">
                        <i class="icon icon-facebook"></i>
                    </v-list-item-content>
                </v-list-item>
            </a>
            <!-- Medium -->
            <a href="https://iob.medium.com/" target="_blank" class="white--text del-underline">
                <v-list-item tile class="pl-8 fs-24 white-hover">
                    <v-list-item-content class="text-hover">
                        <i class="icon icon-medium"></i>
                    </v-list-item-content>
                </v-list-item>
            </a>
            <!-- Github -->
            <a href="https://github.com/IOBteam/iob.fi" target="_blank" class="white--text del-underline">
                <v-list-item tile class="pl-8 fs-24 white-hover">
                    <v-list-item-content class="text-hover">
                        <i class="icon icon-github"></i>
                    </v-list-item-content>
                </v-list-item>
            </a>
        </v-list>
    </v-navigation-drawer>
    <a href="https://discord.com/channels/748595728208625682/748595728208625685" target="_black" class="del-underline" v-if="responsive == 'PC'">
        <v-btn fixed fab x-small right bottom class="white--text gradient-btn">
            <v-icon>icon icon-discord</v-icon>
        </v-btn>
    </a>
    <v-bottom-sheet v-model="isShowCookieStatement" hide-overlay persistent dense v-if="responsive == 'PC'">
        <v-card class="text-center pa-2">
            {{$t("common.cookieStatementMessage")}}
            <a :href="faq + '/getting-started/legal-notice-and-disclaimer/corporate-gdpr-statement'" target="_blank" class="pure-red--text">GDPR</a>
            {{$t("common.period")}}
            <v-btn class="ml-4 button--gradient-orange black--text" tile small @click="acceptCookieStatement">{{$t("common.accept")}}</v-btn>
        </v-card>
    </v-bottom-sheet>
    <v-navigation-drawer v-model="drawer" fixed right temporary hide-overlay style="z-index: 999;" v-if="responsive != 'PC' ">
        <v-card>
            <v-list-item>
                <v-list-item-content>
                    <router-link to="/">
                        <logo type="twoLines" color="gradient"></logo>
                    </router-link>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <router-link to="/managed-pools" class="white--text del-underline">
                <v-list-item tile>
                    <v-list-item-content class="pl-1">
                        {{ $t('home.managedPools') }}
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <v-divider></v-divider>
            <router-link to="/investments" class="white--text del-underline">
                <v-list-item tile>
                    <v-list-item-content class="pl-1">
                        {{ $t('home.myInvestments') }}
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <v-divider></v-divider>
            <v-list class="py-0">
                <router-link v-for="item in poolItems" :to="'/pool/'+ item.poolAddress" class="white--text del-underline title_txt">
                    <v-list-item tile>
                        <v-list-item-content class="pl-1">
                            {{ item.name }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </router-link>
            </v-list>
            <v-list class="py-0" dense flat>
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-content class="ml-1 my-1">{{ $t('common.about') }}</v-list-item-content>
                    </template>
                    <!-- about -->
                    <v-divider></v-divider>
                    <router-link to="/about" class="white--text del-underline">
                        <v-list-item>
                            <v-list-item-content class="ml-1">
                                {{ $t('common.about') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- pool -->
                    <router-link to="/pools" class="white--text del-underline">
                        <v-list-item>
                            <v-list-item-content class="ml-1">
                                {{ $t('common.pools') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- solutions -->
                    <router-link to="/solutions" class="white--text del-underline">
                        <v-list-item>
                            <v-list-item-content class="ml-1">
                                {{ $t('common.solutions') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- tokens -->
                    <router-link to="/tokens" class="white--text del-underline">
                        <v-list-item>
                            <v-list-item-content class="ml-1">
                                {{ $t('common.tokens') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- dao -->
                    <router-link to="/dao" class="white--text del-underline">
                        <v-list-item>
                            <v-list-item-content class="ml-1">
                                {{ $t('common.dao') }}
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <!-- docs -->
                    <a :href="faq" target="_blank" class="white--text del-underline">
                        <v-list-item>
                            <v-list-item-content class="ml-1">
                                {{ $t('common.docs') }}
                            </v-list-item-content>
                        </v-list-item>
                    </a>
                    <!-- team -->
                    <a :href="faq + '/getting-started/faq#who-is-behind-iob-fi-dao'" target="_blank" class="white--text del-underline">
                        <v-list-item>
                            <v-list-item-content class="ml-1">
                                {{ $t('common.team') }}
                            </v-list-item-content>
                        </v-list-item>
                    </a>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <v-list class="py-0" flat>
                <!-- faq -->
                <a :href="faq + '/getting-started/faq'" target="_blank" class="white--text del-underline">
                    <v-list-item tile>
                        <v-list-item-content class="pl-1">
                            {{ $t('common.faq') }}
                        </v-list-item-content>
                    </v-list-item>
                </a>
                <v-divider></v-divider>
                <!-- iobfund -->
                <a :href="fund" target="_blank" class="white--text del-underline">
                    <v-list-item tile>
                        <v-list-item-content class="pl-1">
                            {{ $t('common.iobFund') }}
                        </v-list-item-content>
                    </v-list-item>
                </a>
                
            </v-list>
        </v-card>
        <v-list class="pt-0 pb-16">
            <!-- Discord -->
            <a href="https://discord.com/channels/748595728208625682/748595728208625685" target="_blank" class="white--text del-underline">
                <v-list-item tile>
                    <v-list-item-icon class="pl-1 mb-0">
                        <v-icon>icon icon-discord</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </a>
            <!-- Twitter -->
            <a href="https://twitter.com/IOB_llc" target="_blank" class="white--text del-underline">
                <v-list-item tile>
                    <v-list-item-icon class="pl-1 mb-0">
                        <v-icon>icon icon-twitter</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </a>
            <!-- Telegram -->
            <a href="https://t.me/iob_fi" target="_blank" class="white--text del-underline">
                <v-list-item tile>
                    <v-list-item-icon class="pl-1 mb-0">
                        <v-icon>icon icon-telegram1</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </a>
            <!-- Facebook -->
            <a href="https://www.facebook.com/iob.fi" target="_blank" class="white--text del-underline">
                <v-list-item>
                    <v-list-item-icon class="pl-1 mb-0">
                        <v-icon>icon icon-facebook</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </a>
            <!-- Medium -->
            <a href="https://iob.medium.com/" target="_blank" class="white--text del-underline">
                <v-list-item tile>
                    <v-list-item-icon class="pl-1 mb-0">
                        <v-icon>icon icon-medium</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </a>
            <!-- Github -->
            <a href="https://github.com/IOBteam/iob.fi" target="_blank" class="white--text del-underline">
                <v-list-item tile>
                    <v-list-item-icon class="pl-1 mb-0">
                        <v-icon>icon icon-github</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </a>
        </v-list>
        <v-footer fixed class="fs-12 text-center align-center mx-0 px-0" v-if="responsive == 'pad' || responsive == 'mobile' ">
            <div class="my-1">
                <span class="ml-2 mr-2">IOB © 2020 - 2021</span>
                <a :href="faq + '/getting-started/legal-notice-and-disclaimer/privacy-statement'" target="_blank" class="mr-3 del-underline white--text">Privacy & Legal</a>
                <a :href="faq + '/getting-started/legal-notice-and-disclaimer/terms-and-conditions'" class="del-underline white--text" target="_blank">
                    Terms
                </a>
            </div>
        </v-footer>
    </v-navigation-drawer>
    <div class="mt-2">
        <a href="https://discord.com/channels/748595728208625682/748595728208625685" target="_black" class="del-underline" v-if="responsive == 'pad' || responsive == 'mobile' ">
            <v-btn fixed fab x-small right bottom class="white--text gradient-btn">
                <v-icon>icon icon-discord</v-icon>
            </v-btn>
        </a>
    </div>
    <v-bottom-sheet v-model="isShowCookieStatement" hide-overlay persistent dense v-if="responsive != 'PC'">
        <v-card class="pa-2 fs-12" tile color="#616161">
            <v-row>
                <v-col cols="12">
                    <v-btn class="text-none black--text dark-grey" tile x-small>GDPR</v-btn>
                </v-col>
                <v-col cols="12">
                    {{$t("common.cookieStatementMessage")}}
                    <a :href="faq + '/getting-started/legal-notice-and-disclaimer/corporate-gdpr-statement'" target="_blank" class="pure-red--text">GDPR</a>
                    {{$t("common.period")}}
                </v-col>
                <v-col cols="12">
                    <v-btn class="text-none black--text dark-grey" tile x-small>{{ $t('home.yourInvestments') }}</v-btn>
                </v-col>
                <v-col cols="12">
                    {{ $t('wallet.mobileInvestmentsInformation') }}
                </v-col>
                <v-col cols="12">
                    <v-btn color="button--gradient-orange" class="text-none black--text" tile block @click="acceptCookieStatement">{{ $t('common.continue') }}</v-btn>
                </v-col>
            </v-row>
            <!-- <v-btn class="ml-4 float-right" tile outlined text x-small @click="acceptCookieStatement">{{$t("common.accept")}}</v-btn> -->
        </v-card>
    </v-bottom-sheet>
    <!-- <v-bottom-sheet v-model="warningDialog" hide-overlay persistent dense v-if="responsive != 'PC'">
        <v-card class="pa-2 fs-12" tile>
            Please open iob.fi on a PC/Mac to view your investment balances and to make new investments.
            <v-icon size="16" class="float-right" @click="warningDialog = false">mdi-close</v-icon>
        </v-card>
    </v-bottom-sheet> -->
    <!-- 弹窗 -->
    <v-dialog v-model="walletConnectDialog" width="500" persistent>
        <v-card tile>
            <v-card-title class="pb-2 pt-5">
                <h6>{{ $t('wallet.connectWallet') }}</h6>
                <v-spacer></v-spacer>
                <v-icon size="16" @click="walletConnectDialog = !walletConnectDialog">icon icon-close</v-icon>
            </v-card-title>
            <v-card-text class="pb-1">
                <v-row justify="center" class="border-999 mx-1" dense>
                    <v-col cols="12" v-if="metaMask == null">
                        <!-- <a href="https://metamask.app.link/dapp/10.103.16.249:8080/" target="_blank" rel="noopener noreferrer">打开app</a> -->
                        <a :href="metaMaskLink" target="_blank" class="del-underline title_txt">
                            <v-row class=" ma-1 fs-22">
                                <span class="fs-16 pure-red--text del-underline">MetaMask</span>
                                <v-spacer></v-spacer>
                                <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                            </v-row>
                        </a>
                    </v-col>
                    <v-col cols="12" class="pointer" v-if="metaMask != null" @click="requestAccounts">
                        <v-row class="mx-3 fs-18 ma-1">
                            <span class="fs-16">MetaMask</span>
                            <v-spacer></v-spacer>
                            <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="fs-12 white--text">{{ $t('wallet.nonCustodialAndSecure') }}</div>
                        <div class="caption"> {{ $t('wallet.confirmationInformation') }}</div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="accountInfoDialog" width="430" persistent v-if="account != null">
        <v-card>
            <v-card-title class="pb-2 pt-5">
                <h6>Active Account</h6>
                <v-spacer></v-spacer>
                <v-icon size="16" @click="accountInfoDialog = !accountInfoDialog">icon icon-close</v-icon>
            </v-card-title>
            <v-card-text class="pb-2">
                <v-row class="border-999 mx-1" dense>
                    <v-col cols="12">
                        <v-row class="mx-3 fs-18 ma-1" dense>
                            <img src="https://files.iob.fi/2020/11/1606367867136-2a0d2f8d57075f0f9de812256a79ce91-metamask.webp" width="20px" height="20px" />
                            <span class="fs-14 ml-2">MetaMask</span>
                            <v-spacer></v-spacer>
                            <span class="fs-14">{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}</span>
                            <v-icon size="14" class="ml-2 pointer fs-12" @click="copy()">icon icon-copy1_2</v-icon>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mx-0 pb-3 mt-3" dense>
                    <v-col cols="12">
                        <v-btn block tile @click="disconnect()" small class="text-none">
                            <span class="fs-14">Disconnect</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="defyBreakdownDialog" width="500" persistent v-if="metaMask != null">
        <v-card tile class="button--gradient-green pb-4">
            <v-card-title class="pb-0 pt-5">
                <h6 class="ml-6">{{net == '' ? 'My FI Tokens' : 'Popular Voting on Rinkeby:'}}</h6>
                <v-spacer></v-spacer>
                <v-icon size="16" @click="defyBreakdownDialog = !defyBreakdownDialog">icon icon-close</v-icon>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-divider></v-divider>
                <v-row class="mx-1" justify="center" align="center" dense v-if="net == ''">
                    <v-col cols="12" class="text-center mt-2">
                        <logo type="circle" color="w_1"></logo>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <h3 class="fs-42">{{ formatNum(fiQuantity.toFixed(2)) }}</h3>
                    </v-col>
                    <!-- <v-col cols="12" class="text-center">Value: ${{ formatNum(fiValue.toFixed(2)) }}</v-col> -->
                    <v-col cols="11" class="mt-3">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">iob.fi DAO Assets: </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="4">IOB</v-col>
                                    <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(iobQuantity.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="4">IOB-V2.1</v-col>
                                    <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(iobV2Quantity.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11" class="mt-3">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">Pool Investments: </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="4">DEFY-1</v-col>
                                    <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(defy1Quantity.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="4">DEFY-2</v-col>
                                    <v-col cols="8" class="d-flex flex-row-reverse">{{ formatNum(defy2Quantity.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="6">ioBots Quasar</v-col>
                                    <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(quasarQuantity.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col>
                            <!-- <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="4">Value:</v-col>
                                    <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(quasarValue.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col> -->
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="6">ioBots BTC+</v-col>
                                    <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(btcPlusQuantity.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col>
                            <!-- <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="4">Value:</v-col>
                                    <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(btcPlusValue.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col> -->
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="6">ioBots ETH+</v-col>
                                    <v-col cols="6" class="d-flex flex-row-reverse">{{ formatNum(ethPlusQuantity.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col>
                            <!-- <v-col cols="12" class="my-0 py-0">
                                <v-row dense>
                                    <v-col cols="4">Value:</v-col>
                                    <v-col cols="8" class="d-flex flex-row-reverse">${{ formatNum(ethPlusValue.toFixed(2)) }}</v-col>
                                </v-row>
                            </v-col> -->
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="12" v-if="environment != ''">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="11" v-if="environment != ''">
                        <v-row dense>
                            <v-col cols="12" class="my-0 py-0 text-right"  @click="defyBreakdownDialog = false">
                                <router-link to="/investments" class="del-underline" style="color:#FFFFFFB3;">More</router-link>
                            </v-col>
                        </v-row>
                    </v-col> -->
                </v-row>
                <v-row class="mx-1" justify="center" align="center" dense v-else>
                    <v-col cols="11">
                        <v-row dense>
                            <v-col cols="6" class="ml-1">FI-POPULAR-VOTE</v-col>
                            <v-col cols="5" class="d-flex flex-row-reverse">{{ formatNum(fiPopularQuantity.toFixed(2)) }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="warningDialog" width="450" v-if="environment != '' && responsive != 'PC'">
        <v-alert class="fs-12" border="left" color="blue" icon="mdi-information-outline">
            Please open iob.fi on a PC/Mac to view your investment balances and to make new investments.
        </v-alert>
        <v-card class="pa-3 dark-gray--text">
            Please open iob.fi on a PC/Mac to view your investment balances and to make new investments.
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" small text @click="warningDialog = false">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> -->
    <!-- <v-footer fixed class="py-4" v-if="responsive == 'mobile' ">
        <span color="grey darken-4" class="text--darken-2 fs-14 text-none mr-2 ml-1" v-if="net != ''" :class="netColor + '--text'">{{ net }}</span>
        <span v-show="account == null">
            <v-btn tile outlined text class="text-none" @click="walletConnectDialog = !walletConnectDialog">Connect Wallet</v-btn>
        </span>
        <span v-if="account != null">
            <v-btn tile outlined text class="fs-14 pointer mr-3 text-none" @click="accountInfoDialog = !accountInfoDialog">
                <div class="mr-1" :class="netColor"></div>{{ account.substring(0,6).toLowerCase() + "..." + account.substring(38) }}
            </v-btn>
        </span>
        <span v-show="account != null">
            <v-btn @click="defyBreakdownDialog = !defyBreakdownDialog" tile class="fs-14 button--gradient-pink px-2">{{ net != "" ? "--" : (fiQuantity == 0 ? fiQuantity : formatNum(fiQuantity.toFixed(2))) }} FI</v-btn>
        </span>
    </v-footer> -->
    <!-- 消息通知栏 -->
    <v-snackbar v-model="isShowSnackbar" :timeout="3000">
        {{ snackbarMessage }}
    </v-snackbar>
    <key-warning-dialog></key-warning-dialog>
    <!-- <mobile-footer v-if="responsive != 'PC'"></mobile-footer> -->
</div>
</template>

<style>
/* .v-list :hover >div{
	    color: #FEE108!important;
	} */
.white-hover:hover {
   background-color: rgba(97, 97, 97, 0.6)!important;
}

.v-list-group__header:hover{
	background-color: rgba(97, 97, 97, 0.6)!important;
}
.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
    opacity: 0!important;
}

</style>

<script>
import Vue from 'vue';
import Icon from '@/components/common/Icon.vue';
import config from '@/config';
import { mapGetters } from "vuex";
import Logo from '@/components/common/Logo';
import KeyWarningDialog from '@/components/common/KeyWarningDialog';
import { ChainId, Token, Fetcher, Route} from '@uniswap/sdk';
import Web3 from "web3";
import Bus from "@/components/bus/Bus";
import detectEthereumProvider from '@metamask/detect-provider';
export default {
    data() {
        return {
            drawer: null,
            //是否显示使用cookies消息，null为显示，false为不显示
            isShowCookieStatement: false,
            faq: config.faq,
            fund: config.fund,
            fi: 0,
            walletConnectDialog: false, // 连接弹窗
            accountInfoDialog: false, // 用户信息弹窗
            defyBreakdownDialog: false, // Defy明细弹窗
            metaMask: null, // 钱包对象
            account: null, // 钱包用户名
            net: "", // 网络名称
            netApi: "", // 网络请求地址
            netColor: null, //网络颜色
            // tokens的值
            fiQuantity: 0,
            fiValue: 0,
            // Pool Assets
            defy1Quantity: 0,
            defy2Quantity: 0,
            defy1Value: 0,
            defy2Value: 0,
            quasarQuantity: 0,
            quasarValue: 0,
            btcPlusQuantity: 0,
            btcPlusValue: 0,
            ethPlusQuantity: 0,
            ethPlusValue: 0,
            // ======================
            fiPopularQuantity: 0,
            iobQuantity: 0,
            iobV2Quantity: 0,
            isShowConnectWalletMenu: false,
            environment: config.environment,
            warningDialog: true,
            poolItems:[],
        }
    },
    components: {
        Logo, Icon, KeyWarningDialog
    },
    created() {
        this.getFi();
        this.checkMetaMask();
        this.watchEvent();
    },
    mounted() {
        this.getPools();
        // 监听当前价的事件
        Bus.$on('isShowConnectWalletMenu', data => {
            if (this.responsive == 'PC' || this.responsive == 'pad') {
                this.isShowConnectWalletMenu = data;
            } else {
                this.walletConnectDialog = data;
            }
        })
        Bus.$on('onRequestAccounts', data => {
            this.requestAccounts();
        })
        // 读取Cookie弹窗
        let expires = localStorage.getItem("iob.fi-isShowCookieStatementExpires");
        if (expires == null || expires <= new Date().getTime()) {
            // 已过期，需要重新弹窗
            this.isShowCookieStatement = true;
        }
    },
    computed: {
        ...mapGetters(["responsive", "snackbarMessage"]),
        metaMaskLink() {
            if (config.environment == '') {
                return 'https://metamask.app.link/dapp/iob.fi/';
            } else {
                return 'https://metamask.app.link/dapp/testnet.iob.fi/';
            }
        },
        // 是否显示提示信息
        isShowSnackbar: {
            get() {
              return this.$store.getters.isShowSnackbar;
            },
            set(v) {
              this.$store.dispatch("isShowSnackbarHandler", v);
            }
        }
    },
    watch: {
        group() {
            this.drawer = false
        },
        // quasarQuantity(val){
        //     if(val != 0){
        //         let api = config.baseUrl + "/pool/public/price/0x739f41c699276c9b6182aecb9d5f4eca226da8dd";
        //         this.$http.get(api).then(res=>{
        //             let data = res.data;
        //             if(data.code == 200){
        //                 this.quasarValue = val * data.result;
        //             }
        //         })
        //     }
        // },
        // btcPlusQuantity(val){
        //     if(val != 0){
        //         let api = config.baseUrl + "/pool/public/price/0x5d98a37f4e4d6fcd9d416f241c1b25fa6737691a";
        //         this.$http.get(api).then(res=>{
        //             let data = res.data;
        //             if(data.code == 200){
        //                 this.btcPlusValue = val * data.result;
        //             }
        //         })
        //     }
        // },
        // ethPlusQuantity(val){
        //     if(val != 0){
        //         let api = config.baseUrl + "/pool/public/price/0x9b5518a3b55a4e922064873aa2e9ad1270c8ea77";
        //         this.$http.get(api).then(res=>{
        //             let data = res.data;
        //             if(data.code == 200){
        //                 this.ethPlusValue = val * data.result;
        //             }
        //         })
        //     }
        // }
    },
    methods: {
        acceptCookieStatement() {
            this.isShowCookieStatement = false;
            // 重新赋值60天隐藏期限
            localStorage.setItem("iob.fi-isShowCookieStatementExpires", (new Date()).getTime() + 1000 * 60 * 60 * 24 * 60)
            localStorage.setItem("iob.fi-isShowCookieStatement", 0);
        },
        goHome() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/home.md')
        },
        goAbout() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/about.md')
        },
        goPools() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/pools.md')
        },
        goSolutions() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/solutions.md')
        },
        goTokens() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/tokens.md')
        },
        goDAO() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/DAO.md')
        },
        async getFi() {
            const FI = new Token(ChainId.MAINNET, '0xF03d05eD4Cf4E723C04500Ab64281BfA591968F2', 18)
            const WETH = new Token(ChainId.MAINNET, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18)
            const pair = await Fetcher.fetchPairData(FI, WETH)
            const route = new Route([pair], WETH)
            this.fi = Number(route.midPrice.invert().toSignificant(6))
            // 加入vuex缓存
            this.$store.dispatch('fiethPriceHandler', this.fi);
        },
        async checkMetaMask() {
            // this.account = null;
            if (window.ethereum != null) {
                this.metaMask = window.ethereum;
                this.$store.dispatch('metaMaskHandler', this.metaMask);
                var chainId = null;
                if (this.metaMask.chainId != null) {
                    chainId = this.metaMask.chainId;
                } else {
                    const provider = await detectEthereumProvider();
                    chainId = await provider.request({
                        method: 'eth_chainId'
                    });
                }
                switch (chainId) {
                    case "0x1":
                    case "0x01":
                        this.net = "";
                        this.netApi = "https://mainnet.infura.io/v3/b23820f133fd4e84ae3f99ffc4dcccab";
                        this.netColor = "mainnet";
                        break;
                    case "0x3":
                    case "0x03":
                        this.net = "Ropsten";
                        this.netApi = "https://ropsten.infura.io/v3/b23820f133fd4e84ae3f99ffc4dcccab";
                        this.netColor = "ropsten";
                        break;
                    case "0x4":
                    case "0x04":
                        this.net = "Rinkeby";
                        this.netApi = "https://rinkeby.infura.io/v3/b23820f133fd4e84ae3f99ffc4dcccab";
                        this.netColor = "rinkeby";
                        break;
                    case "0x5":
                    case "0x05":
                        this.net = "Goerli";
                        this.netApi = "https://goerli.infura.io/v3/b23820f133fd4e84ae3f99ffc4dcccab";
                        this.netColor = "goerli";
                        break;
                    case "0x2a":
                        this.net = "Kovan";
                        this.netApi = "https://kovan.infura.io/v3/b23820f133fd4e84ae3f99ffc4dcccab";
                        this.netColor = "kovan";
                        break;
                }
                this.$store.dispatch('metaMaskNetHandler', this.net);
            };
        },
        requestAccounts() {
            this.account = null;
            if (this.metaMask != null) {
                this.metaMask.request({
                    method: 'eth_requestAccounts'
                }).then(res => {
                    if (res.length > 0) {
                        this.account = res[0];
                        this.$store.dispatch('walletAddressHandler', this.account);
                        this.walletConnectDialog = false;
                        this.getBalance(this.account);
                    }
                })
            }
        },
        async getBalance(account) {
            var web3 = new Web3(new Web3.providers.HttpProvider(this.netApi));
            var contractAbi = [{
                "constant": true,
                "inputs": [],
                "name": "name",
                "outputs": [{
                    "name": "",
                    "type": "string"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_spender",
                    "type": "address"
                }, {
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "approve",
                "outputs": [{
                    "name": "success",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "creationBlock",
                "outputs": [{
                    "name": "",
                    "type": "uint256"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "totalSupply",
                "outputs": [{
                    "name": "",
                    "type": "uint256"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_from",
                    "type": "address"
                }, {
                    "name": "_to",
                    "type": "address"
                }, {
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "transferFrom",
                "outputs": [{
                    "name": "success",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "decimals",
                "outputs": [{
                    "name": "",
                    "type": "uint8"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_newController",
                    "type": "address"
                }],
                "name": "changeController",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [{
                    "name": "_owner",
                    "type": "address"
                }, {
                    "name": "_blockNumber",
                    "type": "uint256"
                }],
                "name": "balanceOfAt",
                "outputs": [{
                    "name": "",
                    "type": "uint256"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "version",
                "outputs": [{
                    "name": "",
                    "type": "string"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_cloneTokenName",
                    "type": "string"
                }, {
                    "name": "_cloneDecimalUnits",
                    "type": "uint8"
                }, {
                    "name": "_cloneTokenSymbol",
                    "type": "string"
                }, {
                    "name": "_snapshotBlock",
                    "type": "uint256"
                }, {
                    "name": "_transfersEnabled",
                    "type": "bool"
                }],
                "name": "createCloneToken",
                "outputs": [{
                    "name": "",
                    "type": "address"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [{
                    "name": "_owner",
                    "type": "address"
                }],
                "name": "balanceOf",
                "outputs": [{
                    "name": "balance",
                    "type": "uint256"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "parentToken",
                "outputs": [{
                    "name": "",
                    "type": "address"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_owner",
                    "type": "address"
                }, {
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "generateTokens",
                "outputs": [{
                    "name": "",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "symbol",
                "outputs": [{
                    "name": "",
                    "type": "string"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [{
                    "name": "_blockNumber",
                    "type": "uint256"
                }],
                "name": "totalSupplyAt",
                "outputs": [{
                    "name": "",
                    "type": "uint256"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_to",
                    "type": "address"
                }, {
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "transfer",
                "outputs": [{
                    "name": "success",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "transfersEnabled",
                "outputs": [{
                    "name": "",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "parentSnapShotBlock",
                "outputs": [{
                    "name": "",
                    "type": "uint256"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_spender",
                    "type": "address"
                }, {
                    "name": "_amount",
                    "type": "uint256"
                }, {
                    "name": "_extraData",
                    "type": "bytes"
                }],
                "name": "approveAndCall",
                "outputs": [{
                    "name": "success",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_owner",
                    "type": "address"
                }, {
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "destroyTokens",
                "outputs": [{
                    "name": "",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [{
                    "name": "_owner",
                    "type": "address"
                }, {
                    "name": "_spender",
                    "type": "address"
                }],
                "name": "allowance",
                "outputs": [{
                    "name": "remaining",
                    "type": "uint256"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_token",
                    "type": "address"
                }],
                "name": "claimTokens",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "tokenFactory",
                "outputs": [{
                    "name": "",
                    "type": "address"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "constant": false,
                "inputs": [{
                    "name": "_transfersEnabled",
                    "type": "bool"
                }],
                "name": "enableTransfers",
                "outputs": [],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            }, {
                "constant": true,
                "inputs": [],
                "name": "controller",
                "outputs": [{
                    "name": "",
                    "type": "address"
                }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }, {
                "inputs": [{
                    "name": "_tokenFactory",
                    "type": "address"
                }, {
                    "name": "_parentToken",
                    "type": "address"
                }, {
                    "name": "_parentSnapShotBlock",
                    "type": "uint256"
                }, {
                    "name": "_tokenName",
                    "type": "string"
                }, {
                    "name": "_decimalUnits",
                    "type": "uint8"
                }, {
                    "name": "_tokenSymbol",
                    "type": "string"
                }, {
                    "name": "_transfersEnabled",
                    "type": "bool"
                }],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "constructor"
            }, {
                "payable": true,
                "stateMutability": "payable",
                "type": "fallback"
            }, {
                "anonymous": false,
                "inputs": [{
                    "indexed": true,
                    "name": "_token",
                    "type": "address"
                }, {
                    "indexed": true,
                    "name": "_controller",
                    "type": "address"
                }, {
                    "indexed": false,
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "ClaimedTokens",
                "type": "event"
            }, {
                "anonymous": false,
                "inputs": [{
                    "indexed": true,
                    "name": "_from",
                    "type": "address"
                }, {
                    "indexed": true,
                    "name": "_to",
                    "type": "address"
                }, {
                    "indexed": false,
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "Transfer",
                "type": "event"
            }, {
                "anonymous": false,
                "inputs": [{
                    "indexed": true,
                    "name": "_cloneToken",
                    "type": "address"
                }, {
                    "indexed": false,
                    "name": "_snapshotBlock",
                    "type": "uint256"
                }],
                "name": "NewCloneToken",
                "type": "event"
            }, {
                "anonymous": false,
                "inputs": [{
                    "indexed": true,
                    "name": "_owner",
                    "type": "address"
                }, {
                    "indexed": true,
                    "name": "_spender",
                    "type": "address"
                }, {
                    "indexed": false,
                    "name": "_amount",
                    "type": "uint256"
                }],
                "name": "Approval",
                "type": "event"
            }];
            if (this.net == "") {
                // ==========================FI=======================================
                var fiAddress = "0xf03d05ed4cf4e723c04500ab64281bfa591968f2";
                var fiContract = new web3.eth.Contract(contractAbi, fiAddress);
                var fiDecimal = await fiContract.methods.decimals().call();
                await fiContract.methods.balanceOf(account).call({
                    from: fiAddress
                }).then(res => {
                    this.fiQuantity = res / Math.pow(10, fiDecimal);
                });
                // ==========================DEFY-1=======================================
                var defy1Address = "0x7aF3B2B4093bd52309fE374C5FD05E215762c20C";
                var defy1Contract = new web3.eth.Contract(contractAbi, defy1Address);
                var defy1Decimal = await defy1Contract.methods.decimals().call();
                await defy1Contract.methods.balanceOf(account).call({
                    from: defy1Address
                }).then(res => {
                    this.defy1Quantity = res / Math.pow(10, defy1Decimal);
                });
                // ===========================DEFY-2======================================
                var defy2Address = "0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59";
                var defy2Contract = new web3.eth.Contract(contractAbi, defy1Address);
                var defy2Decimal = await defy2Contract.methods.decimals().call();
                await defy2Contract.methods.balanceOf(account).call({
                    from: defy2Address
                }).then(res => {
                    this.defy2Quantity = res / Math.pow(10, defy2Decimal);
                });
                // ===========================IOB======================================
                var iobAddress = "0x6130ff302f24267380753e52ece48b852be3dd38";
                var iobContract = new web3.eth.Contract(contractAbi, iobAddress);
                var iobDecimal = await iobContract.methods.decimals().call();
                await iobContract.methods.balanceOf(account).call({
                    from: iobAddress
                }).then(res => {
                    this.iobQuantity = res / Math.pow(10, iobDecimal);
                });
                // ===========================IOB V2======================================
                var iobV2Address = "0xe987b1d77da83d84af43a1f1820eb4b68f3b2126";
                var iobV2Contract = new web3.eth.Contract(contractAbi, iobV2Address);
                var iobV2Decimal = await iobV2Contract.methods.decimals().call();
                await iobV2Contract.methods.balanceOf(account).call({
                    from: iobV2Address
                }).then(res => {
                    this.iobV2Quantity = res / Math.pow(10, iobV2Decimal);
                });
                // ============================poolAssets============================
                var quasarAddress = "0x739f41c699276c9b6182aecb9d5f4eca226da8dd";
                var quasarContract = new web3.eth.Contract(contractAbi, quasarAddress);
                var quasarDecimal = await quasarContract.methods.decimals().call();
                await quasarContract.methods.balanceOf(account).call({
                    from: quasarAddress
                }).then(res => {
                    this.quasarQuantity = res / Math.pow(10, quasarDecimal);
                });
                // =================================================================
                var btcPlusAddress = "0x5d98a37f4e4d6fcd9d416f241c1b25fa6737691a";
                var btcPlusContract = new web3.eth.Contract(contractAbi, btcPlusAddress);
                var btcPlusDecimal = await btcPlusContract.methods.decimals().call();
                await btcPlusContract.methods.balanceOf(account).call({
                    from: btcPlusAddress
                }).then(res => {
                    this.btcPlusQuantity = res / Math.pow(10, btcPlusDecimal);
                });
                // =================================================================
                var ethPlusAddress = "0x9b5518a3b55a4e922064873aa2e9ad1270c8ea77";
                var ethPlusContract = new web3.eth.Contract(contractAbi, ethPlusAddress);
                var ethPlusDecimal = await ethPlusContract.methods.decimals().call();
                await ethPlusContract.methods.balanceOf(account).call({
                    from: ethPlusAddress
                }).then(res => {
                    this.ethPlusQuantity = res / Math.pow(10, ethPlusDecimal);
                });
            } else if (this.net == "Rinkeby") {
                var fiPopularAddress = "0x0a774ed04f2eb7a34b7cab01577aeaba8528a604";
                var fiPopularContract = new web3.eth.Contract(contractAbi, fiPopularAddress);
                var fiPopularDecimal = await fiPopularContract.methods.decimals().call();
                await fiPopularContract.methods.balanceOf(account).call({
                    from: fiPopularAddress
                }).then(res => {
                    this.fiPopularQuantity = res / Math.pow(10, fiPopularDecimal);
                });
            } else {}
        },
        async getValue() {
            if (this.fiQuantity != 0) {
                var fi = 0;
                if (this.fi == 0) {
                    const FI = new Token(ChainId.MAINNET, '0xF03d05eD4Cf4E723C04500Ab64281BfA591968F2', 18)
                    const WETH = new Token(ChainId.MAINNET, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18)
                    const fiPair = await Fetcher.fetchPairData(FI, WETH)
                    const fiRoute = new Route([fiPair], WETH)
                    fi = Number(fiRoute.midPrice.invert().toSignificant(6))
                } else {
                    fi = this.fi;
                }
                this.$http.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd").then(res => {
                    var ethUsd = res.data.ethereum.usd;
                    this.fiValue = this.fiQuantity * fi * ethUsd;
                });
            }
            if (this.defy1Quantity != 0) {
                this.$http.get(config.fund + "/iobots/asset/public/getDefyNav?day=1").then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        var defyUsd = data.result.navInUSD[0];
                        this.defy1Value = this.defy1Quantity * defyUsd;
                    };
                });
            };
            if (this.defy2Quantity != 0) {
                this.$http.get(config.fund + "/iobots/asset/public/getDefyNav?day=1").then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        var defyUsd = data.result.navInUSD[0];
                        this.defy2Value = this.defy2Quantity * defyUsd;
                    };
                });
            };
        },
        // 监听事件
        watchEvent() {
            if (this.metaMask != null) {
                let _this = this;
                this.metaMask.on('chainChanged', function () {
                    _this.account = null;
                    _this.defyBreakdownDialog = false;
                    _this.walletConnectDialog = false;
                    _this.accountInfoDialog = false;
                    _this.fiQuantity = 0;
                    _this.fiValue = 0;
                    _this.defy1Quantity = 0;
                    _this.defy2Quantity = 0;
                    _this.defy1Value = 0;
                    _this.defy2Value = 0;
                    _this.fiPopularQuantity = 0;
                    _this.iobQuantity = 0;
                    _this.iobV2Quantity = 0;
                    _this.checkMetaMask();
                    _this.$store.dispatch('walletAddressHandler', null);
                });
                this.metaMask.on('accountsChanged', function (accounts) {});
            }
        },
        // 复制的方法
        copy() {
            var data = this.account;
            this.$copyText(data, this.$refs.container).then(
                function () {
                    // 成功回调
                    alert("Success")
                },
                function () {
                    // 失败回调
                    alert("failed")
                }
            );
        },
        // 断开连接
        disconnect() {
            this.accountInfoDialog = false;
            this.defyBreakdownDialog = false;
            this.walletConnectDialog = false;
            this.account = null;
            this.fiQuantity = 0;
            this.fiValue = 0;
            this.defy1Quantity = 0;
            this.defy2Quantity = 0;
            this.defy1Value = 0;
            this.defy2Value = 0;
            this.fiPopularQuantity = 0;
            this.iobQuantity = 0;
            this.iobV2Quantity = 0;
            this.$store.dispatch('walletAddressHandler', null);
        },
        formatNum(num) {
            if (num == null) return '';
            if (num == NaN || num == 'NaN') return '';
            if (num == 'undefined') return '';
            if (num == '--') return '--';
            let number = num + '';
            let numberArray = number.split('.');
            let integerPart = numberArray[0];
            let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(integerPart)) {
                integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
            }
            return integerPart + decimalPart;
        },
        getPools(){
            let api = config.baseUrl + "/pool/public/info/pools";
            this.$http.get(api).then(res=>{
                let data = res.data;
                if(data.code == 200){
                    this.poolItems = data.result;
                    this.$store.dispatch('managedPoolInfoListHandler', data.result);
                    Bus.$emit('menuPools', this.poolItems);
                }
            })
        },
    },
}
</script>
