<template>
    <div>
        <pc-pools v-if="responsive == 'PC'"></pc-pools>
        <mobile-pools v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-pools>
    </div>
</template>

<style>
</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcPools from "./pc/Pools";
import MobilePools from "./mobile/Pools";

export default {
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/pools.md')
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcPools,
        MobilePools,
    },
    watch: {

    },
    methods: {

    },
}
</script>
