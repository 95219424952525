<template>
<div>
    <v-container class="grid-list-xl px-0">
        <v-row justify="center" align="center" class="h-100 mx-0">
            <v-col cols="11" class="h-35 px-2">
                <div class="fs-22 gradient7 fi_animate">{{ $t('pools.forRetailInvestors') }}</div>
                <div class="fs-68 py-8">{{ $t('pools.pools') }}</div>
                <div class="fs-28 dark-gray--text">{{ $t('pools.poweredByIobots') }}</div>
                <div>
                    <a :href="faq + '/getting-started/faq#what-is-a-defi-hedged-pool'" target="_black" class="del-underline white--text">
                        <v-btn tile class="glow-purple text-none mt-16 button--gradient-pink">{{ $t('common.learnMore') }}</v-btn>
                    </a>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="pt-150">
                <fund-video></fund-video>
        </v-row>
        <v-row justify="center" class="mt-16 pt-16 mx-0">
            <v-col cols="11" class="px-3">
                <div class="fs-22 gradient7 fi_animate">{{ $t('pools.daoManagedSmartContract') }}</div>
                <div class="fs-54 mt-2">{{ $t('pools.hedgedPool') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-8 pb-150 mx-0">
          <v-col cols="11" class="fs-22">
                <div class="fs-16 dark-gray--text pb-6">{{ $t('pools.token') }}:</div>
                <div class="fs-22 dark-gray--text pb-6">DEFY</div>
                <div class="fs-16 dark-gray--text pb-6">{{ $t('pools.tokenContractAddress') }}:</div>
                <div class="fs-14 dark-gray--text pb-6"><a href="https://etherscan.io/address/0x7af3b2b4093bd52309fe374c5fd05e215762c20c" target="_blank" class="del-underline pure-red--text">0x7aF3B2B4093bd52309fE374C5FD05E215762c20C &#8599;</a></div>
                <div class="fs-14 dark-gray--text pb-6"><a href="https://etherscan.io/address/0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59" target="_blank" class="del-underline pure-red--text">0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59 &#8599;</a></div>
                <div class="fs-16 dark-gray--text">{{ $t('pools.activelyManagedFundPools') }}</div>
            </v-col>
            <v-col cols="11" class="fs-22 px-3 pt-16">
                <div class="pb-6"><span class="point-icon-m1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('pools.smartContractAsAHedgedPool') }}</span></div>
                <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('pools.createdAndManagedByASeparateDAO') }}</div>
                <div class="pb-6"><span class="point-icon-m1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('pools.FITokenStakingReward') }}</span></div>
                <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('pools.hedgedPoolInvestors') }}</div>
                <div class="pb-6"><span class="point-icon-m1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7"><span v-html="$t('pools.mobileTradingCryptos')"></span></span></div>
                <div class="fs-16 dark-gray--text ml-7">{{ $t('pools.byAddingTrading') }}</div>
                <div class="pr-16 mr-3">
                    <!-- <a href="https://client.aragon.org/#/defy/0xf783b9e19597d212e3fa61cb71d62e7c5cba2422/" target="_blank" class="del-underline">
                        <v-btn tile class="glow-purple text-none mt-16 button--gradient-pink">Invest now</v-btn>
                    </a> -->
                    <!-- <v-btn tile class="glow-purple text-none mt-16 button--gradient-pink" @click="isShowTokenRequest = true">Staking now</v-btn> -->
                    <router-link to="/staking" class="del-underline white--text pointer">
                        <v-btn tile class="glow-purple text-none mt-16 button--gradient-pink">{{ $t('common.stakingNow') }}</v-btn>
                    </router-link>
                    <!-- <token-request :isShowTokenRequest="isShowTokenRequest" @isShowTokenRequest="changeShowTokenRequest"></token-request> -->
                </div>
            </v-col>
        </v-row>
    </v-container>
    <div class="bg-gradient-1">
        <v-container class="px-0">
            <v-row justify="center" class="pt-150 pb-150 mx-0">
                <v-col cols="11" class="dark-gray--text px-3">
                    <div class="fs-54 black--text">{{ $t('pools.quasarFund') }}</div>
                    <div class="pt-9 pb-8 fs-16 black--text">{{ $t('pools.quasarFundWasLabRat') }}</div>
                    <div class="fs-16 black--text">{{ $t('pools.aSuccessfulTradingSystem') }}</div>
                </v-col>
                <v-col cols="11" class="pt-16 px-3">
                    <div class="fs-22 black--text pb-6"><span class="point-icon-m1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">13.49%</span></div>
                    <div class="fs-16 black--text pb-8 ml-7"><a :href="fund + '/performance/quasar'" target="_black" class="pure-red--text del-underline">{{ $t('pools.oneYearReturn') }}&#8599;</a>{{ $t('pools.whileTestingTheNewTradingBots') }}</div>
                    <div class="fs-22 black--text pb-6"><span class="point-icon-m1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">151.68%</span></div>
                    <div class="fs-16 black--text pb-8 ml-7">{{ $t('pools.signalsGenerated') }}<a :href="fund + '/signals/portfolio'" target="_black" class="pure-red--text del-underline">{{ $t('pools.totalReturn') }}&#8599;</a> {{ $t('pools.eachSignal') }}</div>
                    <div class="fs-22 black--text pb-6"><span class="point-icon-m1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('pools.stableGrowth') }}</span></div>
                    <div class="fs-16 black--text pb-8 ml-7">{{ $t('pools.achievedConsistentGrowth') }}</div>
                    <div>
                        <a :href="fund + '/signals/more'" target="_black" class="del-underline white--text">
                            <v-btn tile class="glow-purple button--gradient-pink text-none mt-16">
                                {{ $t('common.learnMore') }}
                            </v-btn>
                        </a>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <v-row justify="center" class="pt-150 pb-150 mx-0">
        <v-col cols="11" class="text-center px-3">
            <div class="fs-22 gradient7 fi_animate">{{ $t('common.nutshell') }}</div>
            <div class="fs-42 mt-16 pb-16">{{ $t('pools.achieveTheLongTermGrowth') }}</div>
            <a :href="faq + '/getting-started/faq#the-new-market-opportunities'" class="del-underline" target="_blank">
                <v-btn tile class="glow-purple text-none button--gradient-pink">{{ $t('common.learnMore') }}</v-btn>
            </a>
        </v-col>
    </v-row>
    <v-row justify="center" class="pb-100 text-center mx-0">
        <v-col cols="11" class="px-3 fs-12 dark-gray--text">
            {{ $t('pools.notBeIndicativeOfFutureResults') }}
        </v-col>
    </v-row>
</div>
</template>

<style>
</style>

<script>
import Vue from 'vue'
import config from '@/config.js'
import FundVideo from "@/components/common/FundVideo";
import TokenRequest from "@/components/token/TokenRequest";
export default {
    data() {
        return {
            fund: config.fund,
            fi: config.domainName,
            faq: config.faq,
            // 是否显示Token请求
            isShowTokenRequest: false
        }
    },
    components: {
        FundVideo, TokenRequest
    },
    created() {
    },
    mounted() {

    },
    computed: {

    },
    watch: {

    },
    methods: {
        changeShowTokenRequest(){
            this.isShowTokenRequest = false;
        }
    },
}
</script>
