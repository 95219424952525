<template>
	<div>
        <v-dialog v-model="versionReleasedDialog" width="500" persistent>
            <v-card>
                <v-card-title>
                    <span class="fs-16">{{ newVersion }} Released!</span>
                    <v-spacer></v-spacer>
                    <v-btn small class="green--text ml-2 mr-2" outlined width="75" tile @click="refresh">
                        <span v-show="refreshCountdown != null">{{ refreshCountdown }}</span>
                        <span v-show="refreshCountdown == null">OK</span>
                    </v-btn>
                </v-card-title>
            </v-card>
        </v-dialog>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
                // 版本发布弹窗
                versionReleasedDialog: false,
                // 新版本号
                newVersion: null,
                // 查询版本定时器
                checkVersionTimer: null,
                // 刷新倒计时
                refreshCountdown: null,
                // 刷新倒计时定时器
                refreshTimer: null,
            }
        },
        created(){

        },
        mounted(){
            // 15分钟检查一次版本更新
            this.checkVersionTimer = setInterval(() => {
                this.getVersion();
            }, 60000 * 15);
        },
        computed: {
            ...mapGetters(["version"])
        },
        watch:{

        },
        methods: {
            // 查询系统版本号
            getVersion(){
                let api = config.baseUrl + '/system/public/version/iob.fi';
                this.$http.get(api).then(res => {
                  let data = res.data;
                  if (data.code == 200) {
                    this.newVersion = data.result;
                    if (this.newVersion != this.version) {
                        // 有新版本发布
                        this.versionReleasedDialog = true;
                        // 启动倒计时定时器
                        this.startIntervalRefreshTimer();
                    }
                  }
                });
            },
            // 启动倒计时定时器
            startIntervalRefreshTimer(){
                let countdown = 10;
                this.refreshTimer = setInterval(() => {
                    this.refreshCountdown = ' ' + countdown + 'S ';
                    countdown--;
                    if (countdown < 0) {
                      this.clearIntervalRefreshTimer();
                      this.refresh();
                    }
                }, 1000);
            },
            // 清除倒计时定时器
            clearIntervalRefreshTimer(){
                this.refreshCountdown = null;
                if (this.refreshTimer) {
                    clearInterval(this.refreshTimer);
                    this.refreshTimer = null;
                }
            },
            // 刷新
            refresh(){
                this.versionReleasedDialog = false;
                this.$router.go(0);
            }
        },
        beforeDestroy() {
          if(this.checkVersionTimer) {
            clearInterval(this.checkVersionTimer);
          }
        },
    }
</script>
