<template>
	<div>
        <v-row v-if="responsive == 'PC'">
            <v-col cols="12"><h5>{{ $t('pool.feeStructure') }}</h5></v-col>
            <v-col class="mt-n2">
                <v-divider class="dark-grey"></v-divider>
            </v-col>
            <v-col cols="12" class="mb-n6 dark-gray--title">
                <v-row dense>
                    <v-col cols="2">{{ $t('pool.entryFee') }}</v-col>
                    <v-col cols="2">{{ $t('pool.exitFee') }}</v-col>
                    <v-col cols="2">{{ $t('pool.performanceFee') }}</v-col>
                    <v-col cols="2">{{ $t('pool.hurdleRate') }}</v-col>
                    <v-col cols="2">{{ $t('pool.managementFee') }}</v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="mt-n2 pt-0">
                <v-row dense class="dark-gray--text fs-14">
                    <v-col cols="2">{{ fee.entry }}%</v-col>
                    <v-col cols="2">{{ fee.exit }}%</v-col>
                    <v-col cols="2">{{ fee.performance }}%</v-col>
                    <v-col cols="2">{{ fee.hurdle }}%</v-col>
                    <v-col cols="2">{{ fee.management }}%</v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="dark-gray--text fs-12">{{ fee.description }}</v-col>
        </v-row>
        <v-row v-else class="fs-12" dense>
            <v-col cols="12"><h5>{{ $t('pool.feeStructure') }}</h5></v-col>
            <v-col class="my-2" cols="12">
                <v-divider class="dark-grey"></v-divider>
            </v-col>
            <v-col cols="6" class="dark-gray--title">{{ $t('pool.entryFee') }}</v-col>
            <v-col cols="6" class="text-right dark-gray--text">{{ fee.entry }}%</v-col>
            <v-col cols="6" class="dark-gray--title">{{ $t('pool.exitFee') }}</v-col>
            <v-col cols="6" class="text-right dark-gray--text">{{ fee.exit }}%</v-col>
            <v-col cols="6" class="dark-gray--title">{{ $t('pool.performanceFee') }}</v-col>
            <v-col cols="6" class="text-right dark-gray--text">{{ fee.performance }}%</v-col>
            <v-col cols="6" class="dark-gray--title">{{ $t('pool.hurdleRate') }}</v-col>
            <v-col cols="6" class="text-right dark-gray--text">{{ fee.hurdle }}%</v-col>
            <v-col cols="6" class="dark-gray--title">{{ $t('pool.managementFee') }}</v-col>
            <v-col cols="6" class="text-right dark-gray--text">{{ fee.management }}%</v-col>
            <v-col cols="12" class="dark-gray--text fs-12">{{ fee.description }}</v-col>
        </v-row>
    </div>
</template>
<style>

</style>
<script>
    import config from '@/config.js';
    import { mapGetters} from "vuex";

    export default {
        data(){
            return {
                fee:{
                    entry: 0,
                    exit: 0,
                    hurdle: 0,
                    management: 0,
                    performance: 0,
                    description:"",
                },
            }
        },
        created(){
            this.getFee();
        },
        mounted(){
        },
        props:['address'],
        components:{
        },
        computed: {
            ...mapGetters(["responsive"]),
        },
        watch:{
            address(val){
                this.getFee();
            }
        },
        methods: {
            getFee(){
                let api = config.baseUrl + "/pool/public/fee/structure?address=" + this.address;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.fee = data.result;
                    }
                })
            }
        },
    }
</script>