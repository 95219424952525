<template>
    <div>
        <pc-activity v-if="responsive == 'PC'"></pc-activity>
        <mobile-activity v-if="responsive == 'mobile'"></mobile-activity>
    </div>
</template>

<style>
</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcActivity from "./pc/Activity";
import MobileActivity from "./mobile/Activity";

export default {
    data() {
        return {
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcActivity,
        MobileActivity,
    },
    watch: {

    },
    methods: {

    },
}
</script>
