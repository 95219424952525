<template>
    <div>
        <v-container class="grid-list-xl px-0">
            <v-row justify="center" align="center" class="h-100 mx-0">
                <v-col cols="11" class="h-35 px-2">
                    <div class="fs-22 gradient5 fi_animate">FI & IOB</div>
                    <div class="fs-68 py-8">{{ $t('tokens.tokens') }}</div>
                    <div class="fs-28 dark-gray--text pb-16">{{ $t('tokens.differentFunctional') }}{{ $t('tokens.transactionalAndFinancialFunctions') }}</div>
                   <div class="ml-1">
                        <a href="https://app.uniswap.org/#/swap?use=V2&inputCurrency=ETH&outputCurrency=0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline">
                        <v-btn tile class="glow-red text-none gradient-btn-3">{{ $t('tokens.buyFI') }}</v-btn>
                    </a>
                   </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="h-100">
                <v-col cols="11">
                    <iframe
                        src="https://app.uniswap.org/#/swap?use=V2&theme=dark&outputCurrency=0xf03d05ed4cf4e723c04500ab64281bfa591968f2"
                        height="700px"
                        width="100%"
                        style="border: 0; margin: 0 auto; display: block; border-radius: 0px; max-width: 600px; min-width: 300px;" />
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-16 pt-16 mx-0">
                <v-col cols="11" class="px-3">
                    <div class="fs-22 gradient5 fi_animate pb-6">{{ $t('tokens.governanceToken') }}</div>
                    <div class="fs-54">FI</div>
                </v-col>
            </v-row>
            <v-row justify="center" class="pt-8 pb-150 mx-0">
                <v-col cols="11" class="px-3">
                    <div class="fs-16 pb-6">{{ $t('tokens.token') }}:</div>
                    <div class="dark-gray--text pb-6">
                        <span>
                            <logo type="circle" color="w"></logo>
                        </span>
                        <span class="fs-24">
                            FI
                        </span>
                    </div>
                    <div class="fs-16 pb-6">{{ $t('tokens.tokenContractAddress') }}:</div>
                    <div class="fs-14 pb-6"><a href="https://etherscan.io/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline pure-red--text">0xf03d05ed4cf4e723c04500ab64281bfa591968f2 &#8599;</a></div>
                    <div class="fs-16 dark-gray--text pb-8">{{ $t('tokens.FIIsAGovernanceToken') }}</div>
                    <div class="fs-16 dark-gray--text">{{ $t('tokens.liquidityProvidersInvestors') }}</div>
                </v-col>
                <v-col cols="11" class="fs-22 pt-16">
                    <div class="fs-22 pb-6"><span class="point-icon-m6 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('tokens.listing') }}</span></div>
                    <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('tokens.FIWillBeListed') }}</div>
                    <div class="fs-22 pb-6"><span class="point-icon-m6 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('tokens.staking') }}</span></div>
                    <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('tokens.FIOffersVariousStakingRewardRates') }}</div>
                    <div class="fs-22 pb-6"><span class="point-icon-m6 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('tokens.aligningIncentives') }}</span></div>
                    <div class="fs-16 dark-gray--text ml-7">{{ $t('tokens.FIAlignsIncentives') }}</div>
                    <a href="https://v2.info.uniswap.org/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2" class="del-underline" target="_blank">
                        <v-btn tile class="text-none mt-16 gradient-btn-3">{{ $t('common.learnMore') }}</v-btn>
                    </a>
                </v-col>
            </v-row>
            <v-row justify="center" class="pt-150 pb-150 mx-0">
                <v-col md="11" class="fs-22 text-center">{{ $t('tokens.tokenRoadmap') }}</v-col>
                <v-row justify="center" class="mt-70 pr-9">
                    <v-timeline dark dense>
                        <v-timeline-item small color="#2196F3" class="dark-gray--text fs-16">{{ $t('tokens.initialUniswapListing') }}</v-timeline-item>
                        <v-timeline-item small color="#21cbf3" class="dark-gray--text fs-16">{{ $t('tokens.firstTokenAllotment') }}</v-timeline-item>
                        <v-timeline-item small color="#b6dcfb" class="dark-gray--text fs-16">{{ $t('tokens.secondTokenAllotment') }}</v-timeline-item>
                    </v-timeline>
                </v-row>
            </v-row>
        </v-container>
        <div class="bg-gradient-1">
            <v-container class="px-0 pt-150 pb-150">
                <v-row justify="center" class="mx-0">
                    <v-col cols="11" class="px-3">
                        <div class="fs-22 gradient5 fi_animate pb-6">{{ $t('tokens.earlyInvestorToken') }}</div>
                        <div class="fs-54 pb-8 black--text">IOB</div>
                        <div class="fs-16 black--text">{{ $t('tokens.IOBTokenholders') }}</div>
                    </v-col>
                    <v-col cols="11" class="pt-16 px-3">
                        <div class="fs-22 black--text pb-6"><span class="point-icon-m6 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('tokens.ownsFI') }}</span></div>
                        <div class="fs-16 black--text pb-8 ml-7">{{ $t('tokens.initialFITokenSupply') }}</div>
                        <div class="fs-22 black--text pb-6"><span class="point-icon-m6 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('tokens.convertibleToFI') }}</span></div>
                        <div class="fs-16 black--text pb-8 ml-7">{{ $t('tokens.IOBTokens') }}</div>
                        <div class="fs-22 black--text pb-6"><span class="point-icon-m6 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('tokens.higherEconomicalBenefits') }}</span></div>
                        <div class="fs-16 black--text ml-7">{{ $t('tokens.IOBTokenHolders') }}</div>
                        <div>
                            <a :href="faq + '/getting-started/faq#iob-token'" target="_black" class="del-underline white--text">
                                <v-btn tile class="gradient-btn-3 text-none mt-16">
                                    {{ $t('common.learnMore') }}
                                </v-btn>
                            </a>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="px-0">
            <v-row justify="center" class="pt-150 pb-150 mx-0">
                <v-col cols="11" class="text-center">
                    <div class="fs-22 gradient5 fi_animate">{{ $t('common.nutshell') }}</div>
                    <div class="fs-42 mt-16">{{ $t('tokens.youCanBenefit') }}</div>
                    <a :href="faq + '/getting-started/faq/#what-is-the-exchange-rate-between-iob-and-fi-tokens'" class="del-underline" target="_blank">
                        <v-btn tile class="text-none mt-16 gradient-btn-3">{{ $t('common.learnMore') }}</v-btn>
                    </a>
                </v-col>
            </v-row>
            <v-row justify="center" class="my-16 text-center pb-100 mx-0">
                <v-col cols="11" class="py-0 my-0 px-3">
                    <a href="https://poweredby.aragon.org/" target="blank">
                        <logo type="aragon" color="aragon"></logo>
                    </a>
                </v-col>
                <v-col cols="11" class="fs-12 dark-gray--text">
                    {{ $t('tokens.digitalAssetsCanBeVolatileAndRisky') }}
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style>
</style>
<script>
import Vue from 'vue';
import config from '@/config';
import Logo from '@/components/common/Logo';

export default {
    data() {
        return {
            faq: config.faq
        }
    },
    created() {},
    mounted() {

    },
    components: {
        Logo
    },
    computed: {

    },
    watch: {

    },
    methods: {

    },
}
</script>
