<template>
    <div>
        <v-row class="">
            <!-- <v-col cols="12" sm="12" md="12" class="">
                <div class="underline-gradient">
                    <a class="del-underline" target="_blank"  @click="show = !show">{{$t("pool.poolPerformanceAnalyses")}}</a>
                </div>
            </v-col> -->
            <v-col cols="12" sm="12" md="12" class="">
                <h5>{{$t("pool.poolPerformanceAnalyses")}}</h5>
            </v-col>
            <v-col class="mt-n2">
                <v-divider class="dark-grey"></v-divider>
            </v-col>
            <v-col cols="12" v-if="show && responsive == 'PC'" class="dark-gray--text">
                <v-row class="fs-12 mt-n5">
                    <v-col cols="12">
                        <span class="pointer" @click="feeTab = 'before'" :class="feeTab == 'before' ? 'pure-red--text underline-gradient' :''">{{ $t('nav.beforeFees') }}</span>
                        <span class="ml-5 pointer" @click="feeTab = 'after'" :class="feeTab == 'after' ? 'underline-gradient pure-red--text' :''">{{ $t('nav.afterFees') }}</span>
                    </v-col>
                </v-row>
                <v-row class="fs-12 mt-n1">
                    <v-col cols="12">{{$t("common.startingDate")}}: {{CommonJS.formatBritishDate(obj.date)}}</v-col>
					
                </v-row>
                <div v-if="tab == 'history'">
                    <v-row dense class="fs-12 mt-n3">
                        <v-col>
                            <span class="pointer" @click="tab = 'history'" :class="tab == 'history' ? 'underline-gradient pure-red--text' :''">{{$t("common.history")}}</span>
                            <span class="ml-5 pointer" @click="tab = 'recent'" :class="tab == 'recent' ? 'underline-gradient pure-red--text' :''">{{$t("common.recent")}}</span>
                        </v-col>
                        <v-col cols="10" class="mb-5">
                            <span v-for="item in selectList" @click="historyCurrent = item.value" class="mr-5 pointer" :class="historyCurrent == item.value ? 'underline-gradient pure-red--text' : ''">{{item.text}}</span>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '30days' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("common.1m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.pool1Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '60days' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("common.2m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.pool2Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '1quarter' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("common.3m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.pool3Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '6months' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("common.6m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.pool6Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == '1year' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("common.1y")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.pool1Year" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (historyCurrent == null || historyCurrent == 'sinceInception' || historyCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="historyCurrent == 'all'"><h5 class="">{{$t("common.sinceInception")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="historyHeader" :items="obj.history.sinceInception" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.start.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="loading">
                        <v-col cols="12">
                            <v-skeleton-loader type="text@2" ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="tab == 'recent'">
                    <v-row dense class="fs-12 mt-n3">
                        <v-col>
                            <span class="pointer" @click="tab = 'history'" :class="tab == 'history' ? 'underline-gradient pure-red--text' :''">{{$t("common.history")}}</span>
                            <span class="ml-5 pointer" @click="tab = 'recent'" :class="tab == 'recent' ? 'underline-gradient pure-red--text' :''">{{$t("common.recent")}}</span>
                        </v-col>
                        <v-col cols="10" class="mb-5">
                            <span v-for="item in selectList" @click="recentCurrent = item.value" class="mr-5 pointer" :class="recentCurrent == item.value ? 'underline-gradient pure-red--text' : ''">{{item.text}}</span>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '30days' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("common.1m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.pool1Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '60days' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("common.2m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.pool2Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '1quarter' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("common.3m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.pool3Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '6months' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("common.6m")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.pool6Month" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == '1year' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("common.1y")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.pool1Year" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!loading && (recentCurrent == null || recentCurrent == 'sinceInception' || recentCurrent == 'all')" class="mb-5">
                        <v-col cols="12" class="" v-if="recentCurrent == 'all'"><h5 class="">{{$t("common.sinceInception")}}</h5></v-col>
                        <v-col cols="12" class="px-0">
                            <v-data-table :headers="recentHeader" :items="obj.recent.sinceInception" hide-default-footer disable-sort dense>
                                    <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.currency" class="text-right dark-gray--text">
                                            <td class="text-left"><span class="ml-n3">{{ item.currency }}</span></td>
                                            <td>{{ item.current.toFixed(4) }}</td>
                                            <td :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</td>
                                            <td :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</td>
                                            <td>{{ item.start == 0 ? '--' : item.start.toFixed(4) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="loading">
                        <v-col cols="12">
                            <v-skeleton-loader type="text@2" ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </div>
                <!-- <div>
                    <v-row dense class="fs-12">
                        <v-col cols="12">
                            * Calculated with fee components but not adjusted for the fee-adjusted starting value; the net fee-adjusted performances would have been better than figures shown.
                        </v-col>
                    </v-row>
                </div> -->
            </v-col>
            <v-col cols="12" v-if="show && responsive != 'PC'" class="fs-12">
                <v-row class="mt-n5">
                    <v-col cols="12" class="fs-12 dark-gray--text">{{$t("common.startingDate")}}: {{CommonJS.formatBritishDate(obj.date)}}</v-col>
                    <v-col cols="12" class="fs-12 dark-gray--text mt-n3">
                        <span class="pointer" @click="tab = 'history'" :class="tab == 'history' ? 'underline-gradient pure-red--text' :''">{{$t("common.history")}}</span>
                        <span class="ml-5 pointer" @click="tab = 'recent'" :class="tab == 'recent' ? 'underline-gradient pure-red--text' :''">{{$t("common.recent")}}</span>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="tabList" class="fs-12" item-text="text" item-value="value" v-model="feeTab" hide-details dense solo></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="selectList" class="fs-12" item-text="text" item-value="value" v-model="historyCurrent" hide-details dense solo v-if="tab == 'history'"></v-select>
                        <v-select :items="selectList" class="fs-12" item-text="text" item-value="value" v-model="recentCurrent" hide-details dense solo v-if="tab == 'recent'"></v-select>
                    </v-col>
                    <v-col cols="12" class="fs-12 dark-gray--text" v-for="item in obj[tab].pool1Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '30days' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '30days' || recentCurrent == 'all')))">
                        <v-row dense>
                            <v-col cols="12" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("common.1m")}}</h5></v-col>
                            <v-col cols="12" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("common.1m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.currency")}}</v-col>
                            <v-col cols="6" class="text-right">{{item.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.endingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="fs-12 dark-gray--text" v-for="item in obj[tab].pool2Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '60days' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '60days' || recentCurrent == 'all')))">
                        <v-row dense>
                            <v-col cols="12" class="" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("common.2m")}}</h5></v-col>
                            <v-col cols="12" class="" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("common.2m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.currency")}}</v-col>
                            <v-col cols="6" class="text-right">{{item.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.endingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="fs-12 dark-gray--text" v-for="item in obj[tab].pool3Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '1quarter' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '1quarter' || recentCurrent == 'all')))">
                        <v-row dense>
                            <v-col cols="12" class="" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("common.3m")}}</h5></v-col>
                            <v-col cols="12" class="" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("common.3m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.currency")}}</v-col>
                            <v-col cols="6" class="text-right">{{item.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.endingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="fs-12 dark-gray--text" v-for="item in obj[tab].pool6Month" v-if="!loading && ((tab == 'history' && (historyCurrent == '6months' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '6months' || recentCurrent == 'all')))">
                        <v-row dense>
                            <v-col cols="12" class="" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("common.6m")}}</h5></v-col>
                            <v-col cols="12" class="" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("common.6m")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.currency")}}</v-col>
                            <v-col cols="6" class="text-right">{{item.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.endingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="fs-12 dark-gray--text" v-for="item in obj[tab].pool1Year" v-if="!loading && ((tab == 'history' && (historyCurrent == '1year' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == '1year' || recentCurrent == 'all')))">
                        <v-row dense>
                            <v-col cols="12" class="" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("common.1y")}}</h5></v-col>
                            <v-col cols="12" class="" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("common.1y")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.currency")}}</v-col>
                            <v-col cols="6" class="text-right">{{item.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.endingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="fs-12 dark-gray--text" v-for="item in obj[tab].sinceInception" v-if="!loading && ((tab == 'history' && (historyCurrent == 'sinceInception' || historyCurrent == 'all')) || (tab == 'recent' && (recentCurrent == 'sinceInception' || recentCurrent == 'all')))">
                        <v-row dense>
                            <v-col cols="12" class="" v-if="tab == 'history' && historyCurrent == 'all'"><h5 class="">{{$t("common.sinceInception")}}</h5></v-col>
                            <v-col cols="12" class="" v-if="tab == 'recent' && recentCurrent == 'all'"><h5 class="">{{$t("common.sinceInception")}}</h5></v-col>
                            <v-col class="my-0 py-0" cols="12">
                                <v-divider class="my-0 py-0"></v-divider>
                            </v-col>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.currency")}}</v-col>
                            <v-col cols="6" class="text-right">{{item.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.currentValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.start.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.current.toFixed(4) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.roe")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.roe == 0 ? '' : (item.roe > 0 ? 'green--text' : 'red--text')">{{ item.roe == 0 ? "--" : (item.roe > 0 ? "+" + item.roe.toFixed(2) + "%" : item.roe.toFixed(2) + "%") }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" class="dark-gray--title">{{$t("common.pl")}}</v-col>
                            <v-col cols="6" class="text-right" :class="item.pl == 0 ? '' : (item.pl > 0 ? 'green--text' : 'red--text')">{{ item.pl == 0 ? "--" : (item.pl > 0 ? "+" + item.pl.toFixed(4) : item.pl.toFixed(4)) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6" v-if="tab=='history'" class="dark-gray--title">{{$t("common.endingValue")}}</v-col>
                            <v-col cols="6" v-if="tab=='recent'" class="dark-gray--title">{{$t("common.startingValue")}}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='history'">{{ item.end == 0 ? "--" : item.end.toFixed(4) }}</v-col>
                            <v-col cols="6" class="text-right" v-if="tab=='recent'">{{ item.start == 0 ? "--" : item.start.toFixed(4) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-if="loading">
                        <v-skeleton-loader type="text@5" ></v-skeleton-loader>
                    </v-col>
                    <!-- <v-col cols="12" class="fs-12 dark-gray--text">
                        * Calculated with fee components but not adjusted for the fee-adjusted starting value; the net fee-adjusted performances would have been better than figures shown.
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<style scoped>
.theme--dark .v-data-table,.theme--light .v-data-table {
    background-color: transparent !important;
}
</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
                tab: "history",
                feeTab:"before",
                show:true,
                historyCurrent:'sinceInception',
                recentCurrent:'sinceInception',
                loading:false,
                obj:{
                    history:{},
                    recent:{},
                    date:"--",
                },
                before:null,
                after:null,
                historyHeader:[
                    {text:this.$t("common.currency"),width:"20%",class:"pl-1"},
                    {text:this.$t("common.startingValue"),align:"end",width:"20%"},
                    {text:this.$t("common.roe"),align:"end",width:"20%"},
                    {text:this.$t("common.pl"),align:"end",width:"20%"},
                    {text:this.$t("common.endingValue"),align:"end",width:"20%"},
                ],
                recentHeader:[
                    {text:this.$t("common.currency"),width:"20%",class:"pl-1"},
                    {text:this.$t("common.currentValue"),align:"end",width:"20%"},
                    {text:this.$t("common.roe"),align:"end",width:"20%"},
                    {text:this.$t("common.pl"),align:"end",width:"20%"},
                    {text:this.$t("common.startingValue"),align:"end",width:"20%"},
                ],
                selectList: [
                    {text: this.$t("common.1m"),value: "30days"},
                    {text: this.$t("common.2m"),value: "60days"},
                    {text: this.$t("common.3m"),value: "1quarter"},
                    {text: this.$t("common.6m"),value: "6months"},
                    {text: this.$t("common.1y"),value: "1year"},
                    {text: this.$t("common.sinceInception"),value: "sinceInception"},
                    {text: this.$t("common.all"),value: "all"},
                ],
                tabList:[
                    {text:this.$t('nav.beforeFees'),value:"before"},
                    {text:this.$t('nav.afterFees'),value:"after"},
                ],
            }
        },
        props:["address"],
        components: {
        },
        created(){
        },
        mounted(){
            this.getAdjustedAlgorithm1Performance();
        },
        computed: {
            ...mapGetters(["responsive",'darkMode'])
        },
        watch:{
            // 修改一个当前选中的周期 另一个也修改
            historyCurrent(val){
                if(this.recentCurrent != val){
                    this.recentCurrent = val;
                }
            },
            recentCurrent(val){
                if(this.historyCurrent != val){
                    this.historyCurrent = val;
                }
            },
            address(val){
                this.getAdjustedAlgorithm1Performance();
            },
            feeTab(val){
                if(val == 'before'){
                    if( this.before == null ){
                        this.getAdjustedAlgorithm1Performance();
                    }else{
                        this.obj = this.before;
                    }
                }
                if(val == 'after'){
                    if(this.after == null){
                        this.getPerformance();
                    }else{
                        this.obj = this.after;
                    }
                }
            }
        },
        methods: {
            getAdjustedAlgorithm1Performance(){
                this.loading = true;
                let api = config.baseUrl + "/asset/public/iobots/fee-adjusted/algorithm-3/performance/"+this.address;
                // let api = config.baseUrl + "/asset/public/iobots/fee-adjusted/performance/"+this.address;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.before = data.result
                        this.obj = this.before;
                        this.loading = false;
                    }else{
                        this.loading = false;
                    }
                })
            },
            getPerformance(){
                this.loading = true;
                let api = config.baseUrl + "/asset/public/iobots/performance/"+this.address;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.after = data.result
                        this.obj = this.after;
                        this.loading = false;
                    }else{
                        this.loading = false;
                    }
                })
            },
        },
    }
</script>