<template>
<div>
    <div class="mb-3 font-4x fw-bold" v-if="responsive == 'PC'">
        <v-row>
            <v-col cols="12" class="dark-gray--text font-weight-bold fs-20">
                <!-- <blockchain-network :network="blockchainNetwork"></blockchain-network> -->
                <span v-if="!loading">{{ poolName + ' ' + manageName }}</span>
                <span v-if="loading">--</span>
                <span class="float-right">{{ $t('nav.totalPerformance') }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <div>{{ option.series[0].data.length == 0 ? '--' : '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) }}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <span :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')" class="d-flex flex-row-reverse">{{performance == 0 ? '--' : (performance > 0 ? '+' + performance.toFixed(2) : performance.toFixed(2)) + '%'}}</span>
            </v-col>
        </v-row>
    </div>
    <div class="mb-3 fw-bold" v-if="responsive == 'mobile' || responsive == 'pad'">
        <div class="fs-24 mb-8">iob.fi DAO DEFY</div>
        <h4 class="dark-gray--text fw-bold fs-20 mb-n2">{{ $t('nav.totalPerformance') }}</h4>
        <div class="font-3x" :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')">{{performance == 0 ? '--' : (performance > 0 ? '+' + performance.toFixed(2) : performance.toFixed(2)) + '%'}}</div>
        <v-row dense class="mb-n4">
            <v-col cols="8">
                <h4 class="dark-gray--text fw-bold fs-12 mt-2">{{ manageName }}</h4>
            </v-col>
            <v-col cols="4" class="text-right mt-2 ml-n3">
                <div class="fs-12 mb-n2" v-if="option.series[0].data.length > 0">{{ '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) }}</div>
                <div class="fs-12 mb-n2" v-if="option.series[0].data.length == 0">--</div>
            </v-col>
        </v-row>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'PC'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-10" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
        <span class="grey--text">{{ $t('common.interval') }}</span>
        <span class="ml-2 pointer" :class="[interval.value == 'day' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[0]">{{ $t('common.1d') }}</span>
        <span class="ml-2 pointer" :class="[interval.value == 'week' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[1]">{{ $t('common.1w') }}</span>
        <span class="ml-2 pointer" :class="[interval.value == 'month' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[2]">{{ $t('common.1m') }}</span>
        <!-- <span class="float-right mr-2">
            <v-icon size="18" class="lineblue--text" title="Full Screen" @click="toggleFullscreen">mdi-fullscreen</v-icon>
        </span> -->
        <br>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'mobile'|| responsive == 'pad'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-10" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
        <span class="float-right mr-3">
            <span class="grey--text">{{ $t('common.interval') }}</span>
            <span class="ml-2 pointer" :class="[interval.value == 'day' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[0]">{{ $t('common.1d') }}</span>
            <span class="ml-2 pointer" :class="[interval.value == 'week' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[1]">{{ $t('common.1w') }}</span>
            <span class="ml-2 pointer" :class="[interval.value == 'month' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[2]">{{ $t('common.1m') }}</span>
        </span>
        <!-- <span class="float-right mr-2">
            <v-icon size="18" class="lineblue--text" title="Full Screen" @click="toggleFullscreen">mdi-fullscreen</v-icon>
        </span> -->
        <br>
    </div>
    <div :id="id" style="height: 360px" v-if="responsive == 'PC'" class="ml-n3"></div>
    <div :id="id" style="height: 290px" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
    <v-row dense align="center" v-if="responsive == 'PC'">
        <v-col cols="8" class="fs-12">
            <span class="pointer" @click="feeType = 'before'" :class="feeType == 'before' ? 'underline-gradient pure-red--text' :''">{{ $t('nav.beforeFees') }}</span>
            <span class="ml-5 pointer" @click="feeType = 'after'" :class="feeType == 'after' ? 'underline-gradient pure-red--text' :''">{{ $t('nav.afterFees') }}</span>
        </v-col>
        <v-col cols="4" class="fs-12 text-right pr-3">
            <span class="pointer" :class="[logBtn ? 'lineblue--text' :'grey--text']" @click="logBtn = !logBtn">log</span>
        </v-col>
    </v-row>
    <v-row dense align="center" v-if="responsive != 'PC'">
        <v-col cols="6" class="fs-12">
            <v-select :items="tabList" class="fs-12" item-text="text" item-value="value" v-model="feeType" hide-details dense solo></v-select>
        </v-col>
        <v-col cols="6" class="fs-12 text-right pr-3">
            <span class="pointer" :class="[logBtn ? 'lineblue--text' :'grey--text']" @click="logBtn = !logBtn">log</span>
        </v-col>
    </v-row>
    <div class="mt-70">
        <defy-analyses></defy-analyses>
    </div>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Lexend Deca", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import { mapGetters } from "vuex";
import DefyAnalyses from '@/components/pool/DefyAnalyses';
import BlockchainNetwork from "@/components/common/BlockchainNetwork";
export default {
    data() {
        return {
            address: '0xca38034a69ebedd28ce62368890e7cf187309a0f',
            day: 365,
            intervalList: [
                { text: '1D', value: 'day' },
                { text: '1W', value: 'week' },
                { text: '1M', value: 'month' }
            ],
            interval: { text: '1D', value: 'day' },
            currency: 'USD',
            id: 'defyNAVLineChart',
            quasarNav: null,
            chart: null,
            loading: false,
            performance: 0,
            poolName: null,
            manageName: null,
            feeType:"before",
            option: {
                // 图表类型
                chart: {
                    backgroundColor: 'dark',
                    renderTo: 'defyNAVLineChart',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    // zoomType: 'xy'
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#9E9E9E'
                    },
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#999999'
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 1,
                    tickInterval: 30,
                    tickWidth: 1,
                    tickPosition: 'inside',
                    lineColor: '#666666',
                    tickColor: '#666666',
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: true
                    }
                },
                // y轴
                yAxis: {
                    type: 'logarithmic',
                    title: {
                        text: null
                    },
                    // min: 0.8,
                    tickInterval: 0.2,
                    startOnTick: true,
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true,
                    offset: 20,
                    labels: {
                        align: "left",
                        x: -10,
                        formatter: function () {
                            return this.value.toFixed(2);
                        }
                    },
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 4,
                    valuePrefix: '',
                    valueSuffix: '',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                    // type: 'spline',
                    name: this.$t('highcharts.navLineChartDefi.iobQuasarNav'),
                    data: [],
                    // color: '#4CAF50',
					color: {
					       linearGradient: {
					         x1: 0,
					         x2: 0,
					         y1: 0,
					         y2: 1
					       },
					       stops: [
					         [0, '#4CAF50'],
					         [1, '#FEE108']
					       ]
					     },
                    lineWidth: 3
                }],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                // 图例导航
                legend: {
                    enabled: false,
                    align: 'left',
                    itemStyle: {
                        color: '#999999'
                    },
                    itemHoverStyle: {
                        color: '#999999'
                    },
                    itemHiddenStyle: {
                        color: '#606063'
                    },
                    title: {
                        style: {
                            color: '#C0C0C0',
                            fontFamily: '\'Lexend Deca\', sans-serif'
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: 'black',
                        theme: {
                            fill: '#EEEEEE',
                        },
                    }
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: true,
                    href: config.fund,
                    text: '',
                    className: 'a',
                    position: {
                        x: -12,
                        y: -25
                    },
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        target: '_black'
                    }
                },
                exporting: {
                    enabled: false,
                    filename: 'iob-Defy-NAV',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
            logBtn:true,
            tabList:[
                { text: this.$t('nav.beforeFees'), value:"before"},
                { text: this.$t('nav.afterFees'), value:"after"},
            ],
            // 区块链网络
            blockchainNetwork: '',
        }
    },
    props: {

    },
    watch: {
        day(val) {
            this.getDefyNAV();
        },
        'interval.value'(newVal, oldVal){
            // localStorage.setItem("iob.fi-defy-nav-interval", newVal);
            this.getDefyNAV();
        },
        currency() {
            if (this.quasarNav) {
                if (this.currency == 'BTC') {
                    this.option.series[0].data = this.quasarNav.quasarNavInBTC;
                } else {
                    this.option.series[0].data = this.quasarNav.quasarNavInUSD;
                }
            }
            // 重新渲染图表
            this.renderChart();
        },
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
        logBtn(val){
            if(val){
                this.option.yAxis.type = 'logarithmic';
                this.option.yAxis.min = null;
                this.option.yAxis.tickInterval=null;
            }else{
                this.option.yAxis.type = '';
                this.option.yAxis.min = 0.9;
                this.option.yAxis.tickInterval=0.1;
            }
            this.renderChart();
        },
        feeType(val){
            this.getDefyNAV();
        }
    },
    created() {
        // let intervalValue = localStorage.getItem("iob.fi-defy-nav-interval");
        // if (intervalValue == 'day') {
        //     this.interval = this.intervalList[0];
        // } else if (intervalValue == 'week') {
        //     this.interval = this.intervalList[1];
        // } else if (intervalValue == 'month') {
        //     this.interval = this.intervalList[2];
        // } else {
        //     this.interval = this.intervalList[2];
        // }
    },
    mounted() {
        this.getInceptionInfo();
        Highcharts.setOptions({
            lang: {
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
        this.renderChart();
    },
    computed: {
        ...mapGetters(['responsive'])
    },
    components: {
        DefyAnalyses, BlockchainNetwork
    },
    methods: {
        getDefyNAV(){
            if(this.feeType == 'before'){
                this.getFeeAdjustedDefyNAV();
            } else if(this.feeType == 'after'){
                this.getRawDefyNAV();
            }
        },
        // 查询调整fee的NAV
        getFeeAdjustedDefyNAV() {
            if(this.loading){
                return;
            }
            this.loading = true;
            this.performance = 0;
            let interval = this.interval.value;
            if (interval == 'day') {
                this.option.xAxis.tickInterval = 30;
            } else if (interval == 'week') {
                this.option.xAxis.tickInterval = 7;
            } else if (interval == 'month') {
                this.option.xAxis.tickInterval = 2;
            }
            // this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
            // this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
            this.option.series[0].data = [];
            this.option.xAxis.categories = [];
            // let api = config.baseUrl + "/asset/public/getDefyNav?day=" + this.day + "&interval=" + this.interval.value;
            let api = config.baseUrl + "/nav/public/defy/fee-adjusted/algorithm-3?day=" + this.day + "&interval=" + this.interval.value;
            this.$http.get(api).then(res => {
                let data = res.data;
                if (data.code == 200) {
                    // 20201214：现在API直接查询到实时NAV，并计算好RoE
                    let dataList = data.result.navInUSD;
                    this.option.series[0].data = dataList;
                    // this.option.xAxis.categories = data.result.date;
					let temp = [];
					for(let i=0; i<data.result.date.length; i++){
						let element = data.result.date[i];
						temp.push(this.CommonJS.formatBritishDate(element));		 
					}
					this.option.xAxis.categories = temp;
                    this.poolName = 'iob Defy';
                    this.manageName = this.$t('nav.feeAdjustedNav') + ' (' + this.$t('nav.beforeFees') + ')';
                    this.performance = data.result.roe * 100;
                    // if (dataList[0] <= dataList[dataList.length - 1]) {
                    //     this.option.series[0].color = '#4CAF50';
                    // } else {
                    //     this.option.series[0].color = '#F44336';
                    // }
                    // 重新渲染图表
                    this.renderChart();
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.noData();
                }
                // 查询实时的DEFY价格，存入store缓存
                this.getRealTimeDefyNav();
            }, error => {
                this.loading = false;
                this.noData();
            })
        },
        // 查询原始的NAV
        getRawDefyNAV() {
            if(this.loading){
                return;
            }
            this.loading = true;
            this.performance = 0;
            let interval = this.interval.value;
            if (interval == 'day') {
                this.option.xAxis.tickInterval = 30;
            } else if (interval == 'week') {
                this.option.xAxis.tickInterval = 7;
            } else if (interval == 'month') {
                this.option.xAxis.tickInterval = 2;
            }
            // this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
            // this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
            this.option.series[0].data = [];
            this.option.xAxis.categories = [];
            // let api = config.baseUrl + "/asset/public/getDefyNav?day=" + this.day + "&interval=" + this.interval.value;
            let api = config.baseUrl + "/nav/public/defy?day=" + this.day + "&interval=" + this.interval.value;
            this.$http.get(api).then(res => {
                let data = res.data;
                if (data.code == 200) {
                    // 20201214：现在API直接查询到实时NAV，并计算好RoE
                    let dataList = data.result.navInUSD;					
                    this.option.series[0].data = dataList;					
                    // this.option.xAxis.categories = data.result.date;	
						let temp = [];
						for(let i=0; i<data.result.date.length; i++){
							let element = data.result.date[i];
							temp.push(this.CommonJS.formatBritishDate(element));		 
						}
						this.option.xAxis.categories = temp;
                    this.poolName = 'iob Defy';
                    this.manageName = this.$t('nav.nav') + ' (' + this.$t('nav.afterFees') + ')';
                    this.performance = data.result.roe * 100;
                    // if (dataList[0] <= dataList[dataList.length - 1]) {
                    //     this.option.series[0].color = '#4CAF50';
                    // } else {
                    //     this.option.series[0].color = '#F44336';
                    // }
                    // 重新渲染图表
                    this.renderChart();
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.noData();
                }
                // 查询实时的DEFY价格，存入store缓存
                this.getRealTimeDefyNav();
            }, error => {
                this.loading = false;
                this.noData();
            })
        },
        // 查询实时的DEFY价格，存入store缓存
        getRealTimeDefyNav(){
            let api = config.baseUrl + "/iobots/asset/public/getDefyNavFromNet";
            // let api = "http://localhost:3000/asset/public/getDefyNavFromNet";
            this.$http.get(api).then(res => {
                let data = res.data;
                if(data.code == 200){
                    // 缓存defy价格
                    this.$store.dispatch('defyPriceHandler', data.result);
                }
            });
        },
        formatDateFunction(timestamp) {
            if (timestamp == 0) {
                return '--';
            } else {
                if ("localTime" == localStorage.getItem("iobots-time")) {
                    return formatDate(timestamp);
                } else {
                    return formatUTCDate(timestamp)
                }
            }
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
        },
        noData() {
            Highcharts.setOptions({
                lang: {
                    noData: this.$t('highcharts.noData'),
                    contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                    printChart: this.$t('highcharts.printChart'),
                    resetZoom: this.$t('highcharts.resetZoom'),
                    resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                    downloadPNG: this.$t('highcharts.downloadPNG'),
                    downloadJPEG: this.$t('highcharts.downloadJPEG'),
                    downloadPDF: this.$t('highcharts.downloadPDF'),
                    downloadSVG: this.$t('highcharts.downloadSVG')
                }
            });
            this.renderChart();
        },
        //全屏
        toggleFullscreen() {
            if (this.chart.fullscreen) {
              this.chart.fullscreen.toggle();
            }
        },
        // 根据地址查询池子成立信息
        getInceptionInfo(){
            let api = config.baseUrl + "/pool/public/info/inception/" + this.address;
            this.$http.get(api).then(res => {
                let data = res.data;
                if(data.code == 200){
                    let inceptionPeriod = data.result.inceptionPeriod;
                    if(inceptionPeriod.years > 0) {
                        this.day = 0;
                        this.interval = this.intervalList[2];
                    }
                    this.blockchainNetwork = data.result.blockchainNetwork;
                }
                // 查询NAV
                this.getDefyNAV();
            });
        }
    }
}
</script>