<template>
    <div>
        <pc-user-activity v-if="responsive == 'PC'|| responsive == 'pad' "></pc-user-activity>
        <mobile-user-activity v-if="responsive == 'mobile'"></mobile-user-activity>
    </div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcUserActivity from "./pc/UserActivity";
import MobileUserActivity from "./mobile/UserActivity";

export default {
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcUserActivity,
        MobileUserActivity,
    },
    watch: {

    },
    methods: {

    },
}
</script>
