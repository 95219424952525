<template>
<div>
    <video class="border-999" controls="controls" preload="auto" :poster="imgUrl" playsinline="true" x5-video-orientation="portraint" :src="url" height="auto" :width="videoSize"></video>
</div>
</template>
<style>
</style>

<script>
import Vue from 'vue'
import config from '@/config.js';
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            url: "https://files.iob.fi/2020/11/1605670803435-267febbe6001580ea870f637d4d6e403-DEFY.mp4",
            imgUrl: "https://files.iob.fi/2020/11/1605670811438-5346ed4de86c538e9026aacba7a6e30c-DEFY.webp"
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
        videoSize() {
            // responsive == 'PC' ? 849 : 300
            switch (this.responsive) {
                case "PC":
                    return 849;
                case "mobile":
                    return document.body.clientWidth;
                case "pad":
                    return 649;
                default:
                    return 849;
            }
        }
    },
    watch: {

    },
    methods: {

    },
}
</script>
