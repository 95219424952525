<template>
	<div>
        <v-container class="grid-list-xl mt-70">
            <v-row justify="center">
                <v-col cols="11" class="px-0 pb-0">
                    <span class="font-3x font-weight-bold">
                        <span>{{$t('dividend.myDividend')}}</span>
                    </span>
                </v-col>
                <v-col cols="11" class="px-0 pb-0 mt-5">
                    <v-row justify="center">
                        <v-col cols="3" class="pb-0">
                            <h5>{{$t('tokens.token')}}:</h5>
                        </v-col>
                        <v-col cols="9" class="pb-0">
                            <h5>
                                <span v-if="invalidAddress">{{$t('common.invalidAddress')}}</span>
                                <span v-else>{{ tokenDetail.name }}</span>
                            </h5>
                        </v-col>
                        <!-- <v-col cols="3">
                            <h5>Eligible Token Supply:</h5>
                        </v-col>
                        <v-col cols="9">
                            <h5>
                                <count-to :start-val='0' :end-val='Number(blackHolderBalance.eligibleBalance)' :duration='1000' :decimals='4' separator=',' prefix=''></count-to>{{ ' ' + tokenDetail.symbol }}
                            </h5>
                        </v-col> -->
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center" v-if="!invalidAddress">
                <!-- 未连接钱包 -->
                <v-col cols="11" class="px-0 pb-0" v-if="walletAddress == null || metaMaskNet != ''">
                    <v-card class="text-center" height="200px">
                        <v-card-text class="pt-16">
                            <v-btn v-if="metaMaskNet == '' && metaMaskNet == ''" tile class="text-none mt-5 button--gradient-orange glow-orange black--text mt-2" small @click="connectWallet">{{ $t('wallet.connectWallet') }}</v-btn>
                        </v-card-text>
                        <v-card-text class="pt-2">
                            <span v-if="metaMaskNet != ''">{{ $t('investments.mainnetNetworkSupportOnly') }}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- 已连接钱包 -->
                <v-col cols="11" class="px-0 pb-0" v-if="walletAddress != null && metaMaskNet == ''">
                    <v-row justify="center">
                        <!-- <v-col cols="12">
                            <v-divider class="dark-grey"></v-divider>
                        </v-col> -->
                        <v-col cols="3">
                            <h5>{{$t('dividend.myEligibleBalance')}}:</h5>
                        </v-col>
                        <v-col cols="9">
                            <h5>
                                <count-to :start-val='0' :end-val='tokenHolderBalance.eligibleBalance' :duration='1000' :decimals='4' separator=',' prefix=''></count-to>{{ ' ' + tokenDetail.symbol }}
                                <span>&nbsp;( December 31, 2021 )</span>
                            </h5>
                        </v-col>
                        <!-- <v-col cols="3">
                            <h5>Percentage:</h5>
                        </v-col>
                        <v-col cols="9">
                            <h5>
                                <count-to :start-val='0' :end-val='percentage' :duration='1000' :decimals='4' separator=',' prefix='' suffix='%'></count-to>
                            </h5>
                        </v-col>
                        <v-col cols="3">
                            <h5>My Balance:</h5>
                        </v-col>
                        <v-col cols="9">
                            <h5>
                                <count-to :start-val='0' :end-val='myBalance' :duration='1000' :decimals='4' separator=',' prefix=''></count-to>{{ ' ' + tokenDetail.symbol }}
                            </h5>
                        </v-col> -->
                        <v-col cols="3">
                            <h5>{{$t('dividend.myDividend')}}:</h5>
                        </v-col>
                        <v-col cols="9">
                            <h5>
                                <count-to :start-val='0' :end-val='tokenHolderBalance.dividend' :duration='600' :decimals='4' separator=',' prefix='' suffix=' USDC'></count-to>
                            </h5>
                        </v-col>
                        <v-col cols="3" v-if="isRequested">
                            <h5>{{$t('dividend.requestTime')}}:</h5>
                        </v-col>
                        <v-col cols="9" v-if="isRequested">
                            <h5>{{ CommonJS.formatBritishTime(tokenDividendRequest.timestamp) }}</h5>
                        </v-col>
                        <v-col cols="12">
                            <v-divider class="dark-grey"></v-divider>
                        </v-col>
                        <v-col cols="12" v-if="!queryLoading && !isRequested">
                            <v-form ref="confirmForm" v-model="confirmFormValid" lazy-validation>
                                <v-checkbox color="yellow" v-model="confirmCheckbox" :rules="[v => !!v || 'You must agree to continue!']" required :label="$t('dividend.confirmLabel1') + walletAddress + $t('dividend.confirmLabel2')"></v-checkbox>
                            </v-form>
                        </v-col>
                        <v-col cols="12" v-if="!queryLoading && !isRequested" class="text-center">
                            <v-btn tile x-large  x-large :loading="requestLoading" class="text-none mt-5 button--gradient-orange glow-orange black--text mt-2" width="300px" @click="request">{{$t('common.request')}}</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="!queryLoading && isRequested">
                            <h3>{{$t('dividend.requested')}}!</h3>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import Web3 from "web3";
    import Bus from "@/components/bus/Bus";
    export default {
        data(){
            return {
                // 无效的地址
                invalidAddress: false,
                //代币详情
                tokenDetail: {
                    name: "",
                    symbol: "",
                    holders: 0,
                    totalSupply: 0,
                    burned: 0
                },
                // 黑洞余额
                blackHolderBalance: {
                    eligibleBalance: 0
                },
                // 持币人余额
                tokenHolderBalance: {
                    balance: 0,
                    eligibleBalance: 0,
                    dividend: 0,
                },
                // 代币分红申请记录
                tokenDividendRequest: {},
                // 是否已申请
                isRequested: false,
                // 表单验证
                confirmFormValid: true,
                // 当前实时余额
                myBalance: 0,
                // 代币地址
                tokenAddress: this.$route.params.address,
                // 确认
                confirmCheckbox: false,
                // 查询加载中
                queryLoading: false,
                // 申请加载中
                requestLoading: false,
            }
        },
        created(){
            
        },
        mounted(){
            this.getAddressDetail();
            this.getEligibleTokenSupply();
            if(this.walletAddress != null && this.metaMaskNet == ''){
                // this.getMyBalance();
                this.getTokenHolderBalance();
                this.getTokenDividendRequest();
            }
        },
        computed: {
            ...mapGetters(['metaMask', 'metaMaskNet','walletAddress','responsive','fiethPrice','ethPrice']),
            percentage(){
                if(Number(this.blackHolderBalance.eligibleBalance) == 0){
                    return 0;
                }
                return Number(this.tokenHolderBalance.eligibleBalance) / Number(this.blackHolderBalance.eligibleBalance) * 100;
            },
        },
        components:{

        },
        watch:{
            walletAddress(val){
                if(val != null && this.metaMaskNet == ''){
                    // this.getMyBalance();
                    this.getTokenHolderBalance();
                    this.getTokenDividendRequest();
                }
            },
            metaMaskNet(val){
                if(val == '' && this.walletAddress != null){
                    // this.getMyBalance();
                    this.getTokenHolderBalance();
                    this.getTokenDividendRequest();
                }
            }
        },
        methods: {
            // 查询地址详情
            getAddressDetail(){
                let api = config.baseUrl + "/token/public/detail/" + this.tokenAddress;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.tokenDetail = data.result;
                        this.invalidAddress = false;
                    } else {
                        this.invalidAddress = true;
                    }
                })
            },
            // 查询有效的总量
            getEligibleTokenSupply(){
                let api = config.baseUrl + "/token/holder/public/balance?tokenAddress=" + this.tokenAddress + "&holderAddress=0x0000000000000000000000000000000000000000";
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.blackHolderBalance = data.result;
                    }
                })
            },
            // 根据代币地址和持有人地址查询余额
            getMyBalance(){
                let api = config.baseUrl + "/token/public/balance?tokenAddress=" + this.tokenAddress + "&holderAddress=" + this.walletAddress;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.myBalance = data.result;
                    }
                })
            },
            // 查询持币人的代币余额
            getTokenHolderBalance(){
                let api = config.baseUrl + "/token/holder/public/balance?tokenAddress=" + this.tokenAddress + "&holderAddress=" + this.walletAddress;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.tokenHolderBalance = data.result;
                    }
                })
            },
            // 查询申请代币分红记录
            getTokenDividendRequest(){
                this.queryLoading = true;
                let api = config.baseUrl + "/token/dividend/public/request?tokenAddress=" + this.tokenAddress + "&holderAddress=" + this.walletAddress;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.tokenDividendRequest = data.result;
                        this.isRequested = true;
                    } else {
                        this.isRequested = false;
                    }
                    this.queryLoading = false;
                })
            },
            // 申请
            request(){
                if(this.$refs.confirmForm.validate()){
                    this.requestLoading = true;
                    let api = config.baseUrl + "/token/dividend/public/request?tokenAddress=" + this.tokenAddress + "&holderAddress=" + this.walletAddress;
                    this.$http.post(api).then(res=>{
                        let data = res.data;
                        if(data.code == 200){
                            this.getTokenDividendRequest();
                        } else {
                            this.isRequested = false;
                        }
                        this.requestLoading = false;
                    })
                }
            },
            // 连接钱包
            connectWallet(){
                Bus.$emit('isShowConnectWalletMenu', true);
            },
        },
    }
</script>
<style>
</style>