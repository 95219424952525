<template>
    <div>
        <pc-dao v-if="responsive == 'PC'"></pc-dao>
        <mobile-dao v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-dao>
    </div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcDao from "./pc/DAO";
import MobileDao from "./mobile/DAO";

export default {
    data() {
        return {

        }
    },
    created() {
        this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/DAO.md')
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcDao,
        MobileDao,
    },
    watch: {

    },
    methods: {

    },
}
</script>
