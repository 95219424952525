<template>
<div>
    <div class="white">
        <v-container class="px-0 py-0 pb-5">
            <!-- <div>
                    <template>
                        <v-navigation-drawer expand-on-hover width="250" class="ml-1" mini-variant-width="110">
                            <div class="pointer d-custom-flex mt-2">
                                <router-link to="/">
                                    <logo type="fiveLines" color="gradient1"></logo>
                                </router-link>
                            </div>
                        </v-navigation-drawer>
                    </template>
                </div> -->
            <!-- <div class="ml-1">
                    <a id="logo" class="logo collapsed">
                        <span>
                            <logo type="fiveLines" color="w"></logo>
                        </span><span class="collapse"><i>
                                <logo type="fiveLines" color="iob"></logo>
                            </i></span>
                    </a>
                </div> -->
            <!-- <v-col cols="2" class="mt-16 text-center px-0">
                    <div class="black--text fs-12 pb-3 ml-16">gas price (gwei)</div>
                    <a href="https://www.gasnow.org/" target="_black" class="del-underline">

                        <v-card outlined light hover class="pointer float-right" max-width="130" tile>
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="fs-12 black--text">
                                        standard
                                    </div>
                                    <v-list-item-title class="fs-16 fw-bold blue--text mb-1">
                                        {{ gasPriceGwei }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="fs-12 mb-1">${{ gasPriceGweiDoller }} | 3 minutes</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </a>
                </v-col> -->
            <v-row justify="center" align="center" v-if="responsive == 'PC'">
                <v-col cols="11" class="black--text">
                    <span class="mr-3 fs-8">IOB © 2020 - 2021</span>
                    <a :href="sip+'/#/about/privacy'" target="_blank" class="fs-8 mr-3 del-underline black--text">Privacy & Legal</a>
                    <a :href="fund+'/about/terms'" target="_blank" class="mr-3 fs-8 del-underline black--text">Terms</a>
                    <!-- <a :href="fund" target="_blank" class="mr-3 del-underline fs-8 footer-about black--text">ioBots</a> -->
                    <!-- <a :href="sip" target="_blank" class="mr-3 del-underline fs-8 footer-about black--text">SiP</a>
                        <a :href="sip+'/#/about'" target="_blank" class="mr-3 fs-8 del-underline footer-about black--text">About</a>
                        <a :href="sip+'/#/ir'" target="_blank" class="mr-3 fs-8 del-underline footer-about black--text">Investors</a>
                        <a :href="sip+'/#/about/contact'" target="_blank" class="fs-8 mr-3 del-underline footer-about black--text">Contact</a>
                        <a :href="sip+'/#/about/frequently-asked-questions'" target="_blank" class="fs-8 mr-3 del-underline footer-about black--text">FAQ</a> -->
                </v-col>
                <!-- <v-col cols="2">
                        <v-select v-model="languageModel" class="white-hover fs-8" :items="languageItems" prepend-inner-icon="mdi-google-translate" :menu-props="{ openOnHover: true }" item-text="text" item-value="value" dense light tile @input="changeLanguage"></v-select>
                    </v-col> -->
                <!-- <v-dialog v-model="gdprDialog" max-width="780" persistent>
                <v-card light>
                    <v-card-title>
                        <span class="fs-16 font-weight-bold">Corporate GDPR Statement</span>
                        <v-spacer></v-spacer>
                        <v-btn icon  @click="gdprDialog = false">
                            <v-icon size="18">icon icon-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-3">
                        <div class="mb-2">In May 2018, the EU <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679" target="_blank" class="red--text del-underline">General Data Protection Regulation (GDPR)</a> replaces the existing 1995 EU Data Protection Directive (European Directive 95/46/EC).</div>
                        <div class="mb-2">IOB LLC currently complies with applicable data protection regulations and is committed to GDPR compliance across its relevant services when the GDPR takes effect May 25, 2018. IOB LLC has a dedicated internal team made up of cross-functional stakeholders overseeing IOB LLC’s GDPR readiness. IOB LLC’s ongoing compliance efforts include:</div>
                        <div class="mb-2 font-weight-bold">Assessment</div>
                        <div class="mb-2">IOB LLC has reviewed where and how our relevant services collect, use, store and dispose of personal data and has updated policies, standards, governance and documentation where needed. IOB LLC is dedicated to keeping such due diligence current and carrying out re-assessments periodically and/or as required by changed circumstances.</div>
                        <div class="mb-2 font-weight-bold">Contractual Commitments</div>
                        <div class="mb-2">Working in conjunction with our partners and customers, IOB LLC is reviewing our contractual commitments and updating as needed to directly address GDPR requirements. IOB LLC has released a Data Processing Addendum (DPA) with provisions to assist our partners and customers with their GDPR compliance.</div>
                        <div class="mb-2 font-weight-bold">Cross-border Data Transfer</div>
                        <div class="mb-2">In addition to ensuring IOB LLC’s contractual commitments meet the requirements to legally transfer data from the EU to the rest of the world under applicable law, IOB LLC plans to certify under the EU-US Privacy Shield Framework.</div>
                        <div class="mb-2 font-weight-bold">Employee Training And Awareness</div>
                        <div class="mb-2">All IOB LLC employees must complete data privacy and security training. IOB LLC will supplement existing training modules with GDPR-specific content. In addition to these training requirements, IOB LLC conducts ongoing awareness initiatives on a variety of topics, including data protection, security.</div>
                        <div class="mb-2 font-weight-bold">IOB LLC Investors, Partners, and Customers</div>
                        <div class="mb-2">Compliance with the GDPR requires a partnership between IOB LLC and our partners and customers in their use of applicable IOB LLC services. In this context, IOB LLC generally will act as a data processor and our partners and customers generally will act as data controllers. Working together, we hope to explore opportunities within our relevant service offerings to assist our partners and customers meet their GDPR obligations. In the meantime, IOB LLC encourages partners and customers to independently familiarize themselves with the GDPR.</div>
                    </v-card-text>
                </v-card>
            </v-dialog> -->
            </v-row>
        </v-container>
    </div>
</div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
import {
    mapGetters
} from "vuex";

export default {
    data() {
        return {
            domain: config.domainName,
            sip: config.sip,
            fund: config.fund,
            // gdprDialog: false,
            // 当前的语言环境
            languageModel: this.$store.getters.locale,
            // 语言环境选择框
            languageItems: [{
                    text: 'English',
                    value: 'en-US'
                },
                {
                    text: '简体中文',
                    value: 'zh-CN'
                }
            ],
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"])
    },
    watch: {

    },
    methods: {
        // 切换语言
        changeLanguage(locale) {
            this.$i18n.locale = locale;
            this.$store.dispatch("localeHandler", locale);
            this.$router.go();
        },
    },
}
</script>
