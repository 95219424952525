<template>
<div>
    <v-container class="grid-list-xl px-0">
        <v-row justify="center" align="center" class="h-100">
            <v-col cols="11" class="h-30">
                <div class="fs-24 gradient7 fi_animate">{{ $t('pools.forRetailInvestors') }}</div>
                <div class="fs-100 py-8">{{ $t('pools.pools') }}</div>
                <div class="fs-24 dark-gray--text pb-8">{{ $t('pools.poweredByIobots') }}</div>
                <a :href="faq + '/getting-started/faq#what-is-a-defi-hedged-pool'" target="_black" class="del-underline white--text">
                    <v-btn tile class="glow-purple button--gradient-pink text-none">
                        {{ $t('common.learnMore') }}
                    </v-btn>
                </a>
            </v-col>
        </v-row>
        <v-row justify="center" class="pb-250 pt-250">
            <fund-video></fund-video>
        </v-row>
        <v-row justify="center" class="mt-16 pt-16">
            <v-col cols="6"></v-col>
            <v-col cols="5" class="px-6">
                <div class="fs-24 gradient7 fi_animate ml-1">{{ $t('pools.daoManagedSmartContract') }}</div>
                <div class="fs-68 mt-2">{{ $t('pools.hedgedPool') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-8 pb-250 pl-11">
            <v-col md="6" class="fs-22 text-right">
                <div class="pb-6 pr-16"><span class="mr-3">{{ $t('pools.smartContractAsAHedgedPool') }}</span><span class="point-icon-4 animate-point-init animate-point-wave mt-2"></span></div>
                <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('pools.createdAndManagedByASeparateDAO') }}</div>
                <div class="pb-6 pr-16"><span class="mr-3">{{ $t('pools.FITokenStakingReward') }}</span><span class="point-icon-4 animate-point-init animate-point-wave mt-2"></span></div>
                <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('pools.hedgedPoolInvestors') }}</div>
                <div class="pb-6 pr-16"><span class="mr-3">{{ $t('pools.tradingCryptos') }}</span><span class="point-icon-4 animate-point-init animate-point-wave mt-2"></span></div>
                <div class="fs-18 dark-gray--text pr-16 mr-3">{{ $t('pools.byAddingTrading') }}</div>
            </v-col>
            <v-col cols="5" class="fs-22 pl-1">
                <div class="fs-18 dark-gray--text pb-6">{{ $t('pools.token') }}:</div>
                <div class="fs-22 dark-gray--text pb-6">DEFY</div>
                <div class="fs-18 dark-gray--text pb-6">{{ $t('pools.tokenContractAddress') }}:</div>
                <div class="fs-18 dark-gray--text pb-6 title_txt"><a href="https://etherscan.io/address/0x7af3b2b4093bd52309fe374c5fd05e215762c20c" target="_blank" class="del-underline pure-red--text">0x7aF3B2B4093bd52309fE374C5FD05E215762c20C &#8599;</a></div>
                <div class="fs-18 dark-gray--text pb-6 title_txt"><a href="https://etherscan.io/address/0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59" target="_blank" class="del-underline pure-red--text">0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59 &#8599;</a></div>
                <div class="fs-18 dark-gray--text pb-6">{{ $t('pools.activelyManagedFundPools') }}</div>
                <div class="pr-16 mr-3">
                    <!-- <a href="https://client.aragon.org/#/defy/0xf783b9e19597d212e3fa61cb71d62e7c5cba2422/" target="_blank" class="del-underline">
                        <v-btn tile class="glow-purple text-none mt-16 button--gradient-pink">Invest now</v-btn>
                    </a> -->
                    <!-- <v-btn tile class="glow-purple text-none mt-16 button--gradient-pink" @click="isShowTokenRequest = true">Staking now</v-btn> -->
                    <router-link to="/staking" class="del-underline white--text pointer">
                        <v-btn tile class="glow-purple text-none mt-16 button--gradient-pink">{{ $t('common.stakingNow') }}</v-btn>
                    </router-link>
                    <!-- <token-request :isShowTokenRequest="isShowTokenRequest" @isShowTokenRequest="changeShowTokenRequest"></token-request> -->
                </div>
            </v-col>
        </v-row>
    </v-container>
    <div class="bg-gradient-1">
        <v-container>
            <v-row justify="center" class="pt-250 pb-250 pl-11">
                <v-col cols="6" class="text-right black--text">
                    <div class="fs-68 black--text pr-16">{{ $t('pools.quasarFund') }}</div>
                    <div class="pt-9 pb-8 pr-16">{{ $t('pools.quasarFundWasLabRat') }}</div>
                    <div class="pr-16">{{ $t('pools.aSuccessfulTradingSystem') }}</div>
                    <div class="pr-16">
                        <a :href="fund + '/signals/more'" target="_black" class="del-underline white--text">
                            <v-btn tile class="glow-purple button--gradient-pink text-none mt-16">
                                {{ $t('common.learnMore') }}
                            </v-btn>
                        </a>
                    </div>
                </v-col>
                <v-col cols="5" class="mt-16 pt-16">
                    <div class="fs-22 black--text pb-6"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">13.49%</span></div>
                    <div class="fs-18 black--text pb-8 pl-9 title_txt"><a :href="fund + '/performance/quasar'" target="_black" class="pure-red--text del-underline">{{ $t('pools.oneYearReturn') }}&#8599;</a>{{ $t('pools.whileTestingTheNewTradingBots') }}</div>
                    <div class="fs-22 black--text pb-6"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">151.68%</span></div>
                    <div class="fs-18 black--text pb-8 pl-9 title_txt">{{ $t('pools.signalsGenerated') }}<a :href="fund + '/signals/portfolio'" target="_black" class="pure-red--text del-underline">{{ $t('pools.totalReturn') }}&#8599;</a> {{ $t('pools.eachSignal') }}</div>
                    <div class="fs-22 black--text pb-6"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('pools.stableGrowth') }}</span></div>
                    <div class="fs-18 black--text pl-9">{{ $t('pools.achievedConsistentGrowth') }}</div>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <v-row justify="center" class="pt-250 pb-500">
        <v-col md="6" class="text-center">
            <div class="fs-22 gradient7 fi_animate">{{ $t('common.nutshell') }}</div>
            <div class="fs-54 mt-16 pb-16">{{ $t('pools.achieveTheLongTermGrowth') }}</div>
            <a :href="faq + '/getting-started/faq#the-new-market-opportunities'" class="del-underline" target="_blank">
                <v-btn tile class="glow-purple text-none button--gradient-pink">{{ $t('common.learnMore') }}</v-btn>
            </a>
        </v-col>
    </v-row>
    <v-row justify="center" class="pb-100 text-center">
        <v-col md="12" class="fs-14 dark-gray--text">
            {{ $t('pools.notBeIndicativeOfFutureResults') }}
        </v-col>
    </v-row>
</div>
</template>

<style>
#right {
    text-align: right !important;
}
</style>

<script>
import Vue from 'vue'
import config from '@/config.js'
import FundVideo from "@/components/common/FundVideo";
import TokenRequest from "@/components/token/TokenRequest";
export default {
    data() {
        return {
            fund: config.fund,
            faq: config.faq,
            fi: config.domainName,
            // 是否显示Token请求
            isShowTokenRequest: false
        }
    },
     components: {
        FundVideo, TokenRequest
    },
    created() {},
    mounted() {

    },
    computed: {

    },
    watch: {

    },
    methods: {
        changeShowTokenRequest(){
            this.isShowTokenRequest = false;
        }
    },
}
</script>
