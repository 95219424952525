<template>
<div>
    <v-container class="px-0">
        <v-row justify="center" align="center" class="mt-16 pb-150 pt-150 pl-13 pr-12">
            <v-col cols="3" class="text-center">
                <a href="https://app.uniswap.org/#/add/v2/ETH/0xF03d05eD4Cf4E723C04500Ab64281BfA591968F2" target="_blank" class="del-underline pointer">
                    <v-btn width="150" tile large class="text-none black--text button--gradient-orange glow-orange">{{ $t('staking.FI_ETH_Staking') }}</v-btn>
                </a>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="5">
               {{ $t('staking.addingLiquidityToTheFIETHPoolOnUniswap') }}
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="pb-150 pl-13 pr-12">
            <v-col cols="3" class="text-center">
                <router-link to="/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2/buy" class="black--text del-underline">
                    <v-btn width="150" tile large class="text-none black--text button--gradient-orange glow-orange">{{ $t('staking.buyFI') }}</v-btn>
                </router-link>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="5">
                {{ $t('staking.buyFITokenDirectly') }}
                <span class="title_txt">
                    <router-link to="/tokens" class="del-underline pure-red--text">
                        Uniswap
                    </router-link>
                </span>
                {{ $t('staking.buyFITokenDirectly2') }}
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="pb-250 pl-13 pr-12">
            <v-col cols="3" class="text-center">
                <router-link to="/pool/0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59/buy" class="del-underline pointer">
                    <v-btn tile width="150" large class="button--gradient-orange black--text glow-orange text-none">{{ $t('staking.DEFYInvest') }}</v-btn>
                </router-link>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="5" class="align-start">
                {{ $t('staking.addingLiquidityToTheDefyHedgedPool') }}{{ (defyAnnualizedReturn * 100).toFixed(2) }}% {{ $t('staking.earned') }}
            </v-col>
        </v-row>
        <v-row  justify="center" align="center" class="pb-250">
            <v-col cols="8">
                <div class="fs-14 dark-gray--text title_txt text-center">
                    {{ $t('staking.stakingDescription') }} <a :href="faq + '/products/defy-fund-pool'" target="_blank" class="del-underline pure-red--text">https://docs.iob.fi/products/defy-fund-pool.</a>
                </div>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<style>

</style>

<script>
import Vue from 'vue'
import Logo from '@/components/common/Logo';
import config from '@/config.js';
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            faq: config.faq
        }
    },
    created() {

    },
    mounted() {

    },
    components: {
        Logo

    },
    computed: {
        ...mapGetters(["defyAnnualizedReturn"])
    },
    watch: {

    },
    methods: {

    },
}
</script>
