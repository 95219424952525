<template>
	<div>
        <v-container class="grid-list-xl mt-70 pb-250">
            <v-form ref="contactForm" v-model="contactFormValid" lazy-validation>
                <v-row justify="center">
                    <v-col cols="11" class="px-0 pb-0">
                        <span class="font-3x font-weight-bold">
                            <span>{{ $t('contact.tokenholderBasicContactInformation') }}</span>
                        </span>
                    </v-col>
                    <v-col cols="11" class="px-0 pb-0 pt-10 title_txt">
                        <p>{{ $t('contact.undocumentedText') }}</p>
                        <p>{{ $t('contact.accurateInformationText') }}</p>
                        <p v-html="$t('contact.contactUsText')"></p>
                        <p>{{ $t('contact.basicContactText') }}</p>
                    </v-col>
                    <v-col cols="11" class="px-0 pb-0">
                        <v-row justify="center">
                            <v-col cols="12" md="4">
                                <v-text-field v-model="contactInfo.firstName" :rules="[rules.required]" :label="$t('contact.firstName')"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="contactInfo.lastName" :rules="[rules.required]" :label="$t('contact.lastName')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="8">
                                <v-text-field v-model="contactInfo.email" :rules="[rules.required, rules.email]" :label="$t('contact.email')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="selectedArea" :items="internationalAreaCodes" item-text="text" :label="$t('contact.countryCode')" return-object></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="contactInfo.mobilePhone" :label="$t('contact.mobilePhone')"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn tile x-large :loading="loading" class="text-none mt-70 button--gradient-orange glow-orange black--text" width="300px" @click="submit">{{$t('common.submit')}}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
	</div>
</template>
<script>
    import Vue from 'vue'
    import internationalAreaCode from '@/assets/code/international-area-code.json'
    import config from '@/config.js';
    import { mapGetters} from "vuex";
    export default {
        data(){
            return {
                // 加载中
                loading: false,
                // 表单验证
                contactFormValid: true,
                // 表单对象
                contactInfo: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    shortCode: null,
                    telCode: null,
                    mobilePhone: null
                },
                rules: {
                    required: value => !!value || this.$t('common.required'),
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || this.$t('common.invalidEmail')
                    },
                },
                selectedArea: null,
            }
        },
        components: { },
        created(){

        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive", "locale"]),
            internationalAreaCodes(){
                console.log(this.locale)
                internationalAreaCode.forEach(area => {
                    if(this.locale == 'zh-CN'){
                        area.text = '+' + area.tel + ' ' + area.name
                    } else {
                        area.text = '+' + area.tel + ' ' + area.en
                    }
                });
                return internationalAreaCode;
            }
        },
        watch:{
            selectedArea(val){
                if(val) {
                    this.contactInfo.shortCode = val.short;
                    this.contactInfo.telCode = val.tel;
                }
            }
        },
        methods: {
            // 提交
            submit(){
                if(this.$refs.contactForm.validate()){
                    this.loading = true;
                    let api = config.baseUrl + "/contact/public/submit";
                    this.$http.post(api, this.contactInfo).then(res=>{
                        let data = res.data;
                        if(data.code == 200){
                            this.$refs.contactForm.reset();
                        }
                        this.$store.dispatch("snackbarMessageHandler", data.message)
                        this.loading = false;
                    })
                }
            },
        },
    }
</script>
<style>

</style>