<template>
    <div>
        <pc-home v-if="responsive == 'PC'"></pc-home>
        <mobile-home v-if="responsive == 'mobile' || responsive == 'pad'"></mobile-home>
    </div>
</template>

<style>
</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcHome from "./pc/Home";
import MobileHome from "./mobile/Home";

export default {
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/home.md')
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcHome,
        MobileHome,
    },
    watch: {

    },
    methods: {
        
    },
}
</script>
