<template>
    <div>
        
    </div>
</template>
<style>
    
</style>
<script>
    import config from '@/config.js';
    export default {
        data(){
            return {
                faq: config.faq
            }
        },
        computed: {

        },
        created() {
            window.location = this.faq;
        },
        mounted() {

        },
        methods: {

        }
    }
</script>