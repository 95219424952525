<template>
<v-container class="grid-list-xl px-0">
    <v-row justify="center" align="center" class="h-100">
        <v-col cols="12" class="text-center">
            <div class="gradient3 fi_animate homeTitle">
                404
            </div>
            <div class="gradient3 fi_animate fs-72">
                Page Not Found
            </div>
            <div class="fs-24 pt-16">This is not the web page you are looking for.</div>
            <div class="pt-16">
                <router-link to="/" class="del-underline">
                    <v-btn tile outlined color="white" @click="goHome">
                        <b>Back to homepage</b>
                    </v-btn>
                </router-link>
            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<style>
</style>

<script>
import config from "@/config.js";
export default {
    data() {
        return {
            domainName: config.domainName
        };
    },
    methods: {
        goHome() {
            this.$router.push({
                name: "home"
            });
            this.$router.go();
        }
    }
};
</script>
