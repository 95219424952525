<template>
  <div>
    <div :id="id" style="width: 470px; height: 475px;" v-if="responsive == 'PC'"></div>
    <div :id="id" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
  </div>
</template>
<style>
 svg.highcharts-root{
  font-family: "Lexend Deca", sans-serif!important;
}
.test tspan:active {
  text:expression(target="_blank");
}
</style>
<script>
  import config from '@/config.js';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        day: 90,
        currency: 'USD',
        id: 'assetsTrendLineChart',
        quasarNav: null,
        chart: null,
        loading: false,
        option:{
          // 图表类型
          chart: {
            backgroundColor: 'dark',
            renderTo: 'assetsTrendLineChart',
            type: 'line',
            // x方向和y方向都可以用鼠标拖拽放大
            zoomType: 'xy'
          },
          title: {
            text: '',
            align:'left',
            style: {
              color: '#9E9E9E'
            },
          },
          subtitle: {
            text: '',
            align:'left',
            style: {
              color: '#999999'
            },
          },
          // x轴
          xAxis: {
            categories:[],
            lineWidth: 0,
            tickInterval: 10,
            type: 'datetime',
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S',
              second: '%m/%d/%Y %H:%M:%S',
              minute: '%m/%d/%Y %H:%M',
              hour: '%m/%d/%Y %H:%M',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%Y',
              year: '%Y'
            },
            labels: {
              enabled: false
            }
          },
          // y轴
          yAxis: {
            title: {
              text: null
            },
            visible: true,
            // 横线宽度设置为0表示不显示横线
            gridLineWidth: 0,
            opposite:true,
            offset: 20,
            labels:{
              align: "left",
              x: -10,
              formatter:function(){
                return this.value.toFixed(2);
              }
            },
          },
          // 鼠标悬浮提示框
          tooltip: {
            valueDecimals: 4,
            valuePrefix: '',
            valueSuffix: '',
            followTouchMove: true,
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S UTC',
              second: '%m/%d/%Y %H:%M:%S UTC',
              minute: '%m/%d/%Y %H:%M UTC',
              hour: '%m/%d/%Y %H:%M UTC',
              day: '%m/%d/%Y %H:%M UTC',
              week: '%m/%d %H:%M UTC',
              month: '%m/%Y',
              year: '%Y'
            }
          },
          // 数据
          series: [
            {
            // type: 'spline',
              name: this.$t('highcharts.navLineChart.iobQuasarNav'),
              data: [],
              // color: '#ff0000',
			  color: {
			         linearGradient: {
			           x1: 0,
			           x2: 0,
			           y1: 0,
			           y2: 1
			         },
			         stops: [
			           [0, '#4CAF50'],
			           [1, '#FEE108']
			         ]
			       },
              lineWidth: 3
            }
          ],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#303030'
            }
          },
          // 图例导航
          legend: {
            enabled: true,
            align: 'left',
            itemStyle: {
                color: '#999999'
            },
            itemHoverStyle: {
                color: '#999999'
            },
            itemHiddenStyle: {
                color: '#606063'
            },
            title: {
                style: {
                    color: '#C0C0C0',
                    fontFamily: '\'Lexend Deca\', sans-serif'
                }
            }
          },
          navigation: {
            buttonOptions: {
              symbolStroke:'black',
              theme: {
                  fill: '#EEEEEE',
              },
            }
          },
          // 图形参数
          plotOptions: {
            line: {
              lineWidth: 2,
              cursor: 'pointer',
              marker: {
               enabled: false
             }
            }
          },
          // 版权信息
          credits: {
            enabled: true,
            href: config.fund,
            text: 'View on iobots.pro',
            className: 'a',
            position: {
              x: -12,
              y: -25
            },
            style: {
              fontSize: '12px',
              color: 'white',
              target:'_black'
            }
          },
          exporting: {
            enabled: false,
            filename: 'IOB-Quasar-NAV',
            buttons: {
              contextButton: {
                align: "left",
                verticalAlign: "top",
                x: 1,
                y: 0
              }
            }
          },
          loading: {
            style: {
              backgroundColor: 'silver'
            },
            labelStyle: {
              color: 'white',
              fontSize: '36px'
            }
          }
        },
      }
    },
    props:{
      
    },
    watch: {
      day(val){
        this.getAssetsTrend();
      },
      currency(){
        if (this.quasarNav) {
          if (this.currency == 'BTC'){
            this.option.series[0].data = this.quasarNav.quasarNavInBTC;
          } else {
            this.option.series[0].data = this.quasarNav.quasarNavInUSD;
          }
        }
        // 重新渲染图表
        this.renderChart();
      },
      // 监听加载中
      loading(newVal){
        if (this.chart) {
          if (newVal) {
            this.chart.showLoading();
          } else {
            this.chart.hideLoading();
          }
        }
      },
    },
    created(){

    },
    mounted() {
      this.getAssetsTrend();
      Highcharts.setOptions({ lang: {
        noData: "",
        contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
        printChart: this.$t('highcharts.printChart'),
        resetZoom: this.$t('highcharts.resetZoom'),
        resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
        downloadPNG: this.$t('highcharts.downloadPNG'),
        downloadJPEG: this.$t('highcharts.downloadJPEG'),
        downloadPDF: this.$t('highcharts.downloadPDF'),
        downloadSVG: this.$t('highcharts.downloadSVG')
      }});
      this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
      this.renderChart();
    },
    computed: {
      ...mapGetters(['responsive'])
    },
    components: {

    },
    methods:{
      getAssetsTrend(){
        this.loading = true;
        // this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        // this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
        this.option.series[0].data = [];
        let api = config.baseUrl + "/asset/public/getZeroNav?day="+this.day;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200){
            this.quasarNav = data.result;
            if (this.currency == 'BTC'){
              this.option.series[0].data = data.result.quasarNavInBTC;
            } else {
              this.option.series[0].data = data.result.quasarNavInUSD;
            }
            this.option.xAxis.categories = data.result.date;
            this.loading = false;
            // this.getLast(data.result.quasarNavInUSD,data.result.date)
            // 重新渲染图表
            this.renderChart();
          }else{
            this.loading = false;
            this.noData();
          }
        })
      },
      getLast(navInUSD,date){
          let api = config.baseUrl + "/asset/public/getQuasarNAVFromNet";
          // let api = "http://localhost:3000/asset/public/getQuasarNAVFromNet";
          this.$http.get(api).then(res => {
              let data = res.data;
              if(data.code == 200){
                  var tempData = [];
                  var tempDate = [];
                  tempData = navInUSD;
                  tempDate = date;
                  if(data.result != 0){
                      let delayTime = new Date(new Date()).toJSON().split('T')[0];
                      tempData.push(data.result.quasarNavInUsd)
                      tempDate.push(delayTime)
                  }
                  this.option.series[0].data = tempData;
                  this.option.xAxis.categories = tempDate;
                  this.loading = false;
                  this.renderChart();
              }
          });
      },
      formatDateFunction(timestamp){
        if (timestamp == 0) {
          return '--';
        } else {
          if( "localTime" == localStorage.getItem("iobots-time")){
            return formatDate(timestamp);
          }else{
            return formatUTCDate(timestamp)
          }
        }
      },
      // 渲染图表
      renderChart(){
        this.chart = Highcharts.chart(this.id, this.option);
      },
      noData(){
        Highcharts.setOptions({ lang: {
          noData: this.$t('highcharts.noData'),
          contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
          printChart: this.$t('highcharts.printChart'),
          resetZoom: this.$t('highcharts.resetZoom'),
          resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
          downloadPNG: this.$t('highcharts.downloadPNG'),
          downloadJPEG: this.$t('highcharts.downloadJPEG'),
          downloadPDF: this.$t('highcharts.downloadPDF'),
          downloadSVG: this.$t('highcharts.downloadSVG')
        }});
        this.renderChart();
      },
    }
  }
</script>
