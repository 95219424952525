<template>
	<div>
        <v-dialog v-model="isShowTokenRequest" width="520" persistent hide-overlay>
            <v-card class="pa-5 fs-18">
                <v-card-title class="pa-0">
                    <span class="fs-18">New Request</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeTokenRequest">
                      <v-icon size="18">icon icon-close</v-icon>
                    </v-btn>
                  </v-card-title>
                <v-divider class="my-2"></v-divider>
                <div class="fs-14 mb-2">
                    <span>DEFY Price: </span>
                    <span class="float-right">${{ defyPrice.toFixed(4) }}</span>
                </div>
                <div class="fs-14 mb-2">
                    <span>{{ getOfferedTokenName() }} Price: </span>
                    <span class="float-right">${{ offeredTokenPrice.toFixed(4) }}</span>
                </div>
                <v-divider class="my-2"></v-divider>
                <v-form ref="tokenRequestForm" v-if="!isTransactionSigned">
                    <div class="fs-14">Offered amount</div>
                    <v-row>
                        <v-col cols="7" class="py-0 pr-0">
                            <v-text-field v-model="tokenRequestForm.offeredTokenValue" type="number" min="0" step="0.0001" :rules="[rules.min]" dense outlined required @focus="toggleExclusive = 'offered'"></v-text-field>
                        </v-col>
                        <v-col cols="5" class="py-0">
                            <v-select v-model="offeredContractAddress" :items="offeredTokenList" item-text="text" item-value="value" dense outlined required></v-select>
                        </v-col>
                    </v-row>
                    <div class="fs-14">Requested amount</div>
                    <v-row>
                        <v-col cols="7" class="py-0 pr-0">
                            <v-text-field v-model="tokenRequestForm.requestedTokenValue" type="number" min="0" step="0.0001" :rules="[rules.min]" dense outlined required @focus="toggleExclusive = 'requested'"></v-text-field>
                        </v-col>
                        <v-col cols="5" class="py-0">
                            <v-select v-model="requestedContractAddress" :items="requestedTokenList" item-text="text" item-value="value" dense outlined disabled required></v-select>
                        </v-col>
                    </v-row>
                    <div class="fs-14">Reference (Optional)</div>
                    <v-text-field v-model="tokenRequestForm.reference" type="text" dense outlined class="pt-0"></v-text-field>
                    <v-btn v-if="walletAddress == null" block tile class="text-none" @click="connectWallet">Connect Wallet</v-btn>
                    <v-btn v-if="walletAddress != null" block tile class="blue text-none" @click="checkParam">Create request</v-btn>
                </v-form>
                <v-alert border="left" color="orange darken-1" elevation="2" class="mt-5 fs-14" v-if="!isTransactionSigned">
                    Remember, Mainnet organizations use real tokens.<br/><br/>
                    Configure your request above, and sign the transaction with your wallet after clicking “Create request”. It will then show up in your <a :href="tokenRequestAragonUrl" target="_blank" class="blue--text del-underline">Token request app</a> once processed. It will need to be submitted by someone with permission to create proposals, you will be able to withdraw your funds from the request at any time before the proposal is approved.
                </v-alert>
                <!-- <div v-if="isTransactionSigned"> -->
                <div v-if="isTransactionSigned">
                    <div class="text-center green--text py-10">
                        <v-icon color="green" size="24" class="mb-2">icon icon-check</v-icon>
                        <span class="fs-22 ml-2">Transaction signed!</span>
                    </div>
                    <div class="text-center fs-14">
                        <a :href="etherscanUrl + transactionAddress" class="blue--text" target="_blank">See on Etherscan &#8599;</a>
                    </div>
                    <v-btn block tile class="blue text-none mt-10" @click="closeTokenRequest">Close</v-btn>
                </div>
                <v-alert border="left" color="green" elevation="2" class="mt-5 fs-14" v-if="isTransactionSigned">
                    Success! Your transaction has been sent to the network for processing.
                    <!-- You can follow this request on <a href="">DAO</a>. -->
                </v-alert>
            </v-card>
        </v-dialog>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue';
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import Web3 from "web3";
    import Bus from "@/components/bus/Bus";
    export default {
        data(){
            return {
                // 选中的offered/requested
                toggleExclusive: 'offered',
                tokenRequestForm: {
                    offeredTokenValue: 0,
                    requestedTokenValue: 0,
                    reference: ''
                },
                rules: {
                    min: v => v >= 0 || 'Must be greater than 0',
                },
                daiMainnetAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
                // 是否事务已签名
                isTransactionSigned: false,
                // 以太坊交易地址
                transactionAddress: '',
                // 提供的合约地址
                offeredContractAddress: '0x0000000000000000000000000000000000000000'
            }
        },
        props: {
            // 是否显示Token请求
            isShowTokenRequest: Boolean
        },
        created(){

        },
        mounted(){
            
        },
        computed: {
            ...mapGetters(['ethPrice', 'defyPrice', 'metaMask', 'metaMaskNet', 'walletAddress', 'gasPriceGwei']),
            // 提供的代币的价格
            offeredTokenPrice(){
                let offeredTokenName = this.getOfferedTokenName();
                if (offeredTokenName == 'ETH') {
                    return Number(this.ethPrice);
                } else if(offeredTokenName == 'DAI'){
                    return 1;
                } else {
                    return 0;
                }
            },
            // 提供的代币列表
            offeredTokenList(){
                if (this.metaMaskNet == '') {
                    // 主网
                    let offeredTokenListist = [
                        { text: 'ETH', value: '0x0000000000000000000000000000000000000000' },
                        { text: 'DAI', value: '0x6b175474e89094c44da98b954eedeac495271d0f' }
                    ];
                    return offeredTokenListist;
                } else {
                    // 测试网
                    let offeredTokenListist = [
                        { text: 'ETH', value: '0x0000000000000000000000000000000000000000' },
                        { text: 'DAI', value: '0x0527e400502d0cb4f214dd0d2f2a323fc88ff924' }
                    ];
                    return offeredTokenListist;
                }
            },
            // 请求的代币列表
            requestedTokenList(){
                if (this.metaMaskNet == '') {
                    // 主网
                    let requestedTokenList = [
                        { text: 'DEFY-2', value: '0xf783b9e19597d212e3fa61cb71d62e7c5cba2422' }
                    ];
                    return requestedTokenList;
                } else {
                    // 测试网
                    let requestedTokenList = [
                        { text: 'FI-POPULAR-VOTE', value: '0x9e1110b76b469d020184da374ddb334c2fc5308f' }
                    ];
                    return requestedTokenList;
                }
            },
            // 提供的合约地址
            // offeredContractAddress:{
            //     get(){
            //         return '0x0000000000000000000000000000000000000000';
            //     },
            //     set(newValue){

            //     }
            // },
            // 请求的合约地址
            requestedContractAddress: {
                get(){
                    if(this.metaMaskNet == ''){
                        // Token Request合约地址
                        return '0xf783b9e19597d212e3fa61cb71d62e7c5cba2422';
                    } else {
                        // FI-POPULAR-VOTE合约地址
                        return '0x9e1110b76b469d020184da374ddb334c2fc5308f';
                    }
                },
                set(newValue){

                }
            },
            // 以太坊浏览器的URL
            etherscanUrl(){
                let prefix = 'https://';
                let domain = this.metaMaskNet == '' ? '' : (this.metaMaskNet.toLowerCase() + '.');
                let suffix = 'etherscan.io/tx/';
                return prefix + domain + suffix;
            },
            tokenRequestAragonUrl(){
                let prefix = 'https://';
                let domain = this.metaMaskNet == '' ? '' : (this.metaMaskNet.toLowerCase() + '.');
                let suffix = 'client.aragon.org/#';
                if(this.metaMaskNet == ''){
                    // Token Request合约地址
                    suffix +=  '/defy/0xf783b9e19597d212e3fa61cb71d62e7c5cba2422';
                } else {
                    // FI-POPULAR-VOTE合约地址
                    suffix += '/fipopularvote/0x9e1110b76b469d020184da374ddb334c2fc5308f';
                }
                return prefix + domain + suffix;
            },
            // 合约ABI
            contractAbi(){
                if(this.metaMaskNet == ''){
                    // Token Request合约ABI
                    let defy1Abi = [{"constant":true,"inputs":[],"name":"proxyType","outputs":[{"name":"proxyTypeId","type":"uint256"}],"payable":false,"stateMutability":"pure","type":"function"},{"constant":true,"inputs":[],"name":"isDepositable","outputs":[{"name":"","type":"bool"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"implementation","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"appId","outputs":[{"name":"","type":"bytes32"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"kernel","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"inputs":[{"name":"_kernel","type":"address"},{"name":"_appId","type":"bytes32"},{"name":"_initializePayload","type":"bytes"}],"payable":false,"stateMutability":"nonpayable","type":"constructor"},{"payable":true,"stateMutability":"payable","type":"fallback"},{"anonymous":false,"inputs":[{"indexed":false,"name":"sender","type":"address"},{"indexed":false,"name":"value","type":"uint256"}],"name":"ProxyDeposit","type":"event"}];
                    return defy1Abi;
                } else if (this.metaMaskNet == 'Rinkeby') {
                    // FI-POPULAR-VOTE合约ABI
                    let fiFopularVoteAbi = [{"constant":true,"inputs":[],"name":"proxyType","outputs":[{"name":"proxyTypeId","type":"uint256"}],"payable":false,"stateMutability":"pure","type":"function"},{"constant":true,"inputs":[],"name":"isDepositable","outputs":[{"name":"","type":"bool"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"implementation","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"appId","outputs":[{"name":"","type":"bytes32"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"kernel","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"inputs":[{"name":"_kernel","type":"address"},{"name":"_appId","type":"bytes32"},{"name":"_initializePayload","type":"bytes"}],"payable":false,"stateMutability":"nonpayable","type":"constructor"},{"payable":true,"stateMutability":"payable","type":"fallback"},{"anonymous":false,"inputs":[{"indexed":false,"name":"sender","type":"address"},{"indexed":false,"name":"value","type":"uint256"}],"name":"ProxyDeposit","type":"event"}];
                    return fiFopularVoteAbi;
                } else {
                    // 其余空
                    return [];
                }
            }
        },
        watch:{
            isShowTokenRequest(val){
                if (!val) {
                    this.$emit('isShowTokenRequest', false);
                }
            },
            offeredTokenPrice(newVal, oldVal){
                let requestedTokenValue = (this.offeredTokenPrice / this.defyPrice * this.tokenRequestForm.offeredTokenValue).toFixed(4);
                this.tokenRequestForm.requestedTokenValue = Number(requestedTokenValue);
            },
            defyPrice(newVal, oldVal){
                let requestedTokenValue = (this.offeredTokenPrice / this.defyPrice * this.tokenRequestForm.offeredTokenValue).toFixed(4);
                this.tokenRequestForm.requestedTokenValue = Number(requestedTokenValue);
            },
            'tokenRequestForm.offeredTokenValue'(newVal, oldVal){
                this.tokenRequestForm.offeredTokenValue = Number(this.tokenRequestForm.offeredTokenValue);
                if (this.toggleExclusive == 'offered') {
                    let requestedTokenValue = (this.offeredTokenPrice / this.defyPrice * this.tokenRequestForm.offeredTokenValue).toFixed(4);
                    this.tokenRequestForm.requestedTokenValue = Number(requestedTokenValue);
                }
            },
            'tokenRequestForm.requestedTokenValue'(newVal, oldVal){
                this.tokenRequestForm.requestedTokenValue = Number(this.tokenRequestForm.requestedTokenValue);
                if (this.toggleExclusive == 'requested') {
                    let offeredTokenValue = (this.tokenRequestForm.requestedTokenValue * this.defyPrice / this.offeredTokenPrice).toFixed(4);
                    this.tokenRequestForm.offeredTokenValue = Number(offeredTokenValue);
                }
            }
        },
        methods: {
            // 链接钱包
            connectWallet(){
                Bus.$emit('isShowConnectWalletMenu', true);
                // console.log(Number('11').toString(16))
                // var web3 = new Web3(new Web3.providers.HttpProvider(this.netApi));
                // console.log(web3.utils.toWei('1', 'gwei'))
            },
            // 检查参数
            checkParam(){
                if (this.tokenRequestForm.offeredTokenValue <= 0 || this.tokenRequestForm.requestedTokenValue <= 0) {
                    return;
                }
                if (this.walletAddress == null) {
                    // 请先链接钱包
                    this.connectWallet();
                    return;
                }
                // 创建请求
                this.createRequest();
            },
            // 关闭TokenRequest窗口
            closeTokenRequest(){
                this.$emit('isShowTokenRequest', false);
                this.isTransactionSigned = false;
                this.transactionAddress = '';
            },
            // 获得提供的代币名称
            getOfferedTokenName(){
                for (let i = 0; i < this.offeredTokenList.length; i++) {
                    if (this.offeredContractAddress == this.offeredTokenList[i].value) {
                        return this.offeredTokenList[i].text;
                    }
                }
                return 'ETH';
            },
            // 创建请求
            createRequest() {
                var web3 = new Web3(new Web3.providers.HttpProvider(this.netApi));
                // 定义合约
                var tokenRequestContract = new web3.eth.Contract(this.contractAbi, this.requestedContractAddress);
                // var appId = await myContract.methods.appId().call();
                // let offeredTokenValueWei = this.tokenRequestForm.offeredTokenValue * Math.pow(10, 18);
                // let requestedTokenValueWei = this.tokenRequestForm.requestedTokenValue * Math.pow(10, 18);
                let offeredTokenValueWei = web3.utils.toWei(this.tokenRequestForm.offeredTokenValue + '');
                let requestedTokenValueWei = web3.utils.toWei(this.tokenRequestForm.requestedTokenValue + '');
                let offeredTokenValueEncode = web3.eth.abi.encodeParameter('uint256', offeredTokenValueWei);
                // 转为十六进制
                // let offeredTokenValueHex = '0x' + offeredTokenValueWei.toString(16);
                let offeredTokenValueHex = web3.utils.toHex(offeredTokenValueWei);
                // 定义数据
                let data = '';
                // method
                data += '0x85fc7e2c';
                // address
                let addressEncode = this.offeredContractAddress.substring(2);
                let offeredContractAddressZeroLength = 64 - addressEncode.length;
                for (let i = 0; i < offeredContractAddressZeroLength; i++) {
                    addressEncode = '0' + addressEncode;
                }
                data += addressEncode;
                // depositAmount
                data += offeredTokenValueEncode.substring(2);
                // requestAmount
                let requestedTokenValueEncode = web3.eth.abi.encodeParameter('uint256', requestedTokenValueWei);
                data += requestedTokenValueEncode.substring(2);
                // 128
                let valueEncode = web3.eth.abi.encodeParameter('uint256', '128');
                data += valueEncode.substring(2);
                // reference length
                let referenceLength = this.tokenRequestForm.reference.length;
                let referenceLengthEncode = web3.eth.abi.encodeParameter('uint256', referenceLength + '');
                data += referenceLengthEncode.substring(2);
                // reference
                let referenceEncode = web3.utils.toHex(this.tokenRequestForm.reference);
                // 去除0x
                referenceEncode = referenceEncode.substring(2);
                // 引用数据的标准长度64的倍数
                let referenceStandardLength = 64;
                while (true) {
                    if(referenceEncode.length < referenceStandardLength){
                        // 剩余的0的长度，小于需要补0
                        let restZeroLength = referenceStandardLength - referenceEncode.length;
                        for (let i = 0; i < restZeroLength; i++) {
                            referenceEncode += '0';
                        }
                        break;
                    } else {
                        referenceStandardLength += 64;
                    }
            　　}
                data += referenceEncode;
                // 将gwei转为wei
                let gasPriceWei = web3.utils.toWei(this.gasPriceGwei + '', 'gwei');
                // 把gasPrice转为16进制
                let gasPriceHex = web3.utils.toHex(gasPriceWei);
                // 请求查询预估的GasLimit
                this.metaMask.request({
                    method: 'eth_estimateGas',
                    params: [{
                      'from': this.walletAddress,
                      'to': this.requestedContractAddress,
                      'value': offeredTokenValueHex,
                      'gasPrice': gasPriceHex,
                      'data': data
                    }]
                }).then(response => {
                    // 16进制的gaslimit，转为十进制后乘以1.5倍作为上限limit
                    let gasLimit = (parseInt(response, 16) * 1.5).toFixed(0);
                    // 转为16进制
                    let gasLimitHex = web3.utils.toHex(gasLimit + '');
                    this.metaMask.request({
                        method: 'eth_sendTransaction',
                        params: [{
                          'from': this.walletAddress,
                          'to': this.requestedContractAddress,
                          'value': offeredTokenValueHex,
                          'gasPrice': gasPriceHex,
                          'gas': gasLimitHex,
                          'data': data
                        }]
                    }).then(response => {
                        // response是该交易tx
                        // 请求交易发送成功
                        this.transactionAddress = response;
                        this.isTransactionSigned = true;
                        // 清空表单数据
                        this.tokenRequestForm.offeredTokenValue = 0;
                        this.tokenRequestForm.requestedTokenValue = 0;
                        this.tokenRequestForm.reference = '';
                    });
                });
            },
        },
    }
</script>