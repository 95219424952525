<template>
<div>
    <v-container class="grid-list-xl px-0 pt-16">
        <v-row class="">
            <v-col cols="12" class="text-center pure-red--text">
                <div class="font-italic">
                    <span class="fs-14">
                        {{ $t('home.tradingWithRuleBasedAlgos') }}
                    </span>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row justify="center" align="center" class="mt-70 pb-70">
            <router-link to="/investments" class="del-underline">
                <v-btn v-if="metaMaskNet == '' && walletAddress == null" tile class="text-none button--gradient-pink glow-red" @click="connectWallet">Connect Wallet</v-btn>
                <v-btn v-else tile class="text-none button--gradient-pink glow-red">{{ $t('home.myInvestments') }}</v-btn>
            </router-link>
        </v-row> -->
        <v-row justify="center" align="center" class="pb-70">
            <v-col cols="11">
                <manage-pools></manage-pools>
            </v-col>
        </v-row>
    </v-container>
    <!-- <home-carousel></home-carousel> -->
    <!-- <div class="bg-gradient-mobile">
        <div class="grid-list-xl pa-0 pt-2 px-0" id="ieo-header">
            <v-row align="center" justify="center" class="mx-0">
				<router-link to="/staking" class="del-underline white--text pointer">
					<v-row justify="center" class="pb-0">
						<v-col cols="11" class="text-center py-12">
							<div class="pb-12 fs-28">Defy liquidity pool staking</div>
							<div class="fs-18">Pool trading profit</div>
							<div class="fs-18">(currently up {{ (defyAnnualizedReturn * 100).toFixed(2) }}% annualized)</div>
							<div class="fs-36">+</div>
							<div class="fs-42 title_txt">
								<span>28% <span class="fs-22">APY</span> NOW in FI tokens</span>
							</div>
						</v-col>
					</v-row>
				</router-link>
            </v-row>
        </div>
    </div> -->
    <!-- <v-container class="grid-list-xl px-0">
        <v-row justify="center" align="center" class="pt-150">
            <home-video></home-video>
        </v-row>
    </v-container> -->
</div>
</template>

<style>
.display1 {
    animation: dongHua 1s 1 ease-in-out;
    -webkit-animation: dongHua 1s 1 ease-in-out;
}

.display2 {
    animation: dongHua 2s 1 ease-in-out;
    -webkit-animation: dongHua 2s 1 ease-in-out;
}

.display3 {
    animation: dongHua 3s 1 ease-in-out;
    -webkit-animation: dongHua 3s 1 ease-in-out;
}

@keyframes dongHua {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>

<script>
import Vue from 'vue';
import Logo from '@/components/common/Logo';
import config from '@/config.js';
import Icon from '@/components/common/Icon.vue';
import { mapGetters } from "vuex";
import HomeVideo from "@/components/common/HomeVideo";
import ManagePools from "@/components/pool/ManagePools";
import Bus from "@/components/bus/Bus";
import HomeCarousel from "@/components/common/Carousel";
export default {
    data() {
        return {
            fund: config.fund,
            faq: config.faq,
        }
    },
    created() {
        this.$store.dispatch('mobileFooterActivedHandler', 0);
    },
    mounted() {
        
    },
    computed: {
        ...mapGetters(["responsive", "defyAnnualizedReturn", 'metaMask', 'metaMaskNet','walletAddress']),
    },
    components: {
        Logo, HomeVideo, ManagePools, HomeCarousel
    },
    watch: {

    },
    methods: {
        // 连接钱包
        connectWallet(){
            // Bus.$emit('isShowConnectWalletMenu', true);
            Bus.$emit('onRequestAccounts', null);
        },
    },
    beforeDestroy() {

    }
}
</script>
