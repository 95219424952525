<template>
    <div>
        <pc-manage-pool v-if="responsive == 'PC'"></pc-manage-pool>
        <mobile-manage-pool v-if="responsive != 'PC'"></mobile-manage-pool>
    </div>
</template>

<style>
</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcManagePool from '@/pages/pc/ManagePool';
import MobileManagePool from '@/pages/mobile/ManagePool';

export default {
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcManagePool,MobileManagePool
    },
    watch: {

    },
    methods: {

    },
}
</script>
