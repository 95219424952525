<template>
<div>
    <v-container class="grid-list-xl px-0 pt-16">
        <v-row>
            <v-col cols="12" class="text-center vividYellow--text">
                <div class="font-italic">
                    <span class="fs-14 ">
                        {{ $t('home.tradingWithRuleBasedAlgos') }}
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-70 pb-70">
            <router-link to="/investments" class="del-underline">
                <v-btn v-if="metaMaskNet == '' && walletAddress == null" tile class="text-none button--gradient-orange glow-orange black--text" @click="connectWallet">{{ $t('wallet.connectWallet') }}</v-btn>
                <v-btn v-else tile class="text-none button--gradient-orange glow-orange black--text">{{ $t('home.myInvestments') }}</v-btn>
            </router-link>
            <!-- <router-link to="/dividend/0x6130ff302f24267380753e52ece48b852be3dd38" class="del-underline ml-15">
                <v-btn tile class="text-none button--gradient-orange glow-orange black--text" @click="connectWallet">{{ $t('dividend.iobDividend') }}</v-btn>
            </router-link> -->
        </v-row>
        <v-row justify="center" align="center" class="pb-150">
            <v-col cols="11">
                <manage-pools></manage-pools>
            </v-col>
        </v-row>
    </v-container>
    <!-- <home-carousel></home-carousel> -->
    <!-- <div class="bg-gradient mt-150">
        <div class="grid-list-xl pa-0 pt-0 px-0">
			<router-link to="/staking" class="del-underline white--text pointer">
                <v-row align="center" justify="center" class="mx-0 mt-12" id="ieo-header">
        		    <v-row justify="center" class="pb-0">
        		        <v-col cols="12" class="text-center py-12">
        		            <div class="pb-12 fs-22">Defy liquidity pool staking</div>
        		            <div class="fs-28">Pool trading profit (currently up {{ (defyAnnualizedReturn * 100).toFixed(2) }}% annualized)</div>
        		            <div class="fs-36">+</div>
        		            <div class="fs-48 title_txt">
        		                <span>28% <span class="fs-22">APY</span> NOW in FI tokens</span>
        		            </div>
        		        </v-col>
        		    </v-row>
                </v-row>
		  </router-link>
        </div>
    </div> -->
    <!-- <v-container class="grid-list-xl px-0 pt-16">
        <v-row justify="center" align="center" class="pt-250 pb-250">
            <home-video></home-video>
        </v-row>
    </v-container> -->
</div>
</template>

<style scoped>
.display1 {
    animation: dongHua 1s 1 ease-in-out;
    -webkit-animation: dongHua 1s 1 ease-in-out;
}

.display2 {
    animation: dongHua 2s 1 ease-in-out;
    -webkit-animation: dongHua 2s 1 ease-in-out;
}

.display3 {
    animation: dongHua 3s 1 ease-in-out;
    -webkit-animation: dongHua 3s 1 ease-in-out;
}

@keyframes dongHua {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
import Logo from '@/components/common/Logo';
import Icon from '@/components/common/Icon.vue';
import { mapGetters } from "vuex";
import HomeVideo from "@/components/common/HomeVideo";
import ManagePools from "@/components/pool/ManagePools";
import Bus from "@/components/bus/Bus";
import HomeCarousel from "@/components/common/Carousel";

export default {
    data() {
        return {
            scroll: 0,
            fund: config.fund,
            faq: config.faq,
        }
    },
    components: {
        Logo,
        HomeVideo,
        ManagePools,
        HomeCarousel,
    },
    created() {

    },
    mounted() {
        
    },
    computed: {
        ...mapGetters(["responsive", "defyAnnualizedReturn", 'metaMask', 'metaMaskNet','walletAddress']),
    },
    watch: {
        
    },
    methods: {
        // 连接钱包
        connectWallet(){
            // Bus.$emit('isShowConnectWalletMenu', true);
            Bus.$emit('onRequestAccounts', null);
        },
    },
    beforeDestroy() {
        
    },

}
</script>
