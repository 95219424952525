<template>
  <v-app>
    <app-header></app-header>
    <!-- <app-mobile-header v-if="responsive == 'mobile'"></app-mobile-header> -->
    <!-- <app-pad-header v-if="responsive == 'pad'"></app-pad-header> -->
    <div id="App" class="fill" data-app="true">
      <keep-alive>
        <router-view class="view" v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件 -->
        </router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive">
          <!-- 这里是不被缓存的视图组件 -->
      </router-view>
    </div>
    <app-footer></app-footer>
    <version-released></version-released>
    <!-- <app-footer-statements></app-footer-statements> -->
  </v-app>
</template>
<script>
/* eslint-disable */
import Vue from 'vue'
import AppHeader from "@/components/common/Header.vue";
import AppFooter from "@/components/common/Footer.vue";
import VersionReleased from "@/components/common/VersionReleased.vue";
import MobileFooter from '@/components/common/MobileFooter.vue';
import AppFooterStatements from "@/components/common/FooterStatements.vue";
import CountTo from 'vue-count-to';
import { mapGetters } from "vuex";

Vue.component('AppHeader', AppHeader);
Vue.component('AppFooter', AppFooter);
Vue.component('AppFooterStatements', AppFooterStatements);
Vue.component('CountTo', CountTo);
Vue.component('MobileFooter', MobileFooter);
Vue.component('VersionReleased', VersionReleased);
 
export default {
  name: "App",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(["responsive"]),
  },
  created(){
    
  },
  mounted(){
    this.onMovableDialog();
  },
  methods:{
    // 可移动的弹出框
    onMovableDialog(){
      const d = {};
      document.addEventListener("mousedown", e => {
          const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
          if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title") && this.responsive == 'PC') { // element which can be used to move element
              d.el = closestDialog; // element which should be moved
              d.mouseStartX = e.clientX;
              d.mouseStartY = e.clientY;
              d.elStartX = d.el.getBoundingClientRect().left;
              d.elStartY = d.el.getBoundingClientRect().top;
              d.el.style.position = "fixed";
              d.el.style.margin = 0;
              d.oldTransition = d.el.style.transition;
              d.el.style.transition = "none"
          }
      });
      document.addEventListener("mousemove", e => {
          if (d.el === undefined) return;
          d.el.style.left = Math.min(
              Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
              window.innerWidth - d.el.getBoundingClientRect().width
          ) + "px";
          d.el.style.top = Math.min(
              Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
              window.innerHeight - d.el.getBoundingClientRect().height
          ) + "px";
      });
      document.addEventListener("mouseup", () => {
          if (d.el === undefined) return;
          d.el.style.transition = d.oldTransition;
          d.el = undefined
      });
      setInterval(() => { // prevent out of bounds
          const dialog = document.querySelector(".v-dialog.v-dialog--active");
          if (dialog === null) return;
          dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
          dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
      }, 1);
    },
  }
}
</script>
