<template>
	<div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="480">
              <v-card>
                <v-card-title class="vividYellow--text">
                    {{ $t('common.warning') }}
                </v-card-title>
                <!-- <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn dark right icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title> -->
                <v-card-text class="fs-18 mt-5">
                    <div>{{ $t('home.depositWarning') }}</div><br/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile small class="text-none button--gradient-orange glow-orange black--text" @click="dialog = false">{{ $t('common.ok') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
	</div>
</template>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters} from "vuex";
    export default {
        data(){
            return {
                dialog: false
            }
        },
        components: { },
        created(){

        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive"]),
        },
        watch:{

        },
        methods: {

        },
    }
</script>
<style>

</style>