<template>
<div>
    <pc-holdings v-if="responsive == 'PC'"></pc-holdings>
    <mobile-holdings v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-holdings>
</div>
</template>

<style>

</style>

<script>
import Vue from 'vue'
import { mapGetters} from "vuex";
import PcHoldings from "../pc/Holdings";
import MobileHoldings from "../mobile/Holdings";
export default {
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcHoldings,
        MobileHoldings,
    },
    watch: {

    },
    methods: {

    },
}
</script>
