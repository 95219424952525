<template>
	<div class="title_txt">
        <a :href="githubLink" target="_black" class="black--text del-underline">
            <span class="mr-3">{{ $t('footer.editOnGitHub') }}</span><i class="icon icon-github"></i>
        </a>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue';
    import { mapGetters } from 'vuex';
    export default {
        data(){
            return {

            }
        },
        created(){

        },
        mounted(){

        },
        computed: {
            ...mapGetters(["githubLink"]),
        },
        watch:{

        },
        methods: {

        },
    }
</script>
