<template>
	<div>
        <v-container class="grid-list-xl px-0 mt-70">
            <v-row justify="center" align="center">
                <!-- DEFY Pool On Binance -->
                <v-col cols="11" v-if="address=='defy-pool-on-binance'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On Binance</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- DEFY Pool On FTX -->
                <v-col cols="11" v-else-if="address=='defy-pool-on-ftx'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On FTX</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- DEFY Pool On Kraken -->
                <v-col cols="11" v-else-if="address=='defy-pool-on-kraken'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On Kraken</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- DEFY Pool On OKEx -->
                <v-col cols="11" v-else-if="address=='defy-pool-on-okex'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On OKEx</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- None -->
                <v-col cols="11" v-else-if="!hasTokenDetail">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">
                            <span v-if="address.length > 20">{{ address.substring(0,6) + "..." + address.substring(address.length - 4)  }}</span>
                            <span v-else>{{ address }}</span>
                        </div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- Pool -->
                <v-col cols="11" v-else-if="hasTokenDetail">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="d-flex flex-wrap">
							<div v-if="tokenDetail.name != null" class="fs-48">{{ tokenDetail.name }}</div>
							<div style="width:130px;"  class="my-7 py-3" v-else>
							    <v-progress-linear color="dark-grey" indeterminate height="6"></v-progress-linear>
							</div>
							<blockchain-network v-if="tokenDetail.name != null" class="ml-4 mt-4" :network="tokenDetail.blockchainNetwork" :size="6"></blockchain-network>
						</div>
                        <v-row dense class="dark-gray--text">
                            <v-col cols="3">
                                <span>Total Pool Tokens Minted:</span>
                            </v-col>
                            <v-col cols="9" class="ml-n16">
                                <span>{{ tokenDetail.totalSupply == null || tokenDetail.totalSupply == 0 ? "--" : formatNum(tokenDetail.totalSupply) }}</span>
                            </v-col>
                             <v-col cols="3">
                                <span>Holders:</span>
                            </v-col>
                            <v-col cols="9"  class="ml-n16">
                                <!-- <span>{{ tokenDetail.holders == null || tokenDetail.holders == 0 ? "--" : formatNum(tokenDetail.holders) }}</span> -->
                                <span>{{ tokenDetail.holders == null ? "--" : formatNum(tokenDetail.holders) }}</span>
                            </v-col>
                        </v-row>
                        <pool-nav-simple-chart :address="address"></pool-nav-simple-chart>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                            <span class="title_txt ml-5">
                                <a :href="`${CommonJS.getBlockchainExplorerUrl(tokenDetail.blockchainNetwork)}/address/${address}#tokentxns`" class="pure-red--text del-underline" target="_blank" v-if="address != null">
                                    <span>{{ address.substring(0,6) + "..." + address.substring(address.length - 4)  }}&#8599;</span>
                                </a>
                            </span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <v-col cols="11">
                    <v-tabs v-model="type" :slider-size="5" slider-color="vivid-yellow">
                        <v-tab href="#trade">Trade</v-tab>
                        <v-tab href="#ledger">Ledger</v-tab>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-text-field class="pr-10" v-model="searchText" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-tabs>
                    <v-tabs-items v-model="type">
                        <!-- 交易 -->
                        <v-tab-item value="trade">
                            <v-data-table disable-sort :headers="tradeHeaders" :items="tradeItems" :items-per-page="15" class="dark-gray--text" :search="searchText">
                                <template v-slot:body="{ items }">
                                    <tbody v-if="!loading">
                                        <tr v-for="(item, i) in items" :key="i">
                                            <td>{{ item.symbol }}</td>
                                            <td>{{ item.side }}</td>
                                            <td class="text-right">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">{{ formatNum(item.size.toFixed(4)) }}</span>
                                                    </template>
                                                    <span>{{ formatNum(item.size) }}</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="text-right">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">{{ formatNum(item.price) }}</span>
                                                    </template>
                                                    <span>{{ formatNum(item.price) }}</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="text-right">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">{{ formatNum(item.cost.toFixed(6)) }}</span>
                                                    </template>
                                                    <span>{{ formatNum(item.cost) }}</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="text-right">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">{{ formatNum(item.fee.toFixed(6)) + ' ' + item.feeCurrency }}</span>
                                                    </template>
                                                    <span>{{ formatNum(item.fee) + ' ' + item.feeCurrency }}</span>
                                                </v-tooltip>
                                            </td>
                                            <td>{{ item.time }}</td>
                                            <td v-if="item.hash == null">--</td>
                                            <td v-else class="title_txt text-right">
                                                <a :href="`${CommonJS.getBlockchainExplorerUrl(item.blockchainNetwork)}/tx/${item.hash}`" target="_blank" rel="noopener noreferrer" class="del-underline  ml-n10">
                                                    <span class="pure-red--text">{{ item.hash.substring(0,6) + "..." + item.hash.substring(item.hash.length-4) }}&#8599;</span>&nbsp;
                                                </a>
                                                <v-icon size="14" class="ml-2 pointer fs-12" color="#B8B8B8" @click="copy(item.hash)" :title="'Copy: ' + item.hash">icon icon-copy1_2</v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="loading">
                                        <tr v-for="i in 15" :key="i">
                                            <td :colspan="tradeHeaders.length">
                                                <v-skeleton-loader dense type="table-row@1"></v-skeleton-loader>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!loading && tradeItems.length == 0">
                                        <tr class="text-center">
                                            <td :colspan="tradeHeaders.length">
                                                No data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <!-- 流水 -->
                        <v-tab-item value="ledger">
                            <v-data-table disable-sort :headers="lHeaders" :items="ledgerItems" :items-per-page="15" class="dark-gray--text" :search="searchText">
                                <template v-slot:body="{ items }">
                                    <tbody v-if="!loading">
                                        <tr v-if="ledgerItems.length > 0">
                                            <td :colspan="lHeaders.length" class="mx-0 px-0">
                                                <!-- https://v2.vuetifyjs.com/zh-Hans/components/expansion-panels/ -->
                                                <v-expansion-panels accordion focusable tile>
                                                    <v-expansion-panel v-for="(item,index) in items" :key="index">
                                                        <v-expansion-panel-header>
                                                            <v-row dense>
                                                                <v-col cols="3" class="ml-n2">
                                                                    <span class="dark-gray--text">{{ item.time }}</span>
                                                                </v-col>
                                                                <v-col cols="2">
                                                                    <span class="dark-gray--text ml-n1">{{ item.side }}</span>
                                                                </v-col>
                                                                <v-col cols="2">
                                                                    <span class="float-right dark-gray--text mr-n3">
                                                                        <v-tooltip top>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <span v-bind="attrs" v-on="on">{{ formatNum(item.fee.toFixed(4)) + ' ' + item.feeCurrency }}</span>
                                                                            </template>
                                                                            <span>{{ formatNum(item.fee) + ' ' + item.feeCurrency }}</span>
                                                                        </v-tooltip>
                                                                    </span>
                                                                </v-col>
                                                                <v-col cols="2" class="text-right">
                                                                    <span class="dark-gray--text">{{ item.count }}</span>
                                                                </v-col>
                                                                <v-col v-if="item.hash == null">
                                                                    <span class="ml-14 pl-14">--</span>
                                                                </v-col>
                                                                <v-col v-else class="text-right">
                                                                    <span class="title_txt mr-1">
                                                                        <a :href="`${CommonJS.getBlockchainExplorerUrl(item.blockchainNetwork)}/tx/${item.hash}`" target="_blank" rel="noopener noreferrer" class="del-underline">
                                                                            <span class="pure-red--text">{{item.hash.substring(0,6) + "..." + item.hash.substring(item.hash.length-4)}}&#8599;</span>&nbsp;
                                                                        </a>
                                                                        <span class="mr-3">
                                                                            <v-icon size="14" class="ml-2 pointer fs-12" color="#B8B8B8" @click="copy(item.hash)" :title="'Copy: ' + item.hash">icon icon-copy1_2</v-icon>
                                                                        </span>
                                                                    </span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content class="black-gradient">
                                                            <v-row style="color:#FFFFFFB3;" dense>
                                                                <v-col cols="6">Symbol</v-col>
                                                                <v-col cols="6" class="text-right">Amount</v-col>
                                                                <v-col cols="12" class="ma-0 py-0">
                                                                    <v-divider></v-divider>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row v-for="(item2,index) in item.ledgers" dense :key="index">
                                                                <v-col cols="6" class="float-right">
                                                                    <span class="dark-gray--text">{{ item2.symbol }}</span>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <span class="float-right dark-gray--text">
                                                                        <v-tooltip top>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <span v-bind="attrs" v-on="on">{{ formatNum(item2.amount.toFixed(4)) }}</span>
                                                                            </template>
                                                                            <span>{{ formatNum(item2.amount) }}</span>
                                                                        </v-tooltip>
                                                                    </span>
                                                                </v-col>
                                                                <v-col cols="12" class="ma-0 py-0" v-if="index < item.ledgers.length -1">
                                                                    <v-divider></v-divider>
                                                                </v-col>
                                                            </v-row>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </td>
                                        </tr>
                                        <tr class="text-center" v-if="ledgerItems.length == 0">
                                            <td :colspan="ledgerHeaders.length">
                                                No data available
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="loading">
                                        <tr v-for="i in 15" :key="i">
                                            <td :colspan="ledgerHeaders.length">
                                                <v-skeleton-loader dense type="table-row@1"></v-skeleton-loader>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">More info about {{ item.amount }}</td>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<style>
.v-data-footer div, .v-data-footer div i, .v-input__control div{
    color: #999999 !important;
}
.v-expansion-panel-header{
    height: 48px;
}
.v-expansion-panel-content__wrap{
    padding: 0px 24px 0px !important;
}
.theme--dark.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: #999999 !important;
}
</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import Web3 from "web3";
    import PoolNavSimpleChart from "@/highcharts/PoolNAVSimpleChart";
	import BlockchainNetwork from "@/components/common/BlockchainNetwork";
    export default {
        data(){
            return {
                address: this.$route.params.address.toLowerCase(),
                type: 'trade',
                tradeHeaders:[
                    { text: "Symbol", value: 'symbol' },
                    { text: "Side", value: 'side' },
                    { text: "Size", align:"end" },
                    { text: "Avg. Price", align:"end" },
                    { text: "Volume", align:"end" },
                    { text: "Fee", align:"end" },
                    { text: "Time (UTC)", value: 'time' },
                    { text: "Hash" },
                ],
                tradeItems:[],
                ledgerHeaders:[
                    { text: "Symbol", value: 'symbol' },
                    { text: "Side", value: 'side' },
                    { text: "Amount", align:"end" },
                    { text: "Fee", align:"end" },
                    { text: "Time (UTC)", value: 'time' },
                    { text: "Hash" },
                    { text:"" },
                ],
                lHeaders:[
                    { text: "Time (UTC)", value: 'time', width: "23%" },
                    { text: "Type", value: 'side', width: "20%" },
                    { text: "Fee", value: 'feeCurrency', align: "end", width:"15%" },
                    { text: "Num. of asset", align: "end", width: "18%" },
                    { text: "Hash", align: "end", width:"10%" },
                    { text: "", width: "14%" },
                ],
                ledgerItems:[],
                loading:false,
                // addressName:null,
                tokenDetail:{},
                // 存在Token详情信息
                hasTokenDetail: true,
                // 搜索
                searchText: '',
            }
        },
        created(){
        },
        mounted(){
            this.getTokenDetail();
            this.getHistory();
        },
        computed: {
            web3(){
                return new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/b23820f133fd4e84ae3f99ffc4dcccab"));
            }
        },
        components:{
            PoolNavSimpleChart,BlockchainNetwork
        },
        watch:{

        },
        methods: {
            getTokenDetail(){
                if (this.address == 'defy-pool-on-binance' || this.address == 'defy-pool-on-ftx' || this.address == 'defy-pool-on-kraken' || this.address == 'defy-pool-on-okex') {
                    return;
                }
                this.tokenDetail = {};
                let api = config.baseUrl + "/token/public/detail/" + this.address;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.tokenDetail = data.result;
                    } else {
                        this.tokenDetail = {};
                        this.hasTokenDetail = false;
                    }
                })
            },
            // getAddressName(){
            //     this.addressName = null;
            //     let api = config.baseUrl + "/token/public/name/" + this.address;
            //     // let api = "https://eth.tokenview.com/api/eth/token/" + this.address;
            //     this.$http.get(api).then(res=>{
            //         let data = res.data;
            //         if(data.code == 200){
            //             this.addressName = data.result;
            //         }else{
            //             this.addressName = "--";
            //         }
            //     })
            // },
            getHistory(){
                this.loading = true;
                let api = null;
                if (this.address == 'defy-pool-on-binance') {
                    api = config.baseUrl + "/pool/activity/public/exchange/binance";
                } else if (this.address == 'defy-pool-on-ftx') {
                    api = config.baseUrl + "/pool/activity/public/exchange/ftx";
                } else if (this.address == 'defy-pool-on-kraken') {
                    api = config.baseUrl + "/pool/activity/public/exchange/kraken";
                } else if (this.address == 'defy-pool-on-okex') {
                    api = config.baseUrl + "/pool/activity/public/exchange/okex";
                } else {
                    api = config.baseUrl + "/pool/activity/public/transactions?address=" + this.address;
                }
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.tradeItems = data.result.trade;
                        this.ledgerItems = data.result.ledger;
                        this.loading = false;
                    }else{
                        this.tradeItems = [];
                        this.ledgerItems = [];
                        this.loading = false;
                    }
                }, error => {
                    this.tradeItems = [];
                    this.ledgerItems = [];
                    this.loading = false;
                })
            },
            formatUTCTime(timestamp){
                // 在日期格式中，月份是从0开始的，因此要加0
                // 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11
                var date = new Date(timestamp);
                var year = date.getUTCFullYear();
                var month = date.getUTCMonth() + 1;
                month = month < 10 ? ('0' + month) : month;
                var day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
                var hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
                var minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
                var seconds = date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();
                return month + '-' + day + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
            },
            formatNum(num) {
                if (num == null) return '';
                if (num == NaN || num == 'NaN') return '';
                if (num == 'undefined') return '';
                if (num == '--') return '--';
                let number = num + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return integerPart + decimalPart;
            },
            copy(val) {
                let _this = this;
                this.$copyText(val, this.$refs.container).then(
                    function () {
                        // 成功回调
                        _this.$store.dispatch("snackbarMessageHandler","Copied")
                    },
                    function () {
                        // 失败回调
                        _this.$store.dispatch("snackbarMessageHandler","Failed")
                    }
                );
            },
        },
    }
</script>