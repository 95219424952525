<template>
<div>
     <pc-staking v-if="responsive == 'PC'"></pc-staking>
     <mobile-staking v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-staking>
</div>
</template>

<style>

</style>

<script>
import Vue from 'vue'
import config from '@/config.js';
import { mapGetters} from "vuex";
import PcStaking from "./pc/Staking";
import MobileStaking from "./mobile/Staking";
export default {
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcStaking,
        MobileStaking
    },
    watch: {

    },
    methods: {

    },
}
</script>
