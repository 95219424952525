<template>
<div>
    <v-container class="grid-list-xl px-0">
        <v-row justify="center" align="center" class="h-100">
            <v-col cols="12" class="text-center gradient6 fi_animate" :class="scroll > 130 ? 'shrink fs-48 mt-16' : 'homeTitle'">
                <span v-html="bannerTitle"></span>
            </v-col>
        </v-row>
        <v-row justify="center" class="pb-16">
            <v-col cols="12" class="text-center fs-28 dark-gray--text">
                <div>{{ $t('about.investingInOnePlace') }}</div>
                <a :href="faq + '/getting-started/on-diversification-and-risk-control'" class="del-underline" target="_blank">
                    <v-btn tile class="text-none mt-16 button--gradient-orange glow-orange black--text">{{ $t('common.getStarted') }}</v-btn>
                </a>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="pt-250 pb-250">
            <my-video></my-video>
        </v-row>
        <v-row justify="center" class="mt-16 pt-16">
            <v-col cols="6" class="pl-16">
                <div class="fs-24 gradient3 fi_animate">{{ $t('about.mainStreetRetailInvestors') }}</div>
                <div class="fs-68 mt-2"><span v-html="$t('about.easyAccessToSuperiorReturns')"></span></div>
                <a :href="faq + '/getting-started/faq/#what-does-iob-fi-do'" class="del-underline" target="_blank">
                    <v-btn tile class="text-none mt-16 gradient-btn">{{ $t('common.learnMore') }}</v-btn>
                </a>
            </v-col>
            <v-col cols="6" class="fs-24">
                <div class="pb-6 pt-14">
                    <span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span>
                    <span class="pl-9"><span v-html="$t('about.theFirstPool')"></span></span>
                </div>
                <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('about.investingWithAWeb3Wallet') }}</div>
                <div class="pb-6"><span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('about.FITokenStakingReward') }}</span></div>
                <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('about.liquidityProvidersInvestors') }}</div>
                <div class="pb-6"><span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('about.tradingIsNotEasy') }}</span></div>
                <div class="fs-18 dark-gray--text pl-9">{{ $t('about.weMakeProfitabilityEasier') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-250">
            <v-col cols="6" class="pl-16">
                <div class="fs-24 gradient7 fi_animate">{{ $t('about.wallStreetProfessional') }}</div>
                <div class="fs-68 mt-2"><span v-html="$t('about.connectingWallStreetToDeFi')"></span></div>
                <a :href="faq + '/getting-started/faq/#why-can-wall-street-firms-not-build-their-own-defi-solutions'" class="del-underline" target="_blank">
                    <v-btn tile class="text-none mt-16 button--gradient-pink glow-purple">{{ $t('common.learnMore') }}</v-btn>
                </a>
            </v-col>
            <v-col cols="6" class="fs-24">
                <div class="pb-6 pt-14"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('about.enablingHighPerformanceSystems') }}</span></div>
                <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('about.connectingBlackBoxes') }}</div>
                <div class="pb-6"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('about.secureWeb3Interface') }}</span></div>
                <div class="fs-18 dark-gray--text pb-8 pl-9">{{ $t('about.secureWeb3Solutions') }}</div>
                <div class="pb-6"><span class="point-icon-2 animate-point-init animate-point-wave mt-2"></span><span class="pl-9">{{ $t('about.tradingTraditionalAndDEXMarkets') }}</span></div>
                <div class="fs-18 dark-gray--text pl-9">{{ $t('about.aBridgeBetweenFirmsAndCrypto') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-250">
            <v-col md="11">
                <div class="fs-24 gradient6 fi_animate">{{ $t('about.weHaveGotYouCovered') }}</div>
                <div class="fs-48 mt-2">{{ $t('about.supportedMarketsAndExchanges') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="11" class="fs-24 dark-gray--text">
                {{ $t('about.solutionsAndManagedFundProducts') }}
            </v-col>
        </v-row>
        <v-row class="my-16 py-16" justify="center">
            <v-col md="11">
                <div class="fs-36 pb-8">{{ $t('about.markets') }}</div>
                <div class="fs-18 dark-gray--text">{{ $t('about.tradingSystemHasBuiltSufficientData') }}</div>
            </v-col>
            <v-col md="11">
                <v-row>
                    <v-col cols="3" v-for="currency in currencies" :key="currency.name">
                        <a :href='fund + currency.url' target="_black" class="del-underline">
                            <v-hover v-slot:default="{ hover }">
                                <v-card max-width="200" :color="hover ? 'button--gradient-orange rounded-tl-xl rounded-br-xl' : ''">
                                    <v-card-actions justify="center" class="pa-0">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <icon :currency="currency.name"></icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ currency.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card-actions>
                                </v-card>
                            </v-hover>
                        </a>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="my-16 pt-16" justify="center">
            <v-col md="11" class="fs-36">
                {{ $t('about.DEXes') }}
            </v-col>
            <v-col md="11">
                <v-row>
                    <v-col cols="3" v-for="dex in dexes" :key="dex.name">
                        <a :href='dex.url' target="_black" class="del-underline">
                            <v-hover v-slot:default="{ hover }">
                                <v-card max-width="200" :color="hover ? 'button--gradient-orange rounded-tl-xl rounded-br-xl' : ''">
                                    <v-card-actions justify="center" class="pa-0">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <icon :currency="dex.code"></icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ dex.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card-actions>
                                </v-card>
                            </v-hover>
                        </a>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="my-16 py-16  pb-250" justify="center">
            <v-col md="11" class="fs-36">
                {{ $t('about.CEXes') }}
            </v-col>
            <v-col md="11">
                <v-row>
                    <v-col cols="3" v-for="cex in cexes" :key="cex.name">
                        <a :href='cex.url' target="_black" class="del-underline">
                            <v-hover v-slot:default="{ hover }">
                                <v-card max-width="200" :color="hover ? 'button--gradient-orange rounded-tl-xl rounded-br-xl' : ''">
                                    <v-card-actions justify="center" class="pa-0">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <icon :currency="cex.code"></icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ cex.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card-actions>
                                </v-card>
                            </v-hover>
                        </a>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    <div class="bg-gradient-1">
        <v-container class="px-0 grid-list-xl">
            <v-row class="justify-center px-0 pt-250 pb-250" justify="center">
                <v-col cols="12" md="6" class="fs-36 pl-16 mt-1">
                    <!-- <img src="https://images.iob.community/iobots/2020/09/1601022812516-278bc3c8496842e68e2f809ab2eb3195.jpg" width="470" height="475" /> -->
                    <nav-line-chart></nav-line-chart>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="fs-24 gradient6 fi_animate">{{ $t('about.tradingTrackRecord') }}</div>
                    <div class="fs-42 pb-8 mt-2 black--text"><span v-html="$t('about.strategiesObjectives')"></span></div>
                    <div class="fs-18 pb-8 black--text">{{ $t('about.iobQuasarFundOperation') }}</div>
                    <div class="fs-18 black--text">{{ $t('about.signalsGained') }}</div>
                    <div>
                        <a :href="faq + '/getting-started/faq/#what-is-the-best-trading-strategy'" class="del-underline" target="_blank">
                            <v-btn tile class="text-none mt-16 glow-orange button--gradient-orange black--text">{{ $t('common.learnMore') }}</v-btn>
                        </a>
                    </div>
                </v-col>
            </v-row>
            <!-- <v-row>
            <token-balance></token-balance>
            </v-row> -->
        </v-container>
    </div>
    <v-container class="px-0 pt-250 pb-250">
        <v-row justify="center">
            <v-col md="11">
                <div class="fs-24 gradient6 fi_animate">{{ $t('about.workWithUS') }}</div>
                <div class="fs-48 pb-8 mt-2">{{ $t('about.makingContribution') }}</div>
                <div class="fs-24 pb-8 dark-gray--text">{{ $t('about.privilegeToHavePeopleInterested') }}</div>
                <div class="fs-24 pb-8 dark-gray--text">{{ $t('about.contributingMembers') }}</div>
                <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.institutionalQuantDevelopers') }}</span></div>
                <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.quantitativeApplicationDevelopers') }}</span></div>
                <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.counterpartyRiskAnalytics') }}</span></div>
                <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.smartCntractDevelopers') }}</span></div>
                <div class="ml-5 pb-8 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.communityLeaders') }}</span></div>
                <div class="fs-24 pb-8 dark-gray--text">{{ $t('about.requirements') }}</div>
                <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.veryGoodEnglish') }}</span></div>
                <div class="ml-5 pb-6 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.passionateAboutFintech') }}</span></div>
                <div class="ml-5 pb-16 dark-gray--text"><span class="point-icon animate-point-init animate-point-wave mt-2"></span> <span class="ml-8">{{ $t('about.anAutonomousIndividua') }}</span></div>
                <a href="https://forms.gle/vegRcSAwNqacsxbi8" target="_blank" class="del-underline">
                    <v-btn tile color="soft-blue" class="text-none button--gradient-orange glow-orange black--text">{{ $t('common.applyNow') }}</v-btn>
                </a>
            </v-col>
        </v-row>
    </v-container>
    <div class="bg-gradient-1">
        <v-container class="px-0 pt-250 pb-250">
            <v-row justify="center">
                <v-col md="11" class="fs-24 black--text">
                    {{ $t('about.investors') }}
                </v-col>
                <v-col md="11">
                    <v-row no-gutters>
                        <v-col cols="4" class="mt-5">
                            <a :href="fund" class="del-underline" target="_blank">
                                <!-- <v-card light tile outlined max-width="350" class="pa-2 text-center"> -->
                                    <logo type="sponsor" color="iob-m"></logo>
                                    <span class="fs-12 ml-n2 black--text">Token Holders</span>
                                <!-- </v-card> -->
                            </a>
                        </v-col>
                        <v-col cols="4" class="mt-5">
                            <a href="https://spicevc.com/" class="del-underline" target="_blank">
                                <!-- <v-card light outlined tile max-width="350" class="pa-2 text-center"> -->
                                    <logo type="sponsor" color="spice"></logo>
                                    <span class="fs-12 ml-5 black--text">SPiCE VC</span>
                                <!-- </v-card> -->
                            </a>
                        </v-col>
                        <v-col cols="4">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-16 pt-16" justify="center">
                <v-col cols="11" class="fs-24 black--text">
                    {{ $t('about.media') }}
                </v-col>
                <v-col cols="11">
                    <v-row>
                        <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1607322197796-a2a90b1f56ea41ef96814a16233bc4a3-bitcoin.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1607322269797-aff1e11e754746f885614db67939519b-coin.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1607322109797-19a69c82ed74479eac1356dbf00dfdf8-amb.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                               <img src="https://files.iob.fi/2020/11/1605670494928-12d551b9a0e55af28eadf39f1b98a6e4-nasdaq.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                           <img src="https://files.iob.fi/2020/11/1605670488925-b050610df653507ba8311015c54bd422-morningstar.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                           <img src="https://files.iob.fi/2020/11/1605670447902-d897542ae4315d199d6c9e3ade4022d6-bloomberg.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1605670585975-e5fbe65321ed524182bb0b52fa914dc1-yahoofinance.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1606379209080-ab22ec80fa1b56aab9abccb3476357ac-ibt.webp" alt="">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1605670534948-dc4db58e8ac453df8b7b4a64f1d3a203-sina.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                             <img src="https://files.iob.fi/2020/11/1606379072081-ef8c9c3624de5748b3f4caece66d3958-if.webp" alt="">
                        </v-col>
                        <v-col cols="3" class="mt-5">
                            <img src="https://files.iob.fi/2020/11/1605670567967-cf06a60af9f854e9b90cad27bf1e443e-sohu.webp" alt="">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <v-container class="px-0">
        <v-row justify="center" class="pt-250 pb-500">
            <v-col md="11" class="text-center">
                <div class="fs-24 gradient6 fi_animate">{{ $t('common.nutshell') }}</div>
                <div class="fs-54 mt-16 pb-16">{{ $t('about.weAreTheProfessional') }}</div>
                <a :href="faq" class="del-underline" target="_blank">
                    <v-btn tile class="button--gradient-orange glow-orange black--text text-none">{{ $t('common.getStarted') }}</v-btn>
                </a>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<style>
.display1 {
    animation: dongHua 1s 1 ease-in-out;
    -webkit-animation: dongHua 1s 1 ease-in-out;
}

.display2 {
    animation: dongHua 2s 1 ease-in-out;
    -webkit-animation: dongHua 2s 1 ease-in-out;
}

.display3 {
    animation: dongHua 3s 1 ease-in-out;
    -webkit-animation: dongHua 3s 1 ease-in-out;
}

@keyframes dongHua {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
import Logo from '@/components/common/Logo';
import Icon from '@/components/common/Icon.vue';
import { mapGetters } from "vuex";
import NavLineChart from "@/highcharts/NAVLineChart";
import TokenBalance from "@/components/common/TokenBalance";
import MyVideo from "@/components/common/Video";

export default {
    data() {
        return {
            scroll: 0,
            fund: config.fund,
            faq: config.faq,
            currencies: [{
                    name: 'BTC',
                    url: '/trading/swap/usdt/okex/BTC-USDT-SWAP'
                },
                {
                    name: 'ETH',
                    url: '/trading/swap/usdt/okex/ETH-USDT-SWAP'
                },
                {
                    name: 'BCH',
                    url: '/trading/swap/usdt/okex/BCH-USDT-SWAP'
                },
                {
                    name: 'LTC',
                    url: '/trading/swap/usdt/okex/LTC-USDT-SWAP'
                },
                {
                    name: 'XRP',
                    url: '/trading/swap/usdt/okex/XRP-USDT-SWAP'
                },
                {
                    name: 'EOS',
                    url: '/trading/swap/usdt/okex/EOS-USDT-SWAP'
                },
                {
                    name: 'ETC',
                    url: '/trading/swap/usdt/okex/ETC-USDT-SWAP'
                },
                {
                    name: 'BSV',
                    url: '/trading/swap/usdt/okex/BSV-USDT-SWAP'
                },
                {
                    name: 'TRX',
                    url: '/trading/swap/usdt/okex/TRX-USDT-SWAP'
                },
                {
                    name: 'NEO',
                    url: '/trading/swap/usdt/okex/NEO-USDT-SWAP'
                },
                {
                    name: 'LINK',
                    url: '/trading/swap/usdt/okex/LINK-USDT-SWAP'
                },
                {
                    name: 'DASH',
                    url: '/trading/swap/usdt/okex/DASH-USDT-SWAP'
                },
                {
                    name: 'ADA',
                    url: '/trading/swap/usdt/okex/ADA-USDT-SWAP'
                },
                {
                    name: 'ATOM',
                    url: '/trading/swap/usdt/okex/ATOM-USDT-SWAP'
                },
                {
                    name: 'XTZ',
                    url: '/trading/swap/usdt/okex/XTZ-USDT-SWAP'
                },
                {
                    name: 'XLM',
                    url: '/trading/swap/usdt/okex/XLM-USDT-SWAP'
                },
                {
                    name: 'XMR',
                    url: '/trading/swap/usdt/okex/XMR-USDT-SWAP'
                },
				{
				    name: 'DOT',
				    url: '/trading/swap/usdt/okex/DOT-USDT-SWAP'
				},
				{
				    name: 'UNI',
				    url: '/trading/swap/usdt/okex/UNI-USDT-SWAP'
				},
				{
				    name: 'SPX',
				    url: '/market-types'
				},
				{
				    name: 'CHINA A50',
				    url: '/market-types'
				},
				{
				    name: 'TSLA',
				    url: '/market-types'
				},
				{
				    name: 'MSFT',
				    url: '/market-types'
				},
				{
					name:'GOOGLE',
					url: '/market-types'
				},
				{
					name:'AMAZON',
					url: '/market-types'
				},
				{
				    name: 'APPLE',
				    url: '/market-types'
				},
                {
                    name: 'GOLD',
                    url: '/market-types'
                },
                {
                    name: 'SILVER',
                    url: '/market-types'
                },
                
				{
					name:'CRUDE OIL',
					url: '/market-types'
				}
            ],
            cexes: [
                {
                    name: 'Binance',
                    code: 'BINANCE',
                    url: 'https://www.binance.com'
                },
                {
                    name: 'FTX',
                    code: 'FTX',
                    url: 'https://ftx.com'
                },
                {
                    name: 'Kraken',
                    code: 'KRAKEN',
                    url: 'https://www.kraken.com'
                },
                {
                    name: 'Kraken Futures',
                    code: 'KRAKEN',
                    url: 'https://futures.kraken.com'
                },
                {
                    name: 'OKEx',
                    code: 'OKB',
                    url: 'https://www.okex.com'
                }
            ],
            dexes: [{
                    name: '1inch',
                    code: '1INCH',
                    url: 'https://1inch.exchange/#/'
                },
                {
                    name: 'Balancer',
                    code: 'BALANCER',
                    url: 'https://balancer.exchange'
                },
                {
                    name: 'dHEDGE',
                    code: 'DHEDGE',
                    url: 'https://www.dhedge.org'
                },
                {
                    name: 'Synthetix',
                    code: 'SYNTHETIX',
                    url: 'https://synthetix.exchange/#/'
                },
                {
                    name: 'TokenSets',
                    code: 'TOKENSETS',
                    url: 'https://www.tokensets.com'
                },
                {
                    name: 'Zerion',
                    code: 'ZERION',
                    url: 'https://zerion.io'
                }
            ],
            bannerTitle: this.$t('about.beyondYieldFarming'),
            bannerTitleTimeoutTimer: null
        }
    },
    components: {
        Icon, NavLineChart, Logo, TokenBalance, MyVideo
    },
    created() {
        this.scrollEvent();
    },
    mounted() {
        this.bannerTitleTimeoutTimer = setTimeout(() => {
            this.bannerTitle = this.$t('about.stakeTradeConsistency');
        }, 3000);
    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    watch: {

    },
    methods: {
        scrollEvent() {
            window.addEventListener('scroll', this.handleScroll);
        },
        getCurrentScroll() {
            return window.pageYOffset || document.documentElement.scrollTop;
        },
        handleScroll() {
            this.scroll = this.getCurrentScroll();
        },
    },
    beforeDestroy() {
        clearTimeout(this.bannerTitleTimeoutTimer);
    }
}
</script>
