<template>
<div>
    <v-container class="grid-list-xl px-0">
        <v-row justify="center" align="center" class="h-100 mx-0">
            <v-col cols="11" class="h-35 px-2">
                <div class="fs-22 gradient3 fi_animate">{{ $t('solutions.forProfessionalTradersAndExchanges') }}</div>
                <div class="fs-68 py-8">{{ $t('solutions.solutions') }}</div>
                <div class="fs-16 dark-gray--text">{{ $t('solutions.openSourceWeb3TradingInfrastructure') }}</div>
                <div class="pt-8 fs-28 dark-gray--text">{{ $t('solutions.bringingDeFiToTheTraditionalFinancialEstablishments') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-16 pt-16 mx-0">
            <v-col cols="11" class="px-3">
                <div class="fs-22 gradient3 fi_animate pb-6">{{ $t('solutions.introduction') }}</div>
                <div class="fs-54 mt-2">{{ $t('solutions.prodefy') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-8 pb-250 mx-0">
            <v-col cols="11" class="px-3">
                <div class="fs-16 dark-gray--text pb-8">{{ $t('solutions.decentralizedFinanceSolutions') }}</div>
                <div class="fs-16 dark-gray--text">{{ $t('solutions.prodefyDevelopment') }}</div>
            </v-col>
            <v-col cols="11" class="fs-22 pt-16">
                <div class="fs-22 pb-6"><span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('solutions.mobileEnablingBillions') }}</span></div>
                <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('solutions.connectingProfessionalGradeTradingSystems') }}</div>
                <div class="fs-22 pb-6"><span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('solutions.trueInteroperability') }}</span></div>
                <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('solutions.unifyingMultiFinancialProtocols') }}</div>
                <div class="fs-22 pb-6"><span class="point-icon-1 animate-point-init animate-point-wave mt-2"></span><span class="ml-7">{{ $t('solutions.futureProof') }}</span></div>
                <div class="fs-16 dark-gray--text pb-8 ml-7">{{ $t('solutions.web3StandardCompliance') }}</div>
                <a :href="faq + '/getting-started/faq/#what-is-prodefy'" target="_black" class="del-underline white--text">
                    <v-btn tile class="text-none mt-16 gradient-btn glow">{{ $t('common.learnMore') }}</v-btn>
                </a>
            </v-col>

        </v-row>
    </v-container>
    <div class="bg-gradient-1">
        <v-container class="px-0 pt-150 pb-150">
            <v-row justify="center" class="mx-0">
                <v-col md="12" class="text-center">
                    <div class="fs-22 blue--text">{{ $t('solutions.roadmap') }}</div>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-70 pr-9">
                <v-timeline light dense> 
                    <v-timeline-item small color="#2196F3">
                        <div class="black--text fs-14">{{ $t('solutions.Q1_2021') }}<br>{{ $t('solutions.bridgeBetweenProfessionalWallStreetFirmsAndCryptos') }}</div>
                    </v-timeline-item>
                    <v-timeline-item small color="#21cbf3">
                        <div class="black--text fs-14 mt-16">{{ $t('solutions.Q2_2021') }}<br>{{ $t('solutions.unifiedTradingPlatform') }}</div>
                    </v-timeline-item>
                    <v-timeline-item small color="#b6dcfb">
                        <div class="black--text fs-14 mt-16">{{ $t('solutions.Q4_2021') }}<br>{{ $t('solutions.unifiedAssetAndInvestmentManagement') }}</div>
                    </v-timeline-item>
                </v-timeline>
            </v-row>
        </v-container>
    </div>
    <v-container class="px-0">
        <v-row justify="center" class="pt-150 mx-0">
            <v-col cols="11" class="text-center px-3">
                <div class="fs-22 gradient3 fi_animate">{{ $t('common.nutshell') }}</div>
                <div class="fs-42 mt-16">{{ $t('solutions.institutionalTraders') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-16 pb-150 mx-0">
            <v-col cols="11" class="text-center fs-22">
                <div class="pb-16">{{ $t('solutions.experienced') }}</div>
               <a href="https://t.me/iob_llc" class="del-underline" target="_blank">
                    <v-btn tile class="text-none gradient-btn glow">{{ $t('common.getInvolved') }}</v-btn>
                </a>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
export default {
    data() {
        return {
            faq: config.faq
        }
    },
    created() {},
    mounted() {

    },
    computed: {

    },
    watch: {

    },
    methods: {

    },
}
</script>
