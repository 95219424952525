<template>
<div>
    <div class="mb-3 font-4x fw-bold" v-if="responsive == 'PC'">
        <v-col cols="12" class="fw-bold fs-20 pa-0">
            <span class="pure-red--text font-weight-bold">Defy Total Asset Value</span>
            <span class="float-right pure-red--text font-weight-bold">TOTAL PERFORMANCE</span>
        </v-col>
        <v-row>
            <v-col cols="6">
                <div>{{ option.series[0].data.length == 0 ? '--' : '$' + formatNum(option.series[0].data[option.series[0].data.length - 1].toFixed(2)) }}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <span :class="defyReturn == 0 ? '' : (defyReturn > 0 ? 'green--text' : 'red--text')" class="d-flex flex-row-reverse">{{defyReturn == 0 ? '--' : (defyReturn > 0 ? '+' + defyReturn.toFixed(2) + "%" : defyReturn.toFixed(2) + "%")}}</span>
            </v-col>
        </v-row>
    </div>
    <div class="mb-3 font-3x fw-bold" v-if="responsive == 'mobile' || responsive == 'pad'">
        <h4 class="pure-red--text fw-bold fs-20 mb-n2">Defy Total Asset Value</h4>
        <div v-if="option.series[0].data.length > 0">{{ '$' + formatNum(option.series[0].data[option.series[0].data.length - 1].toFixed(2)) }}</div>
        <div v-if="option.series[0].data.length == 0">--</div>
        <h4 class="pure-red--text fw-bold fs-20 mt-5 mb-n2">TOTAL PERFORMANCE</h4>
        <div :class="defyReturn == 0 ? '' : (defyReturn > 0 ? 'green--text' : 'red--text')">{{defyReturn == 0 ? '--' : (defyReturn > 0 ? '+' + defyReturn.toFixed(2) + "%" : defyReturn.toFixed(2) + "%")}}</div>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'PC'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span><br>
        <span class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'mobile'|| responsive == 'pad'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span><br>
        <span class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
    </div>
    <div :id="id" v-if="responsive == 'PC'"></div>
    <div :id="id" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Lexend Deca", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            day: 90,
            id: 'assetsTrendLineChart',
            quasarNav: null,
            domainName: config.domainName,
            chart: null,
            loading: false,
            // DEFY收益率
            // defyReturn: 0,
            option: {
                // 图表类型
                chart: {
                    backgroundColor: this.darkMode ? 'dark' : '',
                    renderTo: 'assetsTrendLineChart',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    zoomType: 'xy'
                },
                // 标题
                // this.$t('highcharts.assetsTrendLineChart.quasarNav')
                title: {
                    text: null,
                    align: 'left',
                    style: {
                        color: '#9E9E9E',
                    },
                },
                // 副标题
                // subtitle: {
                //   text: this.$t('highcharts.assetsTrendLineChart.mouseDragToZoom'),
                //   align:'left',
                //   style: {
                //     color: '#999999',
                //   },
                // },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 1,
                    tickInterval: 20,
                    tickWidth: 1,
                    tickPosition: 'inside',
                    lineColor: '#666666',
                    tickColor: '#666666',
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: true
                    }
                },
                // y轴
                yAxis: {
                    title: {
                        text: null
                    },
                    offset: 40,
                    labels: {
                        align: "left",
                        x: -10
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: ' USD',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                        // type: 'spline',
                        name: 'Defy Total Asset Value',
                        data: [],
                        color: '#ff0000',
                        lineWidth: 3,
                    },
                    {
                        // type: 'spline',
                        name: 'BTC',
                        data: [],
                        color: '#FD5B58'
                    },
                ],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    },
                },
                // 图例导航
                legend: {
                    enabled: false
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: false
                },
                exporting: {
                    filename: 'Defy-Assets-Chart',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: '#ffffff',
                        theme: {
                            fill: this.darkMode ? '' : 'black',
                        },
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props:[],
    watch: {
        day(val) {
            this.getAssetsTrend();
        },
        darkMode(val) {
            this.option.navigation.buttonOptions.symbolStroke = val ? 'white' : 'black';
            this.option.navigation.buttonOptions.theme.fill = val ? '#424242' : 'white';
            this.renderChart();
        },
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
    },
    created() {

    },
    mounted() {
        this.getAssetsTrend();
        // this.getRealtimeDefyNav();
        Highcharts.setOptions({
            lang: {
                // noData: this.$t('highcharts.noData'),
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.renderChart();
    },
    computed: {
        ...mapGetters(['responsive', 'defyPrice']),
        // DEFY收益率
        defyReturn(){
            if (this.defyPrice == 0) {
                return 0;
            } else {
                return (this.defyPrice - 1) * 100;
            }
        }
    },
    components: {

    },
    methods: {
        getAssetsTrend() {
            this.loading = true;
            this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white' : 'black';
            this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#424242' : 'white';
            this.option.series[0].data = [];
            this.option.xAxis.categories = [];
            switch(this.day){
                case 7: this.option.xAxis.tickInterval = 2; break;
                case 30: this.option.xAxis.tickInterval = 10; break;
                case 90: this.option.xAxis.tickInterval = 20; break;
                case 365: this.option.xAxis.tickInterval = 60; break;
                default: this.option.xAxis.tickInterval = 20; break;
            }
            let api = config.baseUrl + "/asset/public/getHoldingAsset?day=" + this.day;
            this.$http.get(api).then(res => {
                let data = res.data;
                if (data.code == 200) {
                    // 重新渲染图表
                    this.option.xAxis.categories = data.result.date;
                    this.option.series[0].data = data.result.fundBalance;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
                this.renderChart();
            }, error => {
                this.loading = false;
                this.noData();
            })
        },
        formatDateFunction(timestamp) {
            if (timestamp == 0) {
                return '--';
            } else {
                if ("localTime" == localStorage.getItem("iobots-time")) {
                    return formatDate(timestamp);
                } else {
                    return formatUTCDate(timestamp)
                }
            }
        },
        // 数字格式化
        formatNum(num) {
            if (num == null) return '';
            if (num == NaN || num == 'NaN') return '';
            if (num == 'undefined') return '';
            if (num == '--') return '--';
            let number = num + '';
            let numberArray = number.split('.');
            let integerPart = numberArray[0];
            let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(integerPart)) {
                integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
            }
            return integerPart + decimalPart;
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
        },
        // 查询实时的NAV
        getRealtimeDefyNav(){
            let api = config.fund + "/iobots/asset/public/getDefyNavFromNet";
            // let api = config.baseUrl + "/asset/public/getDefyNavFromNet";
            this.$http.get(api).then(res => {
                let data = res.data;
                if(data.code == 200){
                    var last = data.result;
                    this.defyReturn = (last - 1) * 100;
                }
            });
        },
    }
}
</script>
