import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from '@/plugins/vuetify';
// 多语言
import VueI18n from "vue-i18n";
// http
import VueResource from "vue-resource";
// 缓存
import Vuex from "vuex";
// 缓存
import { store } from "./store/store";
// CommonJS
import CommonJS from '../static/js/common';

// include all css files
import '@/lib/vuetifyCss'

// JS
// 初始化数据
import init from '@/store/init.js';

// Highcharts
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsAnnotations from 'highcharts/modules/annotations';

// 粘贴板
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard)

Vue.config.productionTip = false


Vue.use(VueI18n);
Vue.use(VueResource);
Vue.use(Vuex);
Vue.use(init);

Vue.prototype.CommonJS = CommonJS;

HighchartsMore(Highcharts)
HighchartsExporting(Highcharts);
HighchartsNoData(Highcharts);
HighchartsAnnotations(Highcharts);

function getLanguage() {
  // 将选择的语言存在localStorage中
  let locale = localStorage.getItem("iob.fi-locale");
  if (locale) {
    return locale;
  } else {
    return "en-US";
  }
}

const language = getLanguage();
Vue.locale = () => {};
const i18n = new VueI18n({
  locale: language,
  messages: {
    "en-US": require("@/assets/language/en-US.json"),
    "zh-CN": require("@/assets/language/zh-CN.json")
  }
});

window.vm = new Vue({
	router,
	store,
	vuetify,
  i18n,
  created: function() {
    this.init();
  },
	render: h => h(App)
}).$mount('#app')
