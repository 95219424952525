const state = {
	// 自适应屏幕,默认PC,否则mobile
	responsive: 'PC',
	// GitHub链接
	githubLink: null,
	// 当前国家代码
	countryCode: null,
	// MetaMask钱包
	metaMask: null,
	// MetaMask钱包网络
	metaMaskNet: "",
	// 钱包地址
	walletAddress: null,
	// ETH价格
	ethPrice: 0,
	// DEFY价格
	defyPrice: 0,
	// FI-ETH价格
	fiethPrice: 0,
	// GAS价格Gwei单位,默认20
	gasPriceGwei: 20,
	// DEFY的年化收益率
	defyAnnualizedReturn: 0,
	// 是否显示消息通知
	isShowSnackbar: false,
	// 消息通知信息
	snackbarMessage: null,
	// 版本号
	version: null,
	// iobots版本号
	iobotsVersion: null,
	// 手机底部导航栏
	mobileFooterActived: 0,
	// 管理的池子的基本信息
	managedPoolInfoList: []
}

const getters = {
	responsive: state => {
		return state.responsive;
	},
	githubLink: state => {
		return state.githubLink;
	},
	countryCode: state => {
  		return state.countryCode;
  	},
  	metaMask: state => {
  		return state.metaMask;
  	},
  	metaMaskNet: state => {
  		return state.metaMaskNet;
  	},
  	walletAddress: state => {
  		return state.walletAddress;
  	},
	ethPrice: state => {
  		return state.ethPrice;
  	},
	defyPrice: state => {
  		return state.defyPrice;
  	},
  	fiethPrice: state => {
  		return state.fiethPrice;
  	},
  	gasPriceGwei: state => {
  		return state.gasPriceGwei;
  	},
  	defyAnnualizedReturn: state => {
  		return state.defyAnnualizedReturn;
  	},
  	isShowSnackbar: state => {
		return state.isShowSnackbar;
	},
	snackbarMessage: state => {
		return state.snackbarMessage;
	},
	version: state =>{
		return state.version;
	},
	iobotsVersion: state =>{
		return state.iobotsVersion;
	},
	mobileFooterActived: state =>{
		return state.mobileFooterActived;
	},
	managedPoolInfoList: state =>{
		return state.managedPoolInfoList;
	}
}

const actions = {
	responsiveHandler(context, payload){
		context.commit('responsiveHandler', payload);
	},
	githubLinkHandler(context, payload){
		context.commit('githubLinkHandler', payload);
	},
	countryCodeHandler(context, payload){
  		context.commit('countryCodeHandler', payload);
  	},
  	metaMaskHandler(context, payload){
  		context.commit('metaMaskHandler', payload);
  	},
  	metaMaskNetHandler(context, payload){
  		context.commit('metaMaskNetHandler', payload);
  	},
  	walletAddressHandler(context, payload){
  		context.commit('walletAddressHandler', payload);
  	},
	ethPriceHandler(context, payload){
  		context.commit('ethPriceHandler', payload);
  	},
	defyPriceHandler(context, payload){
  		context.commit('defyPriceHandler', payload);
  	},
  	fiethPriceHandler(context, payload){
  		context.commit('fiethPriceHandler', payload);
  	},
	gasPriceGweiHandler(context, payload){
  		context.commit('gasPriceGweiHandler', payload);
  	},
  	defyAnnualizedReturnHandler(context, payload){
  		context.commit('defyAnnualizedReturnHandler', payload);
  	},
  	isShowSnackbarHandler(context, payload){
		context.commit('isShowSnackbarHandler', payload);
	},
	snackbarMessageHandler(context, payload){
		context.commit('snackbarMessageHandler', payload);
	},
	versionHandler(context, payload){
		context.commit('versionHandler', payload);
	},
	iobotsVersionHandler(context, payload){
		context.commit('iobotsVersionHandler', payload);
	},
	mobileFooterActivedHandler(context, payload){
		context.commit('mobileFooterActivedHandler', payload);
	},
	managedPoolInfoListHandler(context, payload){
		context.commit('managedPoolInfoListHandler', payload);
	}
}

const mutations = {
	responsiveHandler(state, responsive){
		state.responsive = responsive;
	},
	githubLinkHandler(state, githubLink){
		state.githubLink = githubLink;
	},
	countryCodeHandler(state, countryCode){
		state.countryCode = countryCode;
	},
	metaMaskHandler(state, metaMask){
		state.metaMask = metaMask;
	},
	metaMaskNetHandler(state, metaMaskNet){
		state.metaMaskNet = metaMaskNet;
	},
	walletAddressHandler(state, walletAddress){
		state.walletAddress = walletAddress;
	},
	ethPriceHandler(state, ethPrice){
		state.ethPrice = ethPrice;
	},
	defyPriceHandler(state, defyPrice){
		state.defyPrice = defyPrice;
	},
	fiethPriceHandler(state, fiethPrice){
		state.fiethPrice = fiethPrice;
	},
	gasPriceGweiHandler(state, gasPriceGwei){
		if (gasPriceGwei != 0 && gasPriceGwei != '--') {
			state.gasPriceGwei = gasPriceGwei;
		} else {
			state.gasPriceGwei = 20;
		}
	},
	defyAnnualizedReturnHandler(state, defyAnnualizedReturn){
		state.defyAnnualizedReturn = defyAnnualizedReturn;
	},
	isShowSnackbarHandler(state, isShowSnackbar){
		state.isShowSnackbar = isShowSnackbar;
	},
	snackbarMessageHandler(state, snackbarMessage){
		if(snackbarMessage != null && snackbarMessage != ''){
			state.snackbarMessage = snackbarMessage;
			state.isShowSnackbar = true;
		}
	},
	versionHandler(state, version){
		state.version = version;
	},
	iobotsVersionHandler(state, iobotsVersion){
		state.iobotsVersion = iobotsVersion;
	},
	mobileFooterActivedHandler(state, mobileFooterActived){
		state.mobileFooterActived = mobileFooterActived;
	},
	managedPoolInfoListHandler(state, managedPoolInfoList){
		state.managedPoolInfoList = managedPoolInfoList;
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}