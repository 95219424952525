<template>
    <div>
        <v-container class="px-0">
            <v-row justify="center" class="mt-120">
                <v-col cols="11">
                    <pool-nav-line-chart :address="address" class="pb-70"></pool-nav-line-chart>
                </v-col>
                <v-col cols="11">
                    <pool-fee-structure :address="address" class="pb-70"></pool-fee-structure>
                </v-col>
                <v-col cols="11">
                    <share-price-metrics-pool :address="address" class="pb-70"></share-price-metrics-pool>
                </v-col>
                <v-col cols="11">
                    <v-col cols="12" class="px-0">
                    <h5>{{ $t('holding.holdings') }}
                        <span class="title_txt mr-3 ml-3">
                            <a :href="`${CommonJS.getBlockchainExplorerUrl(blockchainNetwork)}/tokenholdings?a=${address}`" class="pure-red--text del-underline" target="_blank" v-if="address != null">
                                <span>{{ address.substring(0,6) + "..." + address.substring(address.length - 4)  }}&#8599;</span>
                            </a>
                        </span>
                        <span class="title_txt">
                            <router-link :to="'/activity/' + address" target="_blank" class="del-underline white--text pointer" v-if="address != null">
                                <span class="pure-red--text">Activity</span>
                            </router-link>
                        </span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <v-col cols="11" class="mb-n3">
                    <v-row justify="center" class="">
                        <v-col xl="4" lg="4" md="4" cols="12">
                            <v-card dark>
                                <div class="card-body pa-4">
                                    <div class="align-items-center media">
                                        <div class="media-body">
                                            <h2 class="fs-12 dark-gray--title">{{ $t('holding.valueInETH') }}</h2>
                                            <span class="fs-28 fw-normal grey--text">{{ formatNum(valueInETH.toFixed(6))}}</span>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col xl="4" lg="4" md="4" cols="12">
                            <v-card dark>
                                <div class="card-body pa-4">
                                    <div class="align-items-center media">
                                        <div class="media-body">
                                            <h2 class="fs-12 dark-gray--title">{{ $t('holding.valueInUSD') }}</h2>
                                            <span class="fs-28 fw-normal grey--text">${{ formatNum(valueInUSD.toFixed(2)) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col xl="4" lg="4" md="4" cols="12">
                            <v-card dark>
                                <div class="card-body pa-4">
                                    <div class="align-items-center media">
                                        <div class="media-body">
                                            <h2 class="fs-12 dark-gray--title">{{ $t('holding.total') }} {{ minted }} {{ $t('holding.minted') }}</h2>
                                            <span class="fs-28 fw-normal grey--text">{{ formatNum(totalSupply.toFixed(6)) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="11" class="pb-70">
                    <!-- <v-data-table :headers="HoldingHeader" :items="holdingItems" hide-default-footer disable-sort disable-pagination>
                        <template v-slot:body="{ items }">
                            <tbody v-if="!loading">
                                <tr v-for="(item,index) in items" :key="index">
                                    <td class="dark-gray--text">{{item.name}}</td>
                                    <td class="dark-gray--text">{{item.symbol}}</td>
                                    <td class="text-right dark-gray--text">{{formatNum(Number(item.amount).toFixed(6))}}</td>
                                    <td class="text-right dark-gray--text">${{formatNum(item.price.toFixed(4))}}</td>
                                    <td class="text-right dark-gray--text">{{formatNum(Number(item.valueInETH).toFixed(4))}}</td>
                                    <td class="text-right dark-gray--text">${{formatNum(Number(item.value).toFixed(2))}}</td>
                                </tr>
                            </tbody>
                            <tbody v-if="loading">
                                <tr>
                                    <td colspan="6">
                                        <v-skeleton-loader dense type="table-row-divider@4"></v-skeleton-loader>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table> -->
                    <v-card>
                        <v-card-text class="pt-2 pb-2 fs-12 font-weight-bold">
                            <v-row dense>
                                <v-col cols="2" class="ml-2">{{ $t('holding.assetName') }}</v-col>
                                <v-col cols="1" class="ml-n1">{{ $t('holding.symbol') }}</v-col>
                                <v-col cols="2" class="text-right ml-n4">{{ $t('holding.quantity') }}</v-col>
                                <v-col cols="2" class="text-right">{{ $t('holding.lastPrice') }}</v-col>
                                <v-col cols="2" class="text-right ml-n1">{{ $t('holding.valueInETH') }}</v-col>
                                <v-col cols="2" class="text-right ml-13">{{ $t('holding.valueInUSD') }}</v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-expansion-panels hover tile accordion focusable>
                            <v-expansion-panel v-for="item in holdingItems" :readonly="!item.expansion">
                                <v-expansion-panel-header>
                                    <v-row class="fs-14">
                                        <v-col cols="2">
                                            <span :class="item.expansion ? 'title_txt' : ''">
                                                <span>
                                                    <a target="_blank" :class="item.expansion ? 'del-underline pure-red--text' : 'dark-gray--text'">{{ item.name }}</a>
                                                </span>
                                            </span>
                                        </v-col>
                                        <v-col cols="1" class="dark-gray--text">{{item.symbol}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text">{{(item.amount == null || item.amount == 0) ? "--" : formatNum(Number(item.amount).toFixed(6))}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text">{{item.price == 0 ? "--" : "$" + formatNum(item.price.toFixed(4))}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text ml-n1">{{item.valueInETH == 0 ? "--" : formatNum(Number(item.valueInETH).toFixed(4))}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text ml-13">{{item.value == 0 ? "--" : "$" + formatNum(Number(item.value).toFixed(2))}}</v-col>
                                    </v-row>
                                    <template v-slot:actions>
                                        <v-img v-if="!item.expansion" src="../../../public/img/1.png" alt="" class="ml-n5"></v-img>
                                        <v-icon v-else class="ml-n5">mdi-chevron-down</v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="black-gradient">
                                    <v-row>
                                        <v-col cols="12" class="ml-n1">
                                            <span class="title_txt">
                                                <span class="mr-5 ml-1" v-if="item.expansion && item.platform == 'dHEDGE'">
                                                    <a :href="'https://app.dhedge.org/pool/' + item.address" target="_blank" class="del-underline pure-red--text">{{ item.name }}</a>
                                                </span>
                                                </span>
                                                <span class="mr-5 ml-1 title_txt" v-if="item.expansion && item.platform == 'Binance'">
                                                    <a href="https://www.binance.com/en/delivery/btcusd_perpetual" target="_blank" class="del-underline pure-red--text mr-3">{{ item.name }}</a>
                                                    <router-link to="/activity/defy-pool-on-binance" target="_blank" class="del-underline dark-gray--text pointer ml-16">
                                                        <span class="text-hover">Activity</span>
                                                    </router-link>
                                                </span>
                                                <span class="mr-5 ml-1 title_txt" v-if="item.expansion && item.platform == 'FTX'">
                                                    <a href="https://ftx.com/markets/future" target="_blank" class="del-underline pure-red--text mr-3">{{ item.name }}</a>
                                                    <router-link to="/activity/defy-pool-on-ftx" target="_blank" class="del-underline dark-gray--text pointer ml-16">
                                                        <span class="text-hover">Activity</span>
                                                    </router-link>
                                                </span>
                                                <span class="mr-5 ml-1 title_txt" v-if="item.expansion && item.platform == 'Kraken'">
                                                    <a href="https://futures.kraken.com/trade/" target="_blank" class="del-underline pure-red--text mr-3">{{ item.name }}</a>
                                                    <router-link to="/activity/defy-pool-on-kraken" target="_blank" class="del-underline dark-gray--text pointer ml-16">
                                                        <span class="text-hover">Activity</span>
                                                    </router-link>
                                                </span>
                                                <span class="mr-5 ml-1 title_txt" v-if="item.expansion && item.platform == 'OKEx'">
                                                    <a href="https://www.okex.com/derivatives/swap/full" target="_blank" class="del-underline pure-red--text mr-3">{{ item.name }}</a>
                                                    <router-link to="/activity/defy-pool-on-okex" target="_blank" class="del-underline dark-gray--text pointer ml-16">
                                                        <span class="text-hover">Activity</span>
                                                    </router-link>
                                                </span>
                                            </span>
                                            <span class="title_txt" v-if="item.platform == 'dHEDGE' || item.platform == 'Wallet'">
                                                <a :href="`${CommonJS.getBlockchainExplorerUrl(item.blockchainNetwork)}/tokenholdings?a=${item.address}`" class="pure-red--text del-underline" target="_blank" v-if="item.address != null">
                                                    <span>{{ item.address.substring(0,6) + "..." + item.address.substring(item.address.length - 4)  }}&#8599;</span>
                                                </a>
                                            </span>
                                            <span class="ml-16 title_txt" v-if="item.platform == 'dHEDGE' || item.platform == 'Wallet'">
                                                <router-link :to="'/activity/' + item.address" target="_blank" class="del-underline dark-gray--text pointer" v-if="item.address != null">
                                                    <span class="text-hover">Activity</span>
                                                </router-link>
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row class="dark-gray--text fs-12 font-weight-bold" dense v-if="(item.platform == 'dHEDGE' || item.platform == 'Wallet' || item.platform == 'Ledger')">
                                        <v-col cols="2" class="dark-gray--text">{{ $t('holding.assetName') }}</v-col>
                                        <v-col cols="1" class="dark-gray--text">{{ $t('holding.symbol') }}</v-col>
                                        <v-col cols="2" class="text-right ml-n3">{{ $t('holding.quantity') }}</v-col>
                                        <v-col cols="2" class="text-right">{{ $t('holding.lastPrice') }}</v-col>
                                        <v-col cols="2" class="text-right ml-0">{{ $t('holding.valueInETH') }}</v-col>
                                        <v-col cols="2" class="text-right ml-14">{{ $t('holding.valueInUSD') }}</v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                    <v-row class="fs-14" dense v-for="(childItem, index) in item.data" v-if="CommonJS.hasElements(item.data) && (item.platform == 'dHEDGE' || item.platform == 'Wallet' || item.platform == 'Ledger')">
                                        <v-col cols="2" class="dark-gray--text d-flex">
                                            <blockchain-network :network="childItem.blockchainNetwork" :size="3"></blockchain-network>
                                            <span class="ml-2">{{childItem.name}}</span>
                                        </v-col>
                                        <v-col cols="1" class="dark-gray--text">{{childItem.symbol}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text ml-n3">{{(childItem.amount == null || childItem.amount == 0) ? "--" : formatNum(Number(childItem.amount).toFixed(6))}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text">{{childItem.price == 0 ? "--" : "$" + formatNum(childItem.price.toFixed(4))}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text ml-0">{{childItem.valueInETH == 0 ? "--" : formatNum(Number(childItem.valueInETH).toFixed(4))}}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text ml-14">{{childItem.value == 0 ? "--" : "$" + formatNum(Number(childItem.value).toFixed(2))}}</v-col>
                                        <v-col cols="12" v-if="index < item.data.length -1"><v-divider></v-divider></v-col>
                                    </v-row>
                                    <v-row class="fs-14" dense v-if="!CommonJS.hasElements(item.data) && (item.platform == 'dHEDGE' || item.platform == 'Wallet' || item.platform == 'Ledger')">
                                        <v-col cols="12" class="text-center dark-gray--text">{{ $t('common.noDataAvailable') }}</v-col>
                                    </v-row>
                                    <v-row class="dark-gray--text fs-14" dense v-if="(item.platform == 'Binance' || item.platform == 'FTX' || item.platform == 'Kraken' || item.platform == 'OKEx')">
                                        <v-col cols="2" class="">{{ $t('holding.instrument') }}</v-col>
                                        <v-col cols="1" class="">{{ $t('holding.side') }}</v-col>
                                        <v-col cols="2" class="text-right ml-n3">{{ $t('holding.quantity') }}</v-col>
                                        <v-col cols="2" class="text-right">{{ $t('holding.lastPrice') }}</v-col>
                                        <v-col cols="2" class="text-right">{{ $t('holding.valueInETH') }}</v-col>
                                        <v-col cols="2" class="text-right ml-14">{{ $t('holding.valueInUSD') }}</v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                    <v-row class="fs-14" dense v-for="(childItem, index) in item.data" v-if="CommonJS.hasElements(item.data) && (item.platform == 'Binance' || item.platform == 'FTX' || item.platform == 'Kraken' || item.platform == 'OKEx')">
                                        <v-col cols="2" class="dark-gray--text">{{childItem.symbol}}</v-col>
                                        <v-col cols="1" :class="[childItem.side == '--' ? '': (childItem.side == 'Long' ? 'green--text' : 'red--text')]">{{ childItem.side }}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text ml-n3">{{ childItem.symbol.indexOf("-") > -1 ? formatNum(childItem.size) : formatNum(childItem.size.toFixed(4)) }}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text">{{ "$" +formatNum(childItem.price.toFixed(4)) }}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text">{{ childItem.valueInETH == null ? "--" : formatNum(Number(childItem.valueInETH).toFixed(4)) }}</v-col>
                                        <v-col cols="2" class="text-right dark-gray--text ml-14">{{ "$" + formatNum(childItem.value.toFixed(2)) }}</v-col>
                                        <v-col cols="12" v-if="index < item.data.length -1"><v-divider></v-divider></v-col>
                                    </v-row>
                                    <v-row class="fs-14" dense v-if="!CommonJS.hasElements(item.data) && (item.platform == 'Binance' || item.platform == 'FTX' || item.platform == 'Kraken' || item.platform == 'OKEx')">
                                        <v-col cols="12" class="text-center dark-gray--text">{{ $t('common.noDataAvailable') }}</v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </v-col>
                <v-col cols="11" class="pb-250">
                    <pool-description :address="address" :description="description"></pool-description>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters} from "vuex";
import config from '@/config.js';
import PoolNavLineChart from "@/highcharts/PoolNAVLineChart";
import PoolFeeStructure from "@/components/pool/PoolFeeStructure";
import PoolDescription from "@/components/pool/PoolDescription";
import SharePriceMetricsPool from "@/components/asset/SharePriceMetricsPool";
import BlockchainNetwork from "@/components/common/BlockchainNetwork";
export default {
    data() {
        return {
            address:this.$route.params.address,
            faq: config.faq,
            fi: config.domainName,
            environment: config.environment,
            HoldingHeader:[
                { text: this.$t('holding.assetName') },
                { text: this.$t('holding.symbol') },
                { text: this.$t('holding.quantity'), align:"end"},
                { text: this.$t('holding.lastPrice'), align:"end"},
                { text: this.$t('holding.valueInETH'), align:"end"},
                { text: this.$t('holding.valueInUSD'), align:"end"}
            ],
            // 区块链网络
            blockchainNetwork: '',
            holdingItems:[],
            valueInETH:0,
            valueInUSD:0,
            totalSupply:0,
            description: null,
            minted:null,
            loading: false
        }
    },
    created() {
        this.isExist(this.address);
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PoolNavLineChart, PoolFeeStructure, SharePriceMetricsPool, PoolDescription, BlockchainNetwork
    },
    watch: {
        $route() {
            this.address = this.$route.params.address;
        },
        address(val){
            if (val) {
                this.isExist(val);
            }
        }
    },
    methods: {
        // 判读地址是否在数据库中
        isExist(address){
            let api = config.baseUrl + "/pool/public/exist?address=" + address;
            this.$http.get(api).then(res=>{
                let data = res.data;
                if(data.code == 200){
                    this.minted = data.result.poolName + " (" + data.result.symbol +")";
                    this.getHolding();
                }else{
                    this.$router.push({ name:'page404'})
                }
            })
        },
        getHolding(){
            this.loading = true;
            let api =  config.baseUrl + "/pool/public/holdings?address=" + this.address;
            this.$http.get(api).then(res=>{
                let data = res.data;
                this.loading = false;
                if(data.code == 200){
                    this.blockchainNetwork = data.result.blockchainNetwork;
                    this.holdingItems = data.result.holding;
                    this.valueInETH = data.result.valueInETH;
                    this.valueInUSD = data.result.valueInUSD;
                    this.totalSupply = data.result.totalSupply;
                    this.description = data.result.description;
                }
            })
        },
        formatNum(num) {
            if (num == null) return '';
            if (num == NaN || num == 'NaN') return '';
            if (num == 'undefined') return '';
            if (num == '--') return '--';
            let number = num + '';
            let numberArray = number.split('.');
            let integerPart = numberArray[0];
            let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(integerPart)) {
                integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
            }
            return integerPart + decimalPart;
        },
    },
}
</script>
<style scoped>
.theme--dark .v-data-table,.theme--light .v-data-table {
        background-color: #1E1E1E!important;
        color: #FFFFFF!important;
}
</style>