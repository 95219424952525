<template>
  <span>
      <span v-if="currency == 'BTC'">
        <i class="icon icon-btc">
			<span class="icon-btc"><span class="path1"></span><span class="path2"></span></span>
        </i>
      </span>
      <span v-if="currency == 'ETH'">
       <i class="icon icon-ETH"></i>
      </span>
      <span v-if="currency == 'BCH'">
        <i class="icon icon-BCH1"></i>
      </span>
      <span v-if="currency == 'LTC'">
        <i class="icon icon-ltc"></i>
      </span>
      <span v-if="currency == 'XRP'">
        <i class="icon icon-xrp"></i>
      </span>
      <span v-if="currency == 'EOS'">
        <i class="icon icon-eos"></i>
      </span>
      <span v-if="currency == 'ETC'">
       <i class="icon icon-ETC"></i>
      </span>
      <span v-if="currency == 'BSV'">
        <i class="icon icon-bsv"></i>
      </span>
      <span v-if="currency == 'TRX'">
        <i class="icon icon-trx"></i>
      </span>
      <span v-if="currency == 'OKB'">
        <i class="icon icon-okb">
         <span class="icon-okb"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>
        </i>     
      </span>
      <span v-if="currency == 'DASH'">
       <i class="icon icon-dash"></i>
      </span>
      <span v-if="currency == 'NEO'">
        <i class="icon icon-neo"></i>
      </span>
      <span v-if="currency == 'QTUM'">
         <i class="icon icon-qtum1"></i>
      </span>
      <span v-if="currency == 'XTZ'">
        <i class="icon icon-xtz"></i>
      </span>
      <span v-if="currency == 'ATOM'">
        <i class="icon icon-atom">
        </i>
      </span>
      <span v-if="currency == 'ADA'">
         <i class="icon icon-ada">
         </i>
      </span>
      <span v-if="currency == 'XLM'">
        <i class="icon icon-xlm"></i>
      </span>
      <span v-if="currency == 'LINK'">
         <i class="icon icon-chainlink"></i>
      </span>
      <span v-if="currency == 'ONT'">
        <i class="icon icon-ont1"></i>
      </span>
      <span v-if="currency == 'ZEC'">
        <i class="icon icon-zec"></i>
      </span>
      <span v-if="currency == 'IOST'">
        <i class="icon icon-iost"></i>
      </span>
      <span v-if="currency == 'ALGO'">
        <i class="icon icon-algo1"></i>
      </span>
      <span v-if="currency == 'XMR'">
        <i class="icon icon-xmr">
          <span class="icon-xmr"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
        </i>
      </span>
	  <span v-if="currency == 'DOT'">
	    <i class="icon icon-polkadot-new-dot-logo">
			<span class="icon-polkadot-new-dot-logo"><span class="path1"></span><span class="path2"></span></span>
		</i>
	  </span>
	  <span v-if="currency == 'UNI'">
	    <i class="icon icon-uni"></i>
	  </span>
      <span v-if="currency == 'GOLD'">
        <i class="icon icon-gold">
          <span class="icon-gold"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>
        </i>
      </span>
      <span v-if="currency == 'SILVER'">
        <i class="icon icon-silver1"> 
        </i>
      </span>
      <span v-if="currency == 'SPX'">
        <v-avatar color="blue" size="20"><span class="fs-12">spx</span></v-avatar>
      </span>
	  <span v-if="currency == 'CHINA A50'">
	    <v-avatar color="blue" size="20"><span class="fs-12">A50</span></v-avatar>
	  </span>
      <span v-if="currency == 'MSFT'">
        <i class="icon icon-MSFT">
         <span class="icon-MSFT"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
        </i>
      </span>
      <span v-if="currency == 'TSLA'">
        <i class="icon icon-tesla">
        </i>
      </span>
	  <span v-if="currency == 'APPLE'">
		  <i class="icon icon-apple">  
		  </i>
	  </span>
	  <span v-if="currency == 'AMAZON'">
		  <i class="icon icon-ama">  
		  </i>
	  </span>
	  <span v-if="currency == 'GOOGLE'">
		  <i class="icon icon-google"> 
		   <span class="icon-google"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
		  </i>
	  </span>
	  <span v-if="currency == 'CRUDE OIL'">
		  <i class="icon icon-oil">  
		  </i>
	  </span>
      <span v-if="currency == 'KRAKEN'">
        <i class="icon icon-kraken"></i>
      </span>
      <span v-if="currency == 'TOKENSETS'">
          <v-img src="https://files.iob.fi/2020/11/1605670577972-9e6f7cd250c758118dfee089aec182bf-token.webp" style="width: 22px;" class="rounded-circle"></v-img>
      </span>
      <span v-if="currency == '1INCH'">
         <v-img src="https://files.iob.fi/2020/11/1605670502933-696579c46fb95f38aa31e1c1a41fa6d3-oneinch.webp" style="width: 22px;" class="rounded-circle"></v-img>
      </span>
      <span v-if="currency == 'BALANCER'">
        <v-img src="https://files.iob.fi/2020/11/1605670437892-3c74522fa34b549d8fa255293458dae2-balancer.webp" style="width: 22px;" class="rounded-circle"></v-img>
      </span>
      <span v-if="currency == 'ZERION'">
        <v-img src="https://files.iob.fi/2020/11/1605670590976-787d296a250f5183bfbb1916e33261a9-zerion.webp" style="width: 22px;" class="rounded-circle"></v-img>
      </span>
      <span v-if="currency == 'SYNTHETIX'">
        <v-img src="https://files.iob.fi/2020/11/1605670541952-005beee6a78d5d628fe514b84a62240f-synthetix.webp" style="width: 22px;" class="rounded-circle"></v-img>
      </span>
	  <span v-if="currency == 'BINANCE'">
	    <i class="icon icon-bi"></i>
	  </span>
	  <span v-if="currency == 'DHEDGE'">
	    <i class="icon icon-dhedge"></i>
	  </span>
    <span v-if="currency == 'FTX'">
      <span class="icon-ftx"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
    </span>
  </span>
</template>
<style>

</style>
<script>
  import Vue from 'vue'
  export default {
    data() {
      return {}
    },
    created() {

    },
    mounted() {

    },
    props: [
      'currency'
    ],
    watch: {

    },
    methods: {

    }
  }

</script>
