<template>
    <div>
        <pc-about v-if="responsive == 'PC'"></pc-about>
        <mobile-about v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-about>
    </div>
</template>

<style>
</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcAbout from "./pc/About";
import MobileAbout from "./mobile/About";

export default {
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/about.md')
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcAbout,
        MobileAbout,
    },
    watch: {

    },
    methods: {

    },
}
</script>
