<template>
    <div>
        <pc-tokens v-if="responsive == 'PC'"></pc-tokens>
        <mobile-tokens v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-tokens>
    </div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcTokens from "./pc/Tokens";
import MobileTokens from "./mobile/Tokens";

export default {
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/tokens.md')
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcTokens,
        MobileTokens,
    },
    watch: {

    },
    methods: {

    },
}
</script>
