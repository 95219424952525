<template>
	<div>
        <v-container class="grid-list-xl px-0 mt-70">
            <v-row justify="center" align="center">
                <!-- DEFY Pool On Binance -->
                <v-col cols="11" v-if="address=='defy-pool-on-binance'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On Binance</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- DEFY Pool On FTX -->
                <v-col cols="11" v-else-if="address=='defy-pool-on-ftx'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On FTX</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- DEFY Pool On Kraken -->
                <v-col cols="11" v-else-if="address=='defy-pool-on-kraken'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On Kraken</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- DEFY Pool On OKEx -->
                <v-col cols="11" v-else-if="address=='defy-pool-on-okex'">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">DEFY Pool On OKEx</div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- None -->
                <v-col cols="11" v-else-if="!hasTokenDetail">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="12" class="px-0">
                        <div class="fs-48">
                            <span v-if="address.length > 20">{{ address.substring(0,6) + "..." + address.substring(address.length - 4)  }}</span>
                            <span v-else>{{ address }}</span>
                        </div>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <!-- Pool -->
                <v-col cols="11" v-else-if="hasTokenDetail">
                    <!-- 加这层cols为了下面放置横线 -->
                    <v-col cols="11" class="px-0">
                        <div class="d-flex flex-wrap">
							<div v-if="tokenDetail.name != null" class="fs-48">{{ tokenDetail.name }}</div>
							<div style="width:180px;"  class="my-4 py-2" v-else>
							    <v-progress-linear color="dark-grey" indeterminate height="6"></v-progress-linear>
							</div>
							<blockchain-network v-if="tokenDetail.name != null" class="ml-4 mt-3" :network="tokenDetail.blockchainNetwork" :size="5"></blockchain-network>
						</div>
                        <v-row dense class="dark-gray--text">
                            <v-col cols="12">
                                <span>Total Pool Tokens Minted: {{ tokenDetail.totalSupply == null || tokenDetail.totalSupply == 0 ? "--" : formatNum(tokenDetail.totalSupply) }}</span>
                            </v-col>
                            <v-col cols="12">
                                <!-- <span>Holders: {{ tokenDetail.holders == null || tokenDetail.holders == 0 ? "--" : formatNum(tokenDetail.holders) }}</span> -->
                                <span>Holders: {{ tokenDetail.holders == null ? "--" : formatNum(tokenDetail.holders) }}</span>
                            </v-col>
                        </v-row>
                        <pool-nav-simple-chart :address="address"></pool-nav-simple-chart>
                        <h5 class="mt-10">
                            <span @click="getHistory">Activity</span>
                            <span class="title_txt ml-5">
                                <a :href="`${CommonJS.getBlockchainExplorerUrl(tokenDetail.blockchainNetwork)}/address/${address}#tokentxns`" class="pure-red--text del-underline" target="_blank" v-if="address != null">
                                    <span>{{ address.substring(0,6) + "..." + address.substring(address.length - 4)  }}&#8599;</span>
                                </a>
                            </span>
                        </h5>
                    </v-col>
                    <v-divider class="dark-grey"></v-divider>
                </v-col>
                <v-col cols="11">
                    <v-row>
                        <v-col>
                            <v-btn @click="type='trade'" tile :class="[type == 'trade' ? 'button--gradient-orange' : '']" width="90px">
                                <span>Trade</span>
                            </v-btn>
                            <v-btn @click="type='ledger'" tile :class="[type == 'ledger' ? 'button--gradient-pink' : '']" width="90px">
                                <span>Ledger</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-if="type=='trade'">
                            <!-- hide-default-header 隐藏手机版的头部 并使用插槽 -->
                            <v-data-table disable-sort :headers="tradeHeaders" :items="tradeItems" :items-per-page="10" hide-default-header  class="dark-gray--text">
                                <template v-slot:header="{ props: { headers } }">
                                    <thead>
                                    <tr>
                                        <th class="fixed-column w-130"><div class="ml-2">Symbol</div></th>
                                        <th class="pl-150"><div class="w-110">Side</div></th>
                                        <th><div class="text-right">Size</div></th>
                                        <th><div class="w-70 text-right ml-3">Avg. Price</div></th>
                                        <th><div class="text-right">Volume</div></th>
                                        <th><div class="w-90 text-right">Fee</div></th>
                                        <th><div class="w-130">Time (UTC)</div></th>
                                        <th>Hash</th>
                                    </tr>
                                    </thead>
                                </template>
                                <template v-slot:body="{ items }">
                                    <tbody v-if="!loading">
                                        <tr v-for="(item, i) in items" :key="i">
                                            <td class="fixed-column w-130">
                                                <span class="ml-2">{{ item.symbol }}</span>
                                            </td>
                                            <td class="pl-150 w-110"><div>{{ item.side }}</div></td>
                                            <td class="text-right">{{ formatNum(item.size.toFixed(4)) }}</td>
                                            <td class="text-right">{{ formatNum(item.price) }}</td>
                                            <td class="text-right">{{ formatNum(item.cost.toFixed(6)) }}</td>
                                            <td class="text-right">{{ formatNum(item.fee.toFixed(6)) + ' ' + item.feeCurrency }}</td>
                                            <td class="w-130">{{ item.time }}</td>
                                            <td v-if="item.hash == null">--</td>
                                            <td v-if="item.hash != null" class="title_txt">
                                                <a :href="`${CommonJS.getBlockchainExplorerUrl(item.blockchainNetwork)}/tx/${item.hash}`" target="_blank" rel="noopener noreferrer" class="del-underline">
                                                    <span class="pure-red--text">{{item.hash.substring(0,6) + "..." + item.hash.substring(item.hash.length-4)}}&#8599;</span>&nbsp;
                                                </a>
                                            </td>
                                            <td v-if="item.hash != null">
                                                <v-icon size="14" class="ml-n7 pointer fs-12" color="#B8B8B8" @click="copy(item.hash)" :title="'Copy: ' + item.hash">icon icon-copy1_2</v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="loading">
                                        <tr v-for="i in 10" :key="i">
                                            <td :colspan="tradeHeaders.length">
                                                <v-skeleton-loader dense type="table-row@1"></v-skeleton-loader>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!loading && tradeItems.length == 0">
                                        <tr class="text-center">
                                            <td :colspan="tradeHeaders.length" class="fs-16">
                                                No data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-col cols="12" v-if="type=='ledger'">
                            <v-data-table disable-sort :headers="lHeaders" :items="ledgerItems" :items-per-page="10" class="dark-gray--text" hide-default-header>
                                <template v-slot:header="{ props: { headers } }">
                                    <thead>
                                        <tr>
                                            <th><div class="w-90 ml-3">Type</div></th>
                                            <th><div class="w-70 ml-4">Fee</div></th>
                                            <th><div class="w-130">Num. of asset</div></th>
                                            <th><div class="w-70 ">Time (UTC)</div></th>
                                            <th><div class="w-70"></div></th>
                                        </tr>
                                    </thead>
                                </template>
                                <template v-slot:body="{ items }">
                                    <tr v-if="items.length > 0">
                                        <td :colspan="lHeaders.length" class="mx-0 px-0">
                                             <v-expansion-panels accordion focusable tile>
                                                 <v-expansion-panel v-for="(item,index) in items" :key="index">
                                                     <v-expansion-panel-header>
                                                        <v-row class="dark-gray--text">
                                                            <v-col cols="3">{{item.side}}</v-col>
                                                            <v-col cols="3">{{item.fee.toFixed(4) + ' ' + item.feeCurrency}}</v-col>
                                                            <v-col cols="1">{{item.count}}</v-col>
                                                            <v-col cols="5">{{item.time}}</v-col>
                                                        </v-row>
                                                     </v-expansion-panel-header>
                                                     <v-expansion-panel-content class="black-gradient">
                                                        <v-row style="color:#FFFFFFB3;" dense>
                                                            <v-col cols="3">Symbol</v-col>
                                                            <v-col cols="3" class="text-right">Amount</v-col>
                                                            <v-col cols="6">Hash</v-col>
                                                            <v-col cols="12" class="ma-0 py-0">
                                                                <v-divider></v-divider>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row v-for="(item2,index) in item.ledgers" dense :key="index">
                                                            <v-col cols="3" class="float-right">
                                                                <span class="dark-gray--text">{{ item2.symbol }}</span>
                                                            </v-col>
                                                            <v-col cols="3">
                                                                <span class="float-right dark-gray--text">
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <span v-bind="attrs" v-on="on">{{ formatNum(item2.amount.toFixed(4)) }}</span>
                                                                        </template>
                                                                        <span>{{ formatNum(item2.amount) }}</span>
                                                                    </v-tooltip>
                                                                </span>
                                                            </v-col>
                                                            <v-col v-if="item.hash == null">
                                                                <span>--</span>
                                                            </v-col>
                                                            <v-col v-else cols="6">
                                                                <a :href="`${CommonJS.getBlockchainExplorerUrl(item.blockchainNetwork)}/tx/${item.hash}`" target="_blank" rel="noopener noreferrer" class="del-underline">
                                                                    <span class="pure-red--text">{{item.hash.substring(0,6) + "..." + item.hash.substring(item.hash.length-4)}}&#8599;</span>&nbsp;
                                                                </a>
                                                                <v-icon size="14" class="ml-2 pointer fs-12" color="#B8B8B8" @click="copy(item.hash)" :title="'Copy: ' + item.hash">icon icon-copy1_2</v-icon>
                                                            </v-col>
                                                            <v-col cols="12" class="ma-0 py-0" v-if="index < item.ledgers.length -1">
                                                                <v-divider></v-divider>
                                                            </v-col>
                                                        </v-row>
                                                     </v-expansion-panel-content>
                                                 </v-expansion-panel>
                                             </v-expansion-panels>
                                        </td>
                                    </tr>
                                    <tr class="text-center" v-else style="height:48px;">
                                        <td :colspan="tradeHeaders.length" class="fs-16">
                                            No data available
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<style scoped>
.v-data-footer div, .v-data-footer div i, .v-input__control div{
    color: #999999 !important;
}
.v-data-table .fixed-column {
    position: absolute;
    display: flex;
    align-items: center;
    background: #1E1E1E;
    width: 110px;
}
.w-70{
    width: 70px!important;
    table-layout: fixed!important;
}
.w-90{
   width: 90px!important;
   table-layout: fixed!important;
}
.w-110{
   width: 110px!important;
   table-layout: fixed!important;
}
.w-130{
   width: 130px!important;
   table-layout: fixed!important;
}
.w-240{
   width: 240px!important;
   table-layout: fixed!important;
}
.v-data-footer, .v-data-footer__select, .v-data-footer__pagination{
    margin: 0px 0px 0px 0px !important;
}
</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import PoolNavSimpleChart from "@/highcharts/PoolNAVSimpleChart";
	import BlockchainNetwork from "@/components/common/BlockchainNetwork";
    export default {
        data(){
            return {
                address: this.$route.params.address.toLowerCase(),
                type: 'trade',
                tradeHeaders:[
                    { text: "Symbol" },
                    { text: "Side" },
                    { text: "Size", align:"end" },
                    { text: "Avg. Price", align:"end" },
                    { text: "Volume", align:"end" },
                    { text: "Fee", align:"end" },
                    { text: "Time (UTC)" },
                    { text: "Hash" },
                    { text: "" },
                ],
                tradeItems:[],
                ledgerHeaders:[
                    { text: "Symbol" },
                    { text: "Side" },
                    { text: "Amount", align:"end" },
                    { text: "Fee", align:"end" },
                    { text: "Time (UTC)" },
                    { text: "Hash" },
                    { text: "" },
                ],
                lHeaders:[
                    { text: "Time (UTC)",width:"18%"},
                    { text: "Type",width:"10%"},
                    { text: "Fee", align:"end",width:"10%"},
                    { text: "Num. of asset", align:"end",width:"10%"},
                    { text: "Hash", align:"end",width:"10%"},
                    { text: "",width:"5%"},
                ],
                ledgerItems:[],
                loading:false,
                // addressName:null,
                tokenDetail:{},
                // 存在Token详情信息
                hasTokenDetail: true,
            }
        },
        created(){

        },
        mounted(){
            this.getTokenDetail();
            this.getHistory();
        },
        computed: {

        },
        components:{
            PoolNavSimpleChart,
			BlockchainNetwork
        },
        watch:{

        },
        methods: {
            getTokenDetail(){
                if (this.address == 'defy-pool-on-binance' || this.address == 'defy-pool-on-ftx' || this.address == 'defy-pool-on-kraken' || this.address == 'defy-pool-on-okex') {
                    return;
                }
                this.tokenDetail = {};
                let  api = config.baseUrl + "/token/public/detail/" + this.address;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.tokenDetail = data.result;
                    } else {
                        this.tokenDetail = {};
                        this.hasTokenDetail = false;
                    }
                })
            },
            getHistory(){
                this.loading = true;
                let api = null;
                if (this.address == 'defy-pool-on-binance') {
                    api = config.baseUrl + "/pool/activity/public/exchange/binance";
                } else if (this.address == 'defy-pool-on-ftx') {
                    api = config.baseUrl + "/pool/activity/public/exchange/ftx";
                } else if (this.address == 'defy-pool-on-kraken') {
                    api = config.baseUrl + "/pool/activity/public/exchange/kraken";
                } else if (this.address == 'defy-pool-on-okex') {
                    api = config.baseUrl + "/pool/activity/public/exchange/okex";
                } else {
                    api = config.baseUrl + "/pool/activity/public/transactions?address=" + this.address;
                }
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.tradeItems = data.result.trade;
                        this.ledgerItems = data.result.ledger;
                        this.loading = false;
                    }else{
                        this.tradeItems = [];
                        this.ledgerItems = [];
                        this.loading = false;
                    }
                }, error => {
                    this.tradeItems = [];
                    this.ledgerItems = [];
                    this.loading = false;
                })
            },
            formatUTCTime(timestamp){
                // 在日期格式中，月份是从0开始的，因此要加0
                // 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11
                var date = new Date(timestamp);
                var year = date.getUTCFullYear();
                var month = date.getUTCMonth() + 1;
                month = month < 10 ? ('0' + month) : month;
                var day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
                var hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
                var minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
                var seconds = date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();
                return month + '-' + day + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
            },
            formatNum(num) {
                if (num == null) return '';
                if (num == NaN || num == 'NaN') return '';
                if (num == 'undefined') return '';
                if (num == '--') return '--';
                let number = num + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return integerPart + decimalPart;
            },
            copy(val) {
                let _this = this;
                this.$copyText(val, this.$refs.container).then(
                    function () {
                        // 成功回调
                        _this.$store.dispatch("snackbarMessageHandler","Copied")
                    },
                    function () {
                        // 失败回调
                        _this.$store.dispatch("snackbarMessageHandler","Failed")
                    }
                );
            },
        },
    }
</script>