<template>
<div>
    <v-container class="grid-list-xl px-0">
        <v-row justify="center" align="center" class="h-100">
            <v-col cols="11" class="h-30">
                <div class="fs-24 gradient3 fi_animate">{{ $t('solutions.forProfessionalTradersAndExchanges') }}</div>
                <div class="fs-100 py-8">{{ $t('solutions.solutions') }}</div>
                <div class="fs-18 dark-gray--text">{{ $t('solutions.openSourceWeb3TradingInfrastructure') }}</div>
                <div class="pt-8 fs-24 dark-gray--text">{{ $t('solutions.bringingDeFiToTheTraditionalFinancialEstablishments') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-16 pt-16">
            <v-col md="6">
            </v-col>
            <v-col md="5" class="fs-24 px-6">
                <div class="fs-24 gradient3 fi_animate pb-6">{{ $t('solutions.introduction') }}</div>
                <div class="fs-68">{{ $t('solutions.prodefy') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-8 pb-250">
            <v-col md="6" class="fs-22 text-right">
                <div class="pb-6 pr-16"><span class="mr-3">{{ $t('solutions.enablingBillions') }}</span><span class="point-icon-3 animate-point-init animate-point-wave mt-2"></span></div>
                <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('solutions.connectingProfessionalGradeTradingSystems') }}</div>
                <div class="pb-6 pr-16"><span class="mr-3">{{ $t('solutions.trueInteroperability') }}</span><span class="point-icon-3 animate-point-init animate-point-wave mt-2"></span></div>
                <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('solutions.unifyingMultiFinancialProtocols') }}</div>
                <div class="pb-6 pr-16"><span class="mr-3">{{ $t('solutions.futureProof') }}</span><span class="point-icon-3 animate-point-init animate-point-wave mt-2"></span></div>
                <div class="fs-18 dark-gray--text pb-8 pr-16 mr-3">{{ $t('solutions.web3StandardCompliance') }}</div>
            </v-col>
            <v-col md="5" class="px-6">
                <div class="fs-18 dark-gray--text pb-8">{{ $t('solutions.decentralizedFinanceSolutions') }}</div>
                <div class="fs-18 dark-gray--text pb-8">{{ $t('solutions.prodefyDevelopment') }}</div>
                <a :href="faq + '/getting-started/faq/#what-is-prodefy'" target="_black" class="del-underline white--text">
                    <v-btn tile class="text-none mt-16 gradient-btn glow">{{ $t('common.learnMore') }}</v-btn>
                </a>
            </v-col>
        </v-row>
    </v-container>
    <div class="bg-gradient-1">
        <v-container class="px-0 pt-250 pb-250">
            <v-row justify="center">
                <v-col md="11" class="text-center">
                    <div class="fs-24 black--text">{{ $t('solutions.roadmap') }}</div>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-70">
                <v-timeline light>
                    <v-timeline-item small left color="#2196F3">
                        <div class="black--text text-right pl-16">{{ $t('solutions.Q1_2021') }}<br>{{ $t('solutions.bridgeBetweenProfessionalWallStreetFirmsAndCryptos') }}</div>
                    </v-timeline-item>
                     <v-timeline-item right small color="#21cbf3">
                        <div class="black--text fs-14 pr-16">{{ $t('solutions.Q2_2021') }}<br>{{ $t('solutions.unifiedTradingPlatform') }}</div>
                    </v-timeline-item>
                    <v-timeline-item left small color="#b6dcfb">
                        <div class="black--text fs-14 text-right pl-16">{{ $t('solutions.Q4_2021') }}<br>{{ $t('solutions.unifiedAssetAndInvestmentManagement') }}</div>
                    </v-timeline-item>
                </v-timeline>
            </v-row>
        </v-container>
    </div>

    <v-container class="px-0">
        <v-row justify="center" class="pt-250">
            <v-col md="11" class="text-center fs-24">
                <div class="fs-24 gradient fi_animate">{{ $t('common.nutshell') }}</div>
                <div class="fs-54 mt-16">{{ $t('solutions.institutionalTraders') }}</div>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-16 pb-500">
            <v-col md="8" class="text-center fs-24">
                <div class="pb-16">{{ $t('solutions.experienced') }}</div>
                <a href="https://t.me/iob_llc" class="del-underline" target="_blank">
                    <v-btn tile class="text-none gradient-btn glow">{{ $t('common.getInvolved') }}</v-btn>
                </a>
            </v-col>
        </v-row>
    </v-container>

</div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
export default {
    data() {
        return {
            faq: config.faq
        }
    },
    created() {
    },
    mounted() {

    },
    computed: {

    },
    watch: {

    },
    methods: {

    },
}
</script>
