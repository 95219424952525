<template>
<div>
    <v-container class="px-0">
         <v-row justify="center" align="center" class="mx-0 pt-150">
            <v-col cols="11" class="px-2">
                <div class="pb-8 text-center">
                    {{ $t('staking.addingLiquidityToTheFIETHPoolOnUniswap') }}
                </div>
                <div class="ml-1 text-center">
                    <a href="https://app.uniswap.org/#/add/v2/ETH/0xF03d05eD4Cf4E723C04500Ab64281BfA591968F2" target="_blank" class="del-underline pointer">
                        <v-btn width="150" tile large class="text-none button--gradient-pink glow-purple">{{ $t('staking.FI_ETH_Staking') }}</v-btn>
                    </a>
                </div>
            </v-col>
            <v-col cols="11" class="px-2 mt-120">
                <div class="pb-8 text-center">
                    {{ $t('staking.buyFITokenDirectly') }}
                    <span class="title_txt">
                        <router-link to="/tokens" class="del-underline pure-red--text">
                            Uniswap
                        </router-link>
                    </span>
                    {{ $t('staking.buyFITokenDirectly2') }}
                </div>
                <div class="ml-1 text-center">
                    <router-link to="/token/0xf03d05ed4cf4e723c04500ab64281bfa591968f2/buy" class="del-underline pointer">
                        <v-btn tile width="150" large class="button--gradient-pink glow-purple text-none">{{ $t('staking.buyFI') }}</v-btn>
                    </router-link>
                </div>
            </v-col>
            <v-col cols="11" class="px-2 mt-120">
                <div class="pb-8 text-center">
                    {{ $t('staking.addingLiquidityToTheDefyHedgedPool') }}{{ (defyAnnualizedReturn * 100).toFixed(2) }}% {{ $t('staking.earned') }}
                </div>
                <div class="ml-1 text-center">
                    <router-link to="/pool/0x01ed27b6cb2c325e85fbcbbafc79fcd111e19e59/buy" class="del-underline pointer">
                        <v-btn tile width="150" large class="button--gradient-pink glow-purple text-none">{{ $t('staking.DEFYInvest') }}</v-btn>
                    </router-link>
                </div>
            </v-col>
        </v-row>
        <v-row  justify="center" align="center" class="pt-150 pb-150">
            <v-col cols="10">
                <div class="fs-12 title_txt text-center dark-gray--text">
                    {{ $t('staking.stakingDescription') }}<br> <a :href="faq + '/products/defy-fund-pool'" target="_blank" class="del-underline pure-red--text">https://docs.iob.fi/products/defy-fund-pool.</a>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row justify="center" align="center" class="mt-16 pb-250 h-100 pl-13 pr-12">
            <v-col cols="10" class="text-center">
                <div>
                    <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf03d05ed4cf4e723c04500ab64281bfa591968f2" target="_blank" class="del-underline pointer">
                            <v-btn width="150" tile large class="text-none button--gradient-pink glow-purple">FI</v-btn>
                    </a>
                </div>
                <div class="mt-16">
                    <router-link to="/staking/defy" class="del-underline pointer">
                            <v-btn tile width="150" large class="button--gradient-pink glow-purple text-none">DEFY</v-btn>
                    </router-link>
                </div>
            </v-col>
        </v-row> -->
    </v-container>
</div>
</template>

<style>

</style>

<script>
import Vue from 'vue'
import { mapGetters } from "vuex";
import config from '@/config.js';
export default {
    data() {
        return {
            faq: config.faq,
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive", "defyAnnualizedReturn"]),
    },
    components: {
        
    },
    watch: {

    },
    methods: {

    },
}
</script>
