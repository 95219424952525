<template>
	<div>
        <v-row>
            <v-col cols="12" v-if="responsive == 'PC'">
                <v-row>
                    <v-col cols="12">
                        <span class="fs-24">{{ $t('pool.managedPools') }}</span>
                    </v-col>
                    <v-col cols="12" class="pa-0 px-3 mb-16">
                        <v-divider class="dark-grey"></v-divider>
                    </v-col>
                </v-row>
                <v-card tile class="mt-5 pa-2 fs-14" id="poolHeader">
                    <v-row align="center">
                        <v-col cols="3" class="pa-0 pl-3 fs-16 dark-gray--title">
                            {{ $t('pool.pool') }}
                        </v-col>
                        <v-col cols="2" class="pa-0 pr-10 text-right dark-gray--title">
                            {{ $t('pool.last30Days') }}
                        </v-col>
                        <v-col cols="2" class="pa-0 text-right dark-gray--title">
                            {{ $t('pool.totalPerformance') }}
                        </v-col>
                        <v-col cols="2" class="pa-0 text-right dark-gray--title">
                            {{ $t('pool.totalValue') }}
                        </v-col>
                        <v-col cols="2" class="pa-0 text-right dark-gray--title">
                            {{ $t('pool.inceptionDate') }}
                        </v-col>
                    </v-row>
                </v-card>
                <v-row>
                    <v-col cols="12" class="">
                        <router-link :to="'/pool/'+item.poolAddress" class="del-underline " v-for="(item, i) in poolAddresses" :key="i">
                            <v-card v-if="!loading" tile class="mb-5 pa-2 fs-14 pure-red-hover--text">
                                <v-row align="center">
                                    <v-col cols="3" class="pa-0 pl-3 fs-16 dark-gray--text" style="display:flex;flex-wrap:wrap;">
                                        <span class="mt-1 mr-2">
                                            <blockchain-network :network="item.blockchainNetwork" :size="5"></blockchain-network>
                                        </span>
                                        <span class="">
                                            <span class="">{{ item.name }}</span>
                                            <span class="">{{ ' (' + item.symbol + ')'}}</span>
                                            <span v-if="item.deprecated"> *</span>
                                            <br/>
                                            <span class="">{{ item.poolAddress.substring(0, 6) + '...' + item.poolAddress.substring(item.poolAddress.length - 4) }}</span>
                                        </span>
                                    </v-col>
                                    <v-col cols="2" class="pa-0">
                                        <div class="float-right pr-10">
                                            <nav-small-line-chart :address="item.poolAddress"></nav-small-line-chart>
                                        </div>
                                    </v-col>
                                    <v-col cols="2" class="pa-0 text-right dark-gray--text">
                                        <span :class="[item.roe == 0? '' : (item.roe > 0 ? 'green--text' : 'red--text')]">{{ item.roe == 0 ? '0' : (item.roe > 0 ? '+' + formatNum((item.roe * 100).toFixed(2)) + '%' : formatNum((item.roe * 100).toFixed(2)) + '%') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="pa-0 text-right dark-gray--text">
                                        {{ item.deprecated ? '--' : ('$' + formatNum(item.value.toFixed(2))) }}
                                    </v-col>
                                    <v-col cols="2" class="pa-0 text-right dark-gray--text">
                                        <span>{{ item.deprecated ? '--' : CommonJS.formatBritishDate(item.startingDate) }}</span>
                                    </v-col>
                                    <v-col cols="1" class="pa-0">
                                       <!-- <v-icon class="dark-gray--text mr-5 float-right">mdi-chevron-right</v-icon> -->
									  <span class="dark-gray--text mr-5 float-right fs-24">
										   <i class="mdi mdi-chevron-right"></i>
									  </span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </router-link>
                        <v-skeleton-loader v-if="loading" tile dense type="image" height="70" class="mb-5" v-for="i in managedPoolInfoQuantity" :key="i"></v-skeleton-loader>
                        <div class="fs-12 dark-gray--text mt-5">
                            * dHEDGE V1 has been deprecated on April 15th, 2024
                        </div>
                    </v-col>
                </v-row>
                <v-data-table v-if="false" :headers="manageHeader" :items="poolAddresses" hide-default-footer disable-sort disable-pagination>
                    <template v-slot:body="{ items }">
                        <tbody v-if="!loading">
                            <tr v-for="(item,index) in items" :key="index" @click="gotoPoolPage(item.poolAddress)" class="pointer" height="70">
                                <td>
                                    <span class="title_txt">
                                        <router-link :to="'/pool/'+item.poolAddress" class="black--text del-underline">
                                            <span class="pure-red--text">{{ item.name }}</span>
                                            <span class="dark-gray--text">{{ ' (' + item.symbol + ')'}}</span>
                                            <span class="dark-gray--text" v-if="item.deprecated"> *</span>
                                        </router-link>
                                    </span><br/>
                                    <span class="title_txt">
                                        <router-link :to="'/pool/'+item.poolAddress" class="black--text del-underline">
                                            <span class="pure-red--text">{{ item.poolAddress.substring(0, 6) + '...' + item.poolAddress.substring(item.poolAddress.length - 4) }}</span>
                                        </router-link>
                                    </span>
                                </td>
                                <td class="px-0 w-100">
                                    <nav-small-line-chart :address="item.poolAddress"></nav-small-line-chart>
                                </td>
                                <td class="text-right" :class="[item.roe == 0? '' : (item.roe > 0 ? 'green--text' : 'red--text')]">{{ item.roe == 0 ? '0' : (item.roe > 0 ? '+' + formatNum((item.roe * 100).toFixed(2)) + '%' : formatNum((item.roe * 100).toFixed(2)) + '%') }}</td>
                                <td class="text-right grey--text">{{ '$'+formatNum(item.value.toFixed(2)) }}</td>
                                <td class="text-right grey--text">{{CommonJS.formatBritishDate(item.startingDate)}}</td>
                                <!-- <td class="text-right">
                                     <span class="title_txt ml-3">
                                        <router-link :to="'/activity/' + item.poolAddress" target="_blank" class="del-underline">
                                            <span class="pure-red--text">{{ item.poolAddress.substring(0,6) + "..." + item.poolAddress.substring(item.poolAddress.length - 4) }} &#8599;</span>
                                        </router-link>
                                    </span>
                                </td> -->
                            </tr>
                        </tbody>
                        <tbody v-if="loading">
                            <tr>
                                <td colspan="5">
                                    <v-skeleton-loader dense type="table-row-divider@4"></v-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" v-if="responsive != 'PC'">
                <v-row>
                    <v-col cols="12" class="pa-0 px-3 mb-5">
                        <h5>{{ $t('pool.managedPools') }}</h5>
                    </v-col>
                    <v-col cols="12" class="pa-0 px-3 mb-5">
                        <v-divider class="dark-grey"></v-divider>
                    </v-col>
                </v-row>
                <v-card tile v-if="!loading" class="mt-5 pa-2" v-for="(item, i) in poolAddresses" :key="i" @click="gotoPoolPage(item.poolAddress)">
                    <v-row>
                        <v-col cols="5" class="pa-0 pl-3 fs-16">
                            <span>{{ item.name }}</span>
                            <span v-if="item.deprecated"> *</span>
                        </v-col>
                        <v-col cols="2" class="pa-0 pt-2">
                            <nav-small-line-chart :address="item.poolAddress"></nav-small-line-chart>
                        </v-col>
                        <v-col cols="4" class="pa-0 pr-3 text-right dark-gray--text">
                            <span :class="[item.roe == 0? '' : (item.roe > 0 ? 'green--text' : 'red--text')]">{{ item.roe == 0 ? '0' : (item.roe > 0 ? '+' + formatNum((item.roe * 100).toFixed(2)) + '%' : formatNum((item.roe * 100).toFixed(2)) + '%') }}</span>
                        </v-col>
                        <v-col cols="1" class="pa-0">
                            <v-icon class="dark-gray--text mb-n4">mdi-chevron-right</v-icon>
                        </v-col>
                        <v-col cols="7" class="pa-0 pl-3 dark-gray--text fs-12">{{ item.symbol }}</v-col>
                        <v-col cols="4" class="pa-0 pr-3 text-right dark-gray--text fs-12">
                            <!-- {{ '$'+formatNum(item.value.toFixed(2)) }} -->
                            {{ item.deprecated ? '--' : (item.trackRecordDays + ' ' + $t('common.days')) }}
                        </v-col>
                        <v-col cols="1"></v-col>
                        <!-- <v-col cols="12" class="pa-0 pl-3 pr-3 mt-3">
                            <v-divider></v-divider>
                        </v-col> -->
                    </v-row>
                </v-card>
                <v-skeleton-loader v-if="loading" tile dense type="image" height="70" class="mt-5" v-for="i in managedPoolInfoQuantity" :key="i"></v-skeleton-loader>
                <div class="fs-12 dark-gray--text mt-5">
                    * dHEDGE V1 has been deprecated on April 15th, 2024
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import config from '@/config.js';
    import { mapGetters} from "vuex";
    import NavSmallLineChart from "@/highcharts/NAVSmallLineChart";
    import BlockchainNetwork from "@/components/common/BlockchainNetwork";
    export default {
        data(){
            return {
                // 池子地址集合
                poolAddresses: [],
                manageHeader:[
                    { text: "Pool" },
                    { text: "Last 30 Days", align:"end", class: "pr-0" },
                    { text: "Total Performance", align:"end" },
                    { text: "Total Value", align:"end" },
                    { text: "Inception Date", align:"end" },
                ],
                loading:false,
                // 池子数量，默认4个，根据接口更新
                managedPoolInfoQuantity: 4
            }
        },
        created(){
            this.getPoolAddresses();
        },
        mounted(){
        },
        components:{
            NavSmallLineChart, BlockchainNetwork
        },
        computed: {
            ...mapGetters(["responsive", "managedPoolInfoList"]),
        },
        watch:{
            managedPoolInfoList: {
                handler(newVal, oldVal){
                    this.managedPoolInfoQuantity = this.managedPoolInfoList.length;
                },
                deep: true
            }
        },
        methods: {
            // 查询池子数据
            getPoolAddresses(){
                this.loading = true;
                this.poolAddresses = [];
                let api = config.baseUrl + "/pool/public/manage/pools";
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.poolAddresses = data.result;
                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                })
            },
            formatNum(num) {
                if (num == null) return '';
                if (num == NaN || num == 'NaN') return '';
                if (num == 'undefined') return '';
                if (num == '--') return '--';
                let number = num + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return integerPart + decimalPart;
            },
            gotoPoolPage(address){
                this.$router.push({ path: '/pool/' + address });
            },
        },
    }
</script>
<style scoped>
.theme--dark .v-data-table,.theme--light .v-data-table {
    background-color: transparent !important;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: none!important;
}
.w-100{
    width: 100px !important;
}
.w-120{
    width: 120px !important;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
    background-color: transparent!important;
}

#poolHeader{
    background-color: transparent!important;
}
.v-card:hover {
    background-color: rgba(97, 97, 97, 0.6);
}
.pure-red-hover--text :hover>div{
    color: #FEE108!important;
}
.pure-red-hover--text :hover>div>span>i{
    color: #FEE108!important;
	caret-color: #FEE108 !important;
}
/* .v-icon :hover  {
	color: #FEE108!important;
} */

</style>