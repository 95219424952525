<template>
    <div>
        <pc-untrading v-if="responsive == 'PC'"></pc-untrading>
        <mobile-untrading v-if="responsive == 'mobile' || responsive == 'pad' "></mobile-untrading>
    </div>
</template>

<style>
</style>

<script>
import Vue from 'vue';
import { mapGetters} from "vuex";
import PcUntrading from "./pc/Untrading";
import MobileUntrading from "./mobile/Untrading";

export default {
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi')
    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive"]),
    },
    components: {
        PcUntrading,
        MobileUntrading,
    },
    watch: {

    },
    methods: {

    },
}
</script>
