<template>
	<div>
        <v-container class="grid-list-xl px-0 mt-120">
            <v-row justify="center" align="center mt-70">
                <v-col cols="11" class="text-center">
                    <span>PC VIEW SUPPORTED ONLY</span>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    export default {
        data(){
            return {

            }
        },
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {

        },
    }
</script>
