<template>
	<div>
        <h5>{{ poolTitle }}</h5>
        <v-divider class="dark-grey mt-6 mb-6"></v-divider>
        <div v-if="responsive == 'PC'">
            <v-data-table :headers="performanceHeaders" dense hide-default-header hide-default-footer class="elevation-1" disable-pagination>
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr class="dark-gray--title">
                            <th><div class="text-right"></div></th>
                            <th><div class="text-right">{{ poolPerformance.name != null ? poolPerformance.name : '--' }}</div></th>
                            <th><div class="text-right">BTC</div></th>
                            <th><div class="text-right">ETH</div></th>
                            <th><div class="text-right">SPX</div></th>
                            <th><div class="text-right">China A50</div></th>
                            <th><div class="text-right">Gold</div></th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="">
                    <tbody>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.sortinoRatio') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.sortinoRatio == null ? '--' : formatNum((poolPerformance.sortinoRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.sortinoRatio == null ? '--' : formatNum((btcPerformance.sortinoRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.sortinoRatio == null ? '--' : formatNum((ethPerformance.sortinoRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.sortinoRatio == null ? '--' : formatNum((spxPerformance.sortinoRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.sortinoRatio == null ? '--' : formatNum((chinaA50Performance.sortinoRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.sortinoRatio == null ? '--' : formatNum((goldPerformance.sortinoRatio).toFixed(4)) }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.maxDrawdown') }}</td>
                            <td class="text-right dark-gray--text" :class="poolPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ poolPerformance.maxDrawdownRatio == null ? '--' : formatNum((poolPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="btcPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ btcPerformance.maxDrawdownRatio == null ? '--' : formatNum((btcPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="ethPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ ethPerformance.maxDrawdownRatio == null ? '--' : formatNum((ethPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="spxPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ spxPerformance.maxDrawdownRatio == null ? '--' : formatNum((spxPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="chinaA50Performance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ chinaA50Performance.maxDrawdownRatio == null ? '--' : formatNum((chinaA50Performance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="goldPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ goldPerformance.maxDrawdownRatio == null ? '--' : formatNum((goldPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.losingStreak') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.maxDrawdownDays == null ? '--' : poolPerformance.maxDrawdownDays }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.maxDrawdownDays == null ? '--' : btcPerformance.maxDrawdownDays }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.maxDrawdownDays == null ? '--' : ethPerformance.maxDrawdownDays }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.maxDrawdownDays == null ? '--' : spxPerformance.maxDrawdownDays }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.maxDrawdownDays == null ? '--' : chinaA50Performance.maxDrawdownDays }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.maxDrawdownDays == null ? '--' : goldPerformance.maxDrawdownDays }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.negativeDays') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.losingStreak == null ? '--' : poolPerformance.losingStreak }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.losingStreak == null ? '--' : btcPerformance.losingStreak }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.losingStreak == null ? '--' : ethPerformance.losingStreak }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.losingStreak == null ? '--' : spxPerformance.losingStreak }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.losingStreak == null ? '--' : chinaA50Performance.losingStreak }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.losingStreak == null ? '--' : goldPerformance.losingStreak }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.maxDrawup') }}</td>
                            <td class="text-right dark-gray--text" :class="poolPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ poolPerformance.maxRiseRatio == null ? '--' : formatNum((poolPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="btcPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ btcPerformance.maxRiseRatio == null ? '--' : formatNum((btcPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="ethPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ ethPerformance.maxRiseRatio == null ? '--' : formatNum((ethPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="spxPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ spxPerformance.maxRiseRatio == null ? '--' : formatNum((spxPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="chinaA50Performance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ chinaA50Performance.maxRiseRatio == null ? '--' : formatNum((chinaA50Performance.maxRiseRatio * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text" :class="goldPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ goldPerformance.maxRiseRatio == null ? '--' : formatNum((goldPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.winningStreak') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.maxRiseDays == null ? '--' : poolPerformance.maxRiseDays }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.maxRiseDays == null ? '--' : btcPerformance.maxRiseDays }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.maxRiseDays == null ? '--' : ethPerformance.maxRiseDays }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.maxRiseDays == null ? '--' : spxPerformance.maxRiseDays }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.maxRiseDays == null ? '--' : chinaA50Performance.maxRiseDays }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.maxRiseDays == null ? '--' : goldPerformance.maxRiseDays }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.positiveDays') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.winningStreak == null ? '--' : poolPerformance.winningStreak }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.winningStreak == null ? '--' : btcPerformance.winningStreak }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.winningStreak == null ? '--' : ethPerformance.winningStreak }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.winningStreak == null ? '--' : spxPerformance.winningStreak }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.winningStreak == null ? '--' : chinaA50Performance.winningStreak }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.winningStreak == null ? '--' : goldPerformance.winningStreak }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.volatility') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.volatility == null ? '--' : formatNum((poolPerformance.volatility * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.volatility == null ? '--' : formatNum((btcPerformance.volatility * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.volatility == null ? '--' : formatNum((ethPerformance.volatility * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.volatility == null ? '--' : formatNum((spxPerformance.volatility * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.volatility == null ? '--' : formatNum((chinaA50Performance.volatility * 100).toFixed(2)) + '%' }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.volatility == null ? '--' : formatNum((goldPerformance.volatility * 100).toFixed(2)) + '%' }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.sharpeRatio') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.sharpeRatio == null ? '--' : formatNum((poolPerformance.sharpeRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.sharpeRatio == null ? '--' : formatNum((btcPerformance.sharpeRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.sharpeRatio == null ? '--' : formatNum((ethPerformance.sharpeRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.sharpeRatio == null ? '--' : formatNum((spxPerformance.sharpeRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.sharpeRatio == null ? '--' : formatNum((chinaA50Performance.sharpeRatio).toFixed(4)) }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.sharpeRatio == null ? '--' : formatNum((goldPerformance.sharpeRatio).toFixed(4)) }}</td>
                        </tr>
                        <tr>
                            <td class="dark-gray--title pl-0">{{ $t('unitPriceMetrics.trackRecord') }}</td>
                            <td class="text-right dark-gray--text">{{ poolPerformance.trackRecordDays == null ? '--' : formatNum(poolPerformance.trackRecordDays) }}</td>
                            <td class="text-right dark-gray--text">{{ btcPerformance.trackRecordDays == null ? '--' : formatNum(btcPerformance.trackRecordDays) }}</td>
                            <td class="text-right dark-gray--text">{{ ethPerformance.trackRecordDays == null ? '--' : formatNum(ethPerformance.trackRecordDays) }}</td>
                            <td class="text-right dark-gray--text">{{ spxPerformance.trackRecordDays == null ? '--' : formatNum(spxPerformance.trackRecordDays) }}</td>
                            <td class="text-right dark-gray--text">{{ chinaA50Performance.trackRecordDays == null ? '--' : formatNum(chinaA50Performance.trackRecordDays) }}</td>
                            <td class="text-right dark-gray--text">{{ goldPerformance.trackRecordDays == null ? '--' : formatNum(goldPerformance.trackRecordDays) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
            <v-row dense class="mt-5">
                <v-col cols="12" class="fs-12">
                    <span class="pointer" @click="feeType = 'before'" :class="feeType == 'before' ? 'underline-gradient pure-red--text' :''">{{ $t('nav.beforeFees') }}</span>
                    <span class="ml-5 pointer" @click="feeType = 'after'" :class="feeType == 'after' ? 'underline-gradient pure-red--text' :''">{{ $t('nav.afterFees') }}</span>
                </v-col>
            </v-row>
        </div>
        <div v-if="responsive == 'mobile' || responsive == 'pad'" class="pb-70">
            <v-row dense class="fs-12">
                <v-col cols="6">
                    <v-select :items="feeTypeList" class="fs-12" item-text="text" item-value="value" v-model="feeType" hide-details dense solo></v-select>
                </v-col>
                <v-col cols="6">
                    <v-select :items="productList" class="fs-12 pb-5" v-model="selectedProduct" hide-details dense solo></v-select>
                </v-col>
            </v-row>
            <v-row dense class="fs-12 pl-5" v-if="selectedProduct == productList[0]">
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sortinoRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.sortinoRatio == null ? '--' : formatNum((poolPerformance.sortinoRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawdown') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="poolPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ poolPerformance.maxDrawdownRatio == null ? '--' : formatNum((poolPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.losingStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.maxDrawdownDays == null ? '--' : poolPerformance.maxDrawdownDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.negativeDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.losingStreak == null ? '--' : poolPerformance.losingStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawup') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="poolPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ poolPerformance.maxRiseRatio == null ? '--' : formatNum((poolPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.winningStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.maxRiseDays == null ? '--' : poolPerformance.maxRiseDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.positiveDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.winningStreak == null ? '--' : poolPerformance.winningStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.volatility') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.volatility == null ? '--' : formatNum((poolPerformance.volatility * 100).toFixed(2)) + '%' }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sharpeRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.sharpeRatio == null ? '--' : formatNum((poolPerformance.sharpeRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.trackRecord') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ poolPerformance.trackRecordDays == null ? '--' : formatNum(poolPerformance.trackRecordDays) }}
                </v-col>
            </v-row>
            <!-- ******************** BTC ********************* -->
            <v-row dense class="pl-5 fs-12" v-if="selectedProduct == productList[1]">
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sortinoRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.sortinoRatio == null ? '--' : formatNum((btcPerformance.sortinoRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawdown') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="btcPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ btcPerformance.maxDrawdownRatio == null ? '--' : formatNum((btcPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.losingStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.maxDrawdownDays == null ? '--' : btcPerformance.maxDrawdownDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.negativeDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.losingStreak == null ? '--' : btcPerformance.losingStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawup') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="btcPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ btcPerformance.maxRiseRatio == null ? '--' : formatNum((btcPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.winningStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.maxRiseDays == null ? '--' : btcPerformance.maxRiseDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.positiveDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.winningStreak == null ? '--' : btcPerformance.winningStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.volatility') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.volatility == null ? '--' : formatNum((btcPerformance.volatility * 100).toFixed(2)) + '%' }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sharpeRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.sharpeRatio == null ? '--' : formatNum((btcPerformance.sharpeRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.trackRecord') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ btcPerformance.trackRecordDays == null ? '--' : formatNum(btcPerformance.trackRecordDays) }}
                </v-col>
            </v-row>
            <!-- ******************** ETH ********************* -->
            <v-row dense class="pl-5 fs-12" v-if="selectedProduct == productList[2]">
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sortinoRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.sortinoRatio == null ? '--' : formatNum((ethPerformance.sortinoRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawdown') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="ethPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ ethPerformance.maxDrawdownRatio == null ? '--' : formatNum((ethPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.losingStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.maxDrawdownDays == null ? '--' : ethPerformance.maxDrawdownDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.negativeDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.losingStreak == null ? '--' : ethPerformance.losingStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawup') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="ethPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ ethPerformance.maxRiseRatio == null ? '--' : formatNum((ethPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.winningStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.maxRiseDays == null ? '--' : ethPerformance.maxRiseDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.positiveDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.winningStreak == null ? '--' : ethPerformance.winningStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.volatility') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.volatility == null ? '--' : formatNum((ethPerformance.volatility * 100).toFixed(2)) + '%' }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sharpeRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.sharpeRatio == null ? '--' : formatNum((ethPerformance.sharpeRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.trackRecord') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ ethPerformance.trackRecordDays == null ? '--' : formatNum(ethPerformance.trackRecordDays) }}
                </v-col>
            </v-row>
            <!-- ******************** SPX ********************* -->
            <v-row dense class="pl-5 fs-12" v-if="selectedProduct == productList[3]">
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sortinoRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.sortinoRatio == null ? '--' : formatNum((spxPerformance.sortinoRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawdown') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="spxPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ spxPerformance.maxDrawdownRatio == null ? '--' : formatNum((spxPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.losingStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.maxDrawdownDays == null ? '--' : spxPerformance.maxDrawdownDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.negativeDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.losingStreak == null ? '--' : spxPerformance.losingStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawup') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="spxPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ spxPerformance.maxRiseRatio == null ? '--' : formatNum((spxPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.winningStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.maxRiseDays == null ? '--' : spxPerformance.maxRiseDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.positiveDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.winningStreak == null ? '--' : spxPerformance.winningStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.volatility') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.volatility == null ? '--' : formatNum((spxPerformance.volatility * 100).toFixed(2)) + '%' }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sharpeRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.sharpeRatio == null ? '--' : formatNum((spxPerformance.sharpeRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.trackRecord') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ spxPerformance.trackRecordDays == null ? '--' : formatNum(spxPerformance.trackRecordDays) }}
                </v-col>
            </v-row>
            <!-- ******************** China A50 ********************* -->
            <v-row dense class="pl-5 fs-12" v-if="selectedProduct == productList[4]">
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sortinoRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.sortinoRatio == null ? '--' : formatNum((chinaA50Performance.sortinoRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawdown') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="chinaA50Performance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ chinaA50Performance.maxDrawdownRatio == null ? '--' : formatNum((chinaA50Performance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.losingStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.maxDrawdownDays == null ? '--' : chinaA50Performance.maxDrawdownDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.negativeDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.losingStreak == null ? '--' : chinaA50Performance.losingStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawup') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="chinaA50Performance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ chinaA50Performance.maxRiseRatio == null ? '--' : formatNum((chinaA50Performance.maxRiseRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.winningStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.maxRiseDays == null ? '--' : chinaA50Performance.maxRiseDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.positiveDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.winningStreak == null ? '--' : chinaA50Performance.winningStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.volatility') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.volatility == null ? '--' : formatNum((chinaA50Performance.volatility * 100).toFixed(2)) + '%' }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sharpeRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.sharpeRatio == null ? '--' : formatNum((chinaA50Performance.sharpeRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.trackRecord') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ chinaA50Performance.trackRecordDays == null ? '--' : formatNum(chinaA50Performance.trackRecordDays) }}
                </v-col>
            </v-row>
            <!-- ******************** Gold ********************* -->
            <v-row dense class="pl-5 fs-12" v-if="selectedProduct == productList[5]">
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sortinoRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.sortinoRatio == null ? '--' : formatNum((goldPerformance.sortinoRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawdown') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="goldPerformance.maxDrawdownRatio > 0 ? 'green--text' : 'red--text'">{{ goldPerformance.maxDrawdownRatio == null ? '--' : formatNum((goldPerformance.maxDrawdownRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.losingStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.maxDrawdownDays == null ? '--' : goldPerformance.maxDrawdownDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.negativeDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.losingStreak == null ? '--' : goldPerformance.losingStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.maxDrawup') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    <span class="text-right" :class="goldPerformance.maxRiseRatio > 0 ? 'green--text' : 'red--text'">{{ goldPerformance.maxRiseRatio == null ? '--' : formatNum((goldPerformance.maxRiseRatio * 100).toFixed(2)) + '%' }}</span>
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.winningStreak') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.maxRiseDays == null ? '--' : goldPerformance.maxRiseDays }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.positiveDays') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.winningStreak == null ? '--' : goldPerformance.winningStreak }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.volatility') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.volatility == null ? '--' : formatNum((goldPerformance.volatility * 100).toFixed(2)) + '%' }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.sharpeRatio') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.sharpeRatio == null ? '--' : formatNum((goldPerformance.sharpeRatio).toFixed(4)) }}
                </v-col>
                <v-col cols="8" class="dark-gray--title ml-n5">{{ $t('unitPriceMetrics.trackRecord') }}</v-col>
                <v-col cols="4" class="text-right dark-gray--text ml-4">
                    {{ goldPerformance.trackRecordDays == null ? '--' : formatNum(goldPerformance.trackRecordDays) }}
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<style scoped>
.theme--dark .v-data-table,.theme--light .v-data-table {
    background-color: transparent !important;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: none!important;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
background-color: transparent!important;
}
.fixed-column {
    position: absolute;
    display: flex;
    align-items: center;
    background: #1E1E1E;
    width: 150px !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    border-radius: 0% !important;
}
.pl-180{
   padding-left:180px!important
}
</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters} from "vuex";
    export default {
        data(){
            return {
                performanceHeaders: [
                    { text: '', align: 'end', sortable: false, width: '150px' },
                    { text: 'DEFY', align: 'end', sortable: false, width: "100px" },
                    { text: 'ioBots Quasar', align: 'end', sortable: false, width: '100px' },
                    { text: 'ioBots BTC+', align: 'end', sortable: false, width: '100px' },
                    { text: 'ioBots ETH+', align: 'end', sortable: false, width: '100px' },
                    { text: 'BTC', align: 'end', sortable: false, width: '100px' },
                    { text: 'ETH', align: 'end', sortable: false, width: '100px' },
                    { text: 'SPX', align: 'end', sortable: false, width: '100px' },
                    { text: 'China A50', align: 'end', sortable: false, width: '100px' },
                    { text: 'Gold', align: 'end', sortable: false, width: '100px' },
                ],
                poolPerformance: [],
                btcPerformance: [],
                ethPerformance: [],
                spxPerformance: [],
                chinaA50Performance: [],
                goldPerformance: [],
                selectedProduct: null,
                feeType:"before",
                poolTitle:null,
                feeTypeList:[
                    {text:this.$t('nav.beforeFees'),value:"before"},
                    {text:this.$t('nav.afterFees'),value:"after"},
                ],
            }
        },
        props: ['address'],
        created(){

        },
        mounted(){
            this.getPriceMetrics();
            this.getBTCPerformance();
            this.getETHPerformance();
            this.getSPXPerformance();
            this.getChinaA50Performance();
            this.getGoldPerformance();
        },
        computed: {
            ...mapGetters(["responsive"]),
            productList(){
                return [this.poolPerformance.name, 'BTC', 'ETH', 'SPX', 'China A50', 'Gold']
            },
        },
        watch:{
            address(val){
                this.selectedProduct = null;
                this.getPriceMetrics();
                this.getBTCPerformance();
                this.getETHPerformance();
                this.getSPXPerformance();
                this.getChinaA50Performance();
                this.getGoldPerformance();
            },
            feeType(val){
                this.getPriceMetrics();
            }
        },
        methods: {
            getPriceMetrics() {
                if (this.address.toLowerCase() == "0xca38034a69ebedd28ce62368890e7cf187309a0f") {
                    
                    if(this.feeType == 'before'){
                        this.getFeeAdjustedDefyPriceMetrics();
                    } else if(this.feeType == 'after'){
                        this.getRawDefyPriceMetrics();
                    }
                } else {
                    
                    if(this.feeType == 'before'){
                        this.getFeeAdjustedPoolPriceMetrics();
                    } else if(this.feeType == 'after'){
                        this.getRawPoolPriceMetrics();
                    }
                }
            },
            // 查询调整fee的DEFY价格指标数据
            getFeeAdjustedDefyPriceMetrics() {
                let api = config.baseUrl + "/defy/public/price-metrics/fee-adjusted";
                this.poolPerformance = [];
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.poolPerformance = data.result;
                        this.poolTitle = this.poolPerformance.name + " " + this.$t('unitPriceMetrics.feeAdjustedUnitPriceMetrics');
                        if (this.selectedProduct == null) {
                            this.selectedProduct = this.poolPerformance.name;
                        }
                    } else {
                        this.poolPerformance = [];
                    }
                })
            },
            // 查询原始DEFY价格指标数据
            getRawDefyPriceMetrics() {
                let api = config.baseUrl + "/defy/public/price-metrics";
                this.poolPerformance = [];
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.poolPerformance = data.result;
                        this.poolTitle = this.poolPerformance.name + " " + this.$t('unitPriceMetrics.unitPriceMetrics');
                        if (this.selectedProduct == null) {
                            this.selectedProduct = this.poolPerformance.name;
                        }
                    } else {
                        this.poolPerformance = [];
                    }
                })
            },
            // 查询调整fee的池子业绩数据
            getFeeAdjustedPoolPriceMetrics(){
                let api = config.baseUrl + "/pool/public/price-metrics/fee-adjusted/" + this.address.toLowerCase();
                this.poolPerformance = [];
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.poolPerformance = data.result;
                        this.poolTitle = this.poolPerformance.name + " " + this.$t('unitPriceMetrics.feeAdjustedUnitPriceMetrics');
                        if (this.selectedProduct == null) {
                            this.selectedProduct = this.poolPerformance.name;
                        }
                    } else {
                        this.poolPerformance = [];
                    }
                })
            },
            // 查询原始的池子业绩数据
            getRawPoolPriceMetrics(){
                let api = config.baseUrl + "/pool/public/performance/" + this.address.toLowerCase();
                this.poolPerformance = [];
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.poolPerformance = data.result;
                        this.poolTitle = this.poolPerformance.name + " " + this.$t('unitPriceMetrics.unitPriceMetrics');
                        if (this.selectedProduct == null) {
                            this.selectedProduct = this.poolPerformance.name;
                        }
                    } else {
                        this.poolPerformance = [];
                    }
                })
            },
            // 查询BTC业绩数据
            getBTCPerformance() {
                let api = config.baseUrl + "/asset/public/performance/btc?versusPoolAddress=" + this.address.toLowerCase();
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.btcPerformance = data.result;
                    } else {
                        this.btcPerformance = [];
                    }
                })
            },
            // 查询ETH业绩数据
            getETHPerformance() {
                let api = config.baseUrl + "/asset/public/performance/eth?versusPoolAddress=" + this.address.toLowerCase();
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.ethPerformance = data.result;
                    } else {
                        this.ethPerformance = [];
                    }
                })
            },
            // 查询SPX业绩数据
            getSPXPerformance() {
                let api = config.baseUrl + "/asset/public/performance/spx?versusPoolAddress=" + this.address.toLowerCase();
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.spxPerformance = data.result;
                    } else {
                        this.spxPerformance = [];
                    }
                })
            },
            // 查询ChinaA50业绩数据
            getChinaA50Performance() {
                let api = config.baseUrl + "/asset/public/performance/chinaa50?versusPoolAddress=" + this.address.toLowerCase();
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.chinaA50Performance = data.result;
                    } else {
                        this.chinaA50Performance = [];
                    }
                })
            },
            // 查询Gold业绩数据
            getGoldPerformance() {
                let api = config.baseUrl + "/asset/public/performance/gold?versusPoolAddress=" + this.address.toLowerCase();
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.goldPerformance = data.result;
                    } else {
                        this.goldPerformance = [];
                    }
                })
            },
            formatNum(num) {
                if (num == null) return '';
                if (num == NaN || num == 'NaN') return '';
                if (num == 'undefined') return '';
                if (num == '--') return '--';
                let number = num + '';
                let numberArray = number.split('.');
                let integerPart = numberArray[0];
                let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(integerPart)) {
                    integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
                }
                return integerPart + decimalPart;
            },
        },
    }
</script>
