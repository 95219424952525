<template>
<div>
    <!-- 20220705 删除 FI Logo -->
    <!-- <v-container class="py-0 px-0" v-if="responsive == 'PC'">
        <v-row align="center">
            <v-col cols="12" class="mt-10 ml-5">
                <span class="pb-3 pr-10" @click="goHome()">
                    <a href="/" class="del-underline">
                        <logo type="lines" color="fi"></logo>
                    </a>
                </span>
            </v-col>
        </v-row>
    </v-container>
    <v-container class="px-0 py-0" v-if="responsive == 'mobile'">
        <v-row align="center" class="ml-2">
            <v-col cols="5" class="pb-0 pt-10 mt-15">
                <div class="pb-3" @click="goHome()">
                    <a href="/" class="del-underline">
                        <logo type="lines01" color="fi01"></logo>
                    </a>
                </div>
            </v-col>
        </v-row>
    </v-container>
    <v-container class="px-0 py-0" v-if="responsive == 'pad'">
        <v-row align="center" class="pl-4">
            <v-col sm="5" class="pb-0 pt-10 mt-15">
                <div class="pb-3" @click="goHome()">
                    <a href="/" class="del-underline">
                        <logo type="lines02" color="fi02"></logo>
                    </a>
                </div>
            </v-col>
        </v-row>
    </v-container> -->
    <div class="footer-gradient">
        <v-container class="px-0 py-0">
            <v-row v-if="responsive == 'PC'" class="justify-space-between pl-13">
                <!-- 20220705 删除 Gas Price -->
                <!-- <v-col cols="12" class="pt-10">
                    <div class="black--text fs-12 pb-3">Gas {{ $t('common.price') }} (Gwei)</div>
                </v-col>
                <v-col cols="5" class="pt-0 mt-0 pr-16">
                    <a href="https://ethgasstation.info" target="_black" class="del-underline">
                        <v-divider class="black"></v-divider>
                        <v-row dense no-gutters align="center" class="px-1">
                            <v-col cols="4" class="fs-12 black--text">
                                {{ $t('footer.standard') }}
                            </v-col>
                            <v-col cols="4" class="fs-12 black--text text-center fw-bold">
                                {{ gasPriceGwei }}
                            </v-col>
                            <v-col cols="4" class="fs-12 black--text text-right">
                                ${{ gasPriceGweiDoller }} | {{ gasPriceGweiWaitTime }} {{ $t('footer.minutes') }}
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                        <v-divider class="black"></v-divider>
                    </a>
                </v-col> -->
                <v-col cols="10" class="mt-16 ml-0">
                    <div class="fs-14 pb-3 title_txt" @click="goHome()">
                        <a href="/" class="black--text del-underline">{{ $t('common.home') }}</a>
                    </div>
                    <div class="fs-14 pb-3 title_txt">
                        <router-link to="/investments" class="black--text del-underline">
                            {{ $t('footer.myInvestments') }}
                        </router-link>
                    </div>
                    <div class="fs-14 pb-3 title_txt" v-for="item in poolItems">
                        <router-link :to="'/pool/'+ item.poolAddress" class="black--text del-underline">
                            {{ item.name }}
                        </router-link>
                    </div>
                    <div class="fs-14 pb-3 title_txt black--text">
                        <div @click="aboutOpen = !aboutOpen" class="pointer">
                            <router-link to="">
                                <span class="black--text">{{ $t('common.about') }}</span>
                                <v-icon class="black--text" size="20" v-if="!aboutOpen">mdi mdi-chevron-down</v-icon>
                                <v-icon class="black--text" size="20" v-if="aboutOpen">mdi mdi-chevron-up</v-icon>
                            </router-link>
                        </div>
                        <div v-if="aboutOpen">
                            <div class="fs-14 pb-3 title_txt mt-2" @click="goAbout()">
                                <router-link to="/about" class="black--text del-underline">{{ $t('common.about') }}</router-link>
                            </div> 
                            <div class="fs-14 pb-3 title_txt" @click="goPools()">
                                <router-link to="/pools" class="black--text del-underline">{{ $t('common.pools') }}</router-link>
                            </div>
                            <div class="fs-14 pb-3 title_txt" @click="goSolutions()">
                                <router-link to="/solutions" class="black--text del-underline">{{ $t('common.solutions') }}</router-link>
                            </div>
                            <div class="fs-14 pb-3 title_txt" @click="goTokens()">
                                <router-link to="/tokens" class="black--text del-underline">{{ $t('common.tokens') }}</router-link>
                            </div>
                            <div class="fs-14 pb-3 title_txt" @click="goDAO()">
                                <router-link to="/dao" class="black--text del-underline">{{ $t('common.decentralizedAutonomousOrganizations') }}</router-link>
                            </div>
                            <div class="fs-14 pb-3 title_txt">
                                <a :href="faq" target="_blank" class="black--text del-underline">{{ $t('common.docs') }}</a>
                            </div>
                            <div class="fs-14 title_txt">
                                <a :href="faq + '/getting-started/faq#who-is-behind-iob-fi-dao'" target="_blank" class="black--text del-underline">
                                    {{ $t('common.team') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="fs-14 pb-3 title_txt">
                        <a :href="faq + '/getting-started/faq'" target="_blank" class="black--text del-underline">{{ $t('common.faq') }}</a>
                    </div>
                    <div class="fs-14 pb-16 title_txt">
                        <a :href="fund" target="_blank" class="black--text del-underline">iobots.pro</a>
                    </div>
                    <div class="fs-14 pb-3 title_txt">
                        <a href="https://discord.com/channels/748595728208625682/748595728208625685" target="_black" class="black--text del-underline">
                            <span class="mr-2">discord</span><i class="icon icon-discord"></i>
                        </a>
                    </div>
                    <div class="fs-14 pb-3 title_txt">
                        <a href="https://twitter.com/IOB_llc" target="_black" class="black--text del-underline">
                            <span class="mr-3">twitter</span><i class="icon icon-twitter"></i>
                        </a>
                    </div>
                    <div class="fs-14 pb-3 title_txt">
                        <a href="https://github.com/IOBteam/iob.fi" target="_black" class="black--text del-underline">
                            <span class="mr-3">github</span><i class="icon icon-github"></i>
                        </a>
                    </div>
                    <div class="fs-14 pb-3">
                        <github></github>
                    </div>
                    <div class="pb-3">
                        <span class="mr-2 fs-14 black--text">IOB © 2020 - {{ CommonJS.getCurrentYear() }}</span>
                        <span class="title_txt">
                            <a :href="faq + '/getting-started/legal-notice-and-disclaimer/privacy-statement'" target="_blank" class="fs-14 mr-2 del-underline black--text">{{ $t('footer.privacyAndLegal') }}</a>
                        </span>
                        <span class="title_txt">
                            <a :href="faq + '/getting-started/legal-notice-and-disclaimer/terms-and-conditions'" class="fs-14 del-underline black--text mr-2" target="_blank">
                                {{ $t('footer.terms') }}
                            </a>
                        </span>
                    </div>
                    <div class="mr-2 fs-14 black--text">
                        {{ version + ' | ' + iobotsVersion }}
                    </div>
                    <div class="pt-16">
                        <v-menu open-on-hover :nudge-width="70" :nudge-bottom="20" :max-height="350" :min-width="120" class="z-index">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="pointer fs-14 black--text">
                                <span class="mr-3">{{ getLanguageText(languageModel) }}</span><i class="mdi mdi-google-translate"></i>
                            </span>
                          </template>
                          <v-list>
                            <v-list-item v-for="(languageObject, i) in languageItems" :key="i" @click="languageModel = languageObject.value">
                              <v-list-item-title>{{ languageObject.text }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                    </div>
                </v-col>
            </v-row>
            <!-- 20220705 删除 Gas Price -->
            <!-- <v-row dense align="center" justify="center" v-if="responsive == 'mobile'" class="ml-6 mr-10">
                <v-col cols="12" class="pt-10">
                    <div class="black--text fs-12 pb-3">Gas {{ $t('common.price') }} (Gwei)</div>
                </v-col>
                <v-col cols="12" class="pt-0 mt-0">
                    <a href="https://ethgasstation.info" target="_black" class="del-underline">
                        <v-divider class="black"></v-divider>
                        <v-row dense no-gutters align="center">
                            <v-col cols="4" class="fs-12 black--text">
                                {{ $t('footer.standard') }}
                            </v-col>
                            <v-col cols="4" class="fs-12 black--text text-center fw-bold">
                                {{ gasPriceGwei }}
                            </v-col>
                            <v-col cols="4" class="fs-12 black--text text-right">
                                ${{ gasPriceGweiDoller }} | {{ gasPriceGweiWaitTime }} {{ $t('footer.minutes') }}
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                        <v-divider class="black"></v-divider>
                    </a>
                </v-col>
            </v-row> -->
            <!-- 20220705 删除 Gas Price -->
            <!-- <v-row dense align="center" v-if="responsive == 'pad'" class="ml-10 mr-3">
                <v-col sm="12" class="pt-10">
                    <div class="black--text fs-12 pb-3">Gas Price (Gwei)</div>
                </v-col>
                <v-col sm="12" class="pt-0 mt-0">
                    <a href="https://ethgasstation.info" target="_black" class="del-underline">
                        <v-divider class="black"></v-divider>
                        <v-row dense no-gutters align="center">
                            <v-col cols="4" class="fs-12 black--text">
                                {{ $t('footer.standard') }}
                            </v-col>
                            <v-col cols="4" class="fs-12 black--text text-center fw-bold">
                                {{ gasPriceGwei }}
                            </v-col>
                            <v-col cols="4" class="fs-12 black--text text-right">
                                ${{ gasPriceGweiDoller }} | {{ gasPriceGweiWaitTime }} {{ $t('footer.minutes') }}
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                        <v-divider class="black"></v-divider>
                    </a>
                </v-col>
            </v-row> -->
            <v-row align="center" v-if="responsive == 'pad'" class="ml-9">
                <v-col cols="11" class="pt-10">
                    <div class="fs-12 pb-3 title_txt" @click="goHome()">
                        <a href="/" class="black--text del-underline">{{ $t('common.home') }}</a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <router-link to="/investments" class="black--text del-underline">
                            {{ $t('footer.myInvestments') }}
                        </router-link>
                    </div>
                    <div class="fs-12 pb-3 title_txt" v-for="item in poolItems">
                        <router-link :to="'/pool/'+ item.poolAddress" class="black--text del-underline">
                            {{ item.name }}
                        </router-link>
                    </div>
                    <div class="fs-12 pb-3 title_txt black--text">
                        <div @click="aboutOpen = !aboutOpen">
                            <span>{{ $t('common.about') }}</span>
                            <v-icon class="black--text" size="20" v-if="!aboutOpen">mdi mdi-chevron-down</v-icon>
                            <v-icon class="black--text" size="20" v-if="aboutOpen">mdi mdi-chevron-up</v-icon>
                        </div>
                        <div v-if="aboutOpen">
                            <div class="fs-12 pb-3 title_txt mt-2" @click="goAbout()">
                                <router-link to="/about" class="black--text del-underline">{{ $t('common.about') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goPools()">
                                <router-link to="/pools" class="black--text del-underline">{{ $t('common.pools') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goSolutions()">
                                <router-link to="/solutions" class="black--text del-underline">{{ $t('common.solutions') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goTokens()">
                                <router-link to="/tokens" class="black--text del-underline">{{ $t('common.tokens') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goDAO()">
                                <router-link to="/dao" class="black--text del-underline">{{ $t('common.decentralizedAutonomousOrganizations') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt">
                                <a :href="faq" target="_blank" class="black--text del-underline">{{ $t('common.docs') }}</a>
                            </div>
                            <div class="fs-12 title_txt">
                                <a :href="faq + '/getting-started/faq#who-is-behind-iob-fi-dao'" target="_blank" class="black--text del-underline">
                                    {{ $t('common.team') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a :href="faq + '/getting-started/faq'" target="_blank" class="black--text del-underline">{{ $t('common.faq') }}</a>
                    </div>
                    <div class="fs-12 pb-10 title_txt">
                        <a :href="fund" target="_blank" class="black--text del-underline">iobots.pro</a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a href="https://discord.com/channels/748595728208625682/748595728208625685" target="_black" class="black--text del-underline">
                            <span class="mr-2">discord</span><i class="icon icon-discord"></i>
                        </a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a href="https://twitter.com/IOB_llc" target="_black" class="black--text del-underline">
                            <span class="mr-3">twitter</span><i class="icon icon-twitter"></i>
                        </a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a href="https://github.com/IOBteam/iob.fi" target="_black" class="black--text del-underline">
                            <span class="mr-3">github</span><i class="icon icon-github"></i>
                        </a>
                    </div>
                    <div class="fs-12">
                        <github></github>
                    </div>
                </v-col>
            </v-row>
            <v-row align="center" v-if="responsive == 'mobile'" class="ml-4">
                <v-col cols="11" class="pt-10">
                    <div class="fs-12 pb-3 title_txt" @click="goHome()">
                        <a href="/" class="black--text del-underline">{{ $t('common.home') }}</a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <router-link to="/investments" class="black--text del-underline">
                            {{ $t('footer.myInvestments') }}
                        </router-link>
                    </div>
                    <div class="fs-12 pb-3 title_txt" v-for="item in poolItems">
                        <router-link :to="'/pool/'+ item.poolAddress" class="black--text del-underline">
                            {{ item.name }}
                        </router-link>
                    </div>
                    <div class="fs-12 pb-3 title_txt black--text">
                        <div @click="aboutOpen = !aboutOpen">
                            <span>{{ $t('common.about') }}</span>
                            <v-icon class="black--text" size="20" v-if="!aboutOpen">mdi mdi-chevron-down</v-icon>
                            <v-icon class="black--text" size="20" v-if="aboutOpen">mdi mdi-chevron-up</v-icon>
                        </div>
                        <div v-if="aboutOpen">
                            <div class="fs-12 pb-3 title_txt mt-2" @click="goAbout()">
                                <router-link to="/about" class="black--text del-underline">{{ $t('common.about') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goPools()">
                                <router-link to="/pools" class="black--text del-underline">{{ $t('common.pools') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goSolutions()">
                                <router-link to="/solutions" class="black--text del-underline">{{ $t('common.solutions') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goTokens()">
                                <router-link to="/tokens" class="black--text del-underline">{{ $t('common.tokens') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt" @click="goDAO()">
                                <router-link to="/dao" class="black--text del-underline">{{ $t('common.decentralizedAutonomousOrganizations') }}</router-link>
                            </div>
                            <div class="fs-12 pb-3 title_txt">
                                <a :href="faq" target="_blank" class="black--text del-underline">{{ $t('common.docs') }}</a>
                            </div>
                            <div class="fs-12 title_txt">
                                <a :href="faq + '/getting-started/faq#who-is-behind-iob-fi-dao'" target="_blank" class="black--text del-underline">
                                    {{ $t('common.team') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a :href="faq + '/getting-started/faq'" target="_blank" class="black--text del-underline">{{ $t('common.faq') }}</a>
                    </div>
                    <div class="fs-12 pb-10 title_txt">
                        <a :href="fund" target="_blank" class="black--text del-underline">iobots.pro</a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a href="https://discord.com/channels/748595728208625682/748595728208625685" target="_black" class="black--text del-underline">
                            <span class="mr-2">discord</span><i class="icon icon-discord"></i>
                        </a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a href="https://twitter.com/IOB_llc" target="_black" class="black--text del-underline">
                            <span class="mr-3">twitter</span><i class="icon icon-twitter"></i>
                        </a>
                    </div>
                    <div class="fs-12 pb-3 title_txt">
                        <a href="https://github.com/IOBteam/iob.fi" target="_black" class="black--text del-underline">
                            <span class="mr-3">github</span><i class="icon icon-github"></i>
                        </a>
                    </div>
                    <div class="fs-12">
                        <github></github>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="px-0 pt-0 mt-0 pb-70">
            <v-row align="center" v-if="responsive == 'PC'" class="pl-13">
                <v-col sm="11" class="mt-10">
                    <span>
                        <a href="https://ethereum.org/en/" class="del-underline" target="_blank">
                            <!-- <v-card tile flat light outlined max-width="200" class="pa-2 text-center"> -->
                            <img src="https://files.iob.fi/2020/11/1605670462910-b5237a576fad53fca61d1aec11ff28c5-eth.webp" alt="">
                            <!-- </v-card> -->
                        </a>
                    </span>
                </v-col>
                <!-- 20220706 删除DEFI和Aragon的Logo -->
                <!-- <v-col sm="11">
                    <span>
                        <a href="https://defi.network/" class="del-underline" target="_blank">
                            <img src="https://files.iob.fi/2020/11/1605670455907-19c90c0054115b0381105d3deab18962-defy.webp" alt="">
                        </a>
                    </span>
                </v-col>
                <v-col sm="11">
                    <span>
                        <a href="https://poweredby.aragon.org/" class="del-underline" target="_blank">
                            <img src="https://files.iob.fi/2020/11/1605670507936-02caaf59698b51bca4ac662b373321bb-aragon.webp" alt="">
                        </a>
                    </span>
                </v-col> -->
            </v-row>
            <v-row align="center" v-if="responsive == 'pad'" class="pl-9 mt-3">
                <v-col sm="11" class="black--text pl-6">
                    <div class="pb-3">
                        <span class="fs-12 mr-2">IOB © 2020 - {{ CommonJS.getCurrentYear() }}</span>
                        <span class="title_txt">
                            <a :href="faq + '/getting-started/legal-notice-and-disclaimer/privacy-statement'" target="_blank" class="fs-12 mr-2 del-underline black--text">{{ $t('footer.privacyAndLegal') }}</a>
                        </span>
                        <span class="title_txt">
                            <a :href="faq + '/getting-started/legal-notice-and-disclaimer/terms-and-conditions'" class="fs-12 del-underline black--text mr-2" target="_blank">
                            {{ $t('footer.terms') }}
                        </a>
                        </span>
                    </div>
                    <div class="fs-12 mr-2">{{ version + ' | ' + iobotsVersion }}</div>
                    <div class="pt-16">
                        <v-menu open-on-hover :nudge-width="70" :nudge-bottom="20" :max-height="350" :min-width="120" class="z-index">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="pointer fs-14 black--text">
                                <span class="mr-3">{{ getLanguageText(languageModel) }}</span><i class="mdi mdi-google-translate"></i>
                            </span>
                          </template>
                          <v-list>
                            <v-list-item v-for="(languageObject, i) in languageItems" :key="i" @click="languageModel = languageObject.value">
                              <v-list-item-title>{{ languageObject.text }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                    </div>
                </v-col>
                <v-col sm="11" class="pl-6 mt-10">
                    <span>
                        <a href="https://ethereum.org/en/" class="del-underline" target="_blank">
                            <!-- <v-card tile light outlined max-width="200" class="pa-2 text-center">
                                <logo type="eth" color="eth"></logo>
                            </v-card> -->
                            <img src="https://files.iob.fi/2020/11/1605670462910-b5237a576fad53fca61d1aec11ff28c5-eth.webp" alt="">
                        </a>
                    </span>
                </v-col>
                <!-- 20220706 删除DEFI和Aragon的Logo -->
                <!-- <v-col sm="11" class="pl-6">
                    <span>
                        <a href="https://defi.network/" class="del-underline" target="_blank">
                            <img src="https://files.iob.fi/2020/11/1605670455907-19c90c0054115b0381105d3deab18962-defy.webp" alt="">
                        </a>
                    </span>
                </v-col>
                <v-col sm="11" class="pl-6">
                    <span>
                        <a href="https://poweredby.aragon.org/" class="del-underline" target="_blank">
                            <img src="https://files.iob.fi/2020/11/1605670507936-02caaf59698b51bca4ac662b373321bb-aragon.webp" alt="">
                        </a>
                    </span>
                </v-col> -->
            </v-row>
            <v-row align="center" v-if="responsive == 'mobile'" class="ml-4 mt-3">
                <v-col cols="11" class="black--text">
                    <div class="pb-3">
                        <span class="fs-12 mr-2">IOB © 2020 - {{ CommonJS.getCurrentYear() }}</span>
                        <span class="title_txt">
                            <a :href="faq + '/getting-started/legal-notice-and-disclaimer/privacy-statement'" target="_blank" class="fs-12 mr-2 del-underline black--text">{{ $t('footer.privacyAndLegal') }}</a>
                        </span>
                        <span class="title_txt">
                            <a :href="faq + '/getting-started/legal-notice-and-disclaimer/terms-and-conditions'" class="fs-12 del-underline black--text mr-2" target="_blank">
                            {{ $t('footer.terms') }}
                        </a>
                        </span>
                    </div>
                    <div class="fs-12 mr-2">{{ version + ' | ' + iobotsVersion }}</div>
                    <div class="pt-16">
                        <v-menu open-on-hover :nudge-width="70" :nudge-bottom="20" :max-height="350" :min-width="120" class="z-index">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="pointer fs-14 black--text">
                                <span class="mr-3">{{ getLanguageText(languageModel) }}</span><i class="mdi mdi-google-translate"></i>
                            </span>
                          </template>
                          <v-list>
                            <v-list-item v-for="(languageObject, i) in languageItems" :key="i" @click="languageModel = languageObject.value">
                              <v-list-item-title>{{ languageObject.text }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                    </div>
                </v-col>
                <v-col cols="11" class="pl-3 mt-10">
                    <span>
                        <a href="https://ethereum.org/en/" class="del-underline" target="_blank">
                            <img src="https://files.iob.fi/2020/11/1605670462910-b5237a576fad53fca61d1aec11ff28c5-eth.webp" alt="">
                        </a>
                    </span>
                </v-col>
                <!-- 20220706 删除DEFI和Aragon的Logo -->
                <!-- <v-col cols="11" class="pl-3">
                    <span>
                        <a href="https://defi.network/" class="del-underline" target="_blank">
                            <img src="https://files.iob.fi/2020/11/1605670455907-19c90c0054115b0381105d3deab18962-defy.webp" alt="">
                        </a>
                    </span>
                </v-col>
                <v-col cols="11" class="pl-3">
                    <span>
                        <a href="https://poweredby.aragon.org/" class="del-underline" target="_blank">
                            <img src="https://files.iob.fi/2020/11/1605670507936-02caaf59698b51bca4ac662b373321bb-aragon.webp" alt="">
                        </a>
                    </span>
                </v-col> -->
            </v-row>

        </v-container>
    </div>

</div>
</template>

<style>

</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
import Logo from '@/components/common/Logo';
import Github from '@/components/common/Github';
import { mapGetters } from "vuex";
import Bus from "@/components/bus/Bus";
export default {
    data() {
        return {
            domain: config.domainName,
            sip: config.sip,
            fund: config.fund,
            faq: config.faq,
            environment: config.environment,
            // Gas
            gasPriceGwei: '--',
            // 美元价格
            gasPriceGweiDoller: '--',
            // 等待时间：默认3分钟
            gasPriceGweiWaitTime: 3,
            gasPriceInterval: null,
            aboutOpen:false,
            poolItems:[],
            // 当前的语言环境
            languageModel: this.$store.getters.locale,
            // 语言环境选择框
            languageItems: [
               { text: 'English (US)', value: 'en-US' },
               { text: '中文（简体）', value: 'zh-CN' }
            ]
        }
    },
    components: {
        Logo,
        Github
    },
    created() {

    },
    mounted() {
        // 20220705 删除 Gas Price
        // this.getGasPrice();
        // let time = 30000;
        // if(this.environment == 'Development'){
        //     // 开发环境,60秒,测试/生产环境30秒
        //     time = 60000;
        // }
        // this.gasPriceInterval = setInterval(() => {
        //     this.getGasPrice();
        // }, time);
        Bus.$on('menuPools', data => {
            this.poolItems = data;
        });
    },
    computed: {
        ...mapGetters(["responsive", "countryCode", "version", "iobotsVersion"])
    },
    watch: {
        languageModel(newVal, oldVal) {
            this.changeLanguage(newVal);
        },
    },
    methods: {
        // 查询语言
        getLanguageText(locale){
            for(let i = 0, len = this.languageItems.length; i < len; i++){
                if (this.languageItems[i].value == this.languageModel) {
                    return this.languageItems[i].text;
                }
            }
        },
        // 切换语言
        changeLanguage(locale) {
            this.$i18n.locale = locale;
            this.$store.dispatch("localeHandler", locale);
            this.$router.go();
        },
        goHome() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/home.md')
        },
        goAbout() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/about.md')
        },
        goPools() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/pools.md')
        },
        goSolutions() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/solutions.md')
        },
        goTokens() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/tokens.md')
        },
        goDAO() {
            this.$store.dispatch('githubLinkHandler', 'https://github.com/IOBteam/iob.fi/blob/master/DAO.md')
        },
        getGasPrice() {
            let api = 'https://ethgasstation.info/api/ethgasAPI.json';
            let ethApi = 'https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=ethereum';
            this.$http.get(api).then(response => {
                let data = response.data;
                if (data.average) {
                    // 请求数据成功
                    this.gasPriceGwei = (data.average / 10).toFixed(0);
                    this.gasPriceGweiWaitTime = data.avgWait;
                    // 同步到Vuex
                    this.$store.dispatch('gasPriceGweiHandler', this.gasPriceGwei);
                    this.$http.get(ethApi).then(response => {
                        let ethData = response.data;
                        if (ethData.ethereum) {
                            // 请求数据成功
                            let ethPrice = Number(ethData.ethereum.usd);
                            // 缓存eth价格
                            this.$store.dispatch('ethPriceHandler', ethPrice);
                            // 计算gas费
                            this.gasPriceGweiDoller = (Number(this.gasPriceGwei) * Math.pow(10, -9) * 21000 * ethPrice).toFixed(2);
                        }
                    }, error => {

                    });
                } else {
                    // 请求数据失败
                    this.gasPriceGwei = '--';
                    this.gasPriceGweiDoller = '--';
                    this.gasPriceGweiWaitTime = 3;
                }
            }, error => {
                // 请求数据失败
                this.gasPriceGwei = '--';
                this.gasPriceGweiDoller = '--';
                this.gasPriceGweiWaitTime = 3;
            })
        }
    },
    beforeDestroy() {
        clearInterval(this.gasPriceInterval); //清除计时器
        this.gasPriceInterval = null; //设置为null
    }
}
</script>
